import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import { Button } from '@blueprintjs/core';
import sub from 'date-fns/sub';
// Components
import Panel from '../../../components/panel/Panel';
import TimeFrameSelect from './time-frame-select/TimeFrameSelect';
import ProcessingTimeBarChart from './charts/ProcessingTimeChart';
import BackupIcon from 'src/components/icons/BackupIcon';
// Actions
import { getData } from '../../../redux/slices/dashboard/charts/thunk';
import { reset as resetState } from '../../../redux/slices/dashboard/charts/chartsSlice';
// Selectors
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
import { selectDashboardCharts } from '../../../redux/slices/dashboard/charts/selectors';
// Utils
import { filterByDateRange } from './charts/utils';
// Types
import { RequestStatus } from '../../../redux/slices/types';
import { IDateRange } from 'src/redux/slices/dashboard/charts/types';

import './styles.scss';

const options = [
  { hours: 1 },
  { hours: 6 },
  { hours: 12 },
  { days: 1 },
  { weeks: 1 },
  { months: 1 },
];

const ProcessingTimeChartContainer: FC = () => {
  const { data: connector } = useSelector(selectConnectorState);
  const { data, loading } = useSelector(selectDashboardCharts);

  const [dateRange, setDateRange] = useState<IDateRange>([
    sub(new Date(), { hours: 1 }),
    new Date(),
  ]);

  const barsData = useMemo(() => filterByDateRange(data.logs, dateRange), [
    data,
    dateRange,
  ]);

  const isLoading = loading === RequestStatus.pending;
  const [startTime] = dateRange;

  const dispatch = useDispatch();

  // Handlers

  const handleDateSelect = (date) => {
    setDateRange(date);
  };

  const handleUpdate = useCallback(() => {
    if (connector?.nid) {
      dispatch(getData({ connectorId: connector.nid }));
    }
  }, [dispatch, connector]);

  useEffect(() => {
    if (connector?.nid) {
      dispatch(getData({ connectorId: connector.nid }));
    }
  }, [connector, dispatch]);

  // Component did unmount
  useEffect(
    () => () => {
      dispatch(resetState());
    },
    [dispatch]
  );

  return (
    <>
      <Row className="diagrams-panel">
        <Panel>
          <TimeFrameSelect
            onChange={handleDateSelect}
            selectedDate={startTime ?? undefined}
            options={options}
          />
          <Button
            className="action-button"
            icon={<BackupIcon />}
            onClick={handleUpdate}
          />
        </Panel>
      </Row>
      <ProcessingTimeBarChart
        isLoading={isLoading}
        data={barsData}
        dateRange={dateRange}
      />
    </>
  );
};

export const mapProcessingTimeChartProps = (props) => props;

export default ProcessingTimeChartContainer;
