import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Card } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
// Utils
import useChangeNotificationStatus from '../../../components/notifications/useChangeNotificationStatus';
// Components
import NotificationsList from '../../../components/notifications/list/NotificationsList';
// Types
import { IHomeNotifications } from '../componentsMap';
// Styles
import './style.scss';
import { Link } from 'react-router-dom';

const HomeNotifications: FC = () => {
  const { t } = useTranslation(['notifications']);
  const handleNotificationClick = useChangeNotificationStatus();

  return (
    <Row className="main-row home-notificalions">
      <Col xs={12}>
        <Card className="notifications custom-card">
          <Link to="/notifications">
            <h2>{t('titles.Notifications')}</h2>
          </Link>
          <NotificationsList onItemClick={handleNotificationClick} />
        </Card>
      </Col>
    </Row>
  );
};

export const mapHomeNotifications = (props: IHomeNotifications) => props;

export default HomeNotifications;
