import React, { FC, useCallback } from 'react';
import { NumericInput, Keys } from '@blueprintjs/core';
// Types
import { ITableFilterProps } from '../TableFilter';

const IntegerFilter: FC<ITableFilterProps> = ({
  field,
  label,
  onFilterChange,
  onPressEnter,
  filterValue = 0,
}) => {
  const handleChange = useCallback(
    (value) => {
      onFilterChange(field, value);
    },
    [field, onFilterChange]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.which === Keys.ENTER && onPressEnter) {
        onPressEnter();
      }
    },
    [onPressEnter]
  );

  return (
    <div key={field}>
      <NumericInput
        onKeyPress={handleKeyPress}
        onValueChange={handleChange}
        placeholder={label}
        value={filterValue}
      />
    </div>
  );
};

export default IntegerFilter;
