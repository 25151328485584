import yup from '../../../translations/yup/yupLocale';
import i18n from '../../../i18n';

const validationSchema = yup.object({
  field_user_picture: yup
    .object({
      file: yup.string().label('Avatar'),
    })
    .nullable(),
  field_first_name: yup.string().required().label('First Name'),
  field_last_name: yup.string().required().label('Last Name'),
  mail: yup.string().email().required().label('Email'),
  field_mobile_phone: yup.string().label('phone'),
  field_invoice_email: yup.string().email().label('Invoice Email'),
  language: yup.string().required().label('Language'),
  current_password: yup.string().label('Current Password'),
  password: yup
    .string()
    .label('New Password')
    .test('current-password', i18n.t('user:validation:currentPassword'), function (
      value
    ) {
      return !(value && !this.parent.current_password);
    })
    .min(8)
    .matches(/(?=^.*)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/, {
      message: ({ value }) => {
        const signs: string[] = [];
        if (!/[A-Z]+/.test(value)) signs.push(i18n.t('user:validation:capital'));
        if (!/[a-z]+/.test(value)) signs.push(i18n.t('user:validation:small'));
        if (!/\d+/.test(value)) signs.push(i18n.t('user:validation:digit'));
        return `${i18n.t('user:validation:password')} ${signs.join(', ')}`;
      },
    }),
  confirm_password: yup
    .string()
    .label('Confirm password')
    .test('current-password', i18n.t('user:validation:currentPassword'), function (
      value
    ) {
      return !(value && !this.parent.current_password);
    })
    .test('passwords-match', i18n.t('user:validation:passwordsMatch'), function (value) {
      return this.parent.password === value;
    }),
});

export default validationSchema;
