import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { setSelectedRequest } from '../../../../redux/slices/connectors/test/testConnectorSlice';
// Components
import RequestTypeTag from '../../../../components/Wedoio/Connector/request-type-tag/RequestTypeTag';
// Selectors
import { selectTestConnectorState } from '../../../../redux/slices/connectors/test/selectors';
// Types
import { IConnectorRequest } from 'src/types/internal-Types';

interface IRequestTeaserProps {
  request: IConnectorRequest;
}

const RequestTeaser: FC<IRequestTeaserProps> = ({ request }) => {
  const { selectedRequest } = useSelector(selectTestConnectorState);
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(setSelectedRequest(request));
  }, [dispatch, request]);

  return (
    <div
      className={`connector-request-teaser ${
        selectedRequest?.operationId === request.operationId ? 'active' : ''
      }`}
      onClick={handleClick}
    >
      {request.method && <RequestTypeTag type={request.method} />}
      <span className="request-name">{request.operationId}</span>
    </div>
  );
};

export default RequestTeaser;
