import BaseApiService, { RequestType } from './BaseApiService';

class UserConnectorsService extends BaseApiService {
  create = (data) =>
    this.request({
      type: RequestType.post,
      url: 'universal_connector',
      data,
    });

  edit = (id: string, data) =>
    this.request({
      type: RequestType.put,
      url: `universal_connector/${id}`,
      data: {
        action: 'api.update',
        input: {
          data,
        },
        silent: true,
      },
    });

  test = (id: string, { action, ...data }) =>
    this.request({
      type: RequestType.put,
      url: `universal_connector/${id}`,
      data: {
        action,
        input: {
          ...data,
          test: true,
        },
        silent: true,
      },
    });
  authorize = (id: string, data) =>
    this.request({
      type: RequestType.put,
      url: `universal_connector/${id}`,
      data: {
        action: 'settings',
        input: {
          action: 'set_multiple',
          data,
        },
        silent: true,
      },
    });
  getList = () =>
    this.request({
      type: RequestType.get,
      url: '/universal_connector',
    });
}

export default new UserConnectorsService('/rest');
