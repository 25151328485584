import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RedirectParamsState = string;

const initialState = '';

const redirectParamsSlice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    setParameters: (
      state: RedirectParamsState,
      acttion: PayloadAction<string>
    ): RedirectParamsState => acttion.payload,
    reset: (state: RedirectParamsState): RedirectParamsState => initialState,
  },
});

export const { reset, setParameters } = redirectParamsSlice.actions;

export default redirectParamsSlice.reducer;
