import { createSlice } from '@reduxjs/toolkit';
// Types
import { RequestStatus } from '../types';
import { RequestType } from 'src/services/BaseApiService';
import { HomePageState } from './types';
// Utils
import { createThunkReducers } from '../utils';
// Thunks
import { getHomePageLayout } from './thunk';

const initialState: HomePageState = {
  error: null,
  loading: RequestStatus.idle,
  data: null,
};

const homepageSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    reset: (state: HomePageState): HomePageState => initialState,
  },
  extraReducers: {
    ...createThunkReducers(getHomePageLayout, RequestType.put, 'loading'),
  },
});

export const { reset } = homepageSlice.actions;

export default homepageSlice.reducer;
