import React, {
  FC,
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
  ReactNode,
} from 'react';
import { usePaginatedQuery, useQueryCache } from 'react-query';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { Col } from 'react-bootstrap';
import { Button as BlueprintButton, Icon } from '@blueprintjs/core';
// Selectors
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
// Components
import Table, { ITableSort, ITableFilters } from '../../../components/table/Table';
import TableRow from './components/TableRow';
import GenericSelector, {
  IValueKey,
} from 'src/components/generic-selector/GenericSelector';
import Button, { ButtonSize } from 'src/components/button/Button';
import BackupIcon from 'src/components/icons/BackupIcon';
import CloseIcon from 'src/components/icons/CloseIcon';
import CustomSwitch from 'src/components/custom-switch/CustomSwitch';
// Hooks
import useUpdateRows from './useUpdateRows';
import useFormNotification from '../../../utils/useFormNotification';
// Services
import TableManagerService, {
  IGetTableRecordsResult,
} from '../../../services/TableManagerService';
// Types
import { ITableManager } from '../types';
import { SerializedError } from 'src/redux/slices/types';
import { ITableCol } from '../../../components/table/TableHeaderCell';
import { ITableRowProps } from 'src/components/table/TableRow';
import { ESort } from 'src/types/enums';
import { IDashboardTableRow } from 'src/types/internal-Types';
// Utils
import stringifyStateError from 'src/utils/stringifyStateError';
import { getMilliseconds } from 'src/utils/genUtils';
import CreateForm from './components/CreateForm';
import getClassName from '../../../components/dynamic-layout/utils/getClassName';

import './styles.scss';

const ITEMS_PER_PAGE = 10;

interface ITableInitialQuery {
  [field: string]: string;
}

interface IDashboardTableProps {
  tableName: string;
  columns: ITableCol[];
  actions?: IValueKey[];
  enable_creation_form: boolean;
  items_per_page: number;
  children: ReactNode;
  initial_query: { [key: string]: any };
}

const DashboardTable: FC<IDashboardTableProps> = ({
  tableName,
  columns = [],
  actions,
  enable_creation_form = false,
  items_per_page = ITEMS_PER_PAGE,
  children,
  initial_query,
}) => {
  const { t } = useTranslation(['dashboard']);
  const queryCache = useQueryCache();
  const { showNotification } = useFormNotification();

  const { data: connector } = useSelector(selectConnectorState);
  // Table state
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<ITableFilters | undefined>();

  const [selected, setSelected] = useState<IDashboardTableRow[]>([]);
  const selectedIds = useMemo(() => selected.map(({ id }) => id), [selected]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const [sort, setSort] = useState<ITableSort>({
    sortBy: 'created',
    direction: ESort.desc,
  });
  const [selectedAction, setSelectedAction] = useState<IValueKey | undefined>();

  // Form state
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  // Refresh state
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);

  const createOption = (Key: string, Value: string = t('selects.minutes')) => ({
    Key,
    Value: `${Key} ${Value}`,
  });

  const intervalOptions = [createOption('5'), createOption('10'), createOption('30')];
  const [intervalTime, setRefreshInterval] = useState<IValueKey>(intervalOptions[0]);

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  // Queries
  const { isLoading, isFetching, isError, resolvedData, error } = usePaginatedQuery<
    IGetTableRecordsResult,
    SerializedError
  >(
    [
      tableName,
      {
        connectorId: connector?.nid,
        tableName,
        page,
        limit: items_per_page,
        query: {
          ...filters,
          ...initial_query,
        },
        sort: { [sort.sortBy]: sort?.direction },
      },
    ],
    TableManagerService.getRecords,
    {
      enabled: connector?.nid,
      onSuccess: ({ total, rows }) => {
        const maxPage = total ? total / items_per_page : 1;
        if (!rows.length && page > maxPage) {
          setPage(maxPage);
        }
        setSelected([]);
      },
    }
  );

  const onSuccess = useCallback(
    (response) => {
      setSelected([]);
      setPage(1);
      setFilters(undefined);
      queryCache.invalidateQueries(tableName);
      showNotification(response?.output?.notification);
    },
    [queryCache, showNotification, tableName]
  );

  const { updateLoading, updateRows } = useUpdateRows({
    onSuccess,
  });

  // Handlers
  const toggleFormOpen = useCallback(() => {
    setIsFormOpen((open) => !open);
  }, []);

  const handleRefresh = useCallback(() => {
    queryCache.invalidateQueries(tableName);
  }, [tableName, queryCache]);

  const handleFiltersSubmit = useCallback((activeFilters: ITableFilters | undefined) => {
    setPage(1);
    setFilters(activeFilters);
  }, []);

  const handlePageClick = useCallback(async (clickedPage) => {
    setPage(clickedPage.selected + 1);
  }, []);

  const handleSort = useCallback((field, direction) => {
    setSort((sortState) => {
      if (sortState?.sortBy === field && sortState?.direction === direction) {
        return { sortBy: 'created', direction: ESort.desc };
      }
      return { sortBy: field, direction };
    });
  }, []);

  const handleSelect = useCallback(
    (id) => {
      if (selectedIds.includes(id)) {
        setSelected((currentSelected) =>
          currentSelected.filter((elem) => elem.id !== id)
        );
        setIsSelectAll(false);
      } else {
        const newElem = resolvedData?.rows.find((elem) => elem.id === id);
        setSelected((currentSelected) =>
          newElem ? [...currentSelected, newElem] : currentSelected
        );
      }
    },
    [selectedIds, resolvedData]
  );

  const handleSelectVisible = useCallback(() => {
    if (resolvedData?.rows) {
      if (selected.length === resolvedData?.rows.length) {
        setSelected([]);
      } else {
        setSelected(resolvedData?.rows);
      }
    }
  }, [resolvedData, selected]);

  const handleSelectAll = useCallback(() => {
    setIsSelectAll((isSelectAllState) => !isSelectAllState);
  }, []);

  const handleActionSelected = ([option]: IValueKey[] = []) => {
    setSelectedAction(option);
  };

  const handleUpdateSelected = useCallback(() => {
    if (selected.length && selectedAction && connector?.nid) {
      updateRows({
        connectorId: connector?.nid,
        tableName,
        records: selected,
        action: selectedAction?.Key,
        query: {
          ...filters,
          ...initial_query,
        },
        select_all: isSelectAll,
      });
    }
  }, [
    selected,
    filters,
    isSelectAll,
    tableName,
    connector,
    updateRows,
    selectedAction,
    initial_query,
  ]);

  const handleAutoRefreshChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setAutoRefresh(checked);
  };

  const handleIntervalChange = (data: IValueKey[]) => {
    const [intervalData] = data;

    setRefreshInterval(intervalData?.Key ? intervalData : intervalOptions[0]);
  };

  const removeInterval = () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  useEffect(() => {
    removeInterval();

    if (autoRefresh) {
      interval.current = setInterval(() => {
        handleRefresh();
      }, getMilliseconds(+intervalTime.Key));
    }
  }, [autoRefresh, intervalTime, handleRefresh]);

  useEffect(() => {
    if (isError) {
      toastr.error(
        t('alert.Failed'),
        t('alert.Error fetch', { error: stringifyStateError(error) })
      );
    }
  }, [error, isError, t]);

  // Component unmount
  useEffect(() => removeInterval, []);

  const renderRow = (props: ITableRowProps) => {
    return <TableRow {...props} tableName={tableName} connectorId={connector?.nid} />;
  };

  return (
    <div className="table-manager-container shadow-card">
      <div className="table-manager-layout"> {children}</div>
      <div className="table-manager-panel">
        {actions && (
          <div className="table-manager-actions">
            <GenericSelector
              isMultiSelector={false}
              disabled={!selected.length || updateLoading}
              items={actions}
              selectorText={t('inputs.Mass actions')}
              onValueChanged={handleActionSelected}
              selectedItem={selectedAction ? [selectedAction] : []}
            />
            <Button
              disabled={!selectedAction || !selected.length || updateLoading}
              onClick={handleUpdateSelected}
              size={ButtonSize.small}
            >
              {t('buttons.Submit')}
            </Button>
          </div>
        )}
        <Col md={4} xs={6} className="table-manager-refresh">
          <BlueprintButton
            className="action-button"
            icon={<BackupIcon />}
            onClick={handleRefresh}
          />
          <CustomSwitch
            name={t('inputs.Auto Refresh')}
            onChange={handleAutoRefreshChange}
            checked={autoRefresh}
          />
          <GenericSelector
            isMultiSelector={false}
            items={intervalOptions}
            selectorText={t('inputs.Refresh interval')}
            onValueChanged={handleIntervalChange}
          />
        </Col>
        {enable_creation_form && (
          <span onClick={toggleFormOpen}>
            <Icon iconSize={20} icon={isFormOpen ? <CloseIcon /> : 'add'} />
          </span>
        )}
      </div>
      {enable_creation_form && (
        <CreateForm
          onSubmit={toggleFormOpen}
          isOpen={isFormOpen}
          connectorId={connector?.nid}
          tableName={tableName}
        />
      )}

      <Table
        activeSort={sort}
        filters={filters}
        onSortChange={handleSort}
        onFiltersApply={handleFiltersSubmit}
        isLoading={isLoading || isFetching}
        onSelectVisible={handleSelectVisible}
        onSelectAll={handleSelectAll}
        onSelect={handleSelect}
        selected={selectedIds}
        isSelectAll={isSelectAll}
        rows={resolvedData?.rows ?? []}
        columns={columns}
        onPageClick={handlePageClick}
        total={resolvedData?.total ?? 0}
        itemsPerPage={items_per_page}
        currentPage={page - 1}
        rowComponent={renderRow}
      />
    </div>
  );
};

export const mapDashboardTableProps = (
  {
    operation,
    columns = {},
    actions,
    enable_creation_form,
    items_per_page,
    children,
    initial_query,
  }: ITableManager,
  builder
): IDashboardTableProps => ({
  tableName: operation,
  columns: Object.entries(columns).map(
    ([field, { label, type, filter, sort, size, classes }]) => ({
      field,
      label,
      type,
      filter,
      sort,
      size,
      className: getClassName(classes),
    })
  ),
  actions: actions
    ? Object.entries(actions).map(([action, { label }]) => ({
        Key: action,
        Value: label,
      }))
    : undefined,
  enable_creation_form,
  items_per_page,
  children: children ? builder(children) : null,
  initial_query,
});

export default DashboardTable;
