import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import ConnectorConfigService from '../../../../services/ConnectorConfigService';

// Types
import { IGetConnectorConfigSuccess, IUpdateConfigActionPayload } from './types';
import { IFormNotification } from 'src/types/internal-Types';
import { ENotificationTypes } from 'src/types/enums';

export const getConfigForm = createAsyncThunk<IGetConnectorConfigSuccess, string>(
  'dashboard.connectorConfig/getConfigForm',
  async (connectorId, { rejectWithValue }) => {
    try {
      const { data } = await ConnectorConfigService.getForm(connectorId);

      return {
        data: {
          form: data.output,
        },
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);
export const updateConfig = createAsyncThunk<
  { notification?: IFormNotification },
  IUpdateConfigActionPayload
>(
  'dashboard.connectorConfig/updateConfig',
  async ({ connectorId, data }, { rejectWithValue }) => {
    try {
      const { data: response } = await ConnectorConfigService.update(connectorId, data);

      if (response?.output?.error) {
        return rejectWithValue(response?.output?.error);
      }

      if (response?.output?.notification?.type === ENotificationTypes.error) {
        return rejectWithValue(response?.output?.notification.content);
      }

      if (response?.output?.notification) {
        return { notification: response.output.notification };
      }

      return {};
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);
