import React, { FC } from 'react';

// Components
import defaultLogo from '../../images/defaultCompanyLogo.png';
import Avatar, { AvatarSizes } from '../avatar/Avatar';

// Utils
import createSize, { ISize } from '../../utils/createSize';

// Styles
import './company-logo-styles.scss';

export enum CompanyLogoSizes {
  small,
  smallSecondary,
  middle,
}

export interface ICompanyLogoProps {
  src?: string;
  size?: CompanyLogoSizes;
  spacing?: string;
  loading?: boolean;
}

const CompanyLogo: FC<ICompanyLogoProps> = ({
  src,
  size = CompanyLogoSizes.middle,
  spacing = '0',
  loading = false,
}) => {
  return (
    <div
      className="company-logo-background"
      style={{ ...dimensions.get(size), margin: spacing }}
    >
      <Avatar src={src || defaultLogo} size={avatarSize.get(size)} loading={loading} />
    </div>
  );
};

const avatarSize = new Map<CompanyLogoSizes, AvatarSizes>([
  [CompanyLogoSizes.small, AvatarSizes.small],
  [CompanyLogoSizes.smallSecondary, AvatarSizes.smallSecondary],
  [CompanyLogoSizes.middle, AvatarSizes.middle],
]);

const dimensions = new Map<CompanyLogoSizes, ISize>([
  [CompanyLogoSizes.small, createSize(47, 47)],
  [CompanyLogoSizes.smallSecondary, createSize(104, 102)],
  [CompanyLogoSizes.middle, createSize(122, 120)],
]);

export default CompanyLogo;
