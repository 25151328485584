import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
// Types
import { RequestStatus } from '../../../../../redux/slices/types';
import { SignupStep3 } from '../../../../../services/AuthService';
// Actions
import { resetSignupState } from '../../../../../redux/slices/auth';
import { resendCode, submitSignup } from '../../../../../redux/slices/auth/signup/thunk';
// Selectors
import { selectSignupState } from '../../../../../redux/slices/auth/signup/selectors';
// Components
import Input from '../../../../../components/input/Input';
import Button from '../../../../../components/button/Button';

// Validation schema
import validationSchema from './validationSchema';
import Code from '../../../../../images/code.svg';

const Step3: FC = () => {
  const {
    submit,
    loading,
    currentStep,
    registration_key,
    textLoading,
    text,
  } = useSelector(selectSignupState);
  const { t } = useTranslation(['signup']);
  const dispatch = useDispatch();

  // Handlers
  const onSubmit = async (data) => {
    await dispatch(submitSignup(data));
  };

  const handleResendCode = async () => {
    await dispatch(
      resendCode({ resend_code: true, registration_key, step: currentStep })
    );
  };

  const handleChangeEmail = async () => {
    await dispatch(resetSignupState());
  };

  const initialValues: SignupStep3 = {
    code: '',
    step: currentStep,
    registration_key,
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
  } = useFormik<SignupStep3>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const isSubmitDisabled = submit === RequestStatus.pending || !isValid;

  return (
    <Row>
      <Col md={6}>
        <div className="padding-md">
          {textLoading !== RequestStatus.pending && <h1>{t('titles.Ready!')}</h1>}
          {textLoading === RequestStatus.pending && (
            <div className="bp3-skeleton heading-skeleton" />
          )}
          {textLoading !== RequestStatus.pending && (
            <p>
              {text?.left_text ?? (
                <>
                  {' '}
                  <b>{t('titles.We have sent you an email with a code')}</b>{' '}
                  {t('titles.Please add this code below')}
                </>
              )}
            </p>
          )}
          {textLoading === RequestStatus.pending && (
            <div className="bp3-skeleton description-skeleton" />
          )}
          <form onSubmit={handleSubmit}>
            {textLoading !== RequestStatus.pending && <h3>{t('titles.Code')}</h3>}
            {textLoading === RequestStatus.pending && (
              <div className="bp3-skeleton heading-skeleton--small" />
            )}

            <Input
              loading={textLoading === RequestStatus.pending}
              onChange={handleChange}
              name="code"
              placeholder={t('inputs.Token')}
              validationError={touched.code && errors.code}
              value={values.code}
              onBlur={handleBlur}
            />

            {textLoading !== RequestStatus.pending && (
              <Button
                type="submit"
                disabled={isSubmitDisabled}
                loading={submit === RequestStatus.pending}
              >
                {t('buttons.Validate Code')}
              </Button>
            )}
            {textLoading === RequestStatus.pending && (
              <div className="bp3-skeleton button-skeleton" />
            )}
            <Row>
              <Col sm={6} style={{ padding: '0 15px' }}>
                {textLoading !== RequestStatus.pending && (
                  <Button
                    type="button"
                    className="secondary"
                    loading={loading === RequestStatus.pending}
                    onClick={handleResendCode}
                  >
                    {t('buttons.Resend Code')}
                  </Button>
                )}
                {textLoading === RequestStatus.pending && (
                  <div className="bp3-skeleton button-skeleton" />
                )}
              </Col>
              <Col sm={6} style={{ padding: '0 15px' }}>
                {textLoading !== RequestStatus.pending && (
                  <Button type="button" className="secondary" onClick={handleChangeEmail}>
                    {t('buttons.Change the email')}
                  </Button>
                )}
                {textLoading === RequestStatus.pending && (
                  <div className="bp3-skeleton button-skeleton" />
                )}
              </Col>
            </Row>
          </form>
        </div>
      </Col>
      <Col md={6}>
        <div className="Code padding-xs margin-top-xs">
          {textLoading !== RequestStatus.pending && (
            <img
              className="img-responsive"
              alt="code-img"
              title={t('img.Profile')}
              src={Code}
            />
          )}
          {textLoading === RequestStatus.pending && (
            <div className="bp3-skeleton image-skeleton" />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Step3;
