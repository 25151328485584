import React, { FC, useState, useCallback, useMemo, ReactNode } from 'react';
import { Keys, Icon, Popover } from '@blueprintjs/core';
import { Row, Col } from 'react-bootstrap';
// Components
import FieldNamesList from './FieldNamesList';
// Hooks
import useMappingToolContex from '../context/useMappingToolContext';
// Utils
import { filterString } from '../../../utils/genUtils';

interface IMappingRowProps {
  onFieldNameChange: (newFieldName: string) => void;
  onCancel?: () => void;
  fieldName: string;
  path: string;
  controls?: ReactNode;
  children?: ReactNode;
}

const MappingRow: FC<IMappingRowProps> = ({
  fieldName,
  onFieldNameChange,
  onCancel,
  children,
  path,
  controls,
}) => {
  const [inputValue, setInputValue] = useState<string>(fieldName);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { destination, checkIsValidFieldName } = useMappingToolContex();
  const keys = useMemo(
    () =>
      Object.entries(destination).reduce(function getObjectKeys(
        result: string[],
        [field, value]
      ) {
        if (typeof value !== 'object' || Array.isArray(value))
          return [...result, ...(checkIsValidFieldName(field, path) ? [field] : [])];
        return [
          ...Object.entries(value).reduce(
            (subFielKeys, [subField, subFieldValue]) =>
              getObjectKeys(subFielKeys, [subField, subFieldValue]),
            [...result, ...(checkIsValidFieldName(field, path) ? [field] : [])]
          ),
        ];
      },
      []),
    [destination, path, checkIsValidFieldName]
  );

  const options = useMemo(() => keys.filter((key) => filterString(key, inputValue)), [
    keys,
    inputValue,
  ]);

  const isValid = useMemo(
    () =>
      inputValue !== '' &&
      inputValue !== fieldName &&
      checkIsValidFieldName(inputValue, path),
    [checkIsValidFieldName, inputValue, fieldName, path]
  );

  const handleFieldNameChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleEdit = useCallback(() => {
    if (isValid) {
      onFieldNameChange(inputValue);
    } else {
      setInputValue(fieldName);
    }
  }, [inputValue, onFieldNameChange, isValid, fieldName]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    setInputValue(fieldName);
  }, [onCancel, fieldName]);

  const handleKeyDown = useCallback(
    (e) => {
      e.stopPropagation();
      if (e.which === Keys.ENTER) {
        handleEdit();
      }
    },
    [handleEdit]
  );

  const handleSelect = useCallback(
    (value) => {
      onFieldNameChange(value);
    },
    [onFieldNameChange]
  );

  return (
    <Row className="mapping-row">
      <Col xs={5} className="destination">
        <div className="field-controls">
          {!controls || fieldName !== inputValue ? (
            <>
              {isValid && (
                <Icon icon="confirm" className="add-field-button" onClick={handleEdit} />
              )}
              <Icon
                icon="circle-arrow-left"
                className="remove-field-button"
                onClick={handleCancel}
              />
            </>
          ) : (
            controls
          )}
        </div>
        <Popover
          minimal
          isOpen={isOpen}
          placement="bottom-start"
          enforceFocus={false}
          autoFocus={false}
          popoverClassName="options-list"
          onInteraction={(isPopoverOpen) => {
            setIsOpen(isPopoverOpen);
          }}
        >
          <input
            type="text"
            value={inputValue}
            onChange={handleFieldNameChange}
            onKeyDown={handleKeyDown}
          />
          <FieldNamesList onClick={handleSelect} options={options} />
        </Popover>
      </Col>
      {children}
    </Row>
  );
};

export default MappingRow;
