import React, { FC, useCallback } from 'react';
import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars';

import './styles.scss';

const CustomScrollbars: FC<ScrollbarProps> = (props) => {
  const renderTrackVertical = useCallback(
    ({ style }) => <div style={style} className="track-vertical" />,
    []
  );
  const renderThumbVertical = useCallback(
    ({ style }) => <div style={style} className="thumb-vertical" />,
    []
  );
  const renderTrackHorizontal = useCallback(
    ({ style }) => <div style={style} className="track-horizontal" />,
    []
  );
  const renderThumbHorizontal = useCallback(
    ({ style }) => <div style={style} className="thumb-horizontal" />,
    []
  );
  return (
    <Scrollbars
      className="custom-scrollbars"
      renderTrackVertical={renderTrackVertical}
      renderThumbVertical={renderThumbVertical}
      renderTrackHorizontal={renderTrackHorizontal}
      renderThumbHorizontal={renderThumbHorizontal}
      {...props}
    >
      {props.children}
    </Scrollbars>
  );
};

export default CustomScrollbars;
