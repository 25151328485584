import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
// Types
import { IHomeConnectorsList } from '../componentsMap';
// Components
import ConnectorsList from '../../../components/Wedoio/Connector/list/ConnectorsList';
import { Card } from '@blueprintjs/core';
import Scrollbars from '../../../components/custom-scrollbars/CustomScrollbars';

const HomeConnectorsList: FC = () => {
  return (
    <Row className={'main-row'}>
      <Col className="main-connectors connectors-list-wrp" sm={12}>
        <Card className="custom-card">
          <Scrollbars hideTracksWhenNotNeeded autoHeight autoHeightMin="70vh">
            <ConnectorsList />
          </Scrollbars>
        </Card>
      </Col>
    </Row>
  );
};

export const mapHomeConnectorsListProps = (props: IHomeConnectorsList) => props;

export default HomeConnectorsList;
