export const plugin = {
  wrapComponents: {
    authorizeBtn: () => () => null,
    authorizeOperationBtn: () => () => null,
  },
  statePlugins: {
    spec: {
      wrapSelectors: {
        allowTryItOutFor: () => () => false,
        taggedOperations: (ori) => (...args) => {
          return ori(...args).filter(
            (tagMeta) => tagMeta.get('operations') && tagMeta.get('operations').size > 0
          );
        },
      },
    },
  },
};
