import React, { FC, ReactNode } from 'react';

// Styles
import './auth-styles.scss';

export interface IAuthFormProps {
  children: ReactNode;
}

const AuthForm: FC<IAuthFormProps> = ({ children }) => {
  return <div className="container-auth-form col-md-10">{children}</div>;
};

export default AuthForm;
