import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { NonIdealState } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
// Components
import HookStatus from './HookStatus';
// Types
import { IHookStatus } from '../../../../../types/api-Types';

interface IHookStatusListProps {
  data: IHookStatus | undefined;
  entity?: string;
  widgetKey: string;
  loading?: boolean;
}

const HookStatusList: FC<IHookStatusListProps> = ({
  data,
  entity,
  loading,
  widgetKey,
}) => {
  const { t } = useTranslation(['dashboard']);
  const isDataEmpty = !data || !Object.values(data).length;

  return (
    <Row className="hooks-status-list">
      {!loading && data && !isDataEmpty && (
        <Col sm={12}>
          {Object.entries(data).map(([event, status]) => (
            <HookStatus
              key={event}
              event={event}
              status={status}
              widgetKey={widgetKey}
              entity={entity}
            />
          ))}
        </Col>
      )}
      {loading && <Col sm={12} className="bp3-skeleton hooks-manager-skeleton" />}
      {!loading && isDataEmpty && (
        <Col sm={12}>
          <NonIdealState icon="error" title={t('No hooks found')} />
        </Col>
      )}
    </Row>
  );
};

export default HookStatusList;
