import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { ILanguageState } from './types';
import { IValueKey } from '../../../components/generic-selector/GenericSelector';
// Utils
import { getAsValueKey, getByKey } from './languages';
// Services
import LanguageService from '../../../services/LanguageService';
import i18n from '../../../i18n';

const allLanguages = getAsValueKey();
const initialState: ILanguageState = {
  allLanguages,
  selectedLanguage: getByKey(LanguageService.get()),
};

i18n.changeLanguage(initialState.selectedLanguage.Key);

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setSelectedLanguage: (
      state: ILanguageState,
      action: PayloadAction<IValueKey>
    ): ILanguageState => {
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    },
  },
});

export const { setSelectedLanguage } = languageSlice.actions;

export default languageSlice.reducer;
