import { createSlice } from '@reduxjs/toolkit';

// Services
import { CreateCompanyData } from '../../../../services/CompaniesService';

// Types
import { RequestStatus } from '../../types';
import { CreateCompanyState } from './types';

// Thunks
import { createCompany } from './thunks';

// Utils
import { createThunkReducers } from '../../utils';

const initialValues: CreateCompanyData = {
  title: '',
  field_invoice_email: '',
  field_company_vat: '',
  field_company_logo: {
    file: '',
    filename: '',
  },
  field_company_address: {
    country: '',
    locality: '',
    postal_code: '',
    thoroughfare: '',
  },
};

const initialState: CreateCompanyState = {
  error: null,
  submit: RequestStatus.idle,
  initialValues,
};

const createCompanySlice = createSlice({
  name: 'companies.create',
  initialState,
  reducers: {
    reset: (): CreateCompanyState => initialState,
  },
  extraReducers: {
    ...createThunkReducers(createCompany),
  },
});

export const { reset } = createCompanySlice.actions;

export default createCompanySlice.reducer;
