import React, { FC, ReactElement } from 'react';
import { Intent, Icon } from '@blueprintjs/core';
// Styles
import './list-item-styles.scss';

interface IListItem {
  title: string;
  leftElement?: ReactElement;
  onEdit: (e: any) => void;
  onRemove: (e: any) => void;
  intent: Intent;
}

const ListItem: FC<IListItem> = ({
  onEdit,
  onRemove,
  leftElement,
  title,
  intent = Intent.SUCCESS,
}) => {
  return (
    <div className={`list-item ${intent}`}>
      <div className="list-item-title">
        <div className="list-item-icon">{leftElement}</div>
        {title}
      </div>

      <div className="list-item-controls">
        <Icon icon="cog" onClick={onEdit} />
        <Icon icon="trash" onClick={onRemove} />
      </div>
    </div>
  );
};

export default ListItem;
