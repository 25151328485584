import BaseApiService, { RequestType } from './BaseApiService';

// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';

class ConnectorConfigService extends BaseApiService {
  getForm = (connectorId: string) =>
    this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'config',
          action: 'form',
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });

  update = (connectorId: string, data: UpdateConnectorConfigData) => {
    return this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'config',
          action: 'set_multiple',
          data,
        },
      },
    });
  };
}

export interface UpdateConnectorConfigData {
  [key: string]: number | string | boolean;
}

export default new ConnectorConfigService('rest/connector');
