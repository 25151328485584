import React, { FC, useMemo } from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
// Components
import MarketplaceIntegrationTeaser from './MarketplaceIntegrationTeaser';
import CustomScrollbars from '../../../../components/custom-scrollbars/CustomScrollbars';
// Types
import { IMarketPlaceIntegration } from 'src/services/MarketplaceService';
// Utils
import { filterString } from 'src/utils/genUtils';
// Icon
import iconIntegration from '../../../../images/icon-integration.svg';

export interface IMarketplaceIntegrationsListProps {
  integrations: IMarketPlaceIntegration[] | undefined;
  filterValue?: string;
  filterTags?: string[];
  loading?: boolean;
}

const MarketplaceIntegrationsList: FC<IMarketplaceIntegrationsListProps> = ({
  filterValue,
  integrations,
  loading,
  filterTags,
}) => {
  const { t } = useTranslation(['home']);

  const filteredIntegrations = useMemo(() => {
    if (integrations) {
      return integrations.filter(
        (f) =>
          (filterValue ? filterString(f.title, filterValue) : true) &&
          (filterTags?.length ? f.tags?.some((tag) => filterTags.includes(tag)) : true)
      );
    }

    return integrations;
  }, [filterValue, filterTags, integrations]);

  if (!loading && !filteredIntegrations?.length) {
    return (
      <NonIdealState
        icon={
          filterValue ? 'search' : <img src={iconIntegration} alt="integration icon" />
        }
        title={t('No integrations found')}
      />
    );
  }

  return (
    <div className="marketplace-integrations-list">
      <CustomScrollbars
        autoHeight
        autoHeightMin={'50vh'}
        autoHeightMax={'50vh'}
        style={{ width: '100%' }}
      >
        {!loading &&
          filteredIntegrations &&
          filteredIntegrations.map((x, i) => (
            <MarketplaceIntegrationTeaser integration={x} key={i} />
          ))}
        {loading && (
          <>
            <div className="marketplace-integrations-list-item-skeleton bp3-skeleton" />
            <div className="marketplace-integrations-list-item-skeleton bp3-skeleton" />
            <div className="marketplace-integrations-list-item-skeleton bp3-skeleton" />
          </>
        )}
      </CustomScrollbars>
    </div>
  );
};

export default MarketplaceIntegrationsList;
