import React, { FC, useState, useCallback, createContext } from 'react';

export interface IEditModeContext {
  isEdit: boolean;
  toggleEdit: (state?: boolean) => void;
}

export const EditModeContext = createContext<IEditModeContext>({} as IEditModeContext);

const EditModeContextProvider: FC = ({ children }) => {
  const [isEdit, setEdit] = useState<boolean>(false);

  const toggleEdit = useCallback((state = true) => {
    setEdit(state);
  }, []);

  return (
    <EditModeContext.Provider value={{ isEdit, toggleEdit }}>
      {children}
    </EditModeContext.Provider>
  );
};

export default EditModeContextProvider;
