import BaseApiService, { RequestType } from './BaseApiService';

class HomepageLayoutService extends BaseApiService {
  get = (params: string) =>
    this.request({
      url: '/' + params,
      type: RequestType.get,
    });

  submitForm = (callback: string = '', data: any) =>
    this.request({
      url: callback,
      type: RequestType.put,
      data,
    });
}

export default new HomepageLayoutService('rest/layout');
