import React, { FC, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '@blueprintjs/core';
// Types
import { RequestStatus } from '../../../../../redux/slices/types';
import { SignupStep1 } from '../../../../../services/AuthService';
// Actions
import { submitSignup } from '../../../../../redux/slices/auth/signup/thunk';
// Hooks
import useRedirectSearchParams from 'src/layouts/auth/useRedirectSearchParams';
// Selectors
import { selectSignupState } from '../../../../../redux/slices/auth/signup/selectors';
// Components
import Input from '../../../../../components/input/Input';
import Button from '../../../../../components/button/Button';
// Validation schema
import validationSchema from './validationSchema';
// Assets
import Welcome from '../../../../../images/welcome.svg';

const Step1: FC = () => {
  const {
    submit,
    currentStep,
    textLoading,
    text,
    integrationName,
    email: initialEmail,
    cvr: initialCvr,
  } = useSelector(selectSignupState);

  const { t } = useTranslation(['signup']);
  const dispatch = useDispatch();
  const searchParams = useRedirectSearchParams();

  const onSubmit = async ({ email, cvr, step }) => {
    await dispatch(submitSignup({ email, cvr, step, signup: integrationName }));
  };

  const initialValues: SignupStep1 = {
    email: initialEmail,
    cvr: initialCvr,
    step: currentStep,
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    validateForm,
    submitForm,
  } = useFormik<SignupStep1>({
    initialValues,
    enableReinitialize: true,
    initialTouched: {
      cvr: !!initialCvr,
      email: !!initialEmail,
    },
    validationSchema,
    onSubmit,
  });

  const isSubmitDisabled = submit === RequestStatus.pending || !isValid;

  const autoSubmit = useCallback(async () => {
    if (initialCvr !== '' && initialEmail !== '') {
      await validateForm();
      submitForm();
    }
  }, [validateForm, submitForm, initialCvr, initialEmail]);

  useEffect(() => {
    autoSubmit();
  }, [autoSubmit]);

  return (
    <Row>
      <Col md={6}>
        <div className="padding-md">
          {textLoading !== RequestStatus.pending && <h1>{t('titles.Good decision')}</h1>}
          {textLoading === RequestStatus.pending && (
            <div className="bp3-skeleton heading-skeleton" />
          )}
          {textLoading !== RequestStatus.pending && (
            <p>{text?.left_text ?? t('titles.You will be up and running soon')}</p>
          )}
          {textLoading === RequestStatus.pending && (
            <div className="bp3-skeleton description-skeleton" />
          )}

          {textLoading !== RequestStatus.pending && (
            <div className="welcome padding-xs margin-top-xs">
              <img
                className="img-responsive"
                alt="welcome-img"
                title={t('img.Welcome')}
                src={Welcome}
              />
            </div>
          )}
          {textLoading === RequestStatus.pending && (
            <div className="bp3-skeleton image-skeleton margin-top-xs" />
          )}
        </div>
      </Col>
      <Col md={6}>
        <div className="padding-md">
          <form onSubmit={handleSubmit}>
            {textLoading === RequestStatus.pending && (
              <div className="bp3-skeleton heading-skeleton--small" />
            )}
            {textLoading !== RequestStatus.pending && (
              <h3>{t('titles.Your work email address')}</h3>
            )}
            <Input
              loading={textLoading === RequestStatus.pending}
              onChange={handleChange}
              name="email"
              placeholder={t('inputs.Email')}
              validationError={touched.email && errors.email}
              value={values.email}
              onBlur={handleBlur}
            />
            {textLoading === RequestStatus.pending && (
              <div className="bp3-skeleton heading-skeleton--small" />
            )}
            {textLoading !== RequestStatus.pending && (
              <h3>{t('titles.Your VAT/CVR number')}</h3>
            )}
            <Input
              loading={textLoading === RequestStatus.pending}
              onChange={handleChange}
              name="cvr"
              placeholder={t('inputs.CVR')}
              validationError={touched.cvr && errors.cvr}
              value={values.cvr}
              onBlur={handleBlur}
            />

            {textLoading !== RequestStatus.pending && (
              <div className="help-block margin-bottom-xs">
                <small>
                  <Icon className="margin-right-xxs" icon="lock" />
                  {t('titles.We never share your email')}
                </small>
              </div>
            )}
            {textLoading === RequestStatus.pending && (
              <div className="bp3-skeleton help-block-skeleton" />
            )}

            {textLoading !== RequestStatus.pending && (
              <Button
                type="submit"
                disabled={isSubmitDisabled}
                loading={submit === RequestStatus.pending}
              >
                {t('buttons.Sign Up')}
              </Button>
            )}
            {textLoading === RequestStatus.pending && (
              <div className="bp3-skeleton button-skeleton" />
            )}

            <p className="have-account text-center">
              {t('links.Have Account?')}
              <Link to={{ pathname: '/login', search: searchParams }}>
                {t('links.Log In')}
              </Link>
            </p>
          </form>
        </div>
      </Col>
    </Row>
  );
};

export default Step1;
