import { useEffect, useState, useCallback } from 'react';
// Icons
import logo from '../../images/logoSmall.png';

const useBrowserNotifications = () => {
  const [permission, setPermission] = useState<boolean>(
    Notification.permission === 'granted'
  );

  const showNotification = useCallback(
    (title: string, options: NotificationOptions, onClick?) => {
      if (permission) {
        const n = new Notification(title, {
          icon: logo,
          ...options,
        });
        n.addEventListener('click', onClick);
      }
    },
    [permission]
  );

  const requestPermission = async () => {
    const userPermission = (await window.Notification.requestPermission()) === 'granted';
    setPermission(userPermission);
  };

  useEffect(() => {
    if (!permission) {
      requestPermission();
    }
  }, [permission]);

  return { showNotification };
};

export default useBrowserNotifications;
