import React, { FC } from 'react';
import { Alert, Intent, Spinner, Switch } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from '../../../../../redux/slices/types';
// Utils
import useAlert from '../../../../../utils/useAlert';
import useChangeIntegrationStatus from '../../../../../utils/useChangeIntegrationStatus';

export interface IIntegrationStatusSwitchProps {
  status: boolean;
  id: string;
  name: string;
}

const IntegrationStatusSwitch: FC<IIntegrationStatusSwitchProps> = ({
  id,
  status,
  name,
}) => {
  const [submit, changeStatus] = useChangeIntegrationStatus(id);

  const { t } = useTranslation(['switch']);

  const statusChangeAlert = useAlert<boolean>({
    callback: changeStatus,
  });

  const handleOpenAlert = ({ target }) => {
    statusChangeAlert.onOpen(target.checked);
  };

  return (
    <>
      {submit === RequestStatus.pending ? (
        <Spinner size={30} />
      ) : (
        <Switch
          className="integration-switch"
          innerLabelChecked={t('buttons.On')}
          innerLabel={t('buttons.Off')}
          large
          checked={status}
          onChange={handleOpenAlert}
        />
      )}
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Continue"
        icon="warning-sign"
        intent={statusChangeAlert.data ? Intent.SUCCESS : Intent.DANGER}
        isOpen={statusChangeAlert.isOpen}
        onCancel={statusChangeAlert.onClose}
        onConfirm={statusChangeAlert.onSubmit}
      >
        <p>
          {t(statusChangeAlert.data ? 'alert.Activate' : 'alert.Deactivate', {
            integrationName: name,
          })}
        </p>
      </Alert>
    </>
  );
};

export default IntegrationStatusSwitch;
