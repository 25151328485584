import React, { FC } from 'react';
// Components
import Collapse from 'src/components/collapse/Collapse';
import RequestTeaser from './RequestTeaser';
// Types
import { IConnectorEndpoint } from 'src/types/internal-Types';

interface IEndpointTeaserProps {
  endpoint: IConnectorEndpoint;
  active: boolean;
}

const EndpointTeaser: FC<IEndpointTeaserProps> = ({ endpoint, active }) => {
  return (
    <div className={`endpoint-teaser ${active ? 'active' : ''}`}>
      <Collapse header={<span className="endpoint-teaser-title">{endpoint.path}</span>}>
        {endpoint.requests.map((request) => (
          <RequestTeaser key={request.operationId} request={request} />
        ))}
      </Collapse>
    </div>
  );
};

export default EndpointTeaser;
