import React, { FC } from 'react';
import { useField } from 'formik';

// Component
import PasswordComponent from '../../../components/password/Password';

// Types
import { IPassword } from './input-types';

// Utils
import getClassName from '../utils/getClassName';

const Password: FC<IPassword> = ({ label, name, className }) => {
  const [field, meta] = useField({ name });

  return (
    <PasswordComponent
      {...field}
      label={label}
      name={name}
      placeholder={label}
      validationError={meta.touched && meta.error}
      className={className}
    />
  );
};

export default Password;

export const mapPasswordProps = ({ key, label, classes }: IPassword) => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
});
