import { RootState } from '../../../rootReducer';
import { ILinksState } from './types';
import { LinkTypesOptions } from '../../../../types/internal-Types';

export const selectLinks = (state: RootState): ILinksState => state.dashboard.links;

export const selectIsSelectedAllLinks = (state: RootState): boolean => {
  const { selectedLinks, data } = state.dashboard.links;

  return !!data?.links?.length && selectedLinks?.length === data?.links?.length;
};

export const selectLinkTypesOptions = (state: RootState): LinkTypesOptions[] =>
  state.dashboard.links.typeOptions;
