import { createSlice } from '@reduxjs/toolkit';
import PersistTokenService from '../../../../services/PersistTokenService';
import { logoutUser } from '../../user/thunks';

// Actions
import { login } from '../login/loginSlice';
import { submitSignup } from '../signup/thunk';

export type TokenState = string;

const initialState: TokenState = PersistTokenService.get();

const tokenSlice = createSlice({
  name: 'auth.token',
  initialState,
  reducers: {
    clearToken: () => '',
  },
  extraReducers: {
    [String(login.fulfilled)]: (state, action) => action.payload.token,
    [String(submitSignup.fulfilled)]: (state, action) => action.payload.token ?? state,
    [String(logoutUser.fulfilled)]: () => '',
  },
});

export const { clearToken } = tokenSlice.actions;

export default tokenSlice.reducer;
