import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
// Icons
import wedoioLogo from '../../images/logo-front.svg';

// Styles
import './footer.scss';

const Footer: FC = () => {
  return (
    <div className="footer">
      <Grid>
        <Row>
          <Col md={2}>
            <Link to="/">
              <img
                alt="footer logo"
                className="footer-logo responsive-image"
                src={wedoioLogo}
              />
            </Link>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Footer;
