// Types
import { EDashboardTableColTypes } from 'src/types/enums';
import { ITableCol } from './TableHeaderCell';

const getColSize = (columns: ITableCol[]): number => {
  const availableSpace = columns.reduce(
    (left, column) => left - (column?.size ?? 0),
    100
  );
  const unsized = columns.filter(
    (column) =>
      !column.size &&
      column.type !== EDashboardTableColTypes.operation &&
      column.type !== EDashboardTableColTypes.line_color
  );

  return availableSpace / unsized.length;
};

export default getColSize;
