import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
// Selector
import { selectConnectorState } from '../../../../redux/slices/dashboard/connector/selectors';
// Types
import { IHookEntity, IHookStatus } from '../../../../types/api-Types';
import { RequestStatus } from '../../../../redux/slices/types';
// Services
import HooksManagerService from '../../../../services/HooksManagerService';
// Utils
import stringifyStateError from '../../../../utils/stringifyStateError';

interface IHooksWidgetData {
  loading: RequestStatus;
  data: IHookEntity | IHookStatus | undefined;
}

const useHooksWidgetData = (widgetKey: string): IHooksWidgetData => {
  const [data, setData] = useState<IHookEntity | undefined>();
  const [loading, setLoading] = useState<RequestStatus>(RequestStatus.idle);

  const { data: connector } = useSelector(selectConnectorState);

  const { t } = useTranslation(['dashboard']);

  const fetchData = useCallback(async () => {
    try {
      if (connector?.nid) {
        setLoading(RequestStatus.pending);
        const { data: response } = await HooksManagerService.getStatus({
          connectorId: connector?.nid,
          widgetKey,
        });
        if (response.output.error) {
          setLoading(RequestStatus.rejected);
          throw new Error(response.output.error);
        }
        if (response.output && typeof response.output === 'object') {
          setLoading(RequestStatus.fulfilled);
          setData(response.output);
        }
      }
    } catch (error) {
      setLoading(RequestStatus.rejected);
      toastr.error(
        t('alert.Failed'),
        t('alert.Error fetch', { error: stringifyStateError(error) })
      );
    }
  }, [connector, widgetKey, t]);

  useEffect(() => {
    if (connector?.nid) {
      fetchData();
    }
  }, [connector, fetchData]);

  return { data, loading };
};

export default useHooksWidgetData;
