// Consts
import { TEXTAREA_TAG, INPUT_TAG, INPUT_TYPE } from './consts';

export default (node) => {
  const nodeName = node.nodeName.toLowerCase();
  let event;
  const type = node.type;
  let descriptor;
  let initialValue;

  const deletePropertySafe = (elem, prop) => {
    const desc = Object.getOwnPropertyDescriptor(elem, prop);
    if (desc && desc.configurable) {
      delete elem[prop];
    }
  };

  if ((nodeName === INPUT_TAG && type === INPUT_TYPE) || nodeName === TEXTAREA_TAG) {
    descriptor = Object.getOwnPropertyDescriptor(node, 'value');
    event = document.createEvent('UIEvents');
    event.initEvent('focus', false, false);
    node.dispatchEvent(event);

    initialValue = node.value;
    node.value = initialValue + '#';
    deletePropertySafe(node, 'value');
    node.value = initialValue;

    event = document.createEvent('HTMLEvents');
    event.initEvent('propertychange', false, false);
    event.propertyName = 'value';
    node.dispatchEvent(event);

    event = document.createEvent('HTMLEvents');
    event.initEvent('input', true, false);
    node.dispatchEvent(event);

    if (descriptor) {
      Object.defineProperty(node, 'value', descriptor);
    }
  }
};
