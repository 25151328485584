interface IPersistTokenService {
  get: () => string | undefined;
  set: (token: string) => void;
  clear: () => void;
}

const TOKEN_KEY = 'wedoio-userToken';

class PersistTokenService implements IPersistTokenService {
  get(): string {
    return localStorage.getItem(TOKEN_KEY) || '';
  }

  set(token) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  clear() {
    localStorage.removeItem(TOKEN_KEY);
  }
}

export default new PersistTokenService();
