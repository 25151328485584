import { ICompany } from '../../../../types/api-Types';
import { RootState } from '../../../rootReducer';
import { RequestStatus } from '../../types';
import { CompaniesListState } from './types';

export interface CompaniesDataSelected extends CompaniesListState {
  isReady: boolean;
}

export const selectCompaniesListState = (state: RootState): CompaniesDataSelected => {
  const { data, loading, error } = state.companies.list;

  return {
    data,
    loading,
    error,
    isReady: loading === RequestStatus.fulfilled,
  };
};

export const selectCompanyById = (id: string) => (
  state: RootState
): ICompany | undefined => {
  return state.companies.list.data.find(({ nid }) => nid === id);
};

export const selectCompaniesListLoading = (state: RootState): boolean => {
  const { loading } = state.companies.list;

  return loading === RequestStatus.pending;
};

export const selectCompanyByConnectorId = (connectorId?: string) => (
  state: RootState
): ICompany | undefined => {
  return state.companies.list.data.find(({ field_integrations }) =>
    field_integrations.find(({ nid }) => nid === connectorId)
  );
};
