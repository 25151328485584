import { EditorView } from '@codemirror/view';
import { Extension } from '@codemirror/state';
import { HighlightStyle, tags as t } from '@codemirror/highlight';

const darkBackground = '#F5F8FA';
const highlightBackground = 'rgba(84, 173, 168, 0.1)';
const background = '#ffffff';
const selection = 'rgba(84, 173, 168, 0.1)';
const cursor = '#10161A';

/// The editor theme styles
export const theme = EditorView.theme(
  {
    '&': {
      backgroundColor: background,
    },

    '.cm-content': {
      caretColor: cursor,
    },

    '&.cm-focused .cm-cursor': { borderLeftColor: cursor },
    '&.cm-focused .cm-selectionBackground, .cm-selectionBackground, ::selection': {
      backgroundColor: selection,
    },

    '.cm-panels': { backgroundColor: darkBackground },

    '.cm-searchMatch': {
      backgroundColor: '#72a1ff59',
      outline: '1px solid #457dff',
    },
    '.cm-searchMatch.cm-searchMatch-selected': {
      backgroundColor: '#6199ff2f',
    },

    '.cm-activeLine': { backgroundColor: highlightBackground },
    '.cm-selectionMatch': { backgroundColor: '#aafe661a' },

    '.cm-matchingBracket, .cm-nonmatchingBracket': {
      backgroundColor: '#bad0f847',
      outline: '1px solid #515a6b',
    },

    '.cm-gutters': {
      backgroundColor: '#f5f8fa',
      color: '#5C7080',
      border: 'none',
    },

    '.cm-activeLineGutter': {
      backgroundColor: highlightBackground,
    },

    '.cm-foldPlaceholder': {
      backgroundColor: 'transparent',
      border: 'none',
      color: '#ddd',
    },

    '.cm-tooltip': {
      border: '1px solid #54ada875',
      borderRadius: '10px',
      boxShadow: '3px 3px 3.5px rgba(84, 173, 168, 0.3)',
      color: '#303030',
      backgroundColor: darkBackground,
    },
    '.cm-tooltip-autocomplete': {
      '& > ul > li[aria-selected]': {
        backgroundColor: highlightBackground,
      },
    },
  },
  { dark: false }
);

/// The highlighting style for code
export const highlightStyle = HighlightStyle.define([
  { tag: t.keyword, color: '#d16004' },
  {
    tag: [t.name, t.deleted, t.character, t.propertyName, t.macroName],
    color: '#2578af',
  },
  { tag: [t.function(t.variableName), t.labelName], color: '#108f88' },
  { tag: [t.color, t.constant(t.name), t.standard(t.name)], color: '#7e57d9' },
  { tag: [t.definition(t.name), t.separator] },
  {
    tag: [
      t.typeName,
      t.className,
      t.number,
      t.changed,
      t.annotation,
      t.modifier,
      t.self,
      t.namespace,
    ],
    color: '#b167bf',
  },
  {
    tag: [
      t.operator,
      t.operatorKeyword,
      t.url,
      t.escape,
      t.regexp,
      t.link,
      t.special(t.string),
    ],
    color: '#669EFF',
  },
  { tag: [t.comment], color: '#879faf' },
  { tag: [t.meta], color: '#303030' },
  { tag: t.strong, fontWeight: 'bold' },
  { tag: t.emphasis, fontStyle: 'italic' },
  { tag: t.strikethrough, textDecoration: 'line-through' },
  { tag: t.link, color: '#879faf', textDecoration: 'underline' },
  { tag: t.heading, fontWeight: 'bold', color: '#2578af' },
  { tag: [t.bool], color: '#7e57d9' },
  { tag: [t.atom, t.special(t.variableName)], color: '#108f88' },

  { tag: [t.processingInstruction, t.string, t.inserted], color: '#2578af' },
  { tag: t.invalid, color: '#FF7373' },
]);

/// Extension to enable the theme (both the editor theme and
/// the highlight style).
export const themeExt: Extension = [theme, highlightStyle];
