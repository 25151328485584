import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import { Dialog, Classes } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import CloseIcon from '../icons/CloseIcon';
import User from '../Wedoio/User/User';

// Types
import { EUser } from 'src/types/enums';

// Actions
import { logoutUser } from '../../redux/slices/user/thunks';
import { reset } from '../../redux/slices/user/userSlice';

// Styles
import './styles.scss';

export interface IHeaderMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const HeaderMenu: FC<IHeaderMenuProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['menu']);

  const handleLogout = useCallback(() => {
    dispatch(logoutUser());
    dispatch(reset());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Dialog
      backdropClassName="dialog-backdrop"
      className="dialogMenu"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="dialog-close-button" onClick={onClose}>
        <CloseIcon />
      </div>

      <div className={`${Classes.DIALOG_BODY} header-userMenu-dialog`}>
        <User view={EUser.TEASER} />
        <Link className="menu_link" to="/" onClick={onClose}>
          {t('Home')}
        </Link>
        <Link className="menu_link" to="/settings/user-edit" onClick={onClose}>
          {t('Account Settings')}
        </Link>
        <Link className="menu_link" to="/settings/companies" onClick={onClose}>
          {t('Companies')}
        </Link>
        <Link className="menu_link" to="/connectors/" onClick={onClose}>
          {t('Connectors')}
        </Link>
        <Link className="menu_link" to="#" onClick={handleLogout}>
          {t('Log Out')}
        </Link>
      </div>
    </Dialog>
  );
};

export default HeaderMenu;
