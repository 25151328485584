import { createAsyncThunk } from '@reduxjs/toolkit';
// Services
import AuthService from '../../../../services/AuthService';
import PersistTokenService from '../../../../services/PersistTokenService';
import {
  SignupStep1,
  SignupStep2,
  SignupStep3,
  SignupResendCode,
} from '../../../../services/AuthService';
import {
  SignupFirstStepSuccessResult,
  SignupLastStepSuccessResult,
  ResendCodeSuccessResult,
  IGetTextSuccesResult,
  IGetTextPayload,
} from './types';

export const submitSignup = createAsyncThunk<
  SignupFirstStepSuccessResult | {} | SignupLastStepSuccessResult,
  SignupStep1 | SignupStep2 | SignupStep3
>(
  'auth/submitSignup',
  async (payload: SignupStep1 | SignupStep2 | SignupStep3, { rejectWithValue }) => {
    try {
      const { data } = await AuthService.signup(payload);

      if (data?.errors) {
        return rejectWithValue({ errors: data?.errors });
      }

      if (!data?.result) {
        return rejectWithValue({});
      }

      if (data?.result.user_token) {
        PersistTokenService.set(data.result.user_token.token);
      }

      return {
        registration_key: data.result?.registration_key,
        company: data.result?.company,
        token: data.result?.user_token?.token,
        integration: data.result?.integration,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const resendCode = createAsyncThunk<ResendCodeSuccessResult, SignupResendCode>(
  'auth/resendCode',
  async (payload: SignupResendCode, { rejectWithValue }) => {
    try {
      const { data } = await AuthService.signup(payload);
      if (data?.error) {
        return rejectWithValue({ error: data?.error });
      }
      return { status: data.result.status };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const getSignupText = createAsyncThunk<
  { text: IGetTextSuccesResult },
  IGetTextPayload
>('auth/getSignupText', async (payload: IGetTextPayload, { rejectWithValue }) => {
  try {
    const { data } = await AuthService.getSignupText(payload);
    return { text: data };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response);
  }
});
