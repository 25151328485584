import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import AuthService from '../../../../services/AuthService';

// Types
import { RequestType } from '../../../../services/BaseApiService';
import { RequestStatus } from '../../types';
import {
  ResetPasswordActionPayload,
  ResetPasswordActionSuccessResult,
  ResetPasswordState,
} from './types';

// Utils
import { createThunkReducers } from '../../utils';

export const resetPassword = createAsyncThunk<
  ResetPasswordActionSuccessResult,
  ResetPasswordActionPayload
>(
  'auth/resetPassword',
  async (data: ResetPasswordActionPayload, { rejectWithValue }): Promise<any | void> => {
    try {
      await AuthService.resetPassword(data);
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

const initialState: ResetPasswordState = {
  error: null,
  submit: RequestStatus.idle,
  initialValues: {
    username: '',
  },
};

const resetPasswordSlice = createSlice({
  name: 'auth.resetPassword',
  initialState,
  reducers: {
    reset: (): ResetPasswordState => initialState,
  },
  extraReducers: {
    ...createThunkReducers<ResetPasswordState>(resetPassword, RequestType.post),
  },
});

export const { reset } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
