import React, { FC, ReactNode } from 'react';
import { Col } from 'react-bootstrap';
// Types
import { IColumn } from './block-types';
// Utils
import getClassName from '../utils/getClassName';

export interface IColumnProps {
  size: number;
  children: ReactNode;
  className?: string;
}

const Column: FC<IColumnProps> = ({ children, size, className }) => {
  return (
    <Col sm={size} className={className}>
      {children}
    </Col>
  );
};

export const mapColumnProps = ({ children, classes = [], size }: IColumn, builder) => ({
  children: builder(children),
  className: getClassName(classes),
  size,
});

export default Column;
