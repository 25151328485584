import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { RequestType } from '../../../../services/BaseApiService';
import { RequestStatus } from '../../types';
import { ILinksState, EMassActions } from './types';
import { IValueKey } from '../../../../components/generic-selector/GenericSelector';
// Utils
import { createThunkReducers } from '../../utils';
import { getTableColumnsByType, getFormByType } from './utils';
// Thunks
import { getLinks, updateLinks, getTypeOptions, getEditForm, submitForm } from './thunks';
import { ESort } from 'src/types/enums';

export const LINKS_PER_PAGE = 10;

const initialState: ILinksState = {
  data: {
    links: [],
    linksTypes: null,
    totalCount: 0,
    formTypes: null,
  },
  table: {
    columns: null,
  },
  selectedLinks: [],
  isSelectAll: false,
  loading: RequestStatus.idle,
  submit: RequestStatus.idle,
  massActionLoading: RequestStatus.idle,
  error: null,
  optionsLoading: RequestStatus.idle,
  selectedType: undefined,
  selectedAction: undefined,
  currentPage: 1,
  searchKeyword: undefined,
  typeOptions: [],
  form: [],
  formLoading: RequestStatus.idle,
};

const linksSlice = createSlice({
  name: 'dashboard.links',
  initialState,
  reducers: {
    toggleSelectedLink: (
      state: ILinksState,
      action: PayloadAction<string>
    ): ILinksState => {
      const { payload: id } = action;

      const selectedLinks = state.selectedLinks.includes(id)
        ? state.selectedLinks.filter((selectedId) => selectedId !== id)
        : [...state.selectedLinks, id];

      return {
        ...state,
        selectedLinks,
        isSelectAll:
          state.data.links.length === selectedLinks.length ? state.isSelectAll : false,
      };
    },

    toggleSelectVisibleLinks: (state: ILinksState): ILinksState => {
      const { selectedLinks, data } = state;

      return {
        ...state,
        selectedLinks:
          selectedLinks.length === data.links.length
            ? []
            : data.links.map(({ id }) => id),
      };
    },

    toggleSelectAllLinks: (state: ILinksState): ILinksState => ({
      ...state,
      isSelectAll: !state.isSelectAll,
    }),

    setCurrentPage: (state: ILinksState, action: PayloadAction<number>): ILinksState => {
      return {
        ...state,
        selectedLinks: [],
        isSelectAll: false,
        currentPage: action.payload,
      };
    },
    setSelectedType: (
      state: ILinksState,
      action: PayloadAction<IValueKey>
    ): ILinksState => ({
      ...state,
      data: {
        ...state.data,
        links: [],
        totalCount: 0,
      },
      selectedLinks: [],
      isSelectAll: false,
      selectedType: action.payload,
      table: {
        columns: getTableColumnsByType(state.data.linksTypes, action.payload.Key),
      },
      form: getFormByType(state.data.formTypes, action.payload.Key),
      currentPage: 1,
      searchKeyword: '',
      sortBy: initialState.sortBy,
      sortDirection: initialState.sortDirection,
    }),
    setSelectedAction: (state: ILinksState, action: PayloadAction<EMassActions>) => {
      return {
        ...state,
        selectedAction: action.payload,
      };
    },
    setShearchKeyword: (state: ILinksState, action: PayloadAction<string>) => {
      return { ...state, searchKeyword: action.payload, currentPage: 1 };
    },
    setSort: (
      state: ILinksState,
      action: PayloadAction<{ sortBy: string; sortDirection: ESort }>
    ) =>
      state.sortBy === action.payload.sortBy &&
      state.sortDirection === action.payload.sortDirection
        ? {
            ...state,
            sortBy: initialState.sortBy,
            sortDirection: initialState.sortDirection,
          }
        : {
            ...state,
            sortBy: action.payload.sortBy,
            sortDirection: action.payload.sortDirection,
          },
    clearSelectedLinks: (state: ILinksState): ILinksState => ({
      ...state,
      selectedLinks: [],
      isSelectAll: false,
    }),

    clearFormState: (state: ILinksState): ILinksState => ({
      ...state,
      form: null,
    }),

    resetFormState: (state: ILinksState): ILinksState => ({
      ...state,
      form: getFormByType(state.data.formTypes, state.selectedType?.Key),
    }),

    reset: (): ILinksState => initialState,
  },
  extraReducers: {
    ...createThunkReducers<ILinksState>(getLinks, RequestType.put, 'loading'),
    ...createThunkReducers<ILinksState>(
      updateLinks,
      RequestType.put,
      'massActionLoading'
    ),
    ...createThunkReducers<ILinksState>(
      getTypeOptions,
      RequestType.put,
      'optionsLoading'
    ),
    ...createThunkReducers<ILinksState>(getEditForm, RequestType.put, 'formLoading'),
    ...createThunkReducers<ILinksState>(submitForm, RequestType.put, 'submit'),
    [String(getLinks.fulfilled)]: (state: ILinksState, action): ILinksState => {
      return {
        ...state,
        data: {
          ...action.payload.data,
          linksTypes: state.data.linksTypes,
          formTypes: state.data.formTypes,
        },
        table: {
          columns: state.table.columns,
        },
        loading: RequestStatus.fulfilled,
      };
    },
    [String(getTypeOptions.fulfilled)]: (state: ILinksState, action): ILinksState => {
      return {
        ...state,
        data: {
          links: state.data.links,
          totalCount: state.data.totalCount,
          linksTypes: action.payload.linksTypes,
          formTypes: action.payload.forms,
        },
        table: {
          columns: getTableColumnsByType(
            action.payload.linksTypes,
            action.payload.typeOptions[0]?.Key
          ),
        },
        typeOptions: action.payload.typeOptions,
        selectedType: action.payload.typeOptions[0],
        optionsLoading: RequestStatus.fulfilled,
      };
    },
    [String(getEditForm.fulfilled)]: (state: ILinksState, action): ILinksState => {
      return {
        ...state,
        form: action.payload.form,
        formLoading: RequestStatus.fulfilled,
      };
    },
  },
});

export const {
  setSelectedType,
  setSelectedAction,
  setCurrentPage,
  setShearchKeyword,
  setSort,
  toggleSelectedLink,
  toggleSelectVisibleLinks,
  toggleSelectAllLinks,
  clearSelectedLinks,
  clearFormState,
  resetFormState,
  reset,
} = linksSlice.actions;

export default linksSlice.reducer;
