import React, { FC } from 'react';
// Types
import { RequestType } from '../../../../services/BaseApiService';
// Style
import './style.scss';

interface IRequestTypeTagProps {
  type: RequestType;
}

const RequestTypeTag: FC<IRequestTypeTagProps> = ({ type }) => {
  return <span className={`request-type-tag ${type}`}>{type.toUpperCase()}</span>;
};

export default RequestTypeTag;
