import React, { FC, ReactNode } from 'react';

// Styles
import './form-actions-styles.scss';

interface IFormActionsProps {
  children: ReactNode;
}

const FormActions: FC<IFormActionsProps> = ({ children }) => (
  <div className="form-actions">{children}</div>
);

export default FormActions;
