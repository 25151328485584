import { InputGroup } from '@blueprintjs/core';
import * as React from 'react';
import { FC, useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Types
import { ICompany } from 'src/types/api-Types';
import { RootState } from '../../../../redux/rootReducer';
import { selectCompaniesListState } from '../../../../redux/slices/companies/list/selectors';
import { CompaniesListState } from '../../../../redux/slices/companies/list/types';
import { selectSelectedCompanyState } from '../../../../redux/slices/companies/selected-company/selectors';
import { RequestStatus } from '../../../../redux/slices/types';
// Components
import CompanyIntegrationList from './CompanyIntegrationList';
import CompanyData from '../../../company-data/CompanyData';
import Tag from '../../../tag/Tag';
import CompanyActions from '../CompanyActions/CompanyActions';

export interface ICompanyIntegrationState {
  selectedCompany?: ICompany;
  companiesList: CompaniesListState;
}

const CompanyIntegrations: FC = () => {
  const { selectedCompany, companiesList } = useSelector<
    RootState,
    ICompanyIntegrationState
  >((state) => ({
    selectedCompany: selectSelectedCompanyState(state),
    companiesList: selectCompaniesListState(state),
  }));

  const [filterValue, setFilterValue] = useState<string>('');
  const [filterTags, setFilterTags] = useState<string[]>([]);

  const { t } = useTranslation(['home']);

  const handleFilterChange = useCallback((e) => {
    setFilterValue(e.currentTarget.value);
  }, []);

  const handleTagClick = useCallback(
    (tag: string) => {
      if (!filterTags.includes(tag)) {
        setFilterTags([...filterTags, tag]);
      }
    },
    [filterTags]
  );

  const handleTagRemove = useCallback((removeTag) => {
    setFilterTags((tags) => tags.filter((tag) => tag !== removeTag));
  }, []);

  const isLoading = companiesList.loading === RequestStatus.pending;

  return (
    <div className="company-integrations">
      <div className="integrations-title">
        <CompanyData loading={isLoading || !selectedCompany} />
        {selectedCompany && <CompanyActions />}
      </div>
      <div className="integrations-wrapper">
        <Row className="integrations-row">
          <Col md={7}>
            <InputGroup placeholder={t('inputs.Filter')} onChange={handleFilterChange} />
          </Col>
          <Col md={12} className="integration-filter-tags">
            {filterTags.map((tag) => (
              <Tag
                onClick={() => {
                  handleTagRemove(tag);
                }}
                key={tag}
                icon="cross"
              >
                {tag}
              </Tag>
            ))}
          </Col>
        </Row>

        <Row className="compIntegration">
          <Col xs={12}>
            <CompanyIntegrationList
              integrations={selectedCompany?.field_integrations || []}
              filterValue={filterValue}
              filterTags={filterTags}
              onTagClick={handleTagClick}
              loading={isLoading}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CompanyIntegrations;
