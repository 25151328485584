import React, { FC } from 'react';

// Components
import FormHeading from '../../../components/form-heading/FormHeading';

// Types
import { ITextBlock } from './block-types';

// Utils
import getClassName from '../utils/getClassName';

export interface ITextBlockProps {
  markup: string;
  className?: string;
}

const TextBlock: FC<ITextBlockProps> = ({ markup, className }) => {
  return <FormHeading className={className}>{markup}</FormHeading>;
};

export default TextBlock;

export const mapTextBlockProps = ({ markup, classes }: ITextBlock) => ({
  markup,
  className: getClassName(classes),
});
