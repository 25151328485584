const getImageAsBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      resolve(String(fileReader.result));
    };

    fileReader.onerror = reject;

    fileReader.readAsDataURL(file);
  });

export default getImageAsBase64;
