import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { ResponsiveContainer, BarChart, XAxis, Bar, Tooltip, Legend } from 'recharts';
import { NonIdealState } from '@blueprintjs/core';
// Components
import Loader from '../../../../components/loader/Loader';
// Types
import { ILogsDiagramProps } from './types';
import { EState } from '../../../../types/api-Types';
import { IDateRange } from 'src/redux/slices/dashboard/charts/types';
// Consts
import { taskColors, labels } from './consts';
// Utils
import { getFormattedBarsData } from './utils';

const categories = [...Object.values(EState)];

interface IActivityBarChart extends ILogsDiagramProps {
  dateRange: IDateRange;
}

const ActivityBarChart: FC<IActivityBarChart> = ({ data, isLoading, dateRange }) => {
  const { t } = useTranslation(['dashboard']);

  const barsData = useMemo(() => getFormattedBarsData(data, dateRange), [
    data,
    dateRange,
  ]);

  // Formatters
  const labelFormatter = useCallback(
    (value) => {
      return t(`titles.${labels.get(value)}`);
    },
    [t]
  );

  const tooltipFormatter = useCallback(
    (value, name) => [value, t(`titles.${labels.get(name)}`)],
    [t]
  );

  return (
    <>
      <Row className={`shadow-card diagrams-container`}>
        <Loader loading={isLoading} withShadow />
        {!barsData.length && !isLoading && (
          <NonIdealState
            icon="error"
            title={t('No logs found')}
            className="logs-non-ideal-state"
          />
        )}
        {!!barsData.length && (
          <Col sm={12} className="diagram">
            <ResponsiveContainer>
              <BarChart data={barsData}>
                <defs>
                  {categories.map((key, index) => (
                    <linearGradient
                      id={`activityDiagramColor${labels.get(key)}`}
                      x1="92%"
                      y1="100%"
                      x2="8%"
                      y2="0%"
                      key={index}
                    >
                      <stop offset="0%" stopColor={taskColors[key][1]} />
                      <stop offset="100%" stopColor={taskColors[key][2]} />
                    </linearGradient>
                  ))}
                </defs>
                <XAxis
                  dataKey="date"
                  domain={['auto', 'auto']}
                  interval="preserveStartEnd"
                />
                <Tooltip
                  cursor={{ fill: 'rgba(167, 182, 194, 0.3)' }}
                  formatter={tooltipFormatter}
                />
                <Legend
                  formatter={labelFormatter}
                  wrapperStyle={{ height: 'max-content' }}
                />
                {categories.map((key, index) => (
                  <Bar
                    dataKey={key}
                    fill={`url(#activityDiagramColor${labels.get(key)})`}
                    stroke={taskColors[key][0]}
                    key={index}
                    stackId="date"
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ActivityBarChart;
