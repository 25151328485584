import { useContext } from 'react';

import {
  FormVisibilityContext,
  IFormVisibilityContext,
} from './FormVisibilityContextProvider';

const useFormVisibilityContext = (): IFormVisibilityContext => {
  return useContext(FormVisibilityContext);
};

export default useFormVisibilityContext;
