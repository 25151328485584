import { Card, Elevation } from '@blueprintjs/core';
import React, { FC, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Types
import { ICompany } from 'src/types/api-Types';

// Components
import CompanyLogo, { CompanyLogoSizes } from '../../../company-logo/CompanyLogo';
import Button, { ButtonSize } from '../../../button/Button';
import { selectCompany } from 'src/redux/slices/companies/selected-company/selectedCompanySlice';

export interface ICompanyTeaserMediumProps {
  company: ICompany;
}

const CompanyTeaserMedium: FC<ICompanyTeaserMediumProps> = ({ company }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['companies']);

  const handleSetCompany = useCallback(
    async (event) => {
      event.stopPropagation();

      await dispatch(selectCompany(company));

      history.push('/');
    },
    [history, company, dispatch]
  );

  const handleEditRedirect = useCallback(
    (id: string) => (event) => {
      event.stopPropagation();

      history.push('/settings/companies/edit/' + id);
    },
    [history]
  );

  return (
    <Card
      className="cardCompany company medium"
      elevation={Elevation.TWO}
      interactive
      onClick={handleSetCompany}
    >
      <Row>
        <Col className="logo-holder" xs={4} style={{ textAlign: 'center' }}>
          <div className="company-logo">
            <CompanyLogo
              src={company.field_company_logo?.teaser}
              size={CompanyLogoSizes.smallSecondary}
            />
          </div>
        </Col>
        <Col xs={5}>
          <span className="company-title">{company.title}</span>
          <span className="company-integrations">
            {t('integrations', {
              count: company.field_integrations.length,
              target:
                company.field_integrations.length === 1 ? 'integration' : 'integrations',
            })}
          </span>
        </Col>
        <Col xs={3}>
          <Button
            size={ButtonSize.small}
            onClick={handleEditRedirect(company.nid)}
            outlined
            className="btnCompEdit"
          >
            {t('buttons.Edit')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default CompanyTeaserMedium;
