import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
// Selector
import { selectConnectorState } from '../../../../../redux/slices/dashboard/connector/selectors';
// Types
import { RequestStatus } from '../../../../../redux/slices/types';
// Utils
import stringifyStateError from '../../../../../utils/stringifyStateError';
// Services
import HooksManagerService from '../../../../../services/HooksManagerService';

export type ChangeHookStatus = () => void;

interface IUseChangeHookStatus {
  initialStatus: boolean;
  entity?: string;
  event: string;
  widgetKey: string;
}

const useChangeHookStatus = ({
  entity,
  event,
  widgetKey,
  initialStatus,
}: IUseChangeHookStatus): [boolean, RequestStatus, ChangeHookStatus] => {
  const [submit, setSubmit] = useState<RequestStatus>(RequestStatus.idle);
  const [status, setStatus] = useState<boolean>(initialStatus);

  const { data: connector } = useSelector(selectConnectorState);

  const { t } = useTranslation(['dashboard']);

  const changeStatus = useCallback(async () => {
    if (connector?.nid) {
      setSubmit(RequestStatus.pending);

      try {
        const { data } = await HooksManagerService.changeStatus({
          connectorId: connector.nid,
          entity,
          event,
          widgetKey,
        });

        setSubmit(RequestStatus.fulfilled);
        const newStatus = entity
          ? data.output.status[entity][event]
          : data.output.status[event];
        setStatus(newStatus);
      } catch (error) {
        const message = stringifyStateError(error);

        toastr.error(t('alert.Failed hooks'), message);
        setSubmit(RequestStatus.rejected);
      }
    }
  }, [connector, entity, event, widgetKey, t]);

  return [status, submit, changeStatus];
};

export default useChangeHookStatus;
