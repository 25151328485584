import React, { FC, useCallback } from 'react';
// Components
import TreeNodesList from './TreeNodesList';
// Styles
import './style.scss';

interface ITreeProps {
  source: object;
  onSelect?: (path: string) => void;
}

const Tree: FC<ITreeProps> = ({ source, onSelect }) => {
  const handleSelect = useCallback(
    (path: string) => {
      if (onSelect) {
        onSelect(path);
      }
    },
    [onSelect]
  );

  return <TreeNodesList onClick={handleSelect} path="" label="" nodes={source} />;
};

export default Tree;
