import React, { FC, ReactNode } from 'react';

// Types
import { IContextualBlock } from '../block-types';

// Utils
import getClassName from '../../utils/getClassName';

// Styles
import './style.scss';

export interface IContextualBlockProps {
  children: ReactNode;
  context: ReactNode;
  className?: string;
}

const ContextualBlock: FC<IContextualBlockProps> = ({ children, className, context }) => {
  return (
    <div className="contextual-block">
      <div className={`context ${className}`}>{context}</div>
      {children}
    </div>
  );
};

export default ContextualBlock;

export const mapContextualBlockProps = (
  { children, classes = [], context }: IContextualBlock,
  builder
) => {
  return {
    children: builder(children),
    context: builder(context),
    className: getClassName(classes),
  };
};
