import React, { FC, useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMutation, useQueryCache } from 'react-query';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
// Services
import TableManagerService, {
  IEditTableRowSubmit,
} from 'src/services/TableManagerService';
// Components
import Checkbox from '../../../../components/checkbox/Checkbox';
import RowMenu from './RowMenu';
import TableCell from '../../../../components/table/TableCell';
import TableForm from './TableForm';
// Types
import { ITableRowProps } from '../../../../components/table/TableRow';
import { ISubmitForm } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
import { SerializedError } from 'src/redux/slices/types';
// Hooks
import useUpdateRows from '../useUpdateRows';
// Utils
import getColSize from 'src/components/table/getColSize';
import stringifyStateError from 'src/utils/stringifyStateError';
import get from 'lodash/get';

export interface ITableRowWithOperationsProps extends ITableRowProps {
  tableName: string;
  connectorId?: string;
}

const TableRow: FC<ITableRowWithOperationsProps> = ({
  data,
  columns,
  onSelect,
  isSelected,
  tableName,
  connectorId,
  color,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const colSize = useMemo(() => getColSize(columns), [columns]);

  const queryCache = useQueryCache();

  const { t } = useTranslation(['dashboard', 'dynamicLayout']);

  const onSuccess = useCallback(
    (resultData, reqParams) => {
      if (!reqParams.responseOnRow) {
        queryCache.invalidateQueries(tableName);
      } else {
        setIsOpen(true);
      }
    },
    [queryCache, tableName]
  );

  const { updateRows, updateLoading, data: form } = useUpdateRows({ onSuccess });

  const onOperationClick = useCallback(
    (operation) => {
      if (connectorId) {
        updateRows({
          connectorId,
          tableName,
          records: [data],
          action: operation.action,
          responseOnRow: operation.open_row,
        });
      }
    },
    [tableName, connectorId, data, updateRows]
  );

  const [editRow] = useMutation<any, SerializedError, IEditTableRowSubmit>(
    TableManagerService.submitRowForm,
    {
      onSuccess: (resultData, reqParams) => {
        queryCache.invalidateQueries(tableName);
      },
      onError: (updateError) => {
        toastr.error(
          t('dashboard:alert.Failed'),
          t('dashboard:alert.Error record update', {
            error: stringifyStateError(updateError),
          })
        );
      },
    }
  );

  const handleSubmit = useCallback(
    async ({ data: formData, callbackUrl: callback, submitPayload }: ISubmitForm) => {
      if (connectorId) {
        editRow({
          connectorId,
          formData,
          callback,
          submitPayload,
        });
      }
    },
    [editRow, connectorId]
  );

  const closeForm = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Row className="table-row" style={{ background: color }}>
        <Col xs={1} className="table-checkbox">
          <Checkbox
            checked={isSelected}
            onChange={() => {
              onSelect(data.id);
            }}
          />
        </Col>
        {columns?.map(({ field, type, size, className }) => {
          const cell = get(data.values, field);
          return type === 'operation' ? (
            <div key={field} className="table-row-actions pull-right">
              <RowMenu options={cell} onOptionClick={onOperationClick} />
            </div>
          ) : (
            <TableCell
              className={className}
              key={field}
              type={type}
              value={cell}
              size={size ?? colSize}
            />
          );
        })}
      </Row>
      <TableForm
        structure={form}
        onSubmit={handleSubmit}
        isLoading={updateLoading}
        isOpen={isOpen}
        onClose={closeForm}
      />
    </>
  );
};

export default TableRow;
