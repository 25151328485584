import { parse } from 'query-string';

export const jsTimeToString = (timestamp: number | null): string | null => {
  if (!timestamp) {
    return null;
  }

  const dateObj = new Date(timestamp * 1000);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return dateObj.toLocaleDateString('en-GB', options);
};

export const filterString = (str: string = '', filterValue: string = '') => {
  return str.trim().toLowerCase().indexOf(filterValue.trim().toLowerCase()) > -1;
};

export const getQueryParameterByName = (name: string, url: string) => {
  const parsed = parse(url);
  return parsed[name];
};

export const getQueryParameters = (url: string) => {
  return parse(url);
};

export const getMilliseconds = (minutes: number = 10) => minutes * 1000 * 60;
