import React, { FC } from 'react';

// Components
import Avatar from '../../../components/avatar/Avatar';

export interface IUserProps {
  src: string;
  loading?: boolean;
}

const UserTeaserXs: FC<IUserProps> = ({ src, loading }) => (
  <Avatar src={src} loading={loading} />
);

export default UserTeaserXs;
