import componentsMap from '../../components/dynamic-layout/components/componentsMap';
import Component, { mapComponentProps } from './component-config/Component';
import DashboardLogs, { mapDashboardLogsProps } from './logs/DashboardLogs';
// Charts
import ActivityBarChart, {
  mapActivityBarChartProps,
} from './diagrams/ActivityBarChartContainer';
import ActionsPieChart, {
  mapActionsPieChartProps,
} from './diagrams/ActionsPieChartContainer';
import ProcessingTimeChart, {
  mapProcessingTimeChartProps,
} from './diagrams/ProcessingTimeChartContainer';
import DashboardCharts, { mapDashboardChartsProps } from './diagrams/DashboardCharts';
import IntegrationConfig, {
  mapIntegrationConfigProps,
} from './integration-config/IntegrationConfig';
import DashboardTitle, { mapDashboardTitleProps } from './dasboard-title/DashboardTitle';
import { DashboardTypes } from './types';
import DynamicFormActions, { mapDynamicFromActionsProps } from './DynamicFromActions';
import LinksManager, { mapLinksManagerProps } from './links-manager/LinksManager';
import JobDirector, { mapJobDirectorProps } from './job-director/JobDirector';
import DashboardTable, { mapDashboardTableProps } from './table/DashboardTable';
import Cron, { mapCronProps } from './cron/Cron';
import BarChart, { mapBarChartProps } from './charts-components/BarChart';
import LineChart, { mapLineChartProps } from './charts-components/LineChart';
import PieChart, { mapPieChartProps } from './charts-components/PieChart';

// Integration-specific components
import WebcrmHooksManager, {
  mapWebcrmHooksManagerProps,
} from './integrations/webcrm-uniconta/WebcrmHooksManager';
import UnicontaHooksManager, {
  mapUniontaHooksManagerProps,
} from './integrations/webcrm-uniconta/UnicontaHooksManager';

import ComponentPrototype, {
  mapComponentPrototypeProps,
} from './component-prototype/ComponentPrototype';

// Dashboard components
const dashboardComponentsMap = new Map(componentsMap);
dashboardComponentsMap.set(DashboardTypes.componentConfig, [
  Component,
  mapComponentProps,
]);
dashboardComponentsMap.set(DashboardTypes.integrationConfig, [
  IntegrationConfig,
  mapIntegrationConfigProps,
]);
dashboardComponentsMap.set(DashboardTypes.dashboardLogs, [
  DashboardLogs,
  mapDashboardLogsProps,
]);
dashboardComponentsMap.set(DashboardTypes.dashboardTitle, [
  DashboardTitle,
  mapDashboardTitleProps,
]);
dashboardComponentsMap.set(DashboardTypes.linksManager, [
  LinksManager,
  mapLinksManagerProps,
]);
dashboardComponentsMap.set(DashboardTypes.jobDirector, [
  JobDirector,
  mapJobDirectorProps,
]);
dashboardComponentsMap.set(DashboardTypes.cron, [Cron, mapCronProps]);
// Charts
dashboardComponentsMap.set(DashboardTypes.activityBarChart, [
  ActivityBarChart,
  mapActivityBarChartProps,
]);
dashboardComponentsMap.set(DashboardTypes.actionsPieChart, [
  ActionsPieChart,
  mapActionsPieChartProps,
]);
dashboardComponentsMap.set(DashboardTypes.tableManager, [
  DashboardTable,
  mapDashboardTableProps,
]);

dashboardComponentsMap.set(DashboardTypes.processingTimeChart, [
  ProcessingTimeChart,
  mapProcessingTimeChartProps,
]);

dashboardComponentsMap.set(DashboardTypes.dashboardCharts, [
  DashboardCharts,
  mapDashboardChartsProps,
]);

dashboardComponentsMap.set(DashboardTypes.barChart, [BarChart, mapBarChartProps]);

dashboardComponentsMap.set(DashboardTypes.lineChart, [LineChart, mapLineChartProps]);

dashboardComponentsMap.set(DashboardTypes.pieChart, [PieChart, mapPieChartProps]);
// Integration-specific components
dashboardComponentsMap.set(DashboardTypes.webcrmHooksManager, [
  WebcrmHooksManager,
  mapWebcrmHooksManagerProps,
]);
dashboardComponentsMap.set(DashboardTypes.unicontaHooksManager, [
  UnicontaHooksManager,
  mapUniontaHooksManagerProps,
]);
dashboardComponentsMap.set(DashboardTypes.componentPrototype, [
  ComponentPrototype,
  mapComponentPrototypeProps,
]);

// Component forms
export const dashboardFormsComponentsMap = new Map(componentsMap);
dashboardFormsComponentsMap.set(DashboardTypes['form-actions'], [
  DynamicFormActions,
  mapDynamicFromActionsProps,
]);

export default dashboardComponentsMap;
