import React, { FC } from 'react';
// Types
import Avatar, { AvatarSizes } from '../../../components/avatar/Avatar';

// Styles
import './styles.scss';
import {
  ALTER_USER_LAST_NAME,
  ALTER_USER_NAME,
} from '../../../redux/slices/user/userSlice';

export enum UserTeaserSizes {
  middle,
  large,
}

export interface IUserProps {
  src: string;
  firstName?: string;
  lastName?: string;
  loading?: boolean;
  size?: UserTeaserSizes;
}

const UserTeaser: FC<IUserProps> = ({
  firstName,
  lastName,
  loading = false,
  size = UserTeaserSizes.large,
  src,
}) => {
  return (
    <div className="user-teaser">
      <div className="user-teaser-background" style={getDimensions(size)}>
        <Avatar src={src} size={getAvatarSize(size)} loading={loading} />
      </div>
      {size === UserTeaserSizes.large && (
        <span className="user-teaser-name">{`${firstName || ALTER_USER_NAME} ${
          lastName || ALTER_USER_LAST_NAME
        }`}</span>
      )}
    </div>
  );
};

// Utils
const getDimensions = (size: UserTeaserSizes) => {
  const dimensions = {
    [UserTeaserSizes.middle]: { width: '118px', height: '119px' },
    [UserTeaserSizes.large]: { width: '290px', height: '290px' },
  };

  return dimensions[size];
};

const getAvatarSize = (size: UserTeaserSizes): AvatarSizes => {
  const avatarSize = {
    [UserTeaserSizes.middle]: AvatarSizes.middle,
    [UserTeaserSizes.large]: AvatarSizes.large,
  };

  return avatarSize[size] || AvatarSizes.large;
};

export default UserTeaser;
