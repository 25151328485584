import React, { FC } from 'react';
import { FormGroup } from '@blueprintjs/core';
import { useField } from 'formik';

// Components
import JsonEditor from '../../json-editor/JsonEditor';

// Types
import { IJsonEditorOption } from '../../json-editor/options/types';
import { IJsonEditorField } from './input-types';
import { InteractionProps } from 'react-json-view';

// Utils
import getClassName from '../utils/getClassName';

interface IJsonEditorProps {
  label: string;
  name: string;
  description?: string;
  value: object;
  enableEdit?: boolean;
  enableAdd?: boolean;
  enableDelete?: boolean;
  enableClipboard?: boolean;
  valuesSuggestion: IJsonEditorOption[];
  fieldNamesSuggestion: IJsonEditorOption[];
  className?: string;
}

const JsonEditorField: FC<IJsonEditorProps> = ({
  label,
  value = {},
  name,
  enableEdit = false,
  enableAdd = false,
  enableDelete = false,
  enableClipboard = false,
  valuesSuggestion,
  fieldNamesSuggestion,
  className,
}) => {
  const [, meta, helpers] = useField({ name });

  const handleChange = ({ updated_src }: InteractionProps) => {
    helpers.setTouched(true);
    helpers.setValue(updated_src);
  };

  return (
    <FormGroup helperText={meta.touched && meta.error} label={label}>
      <JsonEditor
        src={value}
        onChange={handleChange}
        enableAdd={enableAdd}
        enableDelete={enableDelete}
        enableEdit={enableEdit}
        enableClipboard={enableClipboard}
        valuesSuggestion={valuesSuggestion}
        fieldNamesSuggestion={fieldNamesSuggestion}
        className={className}
      />
    </FormGroup>
  );
};

export default JsonEditorField;

export const mapJsonEditorFieldProps = ({
  key,
  values_suggestion,
  field_suggestion,
  classes,
  label,
  value,
  enableEdit,
  enableAdd,
  enableDelete,
  enableClipboard,
}: IJsonEditorField) => ({
  name: `['${key}']`,
  valuesSuggestion: values_suggestion,
  fieldNamesSuggestion: field_suggestion,
  className: getClassName(classes),
  label,
  value,
  enableEdit,
  enableAdd,
  enableDelete,
  enableClipboard,
});
