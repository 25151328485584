import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import ResponseItem from './ResponseItem';
import Button, { ButtonSize } from '../../../../../../components/button/Button';
import { H6, Icon, NonIdealState } from '@blueprintjs/core';
import Collapse from 'src/components/collapse/Collapse';
// Types
import { IConnectorResponse, CreateConnectorFormData } from 'src/types/internal-Types';

interface IResponsesListProps {
  path: string;
  values: IConnectorResponse[];
}

const ResponsesList: FC<IResponsesListProps> = ({ values, path }) => {
  const { setFieldValue } = useFormikContext<CreateConnectorFormData>();

  const { t } = useTranslation(['connectors']);

  const handleAdd = useCallback(() => {
    const currentValue = [...values];
    currentValue.push({
      description: '',
      statusCode: 200,
    });
    setFieldValue(`${path}.responses`, currentValue);
  }, [setFieldValue, values, path]);

  const handleRemove = useCallback(
    (index: number) => {
      const currentValue = [...values];
      currentValue.splice(index, 1);

      setFieldValue(`${path}.responses`, currentValue);
    },
    [setFieldValue, values, path]
  );

  return (
    <Collapse header={<H6>{t('titles.Responses')}</H6>}>
      <div className="responses-list">
        {values?.length ? (
          values.map((data, index) => (
            <ResponseItem
              onRemove={handleRemove}
              data={data}
              key={index}
              path={`${path}.responses.${index}`}
              index={index}
            />
          ))
        ) : (
          <NonIdealState
            icon={<Icon icon="error" iconSize={40} />}
            description={t('No responses') as string}
          />
        )}
      </div>
      <div className="responses-list-footer">
        <Button onClick={handleAdd} size={ButtonSize.small}>
          {t('buttons.Add response')}
        </Button>
      </div>
    </Collapse>
  );
};

export default ResponsesList;
