import React, { FC, ReactElement } from 'react';
import {
  Elevation,
  Icon,
  Intent,
  Card as BluePrintCard,
  Drawer,
  Position,
} from '@blueprintjs/core';
// Hooks
import useFormVisibilityContext from '../../../components/dynamic-layout/form-visibility-context/useFormVisibilityContext';
// Components
import IntentIcon from '../../../components/intent-icon/IntentIcon';
import CustomScrollbars from 'src/components/custom-scrollbars/CustomScrollbars';
// Styles
import './card-styles.scss';

interface ICardProps {
  logo: ReactElement;
  children: (callback: () => void) => React.ReactElement; // Form;
  intent: Intent;
  className: string;
  loading?: boolean;
}

const Card: FC<ICardProps> = ({
  children,
  logo,
  intent,
  className,
  loading = false,
}: ICardProps) => {
  const { isFormVisible, toggleIsFormVisible } = useFormVisibilityContext();

  const onClose = () => {
    toggleIsFormVisible(false);
  };

  if (loading) {
    className += ' card-skeleton bp3-skeleton';
    return <div className={className} />;
  } else {
    className += ' card-container';
  }

  return (
    <BluePrintCard elevation={Elevation.TWO} className={className}>
      <div className="logo-container">{logo}</div>
      <div className="card-content">
        <IntentIcon intent={intent} />
        {!isFormVisible && (
          <Icon
            className="settings-icon"
            icon="cog"
            onClick={() => toggleIsFormVisible(true)}
          />
        )}
        <Drawer
          isOpen={isFormVisible}
          position={Position.RIGHT}
          usePortal
          title={logo}
          isCloseButtonShown={false}
          vertical={false}
          className="component-config-drawer"
        >
          <CustomScrollbars hideTracksWhenNotNeeded>
            <div className="form-wrapper">{children(onClose)}</div>
          </CustomScrollbars>
        </Drawer>
      </div>
    </BluePrintCard>
  );
};

export default Card;
