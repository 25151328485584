import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { Tab, NonIdealState } from '@blueprintjs/core';
// Components
import Tabs from '../../../../../components/tabs/Tabs';
import HookStatusList from './HookStatusList';
// Types
import { IHookEntity } from '../../../../../types/api-Types';
// Styles
import './styles.scss';

interface IHooksWidgetProps {
  data: IHookEntity | undefined;
  widgetKey: string;
  loading: boolean;
}

const HooksWidget: FC<IHooksWidgetProps> = ({ data, widgetKey, loading }) => {
  const { t } = useTranslation(['dashboard']);
  const isDataEmpty = !data || !Object.values(data).length;

  return (
    <div className="hooks-manager">
      {!loading && !isDataEmpty && data && (
        <Col sm={12}>
          <Tabs id={widgetKey} vertical>
            {Object.entries(data).map(([entity, statuses]) => (
              <Tab
                key={entity}
                id={entity}
                title={entity}
                panel={
                  <HookStatusList data={statuses} entity={entity} widgetKey={widgetKey} />
                }
              />
            ))}
          </Tabs>
        </Col>
      )}
      {loading && (
        <Col sm={12}>
          <Col sm={2} className="bp3-skeleton hooks-manager-skeleton" />
          <Col sm={10} className="bp3-skeleton  hooks-manager-skeleton pull-right" />
        </Col>
      )}
      {!loading && isDataEmpty && (
        <Col sm={12}>
          <NonIdealState icon="error" title={t('No hooks found')} />
        </Col>
      )}
    </div>
  );
};

export default HooksWidget;
