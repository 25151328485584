import React, { FC } from 'react';
import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';
// Components
import componentsMap from '../../components/dynamic-layout/components/componentsMap';
import { Grid } from 'react-bootstrap';

const testStructure = {
  type: 'form',
  children: [
    {
      key: 'test3',
      label: 'Test 3',
      type: 'daterange_input',
      validation: 'required',
      value: [0, 1180126800],
      timePrecision: 'minute',
      format: 'MM-dd-yyyy, HH:mm',
      minDate: 0,
    },
    {
      key: 'test4',
      label: 'Test 4',
      type: 'dateinput',
      validation: '',
      value: 1179867500,

      format: 'MM-dd-yyyy',
      minDate: 1179867600,
    },
    {
      key: 'test',
      label: 'Test 1',
      type: 'mapping-tool',
      value: {
        field_1: '@[var]@ @[field_3]@',
        field_5: [],
        field_6: { field_7: '@fx:func:@[test]@ 1 2 3:efx' },
      },
      source: { field_2: '', field_3: '66666', field_4: [{ test: 4444 }, 7777] },
      destination: { field_8: '', field_9: { field_10: 9999 } },
    },
    {
      type: 'conditional-form-block',
      children: [
        {
          key: 'test2',
          label: 'Test 2',
          type: 'textfield',
          validation: '(when:select:option2;then:required)',
          value: '',
        },
      ],
      effect: 'show',
      fieldName: 'select',
      values: ['option2'],
    },
    {
      key: 'select',
      label: 'Select',
      type: 'select',
      validation: 'required',
      value: '',
      options: { option1: 'Option1', option2: 'Option2' },
    },
    {
      type: 'submit',
    },
  ],
};

const TestPage: FC = () => {
  const handleSubmit = ({ data }) => {
    console.log(data);
  };

  return (
    <Grid>
      <StructureErrorBoundary>
        <DynamicLayout
          componentsMap={componentsMap}
          structure={testStructure}
          callService={handleSubmit}
        />
      </StructureErrorBoundary>
    </Grid>
  );
};

export default TestPage;
