import React, { FC, useState, useCallback, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Menu, Popover, Spinner, Icon, Keys } from '@blueprintjs/core';
// Components
import Scrollbars from '../custom-scrollbars/CustomScrollbars';
import Input from '../input/Input';
// Types
import { IValueKey } from '../generic-selector/GenericSelector';
// Styles
import './styles.scss';

export interface ISuggestProps {
  items: IValueKey[];
  onValueSelected: (value: IValueKey | undefined) => void;
  onInputValueChange: (query: string) => void;
  preSelectedItem?: IValueKey;
  placeholder?: string;
  disabled?: boolean;
  validationError?: string | false | undefined;
  loading?: boolean;
  className?: string;
}

const Suggest: FC<ISuggestProps> = ({
  preSelectedItem,
  onInputValueChange,
  onValueSelected,
  items = [],
  placeholder,
  disabled,
  validationError,
  loading,
  className,
}) => {
  const [selectedItem, setSelectedItem] = useState<IValueKey | undefined>(
    preSelectedItem
  );
  const [query, setQuery] = useState<string>(selectedItem?.Value.toString() ?? '');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t } = useTranslation(['select']);

  const onItemSelect = useCallback(
    (item: IValueKey | undefined) => {
      setSelectedItem(item);
      setQuery(item?.Value ?? '');
      onValueSelected(item);
    },
    [onValueSelected]
  );

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
      onInputValueChange(e.target.value);
      if (e.target.value.length) {
        setSelectedItem(undefined);
        onValueSelected(undefined);
      }
    },
    [onInputValueChange, onValueSelected]
  );

  const handleTargetKeyDown = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (
      event.which === Keys.ARROW_UP ||
      event.which === Keys.ARROW_DOWN ||
      event.which === Keys.ENTER
    ) {
      event.preventDefault();
      setIsOpen(true);
    }
  }, []);

  return (
    <Popover
      minimal
      isOpen={isOpen}
      placement="bottom-end"
      enforceFocus={false}
      autoFocus={false}
      popoverClassName="autocomplete-list"
      className={className}
      onInteraction={(isPopoverOpen) => {
        setIsOpen(isPopoverOpen);
      }}
    >
      <Input
        onKeyDown={handleTargetKeyDown}
        validationError={validationError}
        placeholder={placeholder ?? t('Search')}
        disabled={disabled}
        value={query}
        onChange={onInputChange}
        rightElement={
          query.toString().length ? (
            <Icon
              className="clear-input"
              icon="cross"
              onClick={() => {
                onItemSelect(undefined);
              }}
            />
          ) : (
            <Icon icon="search" />
          )
        }
      />
      <Menu>
        <Scrollbars
          hideTracksWhenNotNeeded
          autoHeight
          autoHeightMax={'50vh'}
          style={{ width: '100%' }}
        >
          {loading && <MenuItem text={t('Loading')} icon={<Spinner size={20} />} />}

          {items.map((item) => (
            <MenuItem
              text={item.Value.toString()}
              key={item.Key}
              active={item?.Key === selectedItem?.Key}
              onClick={() => {
                onItemSelect(item);
              }}
            />
          ))}
          {!items?.length && !loading && <MenuItem disabled text={t('No results')} />}
        </Scrollbars>
      </Menu>
    </Popover>
  );
};

export default Suggest;
