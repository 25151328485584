import React, { FC } from 'react';
import { Drawer } from '@blueprintjs/core';
// Components
import CloseIcon from '../icons/CloseIcon';
// Types

interface IMappingDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const MappingDialog: FC<IMappingDialogProps> = ({ isOpen, onClose, children, title }) => {
  return (
    <Drawer position="right" className="mapping-dialog" isOpen={isOpen} onClose={onClose}>
      <div className="title">{title}</div>
      <div className="dialog-close-button" onClick={onClose}>
        <CloseIcon />
      </div>
      {children}
    </Drawer>
  );
};

export default MappingDialog;
