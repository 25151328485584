import React, { FC, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Types
import { LoginFormValues, LoginState } from '../../../redux/slices/auth/login/types';
import { RequestStatus } from '../../../redux/slices/types';
import { RootState } from '../../../redux/rootReducer';

// Actions
import { login, resetLoginState } from '../../../redux/slices/auth';

// Hooks
import useRedirectSearchParams from '../../../layouts/auth/useRedirectSearchParams';

// Utils
import stringifyStateError from '../../../utils/stringifyStateError';

// Components
import CheckboxRounded from '../../../components/checkbox-rounded/CheckboxRounded';
import Input from '../../../components/input/Input';
import Password from '../../../components/password/Password';
import AuthForm from '../AuthForm';
import Button from '../../../components/button/Button';

// Validation schema
import validationSchema from './validationSchema';

// Styles
import './login-styles.scss';

const Login: FC = () => {
  const { error, submit, initialValues } = useSelector<RootState, LoginState>(
    ({ auth }) => auth.login
  );

  const { t } = useTranslation(['login']);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (data) => {
      await dispatch(login(data));
    },
    [dispatch]
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
  } = useFormik<LoginFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const isSubmitDisabled = submit === RequestStatus.pending || !isValid;

  const searchParams = useRedirectSearchParams();

  // Effects
  useEffect(() => {
    if (error) {
      const message = stringifyStateError(error);

      toastr.error(t('alert.Authentication error '), message);
    }
  }, [error, t]);

  // Component did unmount
  useEffect(
    () => () => {
      dispatch(resetLoginState());
    },
    [dispatch]
  );

  return (
    <form onSubmit={handleSubmit}>
      <AuthForm>
        <Input
          onChange={handleChange}
          name="username"
          placeholder={t('inputs.Login')}
          validationError={touched.username && errors.username}
          value={values.username}
          onBlur={handleBlur}
        />

        <Password
          onChange={handleChange}
          name="password"
          placeholder={t('inputs.Password')}
          value={values.password}
          validationError={touched.password && errors.password}
          onBlur={handleBlur}
        />

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <CheckboxRounded
              id="rememberMe"
              label={t('links.Stay logged in')}
              onChange={() => ''}
            />
          </div>
        </div>

        <Button
          type="submit"
          disabled={isSubmitDisabled}
          loading={submit === RequestStatus.pending}
        >
          {t('buttons.Login')}
        </Button>

        <p className="no-account text-center">
          {t('links.No Account?')}
          <Link to={{ pathname: '/signup', search: searchParams }}>
            {t('links.Sign Up')}
          </Link>
        </p>

        <Link
          to={{ pathname: '/reset-password', search: searchParams }}
          className="custom-link forgot-password"
        >
          {t('links.Forgot your password?')}
        </Link>
      </AuthForm>
    </form>
  );
};

export default Login;
