import React, { FC } from 'react';
// Components
import { MenuItem, Menu } from '@blueprintjs/core';
import Scrollbars from '../../custom-scrollbars/CustomScrollbars';

interface IFieldNamesListProps {
  onClick: (name: string) => void;
  options: string[];
}

const FieldNamesList: FC<IFieldNamesListProps> = ({ onClick, options }) => {
  return (
    <Menu>
      <Scrollbars
        hideTracksWhenNotNeeded
        autoHeight
        autoHeightMax={'50vh'}
        style={{ width: '100%' }}
      >
        {options.map((item, index) => (
          <MenuItem
            text={item}
            key={`${item}${index}`}
            onClick={() => {
              onClick(item);
            }}
          />
        ))}
      </Scrollbars>
    </Menu>
  );
};

export default FieldNamesList;
