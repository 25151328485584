import React, { FC, useMemo } from 'react';
import { Popover, Menu, MenuItem, Icon, Button } from '@blueprintjs/core';
// Types
import { IDashboardTableRowOperation } from '../../types';
import getClassName from 'src/components/dynamic-layout/utils/getClassName';

interface IRowMenuProps {
  options?: IDashboardTableRowOperation[];
  onOptionClick: (option: IDashboardTableRowOperation) => void;
}

const RowMenuItem: FC<{
  option: IDashboardTableRowOperation;
  onClick: (option: IDashboardTableRowOperation) => void;
}> = ({ option, onClick }) => {
  const handleClick = () => {
    onClick(option);
  };

  return (
    <MenuItem
      key={option.action}
      icon={option.icon}
      className={getClassName(option.classes)}
      text={option.label}
      onClick={handleClick}
    />
  );
};

const RowButton: FC<{
  option: IDashboardTableRowOperation;
  onClick: (option: IDashboardTableRowOperation) => void;
}> = ({ option, onClick }) => {
  const handleClick = () => {
    onClick(option);
  };

  return (
    <Button
      key={option.action}
      icon={option.icon}
      className={'table-row-button ' + getClassName(option.classes)}
      onClick={handleClick}
    >
      {option.label}
    </Button>
  );
};

const RowMenu: FC<IRowMenuProps> = ({ options, onOptionClick }) => {
  const buttons = useMemo(() => options?.filter(({ type }) => type === 'button'), [
    options,
  ]);
  const actions = useMemo(() => options?.filter(({ type }) => type === 'action'), [
    options,
  ]);

  return (
    <>
      {buttons?.map((button) => (
        <RowButton key={button.action} onClick={onOptionClick} option={button} />
      ))}
      {!!actions?.length && (
        <Popover
          minimal
          enforceFocus={false}
          canEscapeKeyClose
          popoverClassName="table-row-popover"
        >
          <Icon icon="more" className="table-row-menu-icon" iconSize={20} />
          <Menu>
            {actions.map((action) => (
              <RowMenuItem key={action.action} onClick={onOptionClick} option={action} />
            ))}
          </Menu>
        </Popover>
      )}
    </>
  );
};

export default RowMenu;
