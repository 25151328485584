import { FormGroup, Intent } from '@blueprintjs/core';
import { ITextAreaProps as ITextAreaPropsBlueprint } from '@blueprintjs/core/lib/esm/components/forms/textArea';
import { HTMLInputProps } from '@blueprintjs/core/src/common/props';
import React, { FC } from 'react';
import { TextArea as TextAreaBlueprint } from '@blueprintjs/core';

// Styles
import './textarea-styles.scss';

export interface ITextAreaProps extends ITextAreaPropsBlueprint {
  helperText?: string;
  label?: string;
  labelInfo?: string;
  password?: boolean;
  loading?: boolean;
  validationError?: string | false | undefined;
  className?: string;
}

const TextArea: FC<ITextAreaProps & HTMLInputProps> = ({
  helperText,
  label,
  labelInfo,
  loading = false,
  password = false,
  validationError,
  className,
  ...props
}) => {
  return (
    <FormGroup
      helperText={validationError || helperText}
      label={label}
      labelInfo={labelInfo}
      className={`textarea-form-group ${className}`}
    >
      <TextAreaBlueprint
        {...props}
        intent={validationError ? Intent.DANGER : undefined}
        className={`textarea-styles bp3-fill${loading ? ' bp3-skeleton' : ''}`}
      />
    </FormGroup>
  );
};

export default TextArea;
