import { useEffect, useCallback, useMemo } from 'react';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery, useQueryCache } from 'react-query';
// Selectrors
import { selectLanguageState } from '../../../redux/slices/language/selectors';
// Types
import { SerializedError } from '../../../redux/slices/types';
import { ISubmitForm } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
// Utils
import { stringifyFormData } from '../../../utils/stringifyFormData';
import stringifyStateError from '../../../utils/stringifyStateError';
import useFormNotification from 'src/utils/useFormNotification';
import { getQueryParameterByName } from 'src/utils/genUtils';

// Service
import DashboardLayoutService from '../../../services/DashboardLayoutService';

const useDashboardLayout = (connectorId?: string) => {
  const { selectedLanguage } = useSelector(selectLanguageState);

  const { t } = useTranslation(['dynamicLayout, dynamicForm']);
  const { showNotification } = useFormNotification();

  const search = useLocation().search;

  const pageName = useMemo(() => getQueryParameterByName('page', search), [search]);

  const queryCache = useQueryCache();

  const { data: layout, isLoading: loading, error, isError } = useQuery<
    any,
    SerializedError
  >([connectorId, { id: connectorId, page: pageName }], DashboardLayoutService.get, {
    enabled: connectorId,
  });

  // Handlers
  const handleDynamicFormSubmit = useCallback(
    async ({ data: formData, callbackUrl, submitPayload }: ISubmitForm) => {
      try {
        const { data } = await DashboardLayoutService.submitForm(
          callbackUrl,
          stringifyFormData(formData, submitPayload),
          connectorId
        );
        if (data?.output?.error) {
          throw new Error(data.output.error);
        }
        if (data?.output?.notification) {
          showNotification(data?.output?.notification);
          if (data?.output?.layout) {
            return data.output.layout;
          }
        } else return data.output;
      } catch (error) {
        toastr.error(
          t('dynamicForm:alert.Failed'),
          t('dynamicForm:alert.Error update', { error: stringifyStateError(error) })
        );
      }
    },
    [t, showNotification, connectorId]
  );

  useEffect(() => {
    if (connectorId) {
      queryCache.invalidateQueries(connectorId);
    }
  }, [queryCache, pageName, connectorId, selectedLanguage.Key]);

  useEffect(() => {
    if (isError) {
      toastr.error(
        t('dynamicLayout:alert.Failed'),
        t('dynamicLayout:alert.Error fetch', { error: stringifyStateError(error) })
      );
    }
  }, [isError, error, loading, t]);

  return {
    layout,
    loading,
    handleDynamicFormSubmit,
    errorMessage: stringifyStateError(error),
  };
};

export default useDashboardLayout;
