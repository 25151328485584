import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Actions
import { getCompanies } from '../../companies/list/thunks';
import { buildIntegration } from '../../marketplace/thunk';
// Types
import { GetCompaniesActionSuccessResult } from '../../companies/list/types';
import { IConnectorIdSlugMapState, IIdSlugItems } from './types';
import { IBuildIntegrationSuccessResult } from '../../marketplace/types';

const initialState: IConnectorIdSlugMapState = {
  data: {},
};

const connectorIdSlugMapSlice = createSlice({
  name: 'dashboard.connectorIdSlugMap',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [String(getCompanies.fulfilled)]: (
      state: IConnectorIdSlugMapState,
      action: PayloadAction<GetCompaniesActionSuccessResult>
    ): IConnectorIdSlugMapState => {
      return {
        data: action.payload.data.reduce((acc, { field_integrations }) => {
          return {
            ...acc,
            ...field_integrations.reduce((idSlugs, { nid, slug }) => {
              return { ...idSlugs, [slug || nid]: nid };
            }, {}),
          };
        }, {}) as IIdSlugItems,
      };
    },
    [String(buildIntegration.fulfilled)]: (
      state: IConnectorIdSlugMapState,
      action: PayloadAction<IBuildIntegrationSuccessResult>
    ): IConnectorIdSlugMapState => ({
      ...state,
      data: {
        ...state.data,
        [action.payload.data.slug || action.payload.data.nid]: action.payload.data.nid,
      },
    }),
  },
});

export const { reset } = connectorIdSlugMapSlice.actions;

export default connectorIdSlugMapSlice.reducer;
