import React, { FC } from 'react';

// Styles
import './arrow-icon-styles.scss';

export interface IArrowIconProps {
  disabled?: boolean;
  onClick?: () => void;
}

const ArrowIcon: FC<IArrowIconProps> = ({ disabled, onClick }) => (
  <div
    className={`arrow-icon${disabled ? ' disabled' : ''}`}
    onClick={!disabled ? onClick : undefined}
  >
    <svg viewBox="0 0 9 7" width="9" height="7">
      <g>
        <path d="M4.5 7L0 0L9 0L4.5 7Z" />
      </g>
    </svg>
  </div>
);

export default ArrowIcon;
