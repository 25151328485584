import React, { FC } from 'react';
import { FormGroup } from '@blueprintjs/core';
import { useField } from 'formik';
// import { useTranslation } from 'react-i18next';
// Components
import MappingToolComponent from '../../../components/mapping-tool/MappingTool';
// Types
import { IMappingTool } from './input-types';
// Utils
import getClassName from '../utils/getClassName';
// import { validate } from '../../../components/mapping-tool/utils';

const MappingTool: FC<IMappingTool> = ({
  label,
  name,
  className,
  source,
  destination,
}) => {
  // const { t } = useTranslation('mapping');

  const [field, meta, helpers] = useField({
    name,
    // validate: (result) => {
    //   const isValid = validate(result.value, result.source);
    //   return isValid
    //     ? undefined
    //     : (t(
    //         'validation.An input provided contains invalid variable or function'
    //       ) as string);
    // },
  });

  const handleChange = (result) => {
    helpers.setTouched(true);
    helpers.setValue(result);
  };

  return (
    <FormGroup
      helperText={meta.touched && meta.error}
      label={label}
      className={className}
    >
      <MappingToolComponent
        onChange={handleChange}
        className={className}
        source={source}
        destination={destination}
        initialValue={field.value ?? {}}
      />
    </FormGroup>
  );
};

export default MappingTool;

export const mapMappingToolProps = ({
  key,
  label,
  classes,
  source,
  destination,
}: IMappingTool) => ({
  name: `['${key}']`,
  label,
  source: source ?? {},
  destination: destination ?? {},
  className: getClassName(classes),
});
