import React, { FC } from 'react';
import { StructureErrorBoundary, DynamicLayout } from 'wedoio-dynamic-layout';
// Components
import componentsMap from '../../components/dynamic-layout/components/componentsMap';
// Utils
import { jsTimeToString } from 'src/utils/genUtils';
// Types
import { EDashboardTableColTypes } from 'src/types/enums';

interface ITableCellProps {
  size: number;
  type: EDashboardTableColTypes;
  value: any;
  className?: string;
}

const formatValue = (value, type) => {
  switch (type) {
    case EDashboardTableColTypes.date:
      return jsTimeToString(value);
    case EDashboardTableColTypes.integer:
      return value + '';
    case EDashboardTableColTypes.string:
      return value;
    case EDashboardTableColTypes.markup:
      return (
        <StructureErrorBoundary>
          <DynamicLayout componentsMap={componentsMap} structure={value} />
        </StructureErrorBoundary>
      );
    default:
      return null;
  }
};

const TableCell: FC<ITableCellProps> = ({ size, type, value, className }) => {
  const formattedValue = formatValue(value, type);

  return formattedValue ? (
    <div className={className} style={{ width: `${size}%` }}>
      {formattedValue}
    </div>
  ) : null;
};

export default TableCell;
