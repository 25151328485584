import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import Input from '../../../../../components/input/Input';
import GenericSelector, {
  IValueKey,
} from '../../../../../components/generic-selector/GenericSelector';
import { Card, Icon, Alert, Intent } from '@blueprintjs/core';
import RequestTypeTag from '../../../../../components/Wedoio/Connector/request-type-tag/RequestTypeTag';
import ContentTypesList from './content-types/ContentTypesList';
import ParametersList from './parameters/ParametersList';
import ResponsesList from './responses/ResponsesList';
import Collapse from '../../../../../components/collapse/Collapse';
import useAlert from 'src/utils/useAlert';
import Switch from '../../../../../components/custom-switch/CustomSwitch';
// Types
import { CreateConnectorFormData, IConnectorRequest } from 'src/types/internal-Types';
import { RequestType } from '../../../../../services/BaseApiService';
// Utils
import { getHelperText } from '../../../utils';
// Style
import './request-form-style.scss';

export const requestTypes = [
  { Key: RequestType.get, Value: RequestType.get },
  { Key: RequestType.post, Value: RequestType.post },
  { Key: RequestType.put, Value: RequestType.put },
  { Key: RequestType.patch, Value: RequestType.patch },
  { Key: RequestType.delete, Value: RequestType.delete },
].map(({ Key, Value }) => ({ Key, Value: Value.toUpperCase() }));

interface IRequestFormProps {
  path: string;
  onRemove: () => void;
  data: IConnectorRequest;
  types: IValueKey[];
}

const RequestForm: FC<IRequestFormProps> = ({ onRemove, data, path, types }) => {
  const {
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    values,
  } = useFormikContext<CreateConnectorFormData>();

  const tagItems = useMemo(
    () => values.tags.map(({ name }) => ({ Key: name, Value: name })),
    [values]
  );
  const selectedTags = useMemo(
    () => tagItems.filter((item) => data?.tags?.includes(item.Key)),
    [data, tagItems]
  );
  const selectedType = useMemo(
    () => requestTypes.filter((type) => type.Key === data?.method),
    [data]
  );

  const { t } = useTranslation(['connectors']);

  const handleSelectMethod = useCallback(
    (method) => {
      const [{ Key } = { Key: undefined }] = method;
      setFieldValue(`${path}.method`, Key);
      setFieldTouched(`${path}.method`);
    },
    [setFieldValue, setFieldTouched, path]
  );

  const handleTagsChange = useCallback(
    (tags) => {
      setFieldValue(
        `${path}.tags`,
        tags.map(({ Key }) => Key)
      );
    },
    [setFieldValue, path]
  );

  const handleUseGlobalSecurityChange = useCallback(
    (e) => {
      setFieldValue(`${path}.useGlobalSecurity`, e.target.checked);
      setFieldTouched(`${path}.useGlobalSecurity`);
      setFieldValue(`${path}.security`, []);
    },
    [setFieldValue, setFieldTouched, path]
  );

  const deleteAlert = useAlert({ callback: onRemove });

  const securitySchemesOptions = useMemo(
    () =>
      values.securitySchemes.map((scheme, schemeIndex) => ({
        Key: schemeIndex + '',
        Value: scheme.title,
      })),
    [values.securitySchemes]
  );

  const selectedSchemes = useMemo(
    () =>
      securitySchemesOptions.filter((option) =>
        data.security?.includes(Number.parseInt(option.Key, 10))
      ),
    [securitySchemesOptions, data.security]
  );

  const handleSelectScheme = useCallback(
    (schemes) => {
      setFieldValue(
        `${path}.security`,
        schemes.map(({ Key }) => Number.parseInt(Key, 10))
      );
    },
    [setFieldValue, path]
  );

  return (
    <Card
      className={`request-form ${
        getHelperText(touched, errors, `${path}`) ? 'invalid' : ''
      }`}
    >
      <Collapse
        header={
          <div className="request-form-title">
            <span className="request-name">
              {data?.operationId ? data.operationId : t('inputs.newRequest')}
              {data?.method && <RequestTypeTag type={data?.method} />}
            </span>

            <Icon
              className="delete-icon"
              icon="trash"
              onClick={(e) => {
                e.stopPropagation();
                deleteAlert.onOpen();
              }}
            />
          </div>
        }
      >
        <section className="connector-form-section --border ">
          <Input
            label={t('inputs.Operation id')}
            placeholder="newRequest"
            value={data?.operationId}
            name={`${path}.operationId`}
            onBlur={handleBlur}
            onChange={handleChange}
            validationError={getHelperText(
              touched ?? {},
              errors ?? {},
              `${path}.operationId`
            )}
          />
          <div className="margin-bottom-xs">
            <GenericSelector
              isMultiSelector={false}
              selectorText={t('inputs.Method')}
              items={[...types, ...selectedType]}
              selectedItem={selectedType}
              onValueChanged={handleSelectMethod}
            />
          </div>
          <div className="margin-bottom-xs">
            <GenericSelector
              isMultiSelector
              selectorText={t('inputs.Tags')}
              items={tagItems}
              selectedItem={selectedTags}
              onValueChanged={handleTagsChange}
            />
          </div>
          <div className="margin-bottom-xs ">
            <Switch
              checked={data.useGlobalSecurity}
              onChange={handleUseGlobalSecurityChange}
              name={t('inputs.Use global authentication')}
              className="use-global-security-toggle"
            />
          </div>
          {!data.useGlobalSecurity && (
            <div>
              <GenericSelector
                isMultiSelector
                items={securitySchemesOptions}
                selectedItem={selectedSchemes}
                onValueChanged={handleSelectScheme}
                selectorText={t('inputs.Security schemes')}
              />
            </div>
          )}
        </section>

        <section className="connector-form-section --border ">
          <ParametersList values={data?.parameters ?? []} path={path} />
        </section>

        <section className="connector-form-section --border ">
          <ResponsesList path={path} values={data.responses} />
        </section>

        {data?.method !== RequestType.get && (
          <section className="connector-form-section --border ">
            <ContentTypesList data={data?.requestBody?.content ?? []} path={path} />
          </section>
        )}
      </Collapse>
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={deleteAlert.isOpen}
        onCancel={deleteAlert.onClose}
        onConfirm={deleteAlert.onSubmit}
      >
        <p>{t('alert.Delete request')}</p>
      </Alert>
    </Card>
  );
};

export default RequestForm;
