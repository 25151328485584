import { RootState } from '../../../rootReducer';
import { ILogsState } from './types';

export const selectDashboardLogs = (state: RootState): ILogsState => state.dashboard.logs;

export const selectIsSelectedAll = (state: RootState): boolean => {
  const { selectedLogs, data } = state.dashboard.logs;

  return !!data.logs.length && selectedLogs.length === data.logs.length;
};
