// Types
import { UserPermissions, UserRoles } from '../../types/enums';
import { IUser } from '../../types/api-Types';

type IUserPermissionsList = {
  [key in UserPermissions]: UserRoles[];
};

export type ICheckPermissions = [
  boolean | undefined,
  {
    [key in UserPermissions]?: boolean;
  }?
];

const PERMISSIONS_LIST: IUserPermissionsList = {
  [UserPermissions.create_company]: [UserRoles.account_manager],
  [UserPermissions.integrations_debug]: [UserRoles.administrator],
  [UserPermissions.test_page]: [UserRoles.administrator],
};

const checkPermission = (
  requiredPermissions: UserPermissions[],
  user: IUser
): ICheckPermissions => {
  const userRoles = Object.values(user.roles) as UserRoles[];

  const permissions = requiredPermissions.reduce((map, permission) => {
    const hasPermission = userRoles.some((role) =>
      PERMISSIONS_LIST[permission].includes(role)
    );
    map[permission] = hasPermission;
    return map;
  }, {});

  const hasAll = !Object.values(permissions).includes(false);

  return [hasAll, permissions] as ICheckPermissions;
};

export default checkPermission;
