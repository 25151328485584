import React, { FC } from 'react';
import {} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card } from '@blueprintjs/core';
// Types
import { INotification } from '../../../types/internal-Types';
// Icons
import TickIcon from '../../icons/TickIcon';

interface INotificationProps {
  data: INotification;
  onClick: (data: INotification) => void;
}

const Notification: FC<INotificationProps> = ({ data, onClick }) => {
  const { t } = useTranslation(['notifications']);

  const handleClick = () => {
    onClick(data);
  };

  return (
    <Card className="notification custom-card custom-card--border" onClick={handleClick}>
      <div className="notification-title">{data.title}</div>
      <p className="notification-description">{data.description}</p>
      <small>{data.date}</small>
      <span
        className={`notification-status${data.read ? ' active' : ''}`}
        title={data.read ? t('buttons.Mark as unread') : t('buttons.Mark as read')}
      >
        <TickIcon />
      </span>
      {data?.link && (
        <span className="notifications-link">{t('buttons.See more')}...</span>
      )}
    </Card>
  );
};

export default Notification;
