export const stringifyFormData = (
  formData: any,
  payload: string = '$data',
  placeholder: string = '$data'
) => {
  try {
    const payloadWithData = payload.replace(placeholder, JSON.stringify(formData));
    return JSON.parse(payloadWithData);
  } catch (error) {
    return {};
  }
};
