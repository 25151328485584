import { useField } from 'formik';
import React, { FC } from 'react';

// Component
import Input from '../../../components/input/Input';

// Types
import { ITextField } from './input-types';

// Utils
import getClassName from '../utils/getClassName';

const TextField: FC<ITextField> = ({ label, name, className }) => {
  const [field, meta] = useField({ name });

  return (
    <Input
      {...field}
      label={label}
      placeholder={label}
      name={name}
      validationError={meta.touched && meta.error}
      className={className}
    />
  );
};

export default TextField;

export const mapTextFieldProps = ({ key, label, classes }: ITextField) => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
});
