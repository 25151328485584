import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Menu } from '@blueprintjs/core';
import { Select, MultiSelect, ItemPredicate, ItemRenderer } from '@blueprintjs/select';
// Components
import ArrowIcon from '../icons/ArrowIcon';
import Scrollbars from '../custom-scrollbars/CustomScrollbars';
// Styles
import './styles.scss';
import 'src/components/generic-selector/GenericSelector.css';

export interface IValueKey {
  Key: string;
  Value: string;
}

export interface IGenericSelectorProps {
  items: IValueKey[];
  onValueChanged: (str: IValueKey[]) => void;
  onAddOption?: (value: IValueKey) => void;
  selectedItem?: IValueKey[];
  selectorText?: string;
  isBooleanValues?: boolean;
  isMultiSelector: boolean;
  numberOfDisplay?: number;
  filterable?: boolean; // default is true
  selectorUi?: JSX.Element; // replace button with any Ui element. Though, You have to manually update display text
  disabled?: boolean;
  allowCreate?: boolean;
}

export interface IGenericSelectorState {
  selectedItems: IValueKey[];
}

export const booleansValues: IValueKey[] = [
  { Key: 'true', Value: 'true' },
  { Key: 'false', Value: 'false' },
];

const GenericSelector: React.FC<IGenericSelectorProps> = ({
  selectorText,
  isBooleanValues,
  isMultiSelector,
  selectorUi,
  filterable = true,
  disabled,
  selectedItem = [],
  onValueChanged,
  onAddOption,
  items: propsItems,
  allowCreate = false,
}) => {
  const { t } = useTranslation(['select']);

  const options = isBooleanValues !== undefined ? booleansValues : propsItems;

  const noResults = (
    <>
      <Menu>
        <Scrollbars
          hideTracksWhenNotNeeded
          autoHeight
          autoHeightMax={'50vh'}
          style={{ width: '100%' }}
        >
          <MenuItem disabled text={t('No options available')} />
        </Scrollbars>
      </Menu>
    </>
  );

  const onItemSelect = React.useCallback(
    (fs: IValueKey) => {
      if (allowCreate && onAddOption) {
        onAddOption(fs);
      }
      selectedItem.length && selectedItem.find((item) => item.Key === fs.Key)
        ? onValueChanged(
            isMultiSelector ? selectedItem.filter((item) => item.Key !== fs.Key) : []
          )
        : onValueChanged([...(isMultiSelector ? selectedItem : []), fs]);
    },
    [onValueChanged, selectedItem, isMultiSelector, allowCreate, onAddOption]
  );

  const handleTagRemove = React.useCallback(
    (_, index) => {
      onValueChanged(selectedItem.filter((item, i) => i !== index));
    },
    [onValueChanged, selectedItem]
  );

  const TheSelect = Select.ofType<IValueKey>();
  const TheMultiSelect = MultiSelect.ofType<IValueKey>();

  const handleCreateNewItemFromQuery = (value: string) => {
    return {
      Key: value,
      Value: value,
    };
  };

  const handleCreateNewItemRenderer = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>
  ) => {
    const isAlreadyExists = options.some((option) => option.Key === query);

    if (isAlreadyExists) {
      return;
    }
    return (
      <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    );
  };

  const maybeCreateNewItemFromQuery = allowCreate
    ? handleCreateNewItemFromQuery
    : undefined;
  const maybeCreateNewItemRenderer = allowCreate
    ? handleCreateNewItemRenderer
    : undefined;

  const renderer: ItemRenderer<IValueKey> = (val, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const active = !!selectedItem.find((x) => x.Key === val.Key);
    return (
      <MenuItem
        active={active}
        disabled={modifiers.disabled}
        label={''}
        key={val.Key}
        onClick={handleClick}
        text={val.Value}
      />
    );
  };

  const renderTag = (item: IValueKey) => item.Value;

  if (options && !isMultiSelector) {
    return (
      <TheSelect
        items={options}
        className="dropdown"
        filterable={filterable}
        itemPredicate={filter}
        itemRenderer={renderer}
        noResults={!allowCreate ? noResults : undefined}
        disabled={disabled}
        onItemSelect={onItemSelect}
        inputProps={{ placeholder: t('inputs.Filter') }}
        createNewItemFromQuery={maybeCreateNewItemFromQuery}
        createNewItemRenderer={maybeCreateNewItemRenderer}
      >
        {selectorUi && selectorUi}
        {!selectorUi && (
          <Button
            className="dropdown-button"
            disabled={!options.length || disabled}
            rightIcon={<ArrowIcon />}
            text={
              selectedItem.length > 0
                ? selectedItem[0].Value
                : selectorText
                ? selectorText
                : t('inputs.Select')
            }
          />
        )}
      </TheSelect>
    );
  } else {
    return (
      <TheMultiSelect
        items={options}
        selectedItems={selectedItem}
        itemPredicate={filter}
        itemRenderer={renderer}
        noResults={!allowCreate ? noResults : undefined}
        onItemSelect={onItemSelect}
        tagRenderer={renderTag}
        createNewItemFromQuery={maybeCreateNewItemFromQuery}
        createNewItemRenderer={maybeCreateNewItemRenderer}
        tagInputProps={{
          placeholder: selectorText ?? t('inputs.Filter'),
          className: 'selected-tags-input',
          onRemove: handleTagRemove,
          rightElement: <ArrowIcon />,
          disabled,
        }}
      />
    );
  }
};

export const filter: ItemPredicate<IValueKey> = (query, val) => {
  if (val.Value === undefined) {
    return false;
  }
  const val1 = val?.Value?.toLowerCase();
  return val1.indexOf(query.toLowerCase()) >= 0;
};

export default GenericSelector;
