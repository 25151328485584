import { ReactNode } from 'react';
import { IComponentsMapPropsCallback } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
import { InputTypes } from '../../../../../components/dynamic-layout/components/input-types';

// Components
import PreviewPassword, { mapPasswordProps } from './PreviewPassword';
import PreviewTextfield, { mapTextFieldProps } from './PreviewTextfield';

const componentsMap = new Map<string, [ReactNode, IComponentsMapPropsCallback]>([
  // Inputs
  [InputTypes.textfield, [PreviewTextfield, mapTextFieldProps]],
  [InputTypes.password, [PreviewPassword, mapPasswordProps]],
]);

export default componentsMap;
