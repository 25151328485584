import React, { FC } from 'react';

// Styles
import './form-heading-styles.scss';

export interface ITextBlockProps {
  children: string;
  className?: string;
}

const FormHeading: FC<ITextBlockProps> = ({ children, className }) => {
  return <h4 className={`form-heading ${className}`}>{children}</h4>;
};

export default FormHeading;
