import React, { FC, useCallback } from 'react';
import {
  TagInput as BlueprintTagInput,
  ITagInputProps,
  Intent,
  FormGroup,
  Keys,
} from '@blueprintjs/core';
import { HTMLInputProps } from '@blueprintjs/core/src/common/props';

// Styles
import './tags-input-styles.scss';

interface ITagInputComponentProps extends ITagInputProps {
  validationError?: string | false | undefined;
  label?: string;
}

const TagsInput: FC<ITagInputComponentProps & HTMLInputProps> = ({
  className = '',
  tagProps,
  validationError,
  label,
  ...props
}) => {
  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.which === Keys.ENTER) {
      event.preventDefault();
    }
  }, []);

  return (
    <FormGroup helperText={validationError || ''} label={label}>
      <BlueprintTagInput
        {...props}
        onKeyDown={onKeyDown}
        className={`tags-input ${className}`}
        tagProps={{ ...tagProps, className: 'custom-tag' }}
        intent={validationError ? Intent.DANGER : undefined}
      />
    </FormGroup>
  );
};

export default TagsInput;
