import React, { FC, ReactNode, ReactElement } from 'react';
import { PopoverInteractionKind, IPopoverSharedProps } from '@blueprintjs/core';
// Components
import Popover from 'src/components/popover/Popover';
// Types
import { ITooltip } from '../block-types';
// Utils
import getClassName from '../../utils/getClassName';

type ITooltipProps = {
  content: ReactElement | string;
  children: ReactNode;
  className?: string;
  interactionKind?: PopoverInteractionKind;
  enforceFocus: boolean;
  defaultIsOpen: boolean;
} & Pick<IPopoverSharedProps, 'placement'>;

const Tooltip: FC<ITooltipProps> = ({
  children,
  content,
  className,
  placement,
  defaultIsOpen = false,
  enforceFocus,
  interactionKind = PopoverInteractionKind.HOVER,
}) => {
  return (
    <Popover
      enforceFocus={enforceFocus}
      defaultIsOpen={defaultIsOpen}
      popoverClassName={className}
      placement={placement}
      boundary="viewport"
      interactionKind={interactionKind}
    >
      {children}
      {content}
    </Popover>
  );
};

export const mapTooltipProps = (
  {
    content = '',
    children,
    enforceFocus,
    classes,
    placement,
    interactionKind,
    defaultIsOpen,
  }: ITooltip,
  builder
) => ({
  content: builder(content),
  children: builder(children),
  className: getClassName(classes),
  enforceFocus,
  placement,
  interactionKind,
  defaultIsOpen,
});

export default Tooltip;
