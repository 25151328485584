import React, { FC } from 'react';

// Styles
import './avatar-styles.scss';

// Utils
import createSize, { ISize } from '../../utils/createSize';

export enum AvatarSizes {
  small,
  smallSecondary,
  middle,
  large,
}

interface IAvatarProps {
  src: string;
  size?: AvatarSizes;
  loading?: boolean;
}

const Avatar: FC<IAvatarProps> = ({ src, size = AvatarSizes.small, loading = false }) => {
  if (loading) {
    return <div style={dimensions.get(size)} className="avatar-image bp3-skeleton" />;
  }

  return (
    <img src={src} className="avatar-image" style={dimensions.get(size)} alt="avatar" />
  );
};

const dimensions = new Map<AvatarSizes, ISize>([
  [AvatarSizes.small, createSize(44, 44)],
  [AvatarSizes.smallSecondary, createSize(97, 97)],
  [AvatarSizes.middle, createSize(116, 116)],
  [AvatarSizes.large, createSize(282, 282)],
]);

export default Avatar;
