import React, { FC, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
// Types
import { IOnChangeSubmit } from './input-types';
// Utils
import isEqual from 'lodash/isEqual';

interface IOnChangeSubmitProps {
  payload: string;
  callback: string;
  wrapper: string;
  id: string;
  type: string;
  fields: string[];
}

const OnChangeSubmit: FC<IOnChangeSubmitProps> = ({
  payload,
  callback = '',
  wrapper = '',
  id = '',
  type = 'onchange-submit',
  fields = [],
}) => {
  const {
    setValues,
    dirty,
    values,
    touched,
    submitForm,
    resetForm,
    initialValues,
    isValid,
  } = useFormikContext<object>();

  const submit = useCallback(async () => {
    setValues({
      payload,
      callback,
      wrapper,
      triggering_element: { type, id, callback, payload, wrapper },
      // @ts-ignore
      ...values,
    });
    resetForm({ values });
    await submitForm();
  }, [type, id, callback, payload, values, wrapper, setValues, resetForm, submitForm]);

  useEffect(() => {
    const isChanged = dirty
      ? fields.some((field) => {
          if (values && initialValues) {
            return !isEqual(values[field], initialValues[field]) && touched[field];
          }

          return false;
        })
      : false;
    if (isChanged && isValid) {
      submit();
    }
  }, [fields, initialValues, values, isValid, touched, dirty, submit]);

  return <></>;
};

export const mapOnChangeSubmitProps = (props: IOnChangeSubmit) => props;

export default OnChangeSubmit;
