import { useField } from 'formik';
import React, { FC } from 'react';

// Component
import CheckboxComponent from '../../../components/checkbox/Checkbox';

// Types
import { ICheckbox } from './input-types';

// Utils
import getClassName from '../utils/getClassName';

const Checkbox: FC<ICheckbox> = ({ label, name, className }) => {
  const [field, meta, helpers] = useField({ name });

  const handleChange = (event) => {
    helpers.setValue(event.target.checked);
  };

  return (
    <CheckboxComponent
      checked={field.value}
      label={label}
      validationError={meta.touched && meta.error}
      onBlur={field.onBlur}
      onChange={handleChange}
      className={className}
    />
  );
};

export default Checkbox;

export const mapCheckboxProps = ({ key, label, classes }: ICheckbox) => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
});
