import React, { FC, useCallback } from 'react';
import DateInput from '../../date-input/DateInput';
// Types
import { ITableFilterProps } from '../TableFilter';
// Utils
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import isValid from 'date-fns/isValid';

const StringFilter: FC<ITableFilterProps> = ({
  field,
  label,
  onFilterChange,
  filterValue,
}) => {
  const handleChange = useCallback(
    (value) => {
      onFilterChange(field, isValid(value) ? getUnixTime(value) : undefined);
    },
    [field, onFilterChange]
  );

  return (
    <div key={field}>
      <DateInput
        onChange={handleChange}
        placeholder={label}
        value={typeof filterValue === 'number' ? fromUnixTime(filterValue) : undefined}
      />
    </div>
  );
};

export default StringFilter;
