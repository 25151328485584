import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';
import { FC } from 'react';
import { Grid, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Utils
import { getRandomArray } from '../../../../utils/getRandom';
// Selectors
import { selectConnectorsListState } from '../../../../redux/slices/connectors/list/selectors';
// Components
import ConnectorTeaser from './ConnectorTeaser';
import { RequestStatus } from '../../../../redux/slices/types';
// Styles
import './connectors-list-styles.scss';

const ConnectorsList: FC = () => {
  const { data, loading } = useSelector(selectConnectorsListState);
  const { t } = useTranslation(['home']);

  return (
    <div className="connectors-list">
      <Grid fluid>
        <Row className="connectors-wrapper">
          <div className="wrapper">
            {/* Skeletons */}
            {loading === RequestStatus.pending &&
              !data.length &&
              getRandomArray(4).map((id) => (
                <div key={id} className="connectors-list-skeleton bp3-skeleton" />
              ))}

            {!data.length && loading !== RequestStatus.pending && (
              <NonIdealState icon={'error'} title={t('No connectors found')} />
            )}

            {!!data.length &&
              data.map((connector) => (
                <ConnectorTeaser connector={connector} key={connector.nid} />
              ))}
          </div>
        </Row>
      </Grid>
    </div>
  );
};

export default ConnectorsList;
