import { useMutation } from 'react-query';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
// Services
import TableManagerService, {
  IUpdateTableRows,
} from '../../../services/TableManagerService';
// Types
import { SerializedError } from 'src/redux/slices/types';
// Utils
import stringifyStateError from 'src/utils/stringifyStateError';

interface IUseUpdateRowsParams {
  onSuccess?: (data, variables: IUpdateTableRows) => void;
}

const useUpdateRows = ({ onSuccess }: IUseUpdateRowsParams) => {
  const { t } = useTranslation(['dashboard']);

  const [updateRows, { isLoading: updateLoading, data }] = useMutation<
    any,
    SerializedError,
    IUpdateTableRows
  >(TableManagerService.updateRows, {
    onSuccess,
    onError: (updateError, { action }) => {
      toastr.error(
        t('alert.Failed'),
        t('alert.Error action', {
          action,
          error: stringifyStateError(updateError),
        })
      );
    },
  });

  return { updateRows, updateLoading, data: data?.output };
};

export default useUpdateRows;
