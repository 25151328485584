import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Selectors
import { selectConnectorActions } from '../../../redux/slices/dashboard/connector/selectors';
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
// Actions
import { getConnectorActions } from '../../../redux/slices/dashboard/connector/thunks';
// Types
import { IValueKey } from '../../../components/generic-selector/GenericSelector';
import { RequestStatus } from '../../../redux/slices/types';

interface IUseConnectorActions {
  actions: IValueKey[];
  actionsLoading: RequestStatus;
}

const useConnectorActions = (): IUseConnectorActions => {
  const { data: connector, actionsLoading } = useSelector(selectConnectorState);
  const actions = useSelector(selectConnectorActions);

  const dispatch = useDispatch();

  useEffect(() => {
    if (connector?.nid) {
      dispatch(getConnectorActions(connector.nid));
    }
  }, [connector, dispatch]);

  return {
    actions,
    actionsLoading,
  };
};

export default useConnectorActions;
