import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// Types
import { RootState } from '../redux/rootReducer';
import { selectToken } from '../redux/slices/auth/token/selectors';

const useRedirectIfAuthenticated = (path = '/') => {
  const token = useSelector<RootState>(selectToken);

  const history = useHistory();

  useEffect(() => {
    if (token) {
      history.push(path);
    }
  }, [token, history, path]);
};

export default useRedirectIfAuthenticated;
