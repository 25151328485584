import React, { FC } from 'react';
import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';
// Utils
import useDynamicLayout from './useDynamicLayout';
// Type
import { IComponentPrototype } from '../types';
// Components
import Loader from '../../../components/loader/Loader';
import companentsMap from '../componentsMap';
// Style
import './style.scss';

interface IComponentPrototypeProps {
  init_call: { [key: string]: any };
}

const ComponentPrototype: FC<IComponentPrototypeProps> = ({ init_call }) => {
  const { layout, loading, handleDynamicFormSubmit } = useDynamicLayout(init_call);

  return (
    <div className="component-prototype">
      <Loader loading={loading}>
        {layout && (
          <StructureErrorBoundary>
            <DynamicLayout
              componentsMap={companentsMap}
              structure={layout}
              callService={handleDynamicFormSubmit}
            />
          </StructureErrorBoundary>
        )}
      </Loader>
    </div>
  );
};

export const mapComponentPrototypeProps = ({ init_call }: IComponentPrototype) => ({
  init_call,
});

export default ComponentPrototype;
