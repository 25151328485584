import { NonIdealState } from '@blueprintjs/core';
import { useFormik } from 'formik';
import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import Button, { ButtonSize } from '../../../components/button/Button';
import CompanyLogo from '../../../components/company-logo/CompanyLogo';
import FormActions from '../../../components/form-actions/FormActions';
import GenericSelector, {
  IValueKey,
} from '../../../components/generic-selector/GenericSelector';
import ImagePicker from '../../../components/image-picker/ImagePicker';
import Input from '../../../components/input/Input';
import CustomScrollbars from '../../../components/custom-scrollbars/CustomScrollbars';
// Types
import { RequestStatus } from '../../../redux/slices/types';
import { EditCompanyFormValues } from '../../../redux/slices/companies/edit/types';
// Actions
import {
  resetEditCompanyState,
  setEditCompanyData,
} from '../../../redux/slices/companies';
import { editCompany } from '../../../redux/slices/companies/edit/thunks';
import { getCompanies } from '../../../redux/slices/companies/list/thunks';
// Selectors
import { selectEditCompanyState } from '../../../redux/slices/companies/edit/selectors';
import {
  selectCompaniesListLoading,
  selectCompanyById,
} from '../../../redux/slices/companies/list/selectors';
// Utils
import getImageAsBase64 from '../../../utils/getImageAsBase64';
import stringifyStateError from '../../../utils/stringifyStateError';

import compPic from '../../../images/companies.svg';
import compBg from '../../../images/companiesBG.svg';

import validationSchema from '../validationSchema';

import { useCountriesList } from '../countryList';

export interface IEditCompanyPageProps {
  companyId: string;
}

const CompanyEditPage: FC<IEditCompanyPageProps> = ({ companyId }) => {
  // State
  const { error, submit, data } = useSelector(selectEditCompanyState);
  const company = useSelector(selectCompanyById(companyId));
  const companiesListLoading = useSelector(selectCompaniesListLoading);

  const countriesList = useCountriesList();

  const { t } = useTranslation(['company']);

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formData) => {
    const base64 = /^data:image\/\w+;base64,/;
    const isLogoUpdated = !!formData?.field_company_logo?.file?.match(base64);
    dispatch(
      editCompany({
        id: companyId,
        company: {
          ...formData,
          changed: undefined,
          field_company_logo: isLogoUpdated
            ? {
                ...formData.field_company_logo,
                file: formData.field_company_logo.file.replace(base64, ''),
              }
            : null,
        },
      })
    );
  };

  // Formik State
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
  } = useFormik<EditCompanyFormValues>({
    initialValues: data,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
    isInitialValid: false,
  });

  const {
    title,
    field_invoice_email,
    field_company_vat,
    field_company_logo,
    field_company_address,
  } = values;

  const selectedCountry = useMemo((): IValueKey[] | undefined => {
    if (!field_company_address?.country) {
      return undefined;
    }

    const { country } = field_company_address;

    const countryResult = countriesList.find(
      ({ Key }) => Key.toLocaleUpperCase() === country.toLocaleUpperCase()
    );

    if (countryResult) {
      return [countryResult];
    }

    const defVal = {
      Key: country.toLocaleUpperCase(),
      Value: country.toLocaleUpperCase(),
    } as IValueKey;

    return [defVal];
  }, [field_company_address, countriesList]);

  // Handlers
  const handleTeaserChange = async (file: File) => {
    const base64 = await getImageAsBase64(file);

    setFieldValue('field_company_logo.file', base64, true);
    setFieldValue('field_company_logo.filename', file.name, true);
  };

  const handleTeaserReset = () => {
    setFieldValue('field_company_logo', null);
  };

  const handleCountryChange = (value: IValueKey[]) => {
    const [{ Key: country } = { Key: undefined }] = value;

    setFieldValue('field_company_address.country', country, true);
  };

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  // Effects
  /* eslint react-hooks/exhaustive-deps: 0 */
  useEffect(() => {
    if (submit === RequestStatus.fulfilled) {
      toastr.success(t('Success'), t('alert.Success update', { companyName: title }));

      dispatch(getCompanies());

      setTimeout(history.goBack, 1000);
    }

    if (error) {
      const message = stringifyStateError(error);

      toastr.error(t('alert.Error update'), message);
    }
  }, [submit, error, t]);

  useEffect(() => {
    if (company) {
      dispatch(setEditCompanyData(company));
    }

    return () => {
      dispatch(resetEditCompanyState());
    };
  }, [company]);

  const isSubmitting = submit === RequestStatus.pending;
  const isPending = isSubmitting || !company;
  const isCanLogoReset = !!field_company_logo?.file && !isSubmitting;

  if (!companiesListLoading && !company) {
    return (
      <div className="non-ideal-state-container">
        <div className="companies-list-divider" />
        <NonIdealState icon="search" title={t('Company not found', { companyId })} />
      </div>
    );
  }

  return (
    <div className="company-create">
      <div className="background-picture">
        <img src={compBg} alt="" />
      </div>

      <div className="iconAccount">
        <img src={compPic} alt="" />
      </div>

      <form onSubmit={handleSubmit}>
        {/* Submit button */}
        <FormActions>
          <Button disabled={isSubmitting} size={ButtonSize.small} onClick={handleCancel}>
            {t('buttons.Cancel')}
          </Button>
          <Button
            size={ButtonSize.small}
            disabled={!isValid || isSubmitting}
            type="submit"
            loading={isSubmitting}
          >
            {t('buttons.Save')}
          </Button>
        </FormActions>

        <CustomScrollbars autoHeight autoHeightMax={'50vh'} style={{ width: '100%' }}>
          <Row>
            <Col xs={8}>
              <h4>{t('titles.General Information')}</h4>
              <Input
                loading={isPending}
                name="title"
                placeholder={t('inputs.Company Name')}
                value={title || ''}
                validationError={touched.title && errors.title}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <Input
                loading={isPending}
                name="field_company_vat"
                placeholder={t('inputs.VAT Number')}
                value={field_company_vat || ''}
                validationError={touched.field_company_vat && errors.field_company_vat}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Col>
            <Col xs={4} style={{ marginTop: '40px' }}>
              <ImagePicker
                canReset={isCanLogoReset}
                onChange={handleTeaserChange}
                onReset={handleTeaserReset}
              >
                <CompanyLogo src={field_company_logo?.file} loading={isPending} />
              </ImagePicker>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Input
                loading={isPending}
                name="field_invoice_email"
                placeholder={t('inputs.Invoice Email')}
                value={field_invoice_email || ''}
                validationError={
                  touched.field_invoice_email && errors.field_invoice_email
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <h4>{t('titles.Billing address')}</h4>
              <Input
                loading={isPending}
                name="field_company_address.thoroughfare"
                placeholder={t('inputs.Address')}
                value={field_company_address?.thoroughfare || ''}
                validationError={
                  touched.field_company_address?.thoroughfare &&
                  errors.field_company_address?.thoroughfare
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Input
                loading={isPending}
                name="field_company_address.administrative_area"
                placeholder={t('inputs.Address 2')}
                value={field_company_address?.administrative_area || ''}
                validationError={
                  touched.field_company_address?.administrative_area &&
                  errors.field_company_address?.administrative_area
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Input
                loading={isPending}
                name="field_company_address.locality"
                placeholder={t('inputs.City')}
                value={field_company_address?.locality || ''}
                validationError={
                  touched.field_company_address?.locality &&
                  errors.field_company_address?.locality
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Input
                loading={isPending}
                name="field_company_address.postal_code"
                placeholder={t('inputs.Zip code')}
                value={field_company_address?.postal_code || ''}
                validationError={
                  touched.field_company_address?.postal_code &&
                  errors.field_company_address?.postal_code
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!isPending && (
                <GenericSelector
                  selectedItem={selectedCountry}
                  isMultiSelector={false}
                  items={countriesList}
                  selectorText={t('inputs.Country')}
                  onValueChanged={handleCountryChange}
                />
              )}
            </Col>
          </Row>
        </CustomScrollbars>
      </form>
    </div>
  );
};

export default CompanyEditPage;
