import React, { FC } from 'react';
import { Icon } from '@blueprintjs/core';

// Styles
import './styles.scss';

interface IProps {
  isVisible: boolean;
  onClick: () => void;
}

const PasswordIcon: FC<IProps> = ({ isVisible, onClick }) => {
  return (
    <div onClick={onClick} className="password-icon">
      {isVisible ? (
        <Icon icon="eye-open" iconSize={25} />
      ) : (
        <Icon icon="eye-off" iconSize={25} />
      )}
    </div>
  );
};

export default PasswordIcon;
