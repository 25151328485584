import React, { FC, useMemo } from 'react';
import GenericSelector, {
  IValueKey,
} from 'src/components/generic-selector/GenericSelector';

interface IEditValueFormProps {
  value: string;
  onEditValue: (value: string) => void;
  handleAddOptionValue: (newValue: any) => void;
  optionsValues: IValueKey[];
}

const EditorValue: FC<IEditValueFormProps> = ({
  value,
  onEditValue,
  handleAddOptionValue,
  optionsValues,
}) => {
  const selectedItem = useMemo(() => optionsValues.filter(({ Key }) => Key === value), [
    optionsValues,
    value,
  ]);

  const handleChange = (newValue) => {
    const [{ Key } = { Key: undefined }] = newValue;

    if (onEditValue) {
      onEditValue(Key);
    }
  };

  return (
    <GenericSelector
      items={optionsValues}
      isMultiSelector={false}
      selectedItem={selectedItem}
      onValueChanged={handleChange}
      onAddOption={handleAddOptionValue}
      allowCreate
    />
  );
};

export default EditorValue;
