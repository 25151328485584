import React, { FC } from 'react';
import { Icon, IconName } from '@blueprintjs/core';

// Types
import { ILink } from '../block-types';

// Utils
import getClassName from '../../utils/getClassName';

// Style
import './style.scss';

interface ILinkProps {
  href: string;
  target: string;
  icon: IconName;
  label: string;
  className: string;
}

const Link: FC<ILinkProps> = ({ href, target, icon, label, className }) => {
  return (
    <>
      <a href={href} target={target} className={`link-with-icon ${className}`}>
        {icon && <Icon icon={icon} />} {label}
      </a>
    </>
  );
};

export default Link;

export const mapLinkProps = ({
  label = '',
  classes = [],
  icon = '',
  href,
  target,
}: ILink) => {
  return {
    label,
    icon,
    className: getClassName(classes),
    href,
    target,
  };
};
