import { FC } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';

// Types
import { ICompany } from 'src/types/api-Types';

// Actions
import { selectCompany } from 'src/redux/slices/companies/selected-company/selectedCompanySlice';

// Images
import placeholder from '../../../../images/defaultCompanyLogo.png';

export interface ICompanyTeaserProps {
  company: ICompany;
  selected?: ICompany;
}

const CompanyTeaser: FC<ICompanyTeaserProps> = ({ company, selected }) => {
  const dispatch = useDispatch();

  const handleCompanySelect = () => dispatch(selectCompany(company));

  const className = `company teaser companyListShort-element ${
    selected?.nid === company.nid ? ' selected' : ''
  }`;

  return (
    <div onClick={handleCompanySelect} className={className}>
      <span className="company-nid">#{company.nid}</span>
      <div className="companyListShort-element-wrap wrapper">
        <span className="company-logo">
          <img src={company.field_company_logo?.teaser || placeholder} alt="logo" />
        </span>
        <span className="company-title">{company.title}</span>
        <span className="integrations-count">{company.integrations}</span>
      </div>
    </div>
  );
};

export default CompanyTeaser;
