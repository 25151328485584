import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import HomepageLayoutService from '../../../services/HomepageLayoutService';

// Types
import { GetHomePageLayoutActionResult } from './types';

export const getHomePageLayout = createAsyncThunk<GetHomePageLayoutActionResult, string>(
  'homepage/getHomePageLayout',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await HomepageLayoutService.get(params);

      return {
        data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.message);
    }
  }
);
