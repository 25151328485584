import React, { FC, useCallback, useEffect } from 'react';
import { Row, Grid, Col } from 'react-bootstrap';
import { H2, ButtonGroup, Button, Classes } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
// Components
import SearchForm from '../../components/search-field/SearchForm';
import NotitficationsTable, { IChangeStatus } from './table/NotificationsTable';
import Loader from 'src/components/loader/Loader';
// Image
// Selectors
import { selectNotifications } from '../../redux/slices/notifications/table/selectors';
import { selectCurrentUser } from '../../redux/slices/user/selectors';
import { selectNotificationsList } from 'src/redux/slices/notifications/list/selectors';
// Actions
import {
  resetTable,
  setCurrentPage,
  setShearchKey,
  toggleFilter,
  toggleSelectAllVisibleNotifications,
  toggleSelectAllNotifications,
  toggleSelectedNotitfication,
  clearSelectedNotifications,
} from '../../redux/slices/notifications/table/notificationsTableSlice';
import {
  deleteNotifications,
  getNotifications,
  changeNotificationsStatus,
} from '../../redux/slices/notifications/table/thunks';
// Styles
import './styles.scss';
// Types
import { RequestStatus } from 'src/redux/slices/types';
// Utils
import stringifyStateError from '../../utils/stringifyStateError';
// Const
import { ITEMS_PER_PAGE } from '../../redux/slices/notifications/consts';

const NotificationsPage: FC = () => {
  // State
  const { data: user, loading: userLoading } = useSelector(selectCurrentUser);
  const {
    searchKey,
    unreadFilter,
    currentPage,
    error,
    deleteError,
    changeStatusError,
    submit,
  } = useSelector(selectNotifications);
  const { submit: listUpdated } = useSelector(selectNotificationsList);

  const { t } = useTranslation(['notifications']);
  const dispatch = useDispatch();

  const fetch = () => {
    dispatch(clearSelectedNotifications());
    if (user?.uid) {
      dispatch(
        getNotifications({
          userId: user.uid,
          page: currentPage,
          limit: ITEMS_PER_PAGE,
          keyword: searchKey,
          read: unreadFilter ? false : undefined,
        })
      );
    }
  };

  // Handlers
  const handleSearchFormSubmit = useCallback(
    (value) => {
      dispatch(setShearchKey(value !== '' ? value : undefined));
    },
    [dispatch]
  );

  const handleFilterChange = useCallback(() => {
    dispatch(toggleFilter());
  }, [dispatch]);

  const handleSelect = (id: string) => {
    dispatch(toggleSelectedNotitfication(id));
  };

  const handleDelete = useCallback(
    async (ids: string[] = []) => {
      if (user?.uid) {
        await dispatch(
          deleteNotifications({
            userId: user.uid,
            ids,
            read: unreadFilter ? false : undefined,
          })
        );
        if (currentPage !== 1) {
          dispatch(setCurrentPage(1));
        } else fetch();
      }
    },
    [user?.uid, unreadFilter]
  );

  const handleChangeStatus = useCallback(
    async (data?: IChangeStatus) => {
      if (data && user?.uid) {
        dispatch(
          changeNotificationsStatus({
            userId: user.uid,
            ids: data.ids,
            isRead: data.isRead,
          })
        );
      }
    },
    [user?.uid]
  );

  const handleSelectAllVisible = () => {
    dispatch(toggleSelectAllVisibleNotifications());
  };

  const handleSelectAll = () => {
    dispatch(toggleSelectAllNotifications());
  };

  const handlePageClick = async (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleRefresh = async () => {
    fetch();
  };

  useEffect(
    () => () => {
      dispatch(resetTable());
    },
    [dispatch]
  );

  /* eslint  react-hooks/exhaustive-deps: 0 */
  useEffect(() => {
    fetch();
  }, [user?.uid, currentPage, searchKey, unreadFilter, dispatch]);

  useEffect(() => {
    if (error) {
      toastr.error(
        t('alert.Failed'),
        t('alert.Failed to fetch', { error: stringifyStateError(error) })
      );
    }
  }, [error, t]);

  useEffect(() => {
    if (deleteError) {
      toastr.error(
        t('alert.Failed'),
        t('alert.Failed to delete', { error: stringifyStateError(deleteError) })
      );
    }
  }, [deleteError, t]);

  useEffect(() => {
    if (changeStatusError) {
      toastr.error(
        t('alert.Failed'),
        t('alert.Failed to update', {
          error: stringifyStateError(changeStatusError),
        })
      );
    }
  }, [changeStatusError, t]);

  useEffect(() => {
    if (submit === RequestStatus.fulfilled) {
      toastr.success(t('alert.Success'), t('alert.Success update'));
    }
  }, [submit, t]);

  useEffect(() => {
    if (listUpdated === RequestStatus.fulfilled && unreadFilter) {
      if (currentPage !== 1) {
        dispatch(setCurrentPage(1));
      } else fetch();
    }
  }, [unreadFilter, listUpdated, currentPage]);

  useEffect(() => {
    if (submit === RequestStatus.fulfilled && unreadFilter) {
      if (currentPage !== 1) {
        dispatch(setCurrentPage(1));
      } else fetch();
    }
  }, [unreadFilter, submit, currentPage]);

  return (
    <Grid className="notifications-container">
      <Loader loading={userLoading === RequestStatus.pending && !user}>
        <Row className="main-row">
          <Col sm={12}>
            <H2>{t('titles.Notifications')}</H2>
            <Row className="margin-top-xs margin-bottom-xs">
              <Col sm={3} className="notifications-filter">
                <ButtonGroup>
                  <Button
                    disabled={!unreadFilter}
                    className={!unreadFilter ? Classes.ACTIVE : ''}
                    onClick={handleFilterChange}
                  >
                    {t('buttons.All')}
                  </Button>
                  <Button
                    disabled={unreadFilter}
                    className={unreadFilter ? Classes.ACTIVE : ''}
                    onClick={handleFilterChange}
                  >
                    {t('buttons.Unread')}
                  </Button>
                </ButtonGroup>
              </Col>
              <Col sm={9} className="notifications-search">
                <SearchForm onSubmit={handleSearchFormSubmit} initialValue={searchKey} />
              </Col>
            </Row>
            <NotitficationsTable
              onPageClick={handlePageClick}
              onChangeStatus={handleChangeStatus}
              onDelete={handleDelete}
              onSelect={handleSelect}
              onSelectAllVisible={handleSelectAllVisible}
              onSelectAll={handleSelectAll}
              onRefresh={handleRefresh}
            />
          </Col>
        </Row>
      </Loader>
    </Grid>
  );
};

export default NotificationsPage;
