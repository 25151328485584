import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'react-bootstrap';

// Selectors
import { selectCurrentUser } from '../../redux/slices/user/selectors';

// Images
import backgroundImage from '../../images/bg-home.svg';

// Types
import { RequestStatus } from 'src/redux/slices/types';
import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';

// Selectors
import { selectHomePageState } from '../../redux/slices/homepage/selectors';

// Components
import Loader from '../../components/loader/Loader';
import componentsMap, { HomepageTypes } from './componentsMap';

// Styles
import './home-page-styles.scss';

const fallback = {
  type: 'block',
  classes: ['margin-top'],
  children: [{ type: HomepageTypes.CompaniesIntegrationsInfo }],
};

const HomePage = () => {
  const { loading: userLoading, data: user } = useSelector(selectCurrentUser);
  const { data, loading } = useSelector(selectHomePageState);

  return (
    <Grid className="homepage-container">
      <Loader
        loading={
          loading === RequestStatus.pending ||
          (userLoading === RequestStatus.pending && !user)
        }
      >
        {data && (
          <StructureErrorBoundary>
            <DynamicLayout componentsMap={componentsMap} structure={data} />
          </StructureErrorBoundary>
        )}
        {!data && (
          <StructureErrorBoundary>
            <DynamicLayout componentsMap={componentsMap} structure={fallback} />
          </StructureErrorBoundary>
        )}
      </Loader>
    </Grid>
  );
};

HomePage.backgroundImage = backgroundImage;

export default HomePage;
