// #region User
import { RequestType } from 'src/services/BaseApiService';
import { EDashboardComponents, UserRoles } from './enums';
import {
  IConnectorFormField,
  IConnectorRequest,
  IConnectorResponse,
  IConnectorServer,
  IConnectorTag,
  ISchema,
  ISecurityScheme,
} from './internal-Types';

export interface IUser {
  uid: string;
  name: string;
  mail: string;
  theme: string;
  signature: string;
  signature_format: string;
  created: string;
  access: string;
  login: string;
  status: string;
  timezone: string;
  language: string;
  picture: string;
  data: IData;
  roles: IRoles;
  field_company_name: IFieldCompanyName;
  field_address: IFieldAddress;
  field_invoice_email: string;
  field_partner: null;
  field_company_legal_identificati: string;
  field_first_name: string;
  field_last_name: string;
  field_last_logout: IFieldLastLogout;
  field_mobile_phone: string;
  field_professional_phone: string;
  field_user_picture: IFieldUserPicture;
  rdf_mapping: IRDFMapping;
  password?: string; // does not exist for get value. Just for updating
  current_password?: string; // does not exist for get value. Just for updating
  intercom_user_hash: string;
}

export interface IData {
  htmlmail_plaintext: number;
}

export interface IFieldAddress {
  country: string;
  administrative_area: string;
  sub_administrative_area: null;
  locality: string;
  dependent_locality: string;
  postal_code: string;
  thoroughfare: string;
  premise: string;
  sub_premise: null;
  organisation_name: null;
  name_line: string;
  first_name: string;
  last_name: string;
  data: null;
}

export interface IFieldCompanyName {
  und: IFieldCompanyNameUnd[];
}

export interface IFieldCompanyNameUnd {
  value: string;
  format: null;
  safe_value: string;
}

export interface IFieldLastLogout {
  und: IFieldLastLogoutUnd[];
}

export interface IFieldLastLogoutUnd {
  value: string;
  timezone: string;
  timezone_db: string;
  date_type: string;
}

export interface IFieldUserPicture {
  fid: string;
  uid: string;
  filename: string;
  uri: string;
  filemime: string;
  filesize: string;
  status: string;
  timestamp: string;
  type: string;
  field_file_image_alt_text: any[];
  field_file_image_title_text: any[];
  field_file_image_categories: any[];
  rdf_mapping: any[];
  metadata: IMetadata;
  height: string;
  width: string;
  alt: null;
  title: null;
  original: string;
  teaser: string;
  medium: string;
  file?: File;
  previousTeaser?: string;
}

export interface IMetadata {
  height: number;
  width: number;
}

export interface IRDFMapping {
  rdftype: string[];
  name: IName;
  homepage: IHomepage;
}

export interface IHomepage {
  predicates: string[];
  type: string;
}

export interface IName {
  predicates: string[];
}

export interface IRoles {
  [key: string]: UserRoles;
}

// #endregion

// #region Company
export interface ICompany {
  nid: string;
  vid: string;
  type: string;
  language: string;
  title: string;
  uid: string;
  status: string;
  created: string;
  changed: string;
  comment: string;
  promote: string;
  sticky: string;
  tnid: string;
  translate: string;
  cancelled_sign_up: string;
  body: null;
  field_invoice_email: null | string;
  field_company_description: string;
  field_company_vat: string;
  field_company_phone: string;
  field_company_address: IFieldCompanyAddress;
  field_company_logo: IFieldCompanyLogo;
  field_integrations: IFieldIntegration[];
  integrations: number;
}

export interface IFieldCompanyLogo {
  fid: string;
  uid: string;
  filename: string;
  uri: string;
  filemime: string;
  filesize: string;
  status: string;
  timestamp: string;
  type: string;
  field_file_image_alt_text: any[];
  field_file_image_title_text: any[];
  field_file_image_categories: any[];
  rdf_mapping: any[];
  metadata: IMetadata;
  height: string;
  width: string;
  alt: string;
  title: string;
  original: string;
  teaser: string;
  teaser_medium: string;
  file?: string;
  previousTeaser?: string;
}

export interface IMetadata {
  height: number;
  width: number;
}

export interface IIntegrationIcon {
  fid: string;
  uid: string;
  filename: string;
  uri: string;
  filemime: string;
  filesize: string;
  status: string;
  timestamp: string;
  type: string;
  field_file_image_alt_text: any[];
  field_file_image_title_text: any[];
  field_file_image_categories: any[];
  rdf_mapping: any[];
  metadata: IMetadata;
  height: string;
  width: string;
  alt?: string;
  title?: string;
  thumbnail?: string;
  original: string;
  teaser?: string;
  medium?: string;
  file?: File;
  previousTeaser?: string;
}

export interface IFieldIntegration {
  nid: string;
  title: string;
  slug?: string;
  status: boolean;
  tags?: string[];
  icons?: IIntegrationIcon[];
  body: IIntegrationUnd | null;
}

export interface IFieldCompanyAddress {
  country: string;
  administrative_area: string;
  sub_administrative_area?: any;
  locality: string;
  dependent_locality: string;
  postal_code: string;
  thoroughfare: string;
  premise: string;
  sub_premise?: any;
  organisation_name?: any;
  name_line?: any;
  first_name?: any;
  last_name?: any;
  data?: any;
}

export interface IComponent {
  nid: number;
  key: EDashboardComponents;
  name: string;
  status: boolean;
}

export interface IConnector {
  nid: string;
  name: string;
  actions: string[];
  status: boolean;
  components: { [key: string]: IComponent };
}

// #endregion

// #region

export interface IOutputLog {
  _id: ID;
  task: ILogTask;
  created: number;
}

export interface ID {
  $oid: string;
}

export interface ILogTask {
  id: string;
  action: string;
  output: any;
  error?: any;
  state: EState;
}

export enum EState {
  TaskCompleted = 'TASK COMPLETED',
  TaskReprocessed = 'TASK REPROCESSED',
  TaskRunning = 'TASK RUNNING',
  TaskCreated = 'TASK CREATED',
  TaskFailed = 'TASK FAILED',
  TaskWarning = 'TASK WARNING',
}

export interface Input {
  op: string;
  page: number;
  limit: number;
}
// #endregion

export interface IImageUploadType {
  file: string;
  filename: string;
}

export interface IHookStatus {
  // "create": true,
  // "update": false,
  // "delete": false,
  [key: string]: boolean;
}

export interface IHookEntity {
  //   "organisations": {
  //     "create": true,
  //     "update": false,
  //     "delete": false,
  //     "etc..."
  //   },
  [key: string]: IHookStatus;
}

export interface IIntegrationUnd {
  value: string;
  format: null;
  safe_value: string;
  summary: string;
  safe_summary: string;
}
export interface IFieldDefaultMarketplaceUnd {
  und: IIntegrationUnd[];
}
export interface IMarketPlace {
  vid: string;
  uid: string;
  title: string;
  log: string;
  status: string;
  comment: string;
  promote: string;
  sticky: string;
  ds_switch: string;
  nid: string;
  type: string;
  language: string;
  created: string;
  changed: string;
  tnid: string;
  translate: string;
  cancelled_sign_up: string;
  revision_timestamp: string;
  revision_uid: string;
  base_cancelled_sign_up: string;
  body: IIntegrationUnd;
  field_integrations: IFieldIntegration[];
  field_default_marketplace: IFieldDefaultMarketplaceUnd;
  cid: string;
  last_comment_timestamp: string;
  last_comment_name: string;
  last_comment_uid: string;
  comment_count: string;
  name: string;
  picture: string;
  data: string;
  entity_translation_handler_id: string;
  integrations: number;
}

export interface IFieldConnectorLogo {
  fid: string;
  uid: string;
  filename: string;
  uri: string;
  filemime: string;
  filesize: string;
  status: string;
  timestamp: string;
  type: string;
  field_file_image_alt_text: any[];
  field_file_image_title_text: any[];
  field_file_image_categories: any[];
  rdf_mapping: any[];
  metadata: IMetadata;
  height: string;
  width: string;
  alt: null;
  title: null;
  original: string;
  teaser: string;
  medium: string;
}

export interface IConnectorDescription {
  nid: number;
  info: {
    title: string;
    description: string;
    logo?: IFieldConnectorLogo;
    default_namespace: string;
    namespace: string;
  };
  servers: Pick<IConnectorServer, 'url'>[];
  tags: IConnectorTag[];
  form: Omit<IConnectorFormField, 'id'>[];
  paths: {
    [path: string]: {
      security?: { [name: string]: string[] }[];
    } & {
      [method in RequestType]: Pick<
        IConnectorRequest,
        'tags' | 'operationId' | 'parameters'
      > & {
        requestBody?: {
          content: {
            [type: string]: {
              schema: ISchema;
            };
          };
        };
        responses: {
          [statusCode: number]: Omit<IConnectorResponse, 'statusCode' | 'id'>;
        };
      };
    };
  };
  security: {
    [name: string]: string[];
  }[];
  components: {
    securitySchemes: {
      [name: string]: Omit<ISecurityScheme, 'id' | 'title'>;
    };
  };
}
