import { createSlice } from '@reduxjs/toolkit';
import { RequestType } from '../../../../services/BaseApiService';

// Thunks
import { getConfigForm, updateConfig } from './thunks';

// Types
import { RequestStatus } from '../../types';
import { IConnectorConfigState } from './types';

// Utils
import { createThunkReducers } from '../../utils';

const initialState: IConnectorConfigState = {
  data: {
    form: undefined,
  },
  loading: RequestStatus.idle,
  submit: RequestStatus.idle,
  error: null,
};

const connectorConfigSlice = createSlice({
  name: 'dashboard.connectorConfig',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    ...createThunkReducers(getConfigForm, RequestType.get),
    ...createThunkReducers(updateConfig, RequestType.put),
  },
});

export const { reset } = connectorConfigSlice.actions;

export default connectorConfigSlice.reducer;
