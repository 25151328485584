import React, { FC, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import AuthSchemeTeaser from './AuthSchemeTeaser';
import { H3, Icon, NonIdealState } from '@blueprintjs/core';
import { Col, Row } from 'react-bootstrap';
// Types
import {
  CreateConnectorFormData,
  EConnectorAuthParams,
  EConnectorAuthTypes,
} from 'src/types/internal-Types';
// Style
import './style.scss';

const AuthSchemesList: FC = () => {
  const { values, errors, setFieldValue } = useFormikContext<CreateConnectorFormData>();
  const { t } = useTranslation(['connectors']);

  const history = useHistory();
  const match = useRouteMatch();

  const handleAdd = useCallback(() => {
    const currentValue = [...values.securitySchemes];
    currentValue.push({
      type: EConnectorAuthTypes.basic_auth,
      title: t('titles.New scheme'),
      in: EConnectorAuthParams.query,
    });
    setFieldValue('securitySchemes', currentValue);
    history.push(`${match.url}/${currentValue.length - 1}`);
  }, [setFieldValue, values, history, match.url, t]);

  const handleRemove = useCallback(
    (index: number) => {
      const currentValue = [...values.securitySchemes];
      currentValue.splice(index, 1);
      const endpoints = values.endpoints.map((endpoint) => ({
        ...endpoint,
      }));
      const globalSecurity = values.security.filter((id) => id !== index);
      setFieldValue('securitySchemes', currentValue);
      setFieldValue('endpoints', endpoints);
      setFieldValue('security', globalSecurity);
    },
    [setFieldValue, values]
  );

  return (
    <Row>
      <Col xs={12}>
        <>
          <div className="connector-section-title">
            <H3 className="margin-bottom-sm">{t('titles.Schemes')}</H3>
            <Icon icon="plus" className="edit-list-icon" onClick={handleAdd} />
          </div>
          {!!errors?.securitySchemes?.length && !!values.securitySchemes.length && (
            <p>{t('validation.Incomplete scheme')}</p>
          )}
          {!!values.securitySchemes.length && <p>{errors?.security}</p>}

          {values.securitySchemes.length ? (
            values.securitySchemes.map((value, index) => (
              <AuthSchemeTeaser
                index={index}
                onRemove={handleRemove}
                value={value}
                key={index}
                isComplete={
                  errors?.securitySchemes ? !errors?.securitySchemes[index] : true
                }
              />
            ))
          ) : (
            <div className="non-ideal-state-container">
              <NonIdealState icon="error" title={t('No scheme found')} />
            </div>
          )}
        </>
      </Col>
    </Row>
  );
};

export default AuthSchemesList;
