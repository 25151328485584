import React, { FC } from 'react';
import { HTMLInputProps } from '@blueprintjs/core/src/common/props';
import { FormGroup, IInputGroupProps, InputGroup, Intent } from '@blueprintjs/core';

// Styles
import './input-styles.scss';

export interface IInputProps extends IInputGroupProps {
  helperText?: string;
  label?: string;
  labelInfo?: string;
  password?: boolean;
  loading?: boolean;
  validationError?: string | false | undefined;
}

const Input: FC<IInputProps & HTMLInputProps> = ({
  helperText,
  label,
  labelInfo,
  loading = false,
  password = false,
  validationError,
  className,
  ...props
}) => {
  return (
    <FormGroup
      helperText={validationError || helperText}
      label={label}
      labelInfo={labelInfo}
      className="input-form-group"
    >
      <InputGroup
        type={password ? 'password' : 'text'}
        {...props}
        intent={validationError ? Intent.DANGER : undefined}
        className={`input-styles${loading ? ' bp3-skeleton' : ''} ${className}`}
      />
    </FormGroup>
  );
};

export default Input;
