import React, { FC, useCallback, useEffect, useState } from 'react';
// Components
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import CodeEditor from '../../../../components/code-editor/CodeEditor';
import { Card } from '@blueprintjs/core';
// Types
import { CreateConnectorFormData } from 'src/types/internal-Types';
// Utils
import { getConnectorDescription, getConnectorFormData } from '../../utils';
import { stringify, parse } from 'yaml';

const ConnectorYamlEditor: FC = () => {
  const { values, setValues } = useFormikContext<CreateConnectorFormData>();
  const [value, setValue] = useState(
    stringify(getConnectorDescription(values), { schema: 'json' })
  );

  const [isValid, setIsValid] = useState(true);

  const handleBlur = useCallback(() => {
    if (isValid) {
      setValues(getConnectorFormData(parse(value)));
    }
  }, [value, setValues, isValid]);

  const onError = useCallback((errors) => {
    setIsValid(!errors.length);
  }, []);

  const handleChange = useCallback((newValue) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    setValue(stringify(getConnectorDescription(values), { schema: 'json' }));
  }, [values]);

  return (
    <div>
      <Row className="connector-wrp padding-bottom-xxs">
        <Col xs={12}>
          <Card className="custom-card padding-md">
            <div className="padding-xs">
              <CodeEditor
                className="connector-code-editor"
                mode="yaml"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onLint={onError}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ConnectorYamlEditor;
