import { combineReducers } from 'redux';

// Reducers
import loginReducer from './login/loginSlice';
import resetPasswordReducer from './resetPassword/resetPaswordSlice';
import tokenReducer from './token/tokenSlice';
import signupReducer from './signup/signupSlice';
import redirectParamsSlice from './redirect-params/redirectParamsSlice';

// Actions
export { reset as resetLoginState, login } from './login/loginSlice';
export {
  reset as resetResetPasswordState,
  resetPassword,
} from './resetPassword/resetPaswordSlice';

export { reset as resetSignupState } from './signup/signupSlice';

export default combineReducers({
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  token: tokenReducer,
  signup: signupReducer,
  redirect: redirectParamsSlice,
});
