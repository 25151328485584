import { createSlice } from '@reduxjs/toolkit';
// Types
import { RequestType } from '../../../../services/BaseApiService';
import { RequestStatus } from '../../types';
import { IChartsState } from './types';
// Utils
import { createThunkReducers } from '../../utils';
// Thunks
import { getData } from './thunk';

const initialState: IChartsState = {
  data: {
    logs: [],
    totalCount: 0,
  },
  loading: RequestStatus.idle,
  error: null,
};

const chartsSlice = createSlice({
  name: 'dashboard.charts',
  initialState,
  reducers: {
    reset: (): IChartsState => initialState,
  },
  extraReducers: {
    ...createThunkReducers<IChartsState>(getData, RequestType.get),
  },
});

export const { reset } = chartsSlice.actions;

export default chartsSlice.reducer;
