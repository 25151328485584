import React, { FC } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
// Components
import ConnectorCommonSettings from '../ConnectorCommonSettings';
import AuthSchemesList from '../auth-scheme/AuthSchemesList';
import AuthScheme from '../auth-scheme/AuthScheme';
import FormEditor from '../form-editor/FormEditor';
import Endpoint from '../endpoints/Endpoint';
import EndpointsList from '../endpoints/EndpointsList';

const ConnectorFormRoutes: FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/authentication/form-editor`}>
        <FormEditor />
      </Route>

      <Route path={`${match.url}/authentication/schemes/:schemeId`}>
        <AuthScheme />
      </Route>

      <Route path={`${match.url}/authentication/schemes`}>
        <AuthSchemesList />
      </Route>

      <Route path={`${match.url}/endpoints/:endpointId`}>
        <Endpoint />
      </Route>

      <Route path={`${match.url}/endpoints/`}>
        <EndpointsList />
      </Route>

      <Redirect
        from={`${match.url}/authentication`}
        to={`${match.url}/authentication/form-editor`}
      />
      <Route path={`${match.url}`}>
        <ConnectorCommonSettings />
      </Route>
    </Switch>
  );
};

export default ConnectorFormRoutes;
