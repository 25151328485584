import React, { FC, ReactNode } from 'react';

// Images
import loaderIcon from '../../images/loader.gif';

// Styles
import './loader-styles.scss';

export interface ILoaderProps {
  children?: ReactNode;
  loading: boolean;
  withShadow?: boolean;
  showIcon?: boolean;
}

const Loader: FC<ILoaderProps> = ({
  children = null,
  loading,
  withShadow = false,
  showIcon = true,
}) => {
  if (!loading) {
    return <>{children}</>;
  }

  return (
    <div className={`loader${withShadow ? ' shadow' : ''}`}>
      {showIcon && <img src={loaderIcon} alt="loader" />}
    </div>
  );
};

export default Loader;
