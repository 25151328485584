import React, { FC, useCallback, useState } from 'react';
// Types
import { HTMLInputProps } from '@blueprintjs/core/src/common/props';
import { IInputGroupProps } from '@blueprintjs/core';
// Component
import Input from '../input/Input';
import PasswordIcon from '../password-icon/PasswordIcon';

export interface IPassword extends IInputGroupProps {
  name: string;
  placeholder: string;
  helperText?: string;
  label?: string;
  labelInfo?: string;
  validationError?: string | false | undefined;
  loading?: boolean;
}

const Password: FC<IPassword & HTMLInputProps> = (props) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);

  const handleToggleVisibility = useCallback(
    () => setIsPasswordHidden(!isPasswordHidden),
    [isPasswordHidden]
  );

  return (
    <Input
      {...props}
      type={isPasswordHidden ? 'password' : 'text'}
      rightElement={
        <PasswordIcon isVisible={!isPasswordHidden} onClick={handleToggleVisibility} />
      }
    />
  );
};

export default Password;
