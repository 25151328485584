import { ICompany, IImageUploadType } from '../types/api-Types';
import BaseApiService, { RequestType } from './BaseApiService';

class CompaniesService extends BaseApiService {
  create = (data: CreateCompanyData) =>
    this.request({
      type: RequestType.post,
      url: '',
      data,
    });

  edit = (id: string, data: ICompany) =>
    this.request({
      type: RequestType.put,
      url: id,
      data,
    });

  getList = () =>
    this.request({
      type: RequestType.get,
      url: '',
    });

  getForm = (id: string) =>
    this.request({
      type: RequestType.put,
      url: id,
      data: {
        action: 'form',
        input: {
          action: 'get',
        },
      },
    });

  submitForm = (callback: string = '', data: any) =>
    this.request({
      url: callback,
      type: RequestType.put,
      data,
    });
}

export interface CreateCompanyData {
  title: string;
  field_invoice_email: string;
  field_company_vat: string;
  field_company_logo?: IImageUploadType;
  field_company_address: {
    country: string;
    locality: string;
    postal_code: string;
    thoroughfare: string;
  };
}

export default new CompaniesService('rest/companies');
