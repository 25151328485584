import React, { FC, ReactNode } from 'react';

// Styles
import './settings-styles.scss';

interface ITabFormProps {
  children: ReactNode;
}

const TabForm: FC<ITabFormProps> = ({ children }) => {
  return (
    <div className="tab-form">
      <div className="tab-form-divider" />
      {children}
    </div>
  );
};

export default TabForm;
