import { combineReducers } from 'redux';

// Reducers

import tableReducer from './table/notificationsTableSlice';
import listReducer from './list/notificationsListSlice';

const notificationsReducer = combineReducers({
  table: tableReducer,
  list: listReducer,
});

export default notificationsReducer;
