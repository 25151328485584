import { RootState } from '../../../rootReducer';
import { INotificationsTableState } from './types';

export const selectNotifications = (state: RootState): INotificationsTableState =>
  state.notifications.table;

export const selectIsSelectedAll = (state: RootState): boolean => {
  const { data, selected } = state.notifications.table;

  return !!data.notifications.length && selected.length === data.notifications.length;
};
