import React, { FC, ReactNode, useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NonIdealState, Button, Tooltip } from '@blueprintjs/core';
import { Col, Row } from 'react-bootstrap';
import omit from 'lodash/omit';
// Components
import Checkbox from '../checkbox/Checkbox';
import Loader from 'src/components/loader/Loader';
import TableRow, { ITableRowProps } from './TableRow';
import TableHeaderCell, { ITableCol } from './TableHeaderCell';
import TableFilter from './TableFilter';
import Pagination from 'src/components/pagination/Pagination';
import Popover from 'src/components/popover/Popover';
import CustomButton, { ButtonSize } from '../../components/button/Button';
// Types
import { IDashboardTableRow } from '../../types/internal-Types';
import { ESort } from '../../types/enums';

import './table-styles.scss';
import getColSize from './getColSize';

export interface ITableFilters {
  [field: string]: string;
}

export interface ITableSort {
  sortBy: string;
  direction: ESort;
}

interface ITableProps {
  rows: IDashboardTableRow[];
  columns: ITableCol[];
  isLoading: boolean;
  currentPage: number;
  total: number;
  itemsPerPage: number;
  selected: string[];
  isSelectAll?: boolean;
  activeSort?: ITableSort;
  filters?: ITableFilters;
  onSelect: (id: string) => void;
  onSelectAll?: () => void;
  onSelectVisible: () => void;
  onFiltersApply?: (filters: ITableFilters | undefined) => void;
  onPageClick: (page: { selected: number }) => void;
  onSortChange?: (field: string, direction: ESort) => void;
  rowComponent?: (props: ITableRowProps) => ReactNode;
  nonIDealStateTitle?: string;
}

const Table: FC<ITableProps> = ({
  rows,
  columns,
  isLoading,
  total,
  itemsPerPage,
  currentPage,
  selected,
  isSelectAll,
  activeSort,
  onPageClick,
  onSelect,
  onSelectAll,
  onSelectVisible,
  onFiltersApply,
  onSortChange,
  rowComponent,
  nonIDealStateTitle,
  filters,
}) => {
  const { t } = useTranslation(['dashboard', 'table']);
  const [activeFilters, setFilter] = useState<ITableFilters | undefined>(filters);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const hasFilters = useMemo(() => columns.some((col) => !!col.filter), [columns]);

  const colorField = useMemo(
    () => columns.find(({ type }) => type === 'line_color')?.field,
    [columns]
  );
  const isAllSelected = !!rows.length && selected.length === rows.length;

  const colSize = useMemo(() => getColSize(columns), [columns]);

  const renderRow = useCallback(
    (props: ITableRowProps) => {
      const color = colorField ? props.data.values[colorField] : undefined;
      return rowComponent ? (
        rowComponent({ ...props, color, key: props.data.id })
      ) : (
        <TableRow {...props} key={props.data.id} color={color} />
      );
    },
    [rowComponent, colorField]
  );

  const handleFilter = useCallback((field, value) => {
    setFilter((currentFilters) => {
      if (value && value !== '') {
        return { ...currentFilters, [field]: value };
      } else {
        const newFilters = omit(currentFilters, [field]);
        return Object.keys(newFilters).length ? newFilters : undefined;
      }
    });
  }, []);

  const handleApply = useCallback(() => {
    if (onFiltersApply) {
      onFiltersApply(activeFilters);
      setIsOpen(false);
    }
  }, [activeFilters, onFiltersApply]);

  const handleReset = useCallback(() => {
    if (onFiltersApply) {
      setFilter(undefined);
      onFiltersApply(undefined);
      setIsOpen(false);
    }
  }, [onFiltersApply]);

  useEffect(() => {
    setFilter(filters);
  }, [filters]);

  return (
    <div className="dynamic-table">
      <Row className="table-header">
        <Col xs={1} className="table-checkbox">
          <Checkbox
            checked={isAllSelected}
            onChange={onSelectVisible}
            disabled={!rows?.length}
            large
          />
          {!!selected.length && (
            <Tooltip
              disabled={!onSelectAll || !isAllSelected || rows.length === total}
              content={
                isSelectAll
                  ? t('table:buttons.Select visible', { count: rows.length })
                  : t('table:buttons.Select all', { count: total })
              }
              popoverClassName="custom-popover"
            >
              <Button
                className="select-all-button"
                onClick={onSelectAll}
                disabled={!onSelectAll || !isAllSelected || rows.length === total}
              >
                {isSelectAll ? `(${total})` : `(${selected.length})`}
              </Button>
            </Tooltip>
          )}
        </Col>
        {!!columns?.length &&
          columns?.map(
            ({ label, field, sort, size, className, type }) =>
              type !== 'operation' &&
              type !== 'line_color' && (
                <TableHeaderCell
                  key={field}
                  onSort={onSortChange}
                  field={field}
                  label={label}
                  sort={sort}
                  sortDirection={
                    activeSort?.sortBy === field ? activeSort.direction : undefined
                  }
                  size={size ?? colSize}
                  className={className}
                />
              )
          )}
        {hasFilters && (
          <Popover
            placement="bottom-end"
            popoverClassName="table-filters-popover"
            isOpen={isOpen}
            onInteraction={(isPopoverOpen) => {
              setIsOpen(isPopoverOpen);
            }}
          >
            <Button
              className={`action-button table-fiters-button ${filters ? 'active' : ''}`}
              small
              icon="filter"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
            <div>
              <div className="table-filters-list">
                {!!columns?.length &&
                  columns?.map(
                    ({ label, field, filter, type }) =>
                      filter && (
                        <TableFilter
                          key={field}
                          field={field}
                          label={label}
                          type={type}
                          onFilterChange={handleFilter}
                          onPressEnter={handleApply}
                          filterValue={activeFilters ? activeFilters[field] : undefined}
                        />
                      )
                  )}
              </div>
              <div className="table-filters-buttons">
                <CustomButton
                  disabled={!activeFilters}
                  size={ButtonSize.small}
                  onClick={handleApply}
                >
                  {t('buttons.Apply')}
                </CustomButton>
                <CustomButton
                  size={ButtonSize.small}
                  disabled={!activeFilters}
                  onClick={handleReset}
                >
                  {t('buttons.Cancel')}
                </CustomButton>
              </div>
            </div>
          </Popover>
        )}
      </Row>
      <div className="table-rows-container table-rows">
        <Loader loading={isLoading} withShadow />
        {!!rows.length &&
          rows.map((row, index) =>
            renderRow({
              data: row,
              onSelect,
              isSelected: selected.includes(row.id),
              columns,
              key: `${row.id}${index}`,
            })
          )}
        {!rows?.length && !isLoading && (
          <NonIdealState
            icon="error"
            title={nonIDealStateTitle ?? t('No records found')}
            className="table-non-ideal-state"
          />
        )}
        {!!rows?.length && (
          <Pagination
            forcePage={currentPage}
            pageCount={total ? total / itemsPerPage : 0}
            onPageChange={onPageClick}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
