// Types
import { Value } from 'react-quill/lib';

export const variableRegex = /(@\[[A-Za-z0-9_-]+(?::[A-Za-z0-9_-]+)*\]@)/;
export const functionRegex = /(@fx:[A-Za-z0-9_-]+:(?:(?:@\[[A-Za-z0-9_-]+(?::[A-Za-z0-9_-]+)*\]@|[A-Za-z0-9_-])(\s(?:(?=@\[[A-Za-z0-9_-]+(?::[A-Za-z0-9_-]+)*\]@|[A-Za-z0-9_-])))*)*:efx)/;

export const splitVariables = (value: string): string[] => value.split(variableRegex);

export const extractFunctionName = (template: string) => {
  const [, funcName] = template.match(/@fx:([A-Za-z0-9_-]*?):/) ?? [];
  return funcName;
};

export const extractArgs = (template: string) => {
  const [, argsString] =
    template.match(
      /:((?:(?:@\[[A-Za-z0-9_-]+(:[A-Za-z0-9_-]+)*\]@|[A-Za-z0-9_-])(\s(?:(?=@\[[A-Za-z0-9_-]+(:[A-Za-z0-9_-]+)*\]@|[A-Za-z0-9_-])))*)*?):efx/
    ) ?? [];
  return argsString ? argsString.split(' ') : [];
};

export const extractVariableName = (template: string) => {
  const [, varName] = template.match(/@\[([A-Za-z0-9_-]+(?::[A-Za-z0-9_-]+)*?)\]@/) ?? [];
  return `${varName.replace(/:/g, '.')}`;
};

export const splitByRegex = (template: string = ''): string[] => {
  return template
    ? String(template)
        .split(functionRegex)
        .reduce((result: string[], substring: string) => {
          if (!substring) return result;
          if (functionRegex.test(substring)) return [...result, substring];
          return [...result, ...splitVariables(substring)];
        }, [])
    : [];
};

export const getNewFunction = (name: string, args: string[] | string = []) =>
  `@fx:${name}:${Array.isArray(args) ? args.join(' ') : args}:efx`;
export const getNewVariable = (name: string) => `@[${name.replace(/\./g, ':')}]@`;

export const formatValue = (initialValue: string) => {
  return {
    ops: splitByRegex(initialValue).map((part) => ({
      insert: functionRegex.test(part)
        ? {
            function: {
              name: extractFunctionName(part),
              isValid: true,
              validArgs: extractArgs(part),
              args: extractArgs(part),
            },
          }
        : variableRegex.test(part)
        ? { variable: { name: extractVariableName(part), isValid: true } }
        : part ?? '',
    })),
  } as Value;
};

export const getValue = (content): string => {
  if (!content || !content.ops) {
    return '';
  }

  return content.ops.reduce((result: string, item) => {
    if (!item?.insert) {
      return '';
    }
    if (item?.insert?.variable) {
      const { name } = item?.insert?.variable;
      return (result += getNewVariable(name));
    }
    if (item?.insert?.function) {
      const { name, args } = item?.insert?.function;
      return (result += getNewFunction(name, args));
    }
    return (result += item.insert);
  }, '');
};

export type ValidationFunc = (name: string) => boolean;

export const validateInput = (
  content,
  validateFunction?: ValidationFunc,
  validateVariable?: ValidationFunc
) => {
  if (!content || !content.ops) {
    return { ops: [] };
  }

  return {
    ops: content.ops.map((item) => {
      if (item?.insert?.variable && validateVariable) {
        const { name } = item?.insert?.variable;
        const isValid = validateVariable(name);
        return { insert: { variable: { name, isValid } } };
      }
      if (item?.insert?.function && validateFunction) {
        const { name, args } = item?.insert?.function;
        const isValid = validateFunction(name);

        const validArgs = args.filter((arg) => {
          if (variableRegex.test(arg) && validateVariable) {
            return validateVariable(extractVariableName(arg));
          }
          return true;
        });

        return { insert: { function: { name, isValid, validArgs, args } } };
      }
      return { insert: item.insert };
    }),
  } as Value;
};

export const validateTemplateString = (
  value: string,
  validateFunction,
  validateVariable
) => {
  return splitByRegex(value).every((item) => {
    if (variableRegex.test(item) && !validateVariable(extractVariableName(item))) {
      return false;
    }

    if (functionRegex.test(item)) {
      if (!validateFunction(extractFunctionName(item))) {
        return false;
      }
      return extractArgs(item).every((arg) =>
        variableRegex.test(arg) ? validateVariable(extractVariableName(arg)) : true
      );
    }

    return true;
  });
};
