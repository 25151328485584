import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Selectors
import { selectLanguageState } from '../redux/slices/language/selectors';
// Utils
import { LanguagesKeys } from '../redux/slices/language/languages';
// Actions
import { setSelectedLanguage } from '../redux/slices/language/languageSlice';
// Services
import LanguageService from '../services/LanguageService';
// Components
import { IValueKey } from '../components/generic-selector/GenericSelector';

export const LANGUAGE_PARAM_NAME = 'language';

interface UseLanguage {
  selectedLanguage: IValueKey;
  allLanguages: IValueKey[];
  setLanguage: (value: IValueKey) => void;
}
const useLanguage = (): UseLanguage => {
  const { selectedLanguage, allLanguages } = useSelector(selectLanguageState);

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const updateLanguageInternal = useCallback((value: IValueKey) => {
    LanguageService.set(value.Key as LanguagesKeys);
  }, []);

  const setLanguage = useCallback(
    (value: IValueKey) => {
      updateLanguageInternal(value);
      i18n.changeLanguage(value.Key);
      dispatch(setSelectedLanguage(value));
    },
    [dispatch, updateLanguageInternal, i18n]
  );

  return {
    selectedLanguage,
    allLanguages,
    setLanguage,
  };
};

export default useLanguage;
