import React, { FC, ReactNode, ReactElement, useState, useEffect } from 'react';
import { Tab, TabId, Tabs as TabsComponent } from '@blueprintjs/core';
import { useLocation, useHistory } from 'react-router-dom';
import { stringify, parse } from 'query-string';
// Types
import { ITabs, ITabItem } from './block-types';
// Utils
import getClassName from '../utils/getClassName';
import { getQueryParameterByName } from '../../../utils/genUtils';
// Styles
import '../../tabs/styles.scss';

interface ITabsProps {
  className?: string;
  id: string;
  defaultSelectedTabId?: TabId;
  vertical?: boolean;
  children: ITabItem[];
  builder: (childern: any) => ReactNode;
  useUrlParams: boolean;
}

const Tabs: FC<ITabsProps> = ({
  builder,
  children,
  defaultSelectedTabId = children[0].id,
  id,
  useUrlParams,
  vertical,
  className,
  ...props
}) => {
  const urlParams = useLocation().search;
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState<TabId>(defaultSelectedTabId);

  const handleTabChange = (tabId) => {
    if (useUrlParams) {
      history.push({ search: stringify({ ...parse(urlParams), [id]: tabId }) });
    } else {
      setSelectedTab(tabId);
    }
  };

  useEffect(() => {
    if (useUrlParams) {
      const param = getQueryParameterByName(id, urlParams);
      if (typeof param === 'string' && children.find((tab) => tab.id === param)) {
        setSelectedTab(param);
      }
    }
  }, [urlParams, useUrlParams, children, id]);

  return (
    <TabsComponent
      id={id}
      {...props}
      vertical={vertical}
      onChange={handleTabChange}
      selectedTabId={selectedTab ?? defaultSelectedTabId}
      className={`custom-tabs ${className ?? ''}`}
    >
      {children.map((item: ITabItem, index) => {
        return (
          <Tab
            id={item.id}
            disabled={item.disabled}
            key={index}
            panel={builder(item.children) as ReactElement}
            title={builder(item.label)}
            className={getClassName(item.classes)}
          />
        );
      })}
    </TabsComponent>
  );
};

export default Tabs;

export const mapTabsProps = (
  { id, children, selectedItemId, vertical, classes = [], useUrlParams }: ITabs,
  builder
) => ({
  className: getClassName(classes),
  defaultSelectedTabId: selectedItemId,
  children,
  id,
  vertical,
  builder,
  useUrlParams,
});
