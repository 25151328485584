import React, { FC, useCallback } from 'react';
// Components
import IntegerFilter from './filters/IntegerFilter';
import DateFilter from './filters/DateFilter';
import StringFilter from './filters/StringFilter';

// Types
import { EDashboardTableColTypes } from 'src/types/enums';

export interface ITableFilterProps {
  onFilterChange: (field: string, value?: string | number) => void;
  onPressEnter?: () => void;
  field: string;
  label: string;
  filterValue?: string | number;
  type?: EDashboardTableColTypes;
}

const TableFilter: FC<ITableFilterProps> = ({
  field,
  label,
  onFilterChange,
  onPressEnter,
  filterValue = '',
  type,
}) => {
  const handleChange = useCallback(
    (fieldName, value) => {
      onFilterChange(fieldName, value);
    },
    [onFilterChange]
  );

  const handleKeyPress = useCallback(() => {
    if (onPressEnter) {
      onPressEnter();
    }
  }, [onPressEnter]);

  switch (type) {
    case EDashboardTableColTypes.integer:
      return (
        <IntegerFilter
          field={field}
          label={label}
          filterValue={filterValue}
          onFilterChange={handleChange}
          onPressEnter={handleKeyPress}
        />
      );
    case EDashboardTableColTypes.date:
      return (
        <DateFilter
          field={field}
          label={label}
          filterValue={filterValue}
          onFilterChange={handleChange}
        />
      );
    default:
      return (
        <StringFilter
          field={field}
          label={label}
          filterValue={String(filterValue)}
          onFilterChange={handleChange}
          onPressEnter={handleKeyPress}
        />
      );
  }
};

export default TableFilter;
