import { IUser } from '../types/api-Types';
import BaseApiService, { RequestType } from './BaseApiService';

class UserService extends BaseApiService {
  logout = () =>
    this.request({
      url: 'logout',
      type: RequestType.post,
    });

  getCurrent = () =>
    this.request({
      url: 'me',
      type: RequestType.post,
    });

  update = (id: string, data: UpdateUserData) =>
    this.request({
      url: id,
      type: RequestType.put,
      data,
    });
}

export interface UpdateUserData extends Partial<IUser> {}

export default new UserService('rest/user');
