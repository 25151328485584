import React, { FC } from 'react';
import { HTMLInputProps } from '@blueprintjs/core/src/common/props';
import {
  Checkbox,
  FormGroup,
  ICheckboxProps as ICheckboxPropsBlueprint,
} from '@blueprintjs/core';

// Styles
import './checkbox-styles.scss';

export interface ICheckboxProps extends ICheckboxPropsBlueprint {
  helperText?: string;
  label?: string;
  labelInfo?: string;
  password?: boolean;
  loading?: boolean;
  validationError?: string | false | undefined;
  className?: string;
}

const Input: FC<ICheckboxProps & HTMLInputProps> = ({
  helperText,
  label,
  labelInfo,
  loading = false,
  password = false,
  validationError,
  value,
  className: propsClassName,
  ...props
}) => {
  let className = 'checkbox-form-group';
  if (label) {
    className += ' checkbox-with-label';
  }
  return (
    <FormGroup
      helperText={validationError || helperText}
      label={label}
      labelInfo={labelInfo}
      className={`${className} ${propsClassName}`}
    >
      <Checkbox {...props} large className="checkbox-styles" />
    </FormGroup>
  );
};

export default Input;
