import { useField } from 'formik';
import React, { FC } from 'react';

// Component
import TextAreaComponent from '../../../components/textarea/TextArea';

// Types
import { ITextArea } from './input-types';

// Utils
import getClassName from '../utils/getClassName';

const TextArea: FC<ITextArea> = ({ label, name, className }) => {
  const [field, meta] = useField({ name });

  return (
    <TextAreaComponent
      rows={5}
      {...field}
      label={label}
      placeholder={label}
      name={name}
      validationError={meta.touched && meta.error}
      className={className}
    />
  );
};

export default TextArea;

export const mapTextAreaProps = ({ key, label, classes }: ITextArea) => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
});
