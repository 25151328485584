import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// Actions
import { changeNotificationsStatus } from 'src/redux/slices/notifications/list/thunks';
import { setTaskId } from '../../redux/slices/dashboard/logs/logsSlice';
import { selectCurrentUser } from 'src/redux/slices/user/selectors';
// Types
import { INotification } from 'src/types/internal-Types';

type UseChangeNotificationStatus = (data: INotification) => void;

const useChangeNotificationStatus = (): UseChangeNotificationStatus => {
  const user = useSelector(selectCurrentUser);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleUpdateStatus = useCallback(
    (notification) => {
      if (user.data?.uid) {
        dispatch(
          changeNotificationsStatus({ ids: [notification.id], userId: user.data?.uid })
        );
      }
    },
    [user, dispatch]
  );

  const handleClick = useCallback(
    (notification: INotification) => {
      handleUpdateStatus(notification);
      if (notification?.link) {
        if (notification.task_id) {
          dispatch(setTaskId(notification.task_id));
        }
        history.push('/dashboard/' + notification.link);
      }
    },
    [history, handleUpdateStatus, dispatch]
  );

  return handleClick;
};

export default useChangeNotificationStatus;
