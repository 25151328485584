import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import Input from '../../../../components/input/Input';
import { Icon } from '@blueprintjs/core';
// Types
import {
  CreateConnectorFormData,
  IConnectorServer,
} from '../../../../types/internal-Types';
import { getHelperText } from '../../utils';

interface IServerItemProps {
  data: IConnectorServer;
  path: string;
  onRemove: () => void;
}

const ServerItem: FC<IServerItemProps> = ({ data, path, onRemove }) => {
  const { handleBlur, handleChange, touched, errors } = useFormikContext<
    CreateConnectorFormData
  >();
  const { t } = useTranslation(['connectors']);

  return (
    <div className="server-item">
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={data.url}
        name={path}
        placeholder={t('inputs.Url')}
        validationError={getHelperText(touched, errors, path)}
      />

      <div className="server-item-controls">
        <Icon icon="trash" onClick={onRemove} />
      </div>
    </div>
  );
};

export default ServerItem;
