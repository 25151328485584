import React, { FC, useRef, useEffect, useCallback, useMemo } from 'react';

import {
  ViewUpdate,
  EditorView,
  highlightActiveLine,
  drawSelection,
  highlightSpecialChars,
  keymap,
} from '@codemirror/view';
import { Compartment, EditorState } from '@codemirror/state';
import { history, historyKeymap } from '@codemirror/history';
import { foldGutter, foldKeymap } from '@codemirror/fold';
import { indentOnInput } from '@codemirror/language';
import { highlightActiveLineGutter, lineNumbers } from '@codemirror/gutter';
import { defaultKeymap } from '@codemirror/commands';
import { bracketMatching } from '@codemirror/matchbrackets';
import { closeBrackets, closeBracketsKeymap } from '@codemirror/closebrackets';
import { searchKeymap, highlightSelectionMatches } from '@codemirror/search';
import { rectangularSelection } from '@codemirror/rectangular-selection';
import { defaultHighlightStyle } from '@codemirror/highlight';
import { Diagnostic, linter } from '@codemirror/lint';
// Theme
import { themeExt } from './theme';
// Utils
import { getMode } from './utils';
// Types
import { CodeEditorLanguages } from './types';

// Styles
import './code-editor-style.scss';

const updateListenerCompartment = new Compartment();
const blurListenerCompartment = new Compartment();
const langCompartment = new Compartment();
const lintCompartment = new Compartment();

interface ICodeEditorProps {
  value: string;
  mode: CodeEditorLanguages;
  readOnly?: boolean;
  onChange?: (value: string) => void;
  onLint?: (errors: Diagnostic[]) => void;
  onBlur?: () => void;
  className?: string;
}

const CodeEditor: FC<ICodeEditorProps> = ({
  value,
  mode,
  onChange,
  onBlur,
  onLint,
  className,
  readOnly,
}) => {
  const editorElem = useRef<null | HTMLDivElement>(null);
  const editorRef = useRef<EditorView | null>(null);

  const { langExtention, parser } = useMemo(() => getMode(mode), [mode]);

  const onUpdate = useCallback(
    (update: ViewUpdate) => {
      const newValue = update.state.doc.toString();
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange]
  );

  const lint = useCallback(
    (editor) => {
      const errors = parser(editor.state.doc.toString());
      if (onLint) {
        onLint(errors);
      }
      return errors;
    },
    [parser, onLint]
  );

  useEffect(() => {
    if (editorRef.current) {
      const currentValue = editorRef.current.state.doc.toString();
      if (currentValue !== value) {
        editorRef.current.dispatch({
          changes: [{ insert: value, from: 0, to: currentValue.length }],
        });
      }
    }
  }, [value, editorRef]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.dispatch({
        effects: [
          blurListenerCompartment.reconfigure(
            EditorView.domEventHandlers({ blur: onBlur })
          ),
          updateListenerCompartment.reconfigure(EditorView.updateListener.of(onUpdate)),
          lintCompartment.reconfigure(linter(lint)),
          langCompartment.reconfigure(langExtention),
        ],
      });
    }
  }, [onBlur, onUpdate, lint, langExtention, readOnly]);

  useEffect(() => {
    if (editorElem.current && !editorRef.current) {
      const state = EditorState.create({
        doc: value,
        extensions: [
          EditorView.lineWrapping,
          EditorView.editable.of(!readOnly),
          lineNumbers(),
          highlightActiveLineGutter(),
          highlightSpecialChars(),
          history(),
          foldGutter(),
          drawSelection(),
          EditorState.allowMultipleSelections.of(true),
          indentOnInput(),
          defaultHighlightStyle.fallback,
          bracketMatching(),
          closeBrackets(),
          rectangularSelection(),
          highlightActiveLine(),
          highlightSelectionMatches(),
          themeExt,
          keymap.of([
            ...closeBracketsKeymap,
            ...defaultKeymap,
            ...searchKeymap,
            ...historyKeymap,
            ...foldKeymap,
          ]),
          blurListenerCompartment.of(EditorView.domEventHandlers({ blur: onBlur })),
          updateListenerCompartment.of(EditorView.updateListener.of(onUpdate)),
          langCompartment.of(langExtention),
          lintCompartment.of(linter(lint)),
        ],
      });

      editorRef.current = new EditorView({
        parent: editorElem.current,
        state,
      });
    }
  }, [editorElem, langExtention, readOnly, lint, onBlur, onUpdate, value]);

  useEffect(() => {
    return () => {
      editorRef.current?.destroy();
      editorRef.current = null;
    };
  }, []);

  return <div className={`code-editor ${className ?? ''}`} ref={editorElem} />;
};

export default CodeEditor;
