import { AxiosPromise } from 'axios';
import BaseApiService, { RequestType } from './BaseApiService';

// Types
import { ComponentActions, EDashboardComponents } from '../types/enums';

// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';

class ComponentService extends BaseApiService {
  get = (
    id: string,
    component: EDashboardComponents,
    action: ComponentActions
  ): AxiosPromise =>
    this.request({
      url: id + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'component',
          component,
          input_action: action,
          input_op: 'form',
        },
        silent: !IntegrationDebugSettingsService.get(id),
      },
    });

  update = (
    id: string,
    component: EDashboardComponents,
    data: IComponentData
  ): AxiosPromise =>
    this.request({
      url: id + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'component',
          component,
          input_action: 'settings',
          input_op: 'submit',
          data,
        },
      },
    });
}

export interface IComponentData {
  [key: string]: string;
}

export default new ComponentService('rest/connector');
