import React, { FC, useEffect, useMemo } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Selectros
import {
  selectConnectorById,
  selectConnectorsListState,
} from '../../redux/slices/connectors/list/selectors';
// Actions
import {
  reset,
  setConnectorData,
} from '../../redux/slices/connectors/edit/editConnectorSlice';
import { reset as resetTestState } from '../../redux/slices/connectors/test/testConnectorSlice';
// Components
import EditConnectorPage from './EditConnectorPage';
import { Col, Grid, Row } from 'react-bootstrap';
import { IconName, NonIdealState } from '@blueprintjs/core';
import ConnectorTestPage from './components/testing/ConnectorTestPage';
import DocumentationPage from './components/documentation/DocumentationPage';
import Navigation from './components/Navigation';
import Loader from '../../components/loader/Loader';
// Types
import { RequestStatus } from 'src/redux/slices/types';
// Utils
import { getConnectorFormData } from './utils';

const ConnectorPage: FC = () => {
  const { connectorId } = useParams<{ connectorId: string }>();

  const connector = useSelector(selectConnectorById(connectorId));
  const { loading } = useSelector(selectConnectorsListState);

  const { t } = useTranslation(['connectors']);
  const dispatch = useDispatch();

  const routes = useMemo(
    () => [
      {
        path: `/connectors/${connectorId}/edit/settings`,
        title: t('buttons.Settings'),
        iconType: 'cog' as IconName,
      },
      {
        path: `/connectors/${connectorId}/edit/yaml`,
        title: t('buttons.Code view'),
        iconType: 'code' as IconName,
      },
      {
        path: `/connectors/${connectorId}/testing`,
        title: t('buttons.Testing'),
        iconType: 'tick-circle' as IconName,
      },
      {
        path: `/connectors/${connectorId}/documentation`,
        title: t('buttons.Documentation'),
        iconType: 'book' as IconName,
      },
    ],
    [t, connectorId]
  );

  useEffect(
    () => () => {
      dispatch(reset());
      dispatch(resetTestState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (connector) {
      const { nid, ...rest } = connector;
      dispatch(
        setConnectorData(
          getConnectorFormData({
            ...rest,
            info: {
              ...rest.info,
              logo: {
                filename: rest.info.logo?.filename ?? '',
                file: rest.info.logo?.teaser ?? '',
              },
            },
          })
        )
      );
    }
  }, [connector, dispatch]);

  if (loading !== RequestStatus.pending && !connector) {
    return (
      <div className="non-ideal-state-container">
        <NonIdealState icon="search" title={t('Connector not found', { connectorId })} />
      </div>
    );
  }

  return (
    <Loader loading={loading === RequestStatus.pending}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Navigation routes={routes} />
          </Col>
        </Row>
        <Row className="main-row main-row-connectors">
          <Col xs={12}>
            <Switch>
              <Route path={`/connectors/${connectorId}/edit`}>
                <EditConnectorPage connectorId={connectorId} />
              </Route>
              <Route path={`/connectors/${connectorId}/testing`}>
                <ConnectorTestPage connectorId={connectorId} />
              </Route>
              <Route path={`/connectors/${connectorId}/documentation`}>
                <DocumentationPage connectorId={connectorId} />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Grid>
    </Loader>
  );
};

export default ConnectorPage;
