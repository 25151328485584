import React, { FC } from 'react';
import { Tag, ITagProps } from '@blueprintjs/core';

import './style.scss';

const CustomTag: FC<ITagProps> = (props) => {
  return <Tag {...props} className={`custom-tag ${props.className}`} />;
};

export default CustomTag;
