import { useEffect, useState, useCallback } from 'react';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Selectrors
import { selectSelectedCompanyState } from '../../../../redux/slices/companies/selected-company/selectors';
// Types
import { RequestStatus } from '../../../../redux/slices/types';
import { ISubmitForm } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
// Utils
import { stringifyFormData } from '../../../../utils/stringifyFormData';
import stringifyStateError from '../../../../utils/stringifyStateError';
import useFormNotification from '../../../../utils/useFormNotification';
// Service
import CompaniesService from '../../../../services/CompaniesService';

const useCompanyActionsForm = () => {
  const [layout, setLayout] = useState<any>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(RequestStatus.idle);

  const company = useSelector(selectSelectedCompanyState);

  const { t } = useTranslation(['dynamicLayout, dynamicForm']);

  const { showNotification } = useFormNotification();

  const getLayout = useCallback(async () => {
    if (company?.nid) {
      setLoading(RequestStatus.pending);
      try {
        const { data } = await CompaniesService.getForm(company.nid);
        if (data.output.error) {
          throw new Error(data.output.error);
        }
        if (data.output) {
          setLayout(data.output);
          setLoading(RequestStatus.fulfilled);
        }
      } catch (error) {
        setError(error);
        setLoading(RequestStatus.rejected);
      }
    }
  }, [company]);

  // Handlers
  const handleDynamicFormSubmit = useCallback(
    async ({ data: formData, callbackUrl, submitPayload }: ISubmitForm) => {
      try {
        const { data } = await CompaniesService.submitForm(
          callbackUrl,
          stringifyFormData(formData, submitPayload)
        );
        if (!data.output) {
          throw new Error('');
        }
        if (data?.output?.error) {
          throw new Error(data.output.error);
        }
        if (data?.output?.notification) {
          showNotification(data?.output?.notification);
          if (data?.output?.layout) {
            return data.output.layout;
          }
        } else return data.output;
      } catch (error) {
        toastr.error(
          t('dynamicForm:alert.Failed'),
          t('dynamicForm:alert.Error update', { error: stringifyStateError(error) })
        );
      }
    },
    [t, showNotification]
  );

  useEffect(() => {
    if (error && loading === RequestStatus.rejected) {
      toastr.error(
        t('dynamicLayout:alert.Failed'),
        t('dynamicLayout:alert.Error fetch', { error: stringifyStateError(error) })
      );
    }
  }, [error, loading, t]);

  useEffect(() => {
    setLayout(null);
    setLoading(RequestStatus.idle);
    setError(null);
  }, [company]);

  return { layout, loading, error, handleDynamicFormSubmit, getLayout };
};

export default useCompanyActionsForm;
