import React, { FC, useState, useCallback, createContext } from 'react';

export interface IFormVisibilityContext {
  isFormVisible: boolean;
  toggleIsFormVisible: (state?: boolean) => void;
}

export const FormVisibilityContext = createContext<IFormVisibilityContext>(
  {} as IFormVisibilityContext
);

const FormVisibilityContextProvider: FC = ({ children }) => {
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

  const toggleIsFormVisible = useCallback((state = true) => {
    setIsFormVisible(state);
  }, []);

  return (
    <FormVisibilityContext.Provider value={{ isFormVisible, toggleIsFormVisible }}>
      {children}
    </FormVisibilityContext.Provider>
  );
};

export default FormVisibilityContextProvider;
