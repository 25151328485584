import { SerializedError } from '../redux/slices/types';

const stringifyStateError = (error: SerializedError | null): string => {
  if (error && Array.isArray(error?.message?.data)) {
    return error?.message?.data.join();
  }

  if (error && typeof error?.message?.data === 'string') {
    return error.message.data;
  }

  if (error && typeof error?.message === 'string') {
    return error.message;
  }

  if (error && typeof error?.message?.data === 'object') {
    return Object.values(error.message?.data?.form_errors).join();
  }

  return '';
};

export default stringifyStateError;
