import { useField } from 'formik';
import React, { FC } from 'react';

// Component
import SwitchComponent from '../../../components/custom-switch/CustomSwitch';

// Types
import { ISwitch } from './input-types';

// Utils
import getClassName from '../utils/getClassName';

const Switch: FC<ISwitch> = ({ label, name, className }) => {
  const [field, , helpers] = useField({ name });

  const handleChange = (event) => {
    helpers.setValue(event.target.checked);
  };

  return (
    <SwitchComponent
      checked={field.value}
      onChange={handleChange}
      className={className}
      name={label}
    />
  );
};

export default Switch;

export const mapSwitchProps = ({ key, label, classes }: ISwitch) => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
});
