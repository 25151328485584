import React, { FC } from 'react';

// Types
import { IJsonViewer } from './block-types';
// Components
import CodeEditor from '../../code-editor/CodeEditor';
// Utils
import getClassName from '../utils/getClassName';

interface IJsonViewerProps {
  value: string;
  className?: string;
}

const JsonViewer: FC<IJsonViewerProps> = ({ value = '', className }) => {
  return (
    <div className={`json-editor-container ${className}`}>
      <CodeEditor mode="json" readOnly value={value} />
    </div>
  );
};

export default JsonViewer;

export const mapJsonViewerProps = ({ value, classes }: IJsonViewer) => ({
  value: typeof value === 'string' ? value : JSON.stringify(value, undefined, 2),
  className: getClassName(classes),
});
