import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';
import { CreateConnectorFormData } from '../../../../../types/internal-Types';
import { useFormikContext } from 'formik';
// Components
import componentsMap from './componentsMap';
import { NonIdealState } from '@blueprintjs/core';

const FormPreview: FC = () => {
  const { values } = useFormikContext<CreateConnectorFormData>();

  const { t } = useTranslation(['connectors']);

  return (
    <div className="margin-top-xs ">
      {values?.form.length && values.form.every((field) => field.type) ? (
        <div className="form-preview">
          <StructureErrorBoundary>
            <DynamicLayout structure={values?.form} componentsMap={componentsMap} />
          </StructureErrorBoundary>
        </div>
      ) : (
        <div className="non-ideal-state-container ">
          <NonIdealState icon="form" title={t('Form is empty')} />
        </div>
      )}
    </div>
  );
};

export default FormPreview;
