import { createAsyncThunk } from '@reduxjs/toolkit';
import NotificationsService from '../../../../services/NotificationsService';
import {
  IGetNotificationsListActionPayload,
  IGetNotificationsListResult,
  IChangeListNotificationStatusActionPayload,
  IChangeListNotificationStatusResult,
} from './types';
import { ITEMS_PER_PAGE } from '../consts';

export const getNotificationsList = createAsyncThunk<
  IGetNotificationsListResult,
  IGetNotificationsListActionPayload
>('notifications.list/getNotificationsList', async ({ userId }, { rejectWithValue }) => {
  try {
    const { data } = await NotificationsService.get({
      userId,
      page: 1,
      limit: ITEMS_PER_PAGE,
      read: false,
    });

    return {
      data: data.notifications,
      total: data.total,
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue({ error: error.response });
  }
});

export const changeNotificationsStatus = createAsyncThunk<
  IChangeListNotificationStatusResult,
  IChangeListNotificationStatusActionPayload
>(
  'notifications.list/changeNotificationsStatus',
  async ({ ids, userId }, { rejectWithValue }) => {
    try {
      const { data } = await NotificationsService.update(ids, userId, true);

      return { updatedItems: data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);
