import { yupToFormErrors } from 'formik';
import validationSchema from './validationSchema';

import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

// Types
import {
  CreateConnectorFormData,
  EConnectorAuthTypes,
  IConnectorResponse,
  IConnectorServer,
  ISchema,
} from 'src/types/internal-Types';
import { IConnectorSettings } from '../../redux/slices/connectors/create/types';
import { RequestType } from 'src/services/BaseApiService';

export const validate = async (values) => {
  try {
    await validationSchema.validate(values, {
      abortEarly: false,
      context: values,
    });
    return null;
  } catch (err) {
    return yupToFormErrors(err);
  }
};

export const getHelperText = (touched: object, errors: object, path: string) =>
  get(touched, path) && get(errors, path);

// Get path to list of endpoints and schemes
export const getPrevPath = (url: string, steps: number) =>
  url.replace(/\/$/g, '').split('/').slice(0, -steps).join('/');

export const getConnectorDescription = (
  formData: CreateConnectorFormData
): IConnectorSettings => {
  const {
    logo,
    title,
    default_namespace = '',
    namespace = '',
    description,
    securitySchemes,
    servers,
    tags,
    endpoints,
    security,
    form,
  } = formData;

  // Find schemes names by ids
  const formatSchemas = (ids: number[]) =>
    securitySchemes
      ?.filter((scheme, index) => ids?.includes(index))
      ?.map((currScheme) => ({ [currScheme.title]: [] })) ?? [];

  return {
    info: {
      title,
      description,
      logo,
      default_namespace,
      namespace,
    },
    servers: servers?.map(({ url }) => ({ url })) ?? [],
    tags,
    form: form?.map((field) => omit(field, 'id')) ?? [],
    security: formatSchemas(security),
    components: {
      securitySchemes: mapValues(keyBy(securitySchemes, 'title'), (scheme) => {
        const {
          type,
          name = '',
          value = '',
          username = '',
          password = '',
          in: placeIn,
        } = scheme;
        if (type === EConnectorAuthTypes.api_key) {
          return {
            type,
            name: name?.trim(),
            value: value?.trim(),
            in: placeIn,
          };
        }
        if (type === EConnectorAuthTypes.bearer) {
          return {
            type,
            value: value?.trim(),
          };
        }
        return {
          type,
          username: username?.trim(),
          password: password?.trim(),
        };
      }),
    },
    // @ts-ignore
    paths: mapValues(keyBy(endpoints, 'path'), (endpoint) => {
      const { requests } = endpoint;
      return {
        ...mapValues(keyBy(requests, 'method'), (request) => {
          const {
            tags: endpointTags,
            operationId,
            parameters,
            requestBody,
            responses,
            security: reqSecurity,
            useGlobalSecurity,
          } = request;
          return {
            tags: endpointTags,
            operationId,
            ...(parameters?.length
              ? { parameters: parameters.map((parameter) => omit(parameter, 'id')) }
              : {}),
            ...(request.method === RequestType.put ||
            request.method === RequestType.post ||
            request.method === RequestType.patch
              ? {
                  requestBody: {
                    content: mapValues(
                      keyBy(requestBody?.content, 'type'),
                      (contentType) => omit(contentType, ['id', 'type'])
                    ),
                  },
                }
              : {}),
            responses: mapValues(keyBy(responses, 'statusCode'), (response) =>
              omit(response, ['id', 'statusCode'])
            ),
            ...(!useGlobalSecurity ? { security: formatSchemas(reqSecurity) } : {}),
          };
        }),
      };
    }),
  };
};

export const getConnectorFormData = (
  data: IConnectorSettings
): CreateConnectorFormData => {
  if (!data || typeof data !== 'object') {
    return {
      title: '',
      description: '',
      default_namespace: '',
      namespace: '',
      logo: undefined,
      form: [],
      servers: [],
      tags: [],
      securitySchemes: [],
      security: [],
      endpoints: [],
    };
  }
  const {
    title = '',
    logo,
    description = '',
    default_namespace = '',
    namespace,
  } = data?.info;
  const {
    servers = [],
    tags = [],
    form = [],
    paths = {},
    security = [],
    components,
  } = data;

  const setIds = (array: any[]) => array?.map((item, index) => ({ ...item, id: index }));

  const securitySchemes = components?.securitySchemes
    ? Object.entries(components.securitySchemes).map(
        ([schemeTitle, schemeData], index) => ({
          ...schemeData,
          title: schemeTitle,
          id: index,
        })
      )
    : [];

  // Find selected schemes ids by name
  const getSecuritySchemesIds = (schemes): number[] => {
    return schemes?.reduce((ids: string[], scheme) => {
      const [schemeName] = Object.keys(scheme);
      const schemeData = securitySchemes.find((item) => item.title === schemeName);
      if (schemeData) {
        return [...ids, schemeData.id];
      }
      return ids;
    }, []);
  };

  const endpoints = Object.entries(paths).map(([path, endpoint], index) => {
    const { ...requests } = endpoint;
    return {
      id: index,
      path,
      requests: Object.entries(requests).map(([method, requestData], requestIndex) => {
        const {
          tags: requestTags,
          operationId,
          parameters = [],
          responses = {},
          requestBody = { content: {} },
          security: reqSecurity,
        } = requestData;
        return {
          id: requestIndex,
          method: method as RequestType,
          tags: requestTags,
          operationId,
          responses: Object.entries(responses).map(
            ([statusCode, responseData], responseIndex) => ({
              ...(responseData as Omit<IConnectorResponse, 'statusCode' | 'id'>),
              id: responseIndex,
              statusCode: Number.parseInt(statusCode, 10),
            })
          ),
          parameters: setIds(parameters),
          ...(method === RequestType.put ||
          method === RequestType.post ||
          method === RequestType.patch
            ? {
                requestBody: {
                  content: Object.entries(requestBody.content).map(
                    ([type, contentTypeData], contentTypeIndex) => ({
                      type,
                      id: contentTypeIndex,
                      ...(contentTypeData as { schema: ISchema }),
                    })
                  ),
                },
              }
            : {}),
          security: getSecuritySchemesIds(reqSecurity),
          useGlobalSecurity: !reqSecurity,
        };
      }),
    };
  });

  return {
    title,
    logo,
    description,
    default_namespace,
    namespace,
    tags,
    form: setIds(form),
    servers: setIds(servers) as IConnectorServer[],
    securitySchemes,
    security: getSecuritySchemesIds(security),
    endpoints,
  };
};
