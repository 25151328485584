import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompany } from '../../../../types/api-Types';
import { updateIntegrationStatus } from '../../../actions/system-actions';

// Actions
import updateIntegration from './updateIntegration';
import { buildIntegration } from '../../marketplace/thunk';
// Types
import { IConnectorResultSuccess, SelectedCompanyState } from './types';
import { IBuildIntegrationSuccessResult } from '../../marketplace/types';

const initialState: SelectedCompanyState = {
  data: undefined,
};

const selectedCompanySlice = createSlice({
  name: 'companies.selected',
  initialState,
  reducers: {
    selectCompany: (state, action: PayloadAction<ICompany>): SelectedCompanyState => ({
      ...state,
      data: action.payload,
    }),
    reset: (): SelectedCompanyState => initialState,
  },
  extraReducers: {
    [String(updateIntegrationStatus)]: (
      state: SelectedCompanyState,
      action: PayloadAction<IConnectorResultSuccess>
    ): SelectedCompanyState => {
      const { status, connectorId } = action.payload;
      const field_integrations = state?.data?.field_integrations;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          field_integrations: field_integrations
            ? updateIntegration(field_integrations, connectorId, {
                status,
              })
            : [],
        },
      };
    },
    [String(buildIntegration.fulfilled)]: (
      state: SelectedCompanyState,
      action: PayloadAction<IBuildIntegrationSuccessResult>
    ): SelectedCompanyState => {
      const { company_id, data: newIntegration } = action.payload;
      if (state.data && company_id === state.data.nid) {
        const integrations = [...state.data.field_integrations, newIntegration];
        return { ...state, data: { ...state.data, field_integrations: integrations } };
      }
      return { ...state };
    },
  },
});

export const { selectCompany, reset } = selectedCompanySlice.actions;

export default selectedCompanySlice.reducer;
