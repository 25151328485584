import React, { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import ExpressionInputComponent from '../../../../components/expression-input/ExpressionInput';
import Popover from '../../../../components/popover/Popover';
// Utils
import { formatValue, getValue } from 'src/components/expression-input/utils';
// Types
import ReactQuill from 'react-quill';

const formats = ['variable'];

interface IExpressionInput {
  value: string;
  onChange: (content: string, field?: string) => void;
  onBlur: (field: string) => void;
  name: string;
  helperText?: string;
  validationError?: string | false | undefined;
  placeholder?: string;
  label?: string;
  options: string[];
}

const ExpressionInput: FC<IExpressionInput> = ({
  placeholder,
  label,
  value,
  onBlur,
  onChange,
  validationError,
  options,
  name,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const editorRef = useRef<ReactQuill | null>(null);

  const { t } = useTranslation(['connectors']);

  const validateVariable = useCallback(
    (varName) => !!options.find((field) => field === varName),
    [options]
  );

  const handleChange = useCallback(
    (content, field) => {
      onChange(getValue(content), field);
    },
    [onChange]
  );

  const handleInsertVar = useCallback((varName) => {
    if (editorRef.current) {
      const editor = editorRef.current.getEditor();
      editor.focus();
      const range = editor.getSelection();
      if (range) {
        editor.insertEmbed(
          range?.index ?? editor.getLength(),
          'variable',
          { name: varName },
          'user'
        );
      }
      editor.setSelection((range?.index ?? editor.getLength()) + 1, 0);
    }
  }, []);

  return (
    <Popover
      placement="auto"
      isOpen={isOpen}
      enforceFocus={false}
      autoFocus={false}
      onInteraction={(isPopoverOpen) => {
        setIsOpen(isPopoverOpen);
      }}
      popoverClassName="auth-scheme-variables"
    >
      <ExpressionInputComponent
        ref={editorRef}
        placeholder={placeholder}
        label={label}
        value={formatValue(value)}
        name={name}
        onChange={handleChange}
        formats={formats}
        onBlur={onBlur}
        validateVariable={validateVariable}
        validationError={validationError}
      />
      {options.length ? (
        <div className="pills-list">
          {options.map((variable, index) => (
            <span
              className="variable pill"
              key={index}
              onClick={() => {
                handleInsertVar(variable);
              }}
            >
              {variable}
            </span>
          ))}
        </div>
      ) : (
        t('No options available')
      )}
    </Popover>
  );
};

export default ExpressionInput;
