import BaseApiService, { RequestType } from './BaseApiService';

// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';
// Types
import { SerializedError } from 'src/redux/slices/types';
import { EJobState } from 'src/types/enums';

class JobsService extends BaseApiService {
  getStatus = async (key, { connectorId, jobId }) => {
    const { data } = await this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          operation: 'status',
          op: 'director',
          object: jobId,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });

    if (data?.output?.error) {
      return Promise.reject({ message: data.output.error } as SerializedError);
    }

    if (data?.output?.command) {
      const { name, state, progress, tasks, estimated_end, errors } = data.output.command;

      return {
        jobId,
        name,
        state,
        progress: Number.parseFloat(progress),
        estimated_end,
        tasks: {
          completed: tasks.completed,
          total: tasks.total,
          failed: errors,
        },
      };
    } else throw new Error();
  };

  performJobOperation = async ({ connectorId, jobId, operation }) =>
    this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          operation,
          op: 'director',
          object: jobId,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
}

export default new JobsService('rest/connector');

export interface IGetJobStatusSuccessResult {
  jobId: string;
  name: string;
  state: EJobState;
  progress: number;
  estimated_end?: Date;
  tasks?: {
    total: number;
    completed: number;
    failed: number;
  };
}
