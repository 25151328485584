import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
// Components
import { Alert, Intent, Icon, IconName } from '@blueprintjs/core';
import Checkbox from '../../../../components/checkbox/Checkbox';
import ListItem from '../list-item/ListItem';
// Hooks
import useAlert from '../../../../utils/useAlert';
import useAsGlobal from './useAsGlobal';
// Types
import { ISecurityScheme, EConnectorAuthTypes } from '../../../../types/internal-Types';

export const AuthTypeIcons = new Map<EConnectorAuthTypes, IconName>([
  [EConnectorAuthTypes.api_key, 'key'],
  [EConnectorAuthTypes.oauth2, 'lock'],
  [EConnectorAuthTypes.basic_auth, 'user'],
  [EConnectorAuthTypes.bearer, 'tag'],
]);

interface IAuthSchemeTeaserProps {
  index: number;
  value: ISecurityScheme;
  onRemove: (id: number) => void;
  isComplete?: boolean;
}

const AuthSchemeTeaser: FC<IAuthSchemeTeaserProps> = ({
  value,
  onRemove,
  isComplete = false,
  index,
}) => {
  const { isChecked, toggle, remove } = useAsGlobal(index);

  const icon = AuthTypeIcons.get(value.type);

  const { t } = useTranslation(['connectors']);
  const history = useHistory();
  const match = useRouteMatch();

  const handleRemove = useCallback(() => {
    onRemove(index);
    remove();
  }, [onRemove, remove, index]);

  const handleEdit = useCallback(() => {
    history.push(`${match.url}/${index}`);
  }, [history, index, match.url]);

  const handleCheck = useCallback(
    (e) => {
      e.stopPropagation();
      toggle();
    },
    [toggle]
  );

  const deleteAlert = useAlert({ callback: handleRemove });

  return (
    <div className="auth-scheme-teaser">
      <ListItem
        intent={isComplete ? Intent.SUCCESS : Intent.WARNING}
        title={value.title ? value.title : t('titles.New scheme')}
        leftElement={
          <>
            <Checkbox
              onChange={handleCheck}
              checked={isChecked}
              className="use-globaly-checkbox"
            />
            <Icon icon={icon} className="auth-type-icon" iconSize={20} />
          </>
        }
        onEdit={handleEdit}
        onRemove={(e) => {
          e.stopPropagation();
          deleteAlert.onOpen();
        }}
      />
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={deleteAlert.isOpen}
        onCancel={deleteAlert.onClose}
        onConfirm={deleteAlert.onSubmit}
      >
        <p>{t('alert.Delete scheme')}</p>
      </Alert>
    </div>
  );
};

export default AuthSchemeTeaser;
