import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useHistory, useLocation, useParams } from 'react-router';
// Components
import Input from '../../../../components/input/Input';
import { NonIdealState, H4, Icon } from '@blueprintjs/core';
import RequestsList from './request-forms/RequestsList';
// Types
import { CreateConnectorFormData } from 'src/types/internal-Types';
// Utils
import { getHelperText, getPrevPath } from '../../utils';

// Style
import './styles.scss';

const Endpoint: FC = () => {
  const { handleBlur, handleChange, touched, errors, values } = useFormikContext<
    CreateConnectorFormData
  >();

  const { endpointId } = useParams<{ endpointId: string }>();

  const index = Number.parseInt(endpointId, 10);

  const { t } = useTranslation(['connectors']);

  const history = useHistory();
  const location = useLocation();

  const endpointValues = values.endpoints[index];

  const handleGoBack = useCallback(() => {
    history.push(getPrevPath(location.pathname, 1));
  }, [history, location]);

  if (!endpointValues) {
    return (
      <>
        <div className="connector-section-title">
          <Icon icon="circle-arrow-left" onClick={handleGoBack} iconSize={20} />
        </div>
        <div className="non-ideal-state-container">
          <NonIdealState icon="search" title={t('Endpoint not found')} />
        </div>
      </>
    );
  }

  return (
    <div className="endpoint">
      <div className="connector-section-title">
        <Icon icon="circle-arrow-left" onClick={handleGoBack} iconSize={20} />
        <H4>{endpointValues.path ? endpointValues.path : t('titles.New endpoint')}</H4>
      </div>
      <div className="endpoint-form">
        <Input
          label={t('inputs.Path')}
          placeholder="path/"
          value={endpointValues.path}
          name={`endpoints.${index}.path`}
          onBlur={handleBlur}
          onChange={handleChange}
          validationError={getHelperText(touched, errors, `endpoints.${index}.path`)}
        />
        <div className="connector-form-section">
          <RequestsList path={`endpoints.${index}`} values={endpointValues.requests} />
        </div>
      </div>
    </div>
  );
};

export default Endpoint;
