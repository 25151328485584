import ReduxToastr from 'react-redux-toastr';
import * as React from 'react';

// Styles
import './toaster-styles.scss';

class Toaster extends React.PureComponent {
  public render() {
    return (
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        className="toaster-custom"
        position="bottom-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
      />
    );
  }
}

export default Toaster;
