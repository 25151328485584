import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Formik } from 'formik';
// Selectros
import { selectCreateConnectorState } from '../../redux/slices/connectors/create/selectors';
// Actions
import { createConnector } from '../../redux/slices/connectors/create/thunks';
import { reset } from '../../redux/slices/connectors/create/createConnectorSlice';
// Components
import { Col, Grid, Row } from 'react-bootstrap';
import ConnectorForm from './components/form/ConnectorForm';
import ConnectorYamlEditor from './components/yaml-editor/ConnectorYamlEditor';
import FormActions from 'src/components/form-actions/FormActions';
import Button, { ButtonSize } from 'src/components/button/Button';
import Navigation from './components/Navigation';
// Types
import { RequestStatus } from 'src/redux/slices/types';
import { IconName } from '@blueprintjs/icons';
// Utils
import stringifyStateError from 'src/utils/stringifyStateError';
import { getConnectorDescription, validate } from './utils';

const CreateConnector: FC = () => {
  const { newConnectorId, initialValues, submit, error } = useSelector(
    selectCreateConnectorState
  );
  const isSubmitting = submit === RequestStatus.pending;

  const history = useHistory();
  const { t } = useTranslation(['connectors']);
  const dispatch = useDispatch();

  const routes = useMemo(
    () => [
      {
        path: '/connectors/create/settings',
        title: t('buttons.Settings'),
        iconType: 'cog' as IconName,
      },
      {
        path: '/connectors/create/yaml',
        title: t('buttons.Code view'),
        iconType: 'code' as IconName,
      },
    ],
    [t]
  );

  const handleCreateConnector = useCallback(
    (data) => {
      const { logo, ...formData } = data;
      dispatch(
        createConnector(
          getConnectorDescription({
            ...formData,
            ...(logo?.file
              ? {
                  logo: {
                    ...logo,
                    file: logo.file.replace(/^data:image\/\w+;base64,/, ''),
                  },
                }
              : {}),
          })
        )
      );
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (error) {
      const message = stringifyStateError(error);

      toastr.error(t('alert.Error create'), message);
    }
  }, [error, t]);

  useEffect(() => {
    if (submit === RequestStatus.fulfilled) {
      toastr.success(t('Success'), t('alert.Success create'));
      history.push(`/connectors/${newConnectorId}/testing`);
    }
  }, [dispatch, submit, t, history, newConnectorId]);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <Navigation routes={routes} />
        </Col>
      </Row>
      <Row className="main-row main-row-connectors">
        <Formik
          initialValues={initialValues}
          onSubmit={handleCreateConnector}
          validate={validate}
        >
          {({ handleSubmit, isValid, dirty }) => (
            <form onSubmit={handleSubmit} className="connector-form">
              <FormActions>
                <Button
                  disabled={isSubmitting}
                  size={ButtonSize.small}
                  onClick={handleCancel}
                >
                  {t('buttons.Cancel')}
                </Button>
                <Button
                  size={ButtonSize.small}
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting || !dirty}
                  type="submit"
                >
                  {t('buttons.Save')}
                </Button>
              </FormActions>
              <Switch>
                <Route path="/connectors/create/yaml/" exact>
                  <ConnectorYamlEditor />
                </Route>
                <Route path="/connectors/create/settings">
                  <ConnectorForm />
                </Route>
              </Switch>
            </form>
          )}
        </Formik>
      </Row>
    </Grid>
  );
};

export default CreateConnector;
