import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import CompaniesService from '../../../../services/CompaniesService';

// Types
import { CreateCompanyActionSuccessResult, CreateCompanyActionPayload } from './types';

export const createCompany = createAsyncThunk<
  CreateCompanyActionSuccessResult,
  CreateCompanyActionPayload
>('companies/create/postCreateCompany', async (data, { rejectWithValue }) => {
  try {
    await CompaniesService.create(data);

    return {};
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response);
  }
});
