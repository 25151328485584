import React, { useEffect, useMemo } from 'react';
import { Label } from '@blueprintjs/core';
import { useFormik } from 'formik';
import _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Images
import accountDetailsImage from '../../../images/accountDetails.svg';
import backgroundImage from '../../../images/accountDetailsBackground.svg';
import placeholder from '../../../images/UserIcon.png';

// Selectors
import { selectCurrentUser } from '../../../redux/slices/user/selectors';
import { selectLanguageState } from '../../../redux/slices/language/selectors';

// Actions
import { getUser, updateUser } from '../../../redux/slices/user/thunks';
import { login } from '../../../redux/slices/auth';

// Types
import { EditUserFormValues } from '../../../redux/slices/user/types';
import { RequestStatus } from '../../../redux/slices/types';
import { IUser } from 'src/types/api-Types';

// Utils
import getImageAsBase64 from '../../../utils/getImageAsBase64';
import stringifyStateError from '../../../utils/stringifyStateError';

// Components
import Button, { ButtonSize } from '../../button/Button';
import FormActions from '../../form-actions/FormActions';
import ImagePicker from '../../image-picker/ImagePicker';
import Input from '../../input/Input';
import GenericSelector, {
  IValueKey,
} from 'src/components/generic-selector/GenericSelector';
import UserTeaser, { UserTeaserSizes } from './UserTeaser';
import CustomScrollbars from '../../custom-scrollbars/CustomScrollbars';
// Styles
import './user-edit-styles.scss';

import validationSchema from './validationSchema';

const UserEdit = () => {
  // State
  const { allLanguages } = useSelector(selectLanguageState);
  const { data, submit, loading, initialValues, error } = useSelector(selectCurrentUser);

  const { t } = useTranslation(['user', 'language']);

  const dispatch = useDispatch();
  const history = useHistory();

  // Handle form submit
  const onSubmit = async (formData: EditUserFormValues) => {
    if (data?.uid) {
      const newData = _.omit(formData, [
        ...(!formData.current_password
          ? ['current_password', 'password', 'confirm_password']
          : []),
        ...(formData.field_user_picture?.file === data.field_user_picture?.teaser
          ? ['field_user_picture']
          : []),
      ]);

      if (newData.field_user_picture?.file) {
        newData.field_user_picture = {
          ...newData.field_user_picture,
          file: newData.field_user_picture.file.replace(/^data:image\/\w+;base64,/, ''),
        };
      }

      await dispatch(updateUser({ id: data.uid, user: newData as Partial<IUser> }));

      // If password was updated - force user login with new credentials
      if (formData.password && data?.name) {
        dispatch(login({ username: data.name, password: formData.password }));
      } else {
        dispatch(getUser());
      }
    }
  };

  // Formik
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
    resetForm,
  } = useFormik<EditUserFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
    isInitialValid: false,
    enableReinitialize: true,
  });

  const language = useMemo(
    () => allLanguages.filter(({ Key }) => Key === values?.language),
    [allLanguages, values.language]
  );

  // Handlers
  const handleTeaserChange = async (file: File) => {
    const base64 = await getImageAsBase64(file);

    setFieldValue('field_user_picture.file', base64);
    setFieldValue('field_user_picture.filename', file.name);
  };

  const handleTeaserReset = () => {
    setFieldValue('field_user_picture', null);
  };

  const handleLanguageSelect = (res: IValueKey[]) => {
    setFieldValue('language', res[0]?.Key ?? undefined);
  };

  const handleCancel = () => {
    resetForm();
    history.goBack();
  };

  // Effects
  /* eslint react-hooks/exhaustive-deps: 0 */
  useEffect(() => {
    if (error) {
      const message = stringifyStateError(error);

      toastr.error('Failed', 'Failed to save user data.' + message);
    }

    if (!error && submit === RequestStatus.fulfilled) {
      toastr.success('Success', 'Successfully updated user data.');
    }
  }, [submit, error]);

  const isSubmit = submit === RequestStatus.pending;
  const isLoading = loading === RequestStatus.pending;
  const userAvatar = values.field_user_picture?.file || placeholder;
  const isCanAvatarReset =
    !!values.field_user_picture?.file &&
    values.field_user_picture?.file !== '' &&
    !isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <div className="userForm">
        <div className="background-picture">
          <img src={backgroundImage} alt="" />
        </div>
        <div className="iconAccount">
          <img src={accountDetailsImage} alt="" />
        </div>
        <FormActions>
          <Button
            disabled={isSubmit || isLoading}
            size={ButtonSize.small}
            onClick={handleCancel}
          >
            {t('buttons.Cancel')}
          </Button>
          <Button
            type="submit"
            size={ButtonSize.small}
            loading={isSubmit}
            disabled={isLoading || isSubmit || !isValid}
          >
            {t('buttons.Save')}
          </Button>
        </FormActions>
        <CustomScrollbars autoHeight autoHeightMax={'50vh'} style={{ width: '100%' }}>
          <div className="form-holder">
            <Row>
              <Col xs={8}>
                <Input
                  loading={isLoading}
                  placeholder={t('inputs.First Name')}
                  value={values.field_first_name}
                  name="field_first_name"
                  validationError={touched.field_first_name && errors.field_first_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <Input
                  loading={isLoading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('inputs.Last name')}
                  validationError={touched.field_last_name && errors.field_last_name}
                  value={values.field_last_name}
                  name="field_last_name"
                />
              </Col>
              <Col xs={4}>
                <ImagePicker
                  canReset={isCanAvatarReset}
                  onChange={handleTeaserChange}
                  onReset={handleTeaserReset}
                >
                  <UserTeaser
                    src={userAvatar}
                    size={UserTeaserSizes.middle}
                    loading={isLoading}
                  />
                </ImagePicker>
              </Col>
            </Row>
            <Input
              loading={isLoading}
              placeholder={t('inputs.Email')}
              value={values.mail}
              name="mail"
              validationError={touched.mail && errors.mail}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Input
              loading={isLoading}
              placeholder={t('inputs.Current Password')}
              type="password"
              name="current_password"
              value={values.current_password}
              validationError={touched.current_password && errors.current_password}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Input
              loading={isLoading}
              placeholder={t('inputs.New Password')}
              type="password"
              name="password"
              value={values.password}
              validationError={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Input
              loading={isLoading}
              placeholder={t('inputs.Confirm Password')}
              type="password"
              name="confirm_password"
              value={values.confirm_password}
              validationError={touched.confirm_password && errors.confirm_password}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <Input
              loading={isLoading}
              placeholder={t('inputs.Phone')}
              value={values.field_mobile_phone}
              name="field_mobile_phone"
              validationError={touched.field_mobile_phone && errors.field_mobile_phone}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <Label>{t('titles.Personal Information')}</Label>
            <Input
              loading={isLoading}
              placeholder={t('inputs.Invoice Email')}
              value={values.field_invoice_email}
              name="field_invoice_email"
              validationError={touched.field_invoice_email && errors.field_invoice_email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <div className="language-selector">
              <GenericSelector
                selectedItem={language}
                selectorText={t('Preferred Language')}
                isMultiSelector={false}
                onValueChanged={handleLanguageSelect}
                items={allLanguages}
              />
            </div>
          </div>
        </CustomScrollbars>
      </div>
    </form>
  );
};

export default UserEdit;
