import { combineReducers } from 'redux';

// Reducers
import createConnectorReducer from './create/createConnectorSlice';
import connectorsListReducer from './list/connectorsListSlice';
import editConnectorReducer from './edit/editConnectorSlice';
import testConnectorReducer from './test/testConnectorSlice';

export default combineReducers({
  create: createConnectorReducer,
  list: connectorsListReducer,
  edit: editConnectorReducer,
  test: testConnectorReducer,
});
