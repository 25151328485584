import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import ContentTypeItem from './ContentTypeItem';
import Button, { ButtonSize } from '../../../../../../components/button/Button';
import { H6, Icon, NonIdealState } from '@blueprintjs/core';
import Collapse from '../../../../../../components/collapse/Collapse';
// Types
import { IRequestContentType, CreateConnectorFormData } from 'src/types/internal-Types';

interface IContentTypesListProps {
  path: string;
  data: IRequestContentType[];
}

const ContentTypesList: FC<IContentTypesListProps> = ({ data, path }) => {
  const { setFieldValue } = useFormikContext<CreateConnectorFormData>();

  const { t } = useTranslation(['connectors']);

  const handleAdd = useCallback(() => {
    const currentValue = [...data];
    currentValue.push({
      type: '',
      schema: {
        type: 'string',
      },
    });
    setFieldValue(`${path}.requestBody.content`, currentValue);
  }, [setFieldValue, data, path]);

  const handleRemove = useCallback(
    (index: number) => {
      const currentValue = [...data];
      currentValue.splice(index, 1);
      setFieldValue(`${path}.requestBody.content`, currentValue);
    },
    [setFieldValue, data, path]
  );

  return (
    <Collapse header={<H6>{t('titles.Content types')}</H6>}>
      <div className="content-types-list">
        {data?.length ? (
          data.map((contentTypeItem, index) => (
            <ContentTypeItem
              index={index}
              onRemove={handleRemove}
              data={contentTypeItem}
              key={index}
              path={`${path}.requestBody.content.${index}`}
            />
          ))
        ) : (
          <NonIdealState
            icon={<Icon icon="error" iconSize={40} />}
            description={`${t('No content types')}`}
          />
        )}
      </div>
      <div className="content-types-list-footer">
        <Button onClick={handleAdd} size={ButtonSize.small}>
          {t('buttons.Add content type')}
        </Button>
      </div>
    </Collapse>
  );
};

export default ContentTypesList;
