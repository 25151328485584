import React, { FC, FocusEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateInput as BlueprintDateInput, TimePrecision } from '@blueprintjs/datetime';
// Utils
import formatDateUtil from 'date-fns/format';
import parseDateUtil from 'date-fns/parse';
// Types
import { Intent } from '@blueprintjs/core/';

// Style
import './date-input-style.scss';

interface IDateInputProps {
  value?: Date;
  format?: string;
  className?: string;
  onChange: (selectedDate?: Date | null | string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  timePrecision?: TimePrecision;
  minDate?: Date;
  maxDate?: Date;
  locale?: string;
  intent?: Intent;
  placeholder?: string;
}

const DateInput: FC<IDateInputProps> = ({
  onChange,
  onBlur,
  value,
  className,
  timePrecision,
  locale,
  maxDate,
  minDate,
  format = 'MM-dd-yyyy',
  intent,
  placeholder,
}) => {
  const { t } = useTranslation(['dateinput']);

  const handleChange = useCallback(
    (date) => {
      onChange(date);
    },
    [onChange]
  );

  const formatDate = useCallback(
    (date, datelLocale) => formatDateUtil(date, format, { locale: datelLocale }),
    [format]
  );
  const parseDate = useCallback(
    (date, datelLocale) =>
      parseDateUtil(date, format, new Date(), { locale: datelLocale }),
    [format]
  );

  return (
    <BlueprintDateInput
      fill
      shortcuts={false}
      value={value}
      placeholder={placeholder ?? format}
      timePrecision={timePrecision}
      onChange={handleChange}
      locale={locale}
      maxDate={maxDate}
      minDate={minDate}
      formatDate={formatDate}
      parseDate={parseDate}
      className={className}
      invalidDateMessage={t('validation.Invalid date')}
      outOfRangeMessage={t('validation.Out of range')}
      popoverProps={{
        minimal: true,
        popoverClassName: 'date-input-popover',
      }}
      inputProps={{
        className: 'input-styles date-input-textfield',
        intent,
        onBlur,
      }}
      timePickerProps={
        timePrecision
          ? {
              showArrowButtons: true,
            }
          : undefined
      }
    />
  );
};

export default DateInput;
