import { IValueKey } from '../../../../components/generic-selector/GenericSelector';
import { RootState } from '../../../rootReducer';
import { IConnectorState } from './types';

export const selectConnectorState = (state: RootState): IConnectorState => {
  return state.dashboard.connector;
};

export const selectConnectorActions = (state: RootState): IValueKey[] => {
  const { connector } = state.dashboard;

  return connector?.actions || [];
};
