import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import Button, { ButtonSize } from '../../../components/button/Button';
import FormActions from '../../../components/form-actions/FormActions';

import Card from './Card';
import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';
// Actions
import {
  getComponentSettings,
  updateComponentSettings,
} from '../../../redux/slices/dashboard/components/thunks';
// Types
import { RequestStatus } from '../../../redux/slices/types';
import { IComponentProps } from './types';
import { IComponentConfig } from '../types';
// Utils
import useComponentData from './useComponentData';
import { getComponentStatus, getStatusIntent } from './utils';
import getClassName from '../../../components/dynamic-layout/utils/getClassName';
import FormVisibilityContextProvider from '../../../components/dynamic-layout/form-visibility-context/FormVisibilityContextProvider';
import { dashboardFormsComponentsMap as componentsMap } from '../componentsMap';

const Component: FC<IComponentProps> = ({ componentKey, logo, className }) => {
  const {
    connectorId,
    component,
    settings,
    componentStatus,
    submit,
    loading,
  } = useComponentData(componentKey);

  const dispatch = useDispatch();

  const { t } = useTranslation(['dashboard']);

  const onSubmit = async ({ data }) => {
    if (connectorId && component) {
      await dispatch(
        updateComponentSettings({
          connectorId,
          component,
          settings: data,
        })
      );

      dispatch(getComponentSettings({ connectorId, component }));
    }
  };

  const isLoading = loading === RequestStatus.pending;
  const isSubmit = submit === RequestStatus.pending;
  const componentStatusMessage = getComponentStatus(componentStatus);
  const isFormDataEmpty = !settings || !settings?.length;
  const [formStructure, setFormStructure] = useState<any>(null);

  useEffect(() => {
    setFormStructure({
      type: 'form',
      children: [
        // @ts-ignore
        ...(isFormDataEmpty ? [] : settings),
        { type: 'form-actions' },
      ],
    });
  }, [settings, isFormDataEmpty]);

  return (
    <FormVisibilityContextProvider>
      <Card
        intent={getStatusIntent(componentStatus)}
        logo={logo ?? <div>{component?.key}</div>}
        loading={isLoading}
        className={className}
      >
        {(onClose) => (
          <>
            {componentStatusMessage && (
              <div className="card-error-message">
                <span>{t('titles.Error')}</span>
                {componentStatusMessage}
              </div>
            )}
            <StructureErrorBoundary>
              {!isFormDataEmpty && (
                <DynamicLayout
                  structure={formStructure}
                  callService={onSubmit}
                  componentsMap={componentsMap}
                />
              )}
              {isFormDataEmpty && (
                <FormActions>
                  <Button disabled={isSubmit} size={ButtonSize.small} onClick={onClose}>
                    {t('buttons.Close')}
                  </Button>
                </FormActions>
              )}
            </StructureErrorBoundary>
          </>
        )}
      </Card>
    </FormVisibilityContextProvider>
  );
};

export default Component;

export const mapComponentProps = (
  { component, html = '', classes = [] }: IComponentConfig,
  builder
) => {
  return {
    componentKey: component,
    logo: html === '' ? null : builder(html),
    className: getClassName(classes),
  };
};
