import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import { Icon } from '@blueprintjs/core';
// Types
import { ISecurityScheme } from '../../../../types/internal-Types';

import { AuthTypeIcons } from './AuthSchemeTeaser';

interface IAuthSchemeTeaserSmallProps {
  value: ISecurityScheme;
}

const AuthSchemeTeaserSmall: FC<IAuthSchemeTeaserSmallProps> = ({ value }) => {
  const icon = AuthTypeIcons.get(value.type);

  const { t } = useTranslation(['connectors']);

  return (
    <div className={`auth-scheme-teaser --small`}>
      <div className="auth-scheme-teaser-title">
        <Icon icon={icon} className="auth-type-icon" />
        {value.title ? value.title : t('titles.New scheme')}
      </div>
    </div>
  );
};

export default AuthSchemeTeaserSmall;
