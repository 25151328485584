import React, { FC, useCallback, useState } from 'react';
// Components
import { Col } from 'react-bootstrap';
import { Icon, Collapse } from '@blueprintjs/core';
import MappingRow from './MappingRow';
import MappingValue from './MappingValue';
// Utils
import { getNewPath, getParentPath } from '../utils';

interface IMappingResultRowProps {
  onEditValueRequest: (path: string) => void;
  onFieldNameChange: (path: string, newFieldName: string) => void;
  onAddField: (path: string, newFieldName: string) => void;
  onRemoveField: (path: string) => void;
  currentValue: string | object;
  fieldName: string;
  path: string;
}

const MappingResultRow: FC<IMappingResultRowProps> = ({
  fieldName,
  onFieldNameChange,
  onAddField,
  onRemoveField,
  onEditValueRequest,
  path,
  currentValue,
}) => {
  const [showCreateNestedField, setShowCreateNestedField] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    onEditValueRequest(path);
  }, [path, onEditValueRequest]);

  const handleChange = useCallback(
    (newFieldName) => {
      onFieldNameChange(path, newFieldName);
    },
    [path, onFieldNameChange]
  );

  const handleAddNested = useCallback(
    (newFieldName) => {
      onAddField(path, newFieldName);
      setShowCreateNestedField(false);
    },
    [path, onAddField]
  );

  const handleRemove = useCallback(() => {
    onRemoveField(path);
  }, [path, onRemoveField]);

  return (
    <MappingRow
      path={getParentPath(path)}
      fieldName={fieldName}
      onFieldNameChange={handleChange}
      controls={
        <>
          {!showCreateNestedField && fieldName && (
            <Icon
              className="add-field-button"
              icon="circle-arrow-right"
              onClick={() => setShowCreateNestedField(true)}
            />
          )}
          <Icon className="remove-field-button" icon="remove" onClick={handleRemove} />
        </>
      }
    >
      {!currentValue ||
      typeof currentValue !== 'object' ||
      Array.isArray(currentValue) ||
      !Object.keys(currentValue).length
        ? !showCreateNestedField &&
          fieldName && (
            <>
              <Col xs={7} className="source" onClick={handleClick}>
                <MappingValue
                  value={
                    currentValue && typeof currentValue !== 'object'
                      ? String(currentValue)
                      : ''
                  }
                  field={fieldName}
                />
              </Col>
              <Icon icon="edit" onClick={handleClick} />
            </>
          )
        : Object.entries(currentValue).map(([subFieldName, subFieldValue]) => (
            <MappingResultRow
              key={getNewPath(path, subFieldName)}
              fieldName={subFieldName}
              path={getNewPath(path, subFieldName)}
              currentValue={subFieldValue}
              onEditValueRequest={onEditValueRequest}
              onFieldNameChange={onFieldNameChange}
              onAddField={onAddField}
              onRemoveField={onRemoveField}
            />
          ))}{' '}
      <Collapse isOpen={showCreateNestedField} className="add-field-collapse">
        <MappingRow
          path={path}
          fieldName=""
          onFieldNameChange={handleAddNested}
          onCancel={() => setShowCreateNestedField(false)}
        />
      </Collapse>
    </MappingRow>
  );
};

export default MappingResultRow;
