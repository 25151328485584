import i18n from '../../../i18n';

// Icons
import en from '../../../images/british-flag.svg';
import da from '../../../images/dane-flag.svg';
// Types
import { IValueKey } from '../../../components/generic-selector/GenericSelector';

export enum LanguagesKeys {
  en = 'en',
  da = 'da',
}

export const DEFAULT_LANG_KEY = LanguagesKeys.en;

export type Language = [LanguagesKeys, string, string]; // language key, name, flag

const languages: Language[] = [
  [LanguagesKeys.en, i18n.getResource(LanguagesKeys.en, 'name', '') ?? 'English', en],
  [LanguagesKeys.da, i18n.getResource(LanguagesKeys.da, 'name', '') ?? 'Danish', da],
];

const findLanguage = (key: LanguagesKeys): Language => {
  return (languages.find(([currentKey]) => currentKey === key) ||
    languages[0]) as Language;
};

export const getAsValueKey = (): IValueKey[] =>
  languages.map(([Key, Value]) => ({ Key, Value }));

export const getByKey = (key: LanguagesKeys): IValueKey => {
  const [Key, Value] = findLanguage(key);

  return { Key, Value };
};

export const getFlagByKey = (key: LanguagesKeys) => {
  const [, , flag] = findLanguage(key);

  return flag;
};

export default languages;
