import React, { FC, useCallback } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// Actions
import { setFilterTag } from '../../../../redux/slices/marketplace/marketplaceSlice';
// Types
import { IMarketPlaceIntegration } from '../../../../services/MarketplaceService';
// Components
import Tag from '../../../../components/tag/Tag';
import IntegrationLogo from '../../../../components/Wedoio/Company/CompanyIntegrations/Integration/IntegrationLogo';
import TextCollapse from '../../../../components/text-collapse/TextCollapse';

interface IMarketplaceIntegrationProps {
  integration: IMarketPlaceIntegration;
}

const MarketplaceIntegrationTeaserSmall: FC<IMarketplaceIntegrationProps> = ({
  integration,
}) => {
  const [left, right] = integration?.icons || [];

  const dispatch = useDispatch();

  const onTagClick = useCallback(
    (tagName) => {
      dispatch(setFilterTag(tagName));
    },
    [dispatch]
  );

  return (
    <>
      <span className="marketplace-integration-nid">#{integration.nid}</span>

      <Col lg={3} className="marketplace-integration-icons-wrapper">
        <IntegrationLogo
          src={left?.thumbnail}
          alt={left?.alt ?? 'integration icon'}
          title={left?.title}
        />
        <IntegrationLogo
          src={right?.teaser || right?.medium}
          alt={right?.alt ?? 'integration icon'}
          title={right?.title}
        />
      </Col>
      <Col sm={6} className="marketplace-integration-info-wrapper">
        <div className="marketplace-integration-title">{integration.title}</div>
        <div className="marketplace-integration-description">
          <TextCollapse maxHeight={36}>
            <p>{integration.body?.value}</p>
          </TextCollapse>
        </div>

        {integration?.tags && (
          <div className="marketplace-integration-tags">
            {integration.tags.map((tag) => (
              <Tag
                intent="primary"
                large
                className="marketplace-integration-tag"
                key={tag}
                onClick={() => onTagClick(tag)}
              >
                {tag}
              </Tag>
            ))}
          </div>
        )}
      </Col>
    </>
  );
};

export default MarketplaceIntegrationTeaserSmall;
