import React, { FC, ReactNode } from 'react';
import { Row } from 'react-bootstrap';
// Types
import { IRow } from './block-types';
// Utils
import getClassName from '../utils/getClassName';

export interface IRowProps {
  children: ReactNode;
  className?: string;
}

const RowComponent: FC<IRowProps> = ({ children, className }) => {
  return <Row className={className}>{children}</Row>;
};

export const mapRowProps = ({ children, classes = [] }: IRow, builder) => ({
  children: builder(children),
  className: getClassName(classes),
});

export default RowComponent;
