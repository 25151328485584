import translations from './english.json';
import validation from './yup/english.json';
import countriesEN from './countries/english.json';
import connectors from './pages/connectors/english.json';
import dashboard from './pages/dashboard/english.json';
import notifications from './pages/notifications/english.json';
import home from './pages/home/english.json';
import companies from './pages/companies/english.json';
import company from './pages/company/english.json';
import login from './pages/login/english.json';
import signup from './pages/signup/english.json';
import settings from './pages/settings/english.json';
import user from './pages/user/english.json';

export default {
  ...translations,
  validation,
  countries: countriesEN,
  connectors,
  dashboard,
  notifications,
  home,
  companies,
  company,
  login,
  signup,
  settings,
  user,
};
