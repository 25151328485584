import React, { useEffect, useCallback } from 'react';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { useQueryCache, useQuery, useMutation } from 'react-query';
// Components
import TableForm from './TableForm';
// Types
import { ISubmitForm } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
import { SerializedError } from '../../../../redux/slices/types';
import { IDynamicLayout } from '../../../../types/internal-Types';
// Utils
import stringifyStateError from '../../../../utils/stringifyStateError';
// Service
import TableManagerService, {
  ICreateRowTableSubmit,
} from '../../../../services/TableManagerService';

interface ICreateFormPrors {
  connectorId?: string;
  tableName: string;
  isOpen: boolean;
  onSubmit?: () => void;
}

const CreateForm = ({ tableName, connectorId, isOpen, onSubmit }: ICreateFormPrors) => {
  const { t } = useTranslation(['dashboard', 'dynamicLayout']);

  const queryCache = useQueryCache();

  const { data: form, isLoading: formLoading, error } = useQuery<
    IDynamicLayout,
    SerializedError
  >(
    [`${tableName}-form`, { connectorId, tableName }],
    TableManagerService.getCreationForm,
    {
      enabled: connectorId && isOpen,
    }
  );

  const [createRow] = useMutation<void, SerializedError, ICreateRowTableSubmit>(
    TableManagerService.submitCreationForm,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(tableName);
        queryCache.invalidateQueries([`${tableName}-form`, { connectorId, tableName }]);
        if (onSubmit) {
          onSubmit();
        }
      },
      onError: (updateError) => {
        toastr.error(
          t('dashboard:alert.Failed'),
          t('dashboard:alert.Error record update', {
            error: stringifyStateError(updateError),
          })
        );
      },
    }
  );

  const handleSubmit = useCallback(
    async ({ data: formData }: ISubmitForm) => {
      if (connectorId) {
        await createRow({
          connectorId,
          tableName,
          formData,
        });
      }
    },
    [createRow, connectorId, tableName]
  );

  useEffect(() => {
    if (error) {
      toastr.error(
        t('dynamicLayout:alert.Failed'),
        t('dynamicLayout:alert.Error fetch', { error: stringifyStateError(error) })
      );
    }
  }, [error, t]);

  return (
    <TableForm
      structure={form}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      isLoading={formLoading}
    />
  );
};

export default CreateForm;
