import React, { FC } from 'react';
import { useTranslation } from 'react-i18next/';
import { useRouteMatch, NavLink } from 'react-router-dom';
import { useFormikContext } from 'formik';
// Components
import AuthSchemeTeaserSmall from '../auth-scheme/AuthSchemeTeaserSmall';
import EndpointTeaserSmall from '../endpoints/EndpointTeaserSmall';
import CustomScrollbars from 'src/components/custom-scrollbars/CustomScrollbars';
import { Card } from '@blueprintjs/core';
import Collapse from '../../../../components/collapse/Collapse';
// Types
import { CreateConnectorFormData } from 'src/types/internal-Types';

const FormNavigation: FC = () => {
  const { values } = useFormikContext<CreateConnectorFormData>();
  const { t } = useTranslation(['connectors']);
  const match = useRouteMatch();

  return (
    <Card className="custom-card">
      <CustomScrollbars hideTracksWhenNotNeeded autoHeightMin="70vh" autoHeight>
        <NavLink
          replace
          className="connector-menu-link"
          activeClassName="active"
          to={`${match.url}`}
        >
          {t('titles.Settings')}
        </NavLink>
        <NavLink
          replace
          activeClassName="active"
          className="connector-menu-link"
          to={`${match.url}/authentication/`}
        >
          {t('titles.Authentication')}
        </NavLink>
        <div className="padding-left-xxs">
          <NavLink
            replace
            activeClassName="active"
            className="connector-menu-link"
            to={`${match.url}/authentication/form-editor`}
          >
            {t('titles.Form')}
          </NavLink>
          <Collapse
            header={
              <NavLink
                replace
                className="connector-menu-link"
                activeClassName="active"
                to={`${match.url}/authentication/schemes`}
              >
                {t('titles.Schemes')}
              </NavLink>
            }
          >
            {values.securitySchemes.map((scheme, index) => (
              <NavLink
                replace
                key={index}
                className="connector-menu-link padding-left-xxs"
                activeClassName="active"
                to={`${match.url}/authentication/schemes/${index}`}
              >
                <AuthSchemeTeaserSmall value={scheme} />
              </NavLink>
            ))}
          </Collapse>
        </div>
        <Collapse
          header={
            <NavLink
              replace
              className="connector-menu-link"
              activeClassName="active"
              to={`${match.url}/endpoints`}
            >
              {t('titles.Endpoints')}
            </NavLink>
          }
        >
          {values.endpoints.map((endpoint, index) => (
            <NavLink
              replace
              key={index}
              className="connector-menu-link padding-left-xxs"
              activeClassName="active"
              to={`${match.url}/endpoints/${index}`}
            >
              <EndpointTeaserSmall value={endpoint} />
            </NavLink>
          ))}
        </Collapse>
      </CustomScrollbars>
    </Card>
  );
};

export default FormNavigation;
