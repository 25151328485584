import React, { FC, useEffect, useCallback, useState } from 'react';
import { Card, ProgressBar, Intent, Collapse } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { useQuery, useQueryCache, useMutation } from 'react-query';
// Components
import ArrowIcon from 'src/components/icons/ArrowIcon';
import Button, { ButtonSize } from 'src/components/button/Button';
import ControlButton from '../control-button/ControlButton';
import Loader from 'src/components/loader/Loader';
import IntentIcon from '../../../components/intent-icon/IntentIcon';
// Services
import CronsService, {
  ECronStatus,
  ECronOperation,
} from '../../../services/CronsService';
// Selectors
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
// Types
import { ICron } from '../types';
import { SerializedError } from 'src/redux/slices/types';
import { IGetCronStatusSuccessResult } from '../../../services/CronsService';
// Hooks
import useCronProgress from './useCronProgress';
import useRefreshInterval from '../utils/useRefreshInterval';
import './styles.scss';

interface CronProps {
  action: string;
  label?: string;
}

const Cron: FC<CronProps> = ({ action, label }) => {
  const { data: connector } = useSelector(selectConnectorState);

  const { data, isLoading: loading, error, isFetching, isError } = useQuery<
    IGetCronStatusSuccessResult,
    SerializedError
  >([action, { connectorId: connector?.nid, action }], CronsService.getStatus, {
    enabled: connector?.nid,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { progress } = useCronProgress(data);

  const isActiveRefresh =
    !!data && progress === 1 && data?.status === ECronStatus.enabled;

  useRefreshInterval(action, isActiveRefresh);

  const { t } = useTranslation(['dashboard']);
  const queryCache = useQueryCache();

  const [changeJobStatus, { isLoading: operationLoading }] = useMutation(
    CronsService.changeStatus,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(action);
      },
      onError: (changeStatusError, { operation }) => {
        toastr.error(
          t('alert.Failed'),
          t('alert.Cron status change failed', {
            cron: label,
            operation,
            error: changeStatusError,
          })
        );
      },
    }
  );

  const [execute, { isLoading: executeLoading }] = useMutation(CronsService.execute, {
    onSuccess: () => {
      queryCache.invalidateQueries(action);
    },
    onError: (executeError) => {
      toastr.error(
        t('alert.Failed'),
        t('alert.Cron execution error', {
          cron: label,
          error: executeError,
        })
      );
    },
  });

  // Handlers
  const toggleStatus = useCallback(
    async (operation: ECronOperation) => {
      if (connector?.nid && action) {
        changeJobStatus({
          connectorId: connector?.nid,
          action,
          operation,
        });
      }
    },
    [connector, action, changeJobStatus]
  );

  const handleExecute = useCallback(async () => {
    if (connector?.nid && action) {
      execute({
        connectorId: connector?.nid,
        action,
      });
    }
  }, [connector, action, execute]);

  useEffect(() => {
    if (error) {
      toastr.error(
        t('alert.Error'),
        t('alert.Cron error', {
          error: error.message,
          cron: label,
        })
      );
    }
  }, [error, label, t]);

  useEffect(() => {
    if (isActiveRefresh) {
      queryCache.invalidateQueries(action);
    }
  }, [isActiveRefresh, queryCache, action]);

  return (
    <>
      {!data && loading && <div className="bp3-skeleton cron-skeleton" />}
      {!loading && (
        <Card
          className={`cron custom-card custom-card--border ${error ? ' disabled' : ''}`}
        >
          <Loader withShadow showIcon={false} loading={isFetching} />
          <div className="cron-header">
            <div className="cron-name">{label}</div>
            <div className="cron-controls">
              <>
                {(data?.status === ECronStatus.disabled || !data) && (
                  <ControlButton
                    icon="play"
                    disabled={operationLoading || executeLoading || isError}
                    className={`cron-control ${
                      operationLoading || isError ? ' disabled' : ''
                    }`}
                    onClick={() => {
                      toggleStatus(ECronOperation.enable);
                    }}
                  />
                )}
                {data?.status === ECronStatus.enabled && (
                  <ControlButton
                    disabled={operationLoading || executeLoading || isError}
                    icon="pause"
                    className={`cron-control ${
                      operationLoading || isError ? ' disabled' : ''
                    }`}
                    onClick={() => {
                      toggleStatus(ECronOperation.disable);
                    }}
                  />
                )}
              </>
              <Button
                disabled={operationLoading || executeLoading || isError}
                onClick={handleExecute}
                size={ButtonSize.small}
                loading={executeLoading}
                className="cron-button"
              >
                {t('buttons.Execute')}
              </Button>
            </div>
          </div>
          <div className="cron-body">
            <div className="cron-progress">
              <ProgressBar
                value={progress}
                intent={Intent.SUCCESS}
                animate={data?.status === ECronStatus.enabled}
                stripes={data?.status === ECronStatus.enabled}
                className="cron-progress-bar"
              />
            </div>
            <div>
              <IntentIcon
                animate={data?.status === ECronStatus.enabled}
                intent={
                  isError
                    ? Intent.DANGER
                    : isActiveRefresh
                    ? Intent.WARNING
                    : Intent.SUCCESS
                }
              />
            </div>
            <div>
              <span className={`cron-details-button ${isOpen ? 'opened' : ''}`}>
                <ArrowIcon onClick={() => setIsOpen(!isOpen)} disabled={!data} />
              </span>
            </div>
            <div className="cron-details">
              <Collapse isOpen={isOpen}>
                {data && (
                  <>
                    <p>
                      {t('titles.Status')}: {data?.status}{' '}
                      {isActiveRefresh && `(${t('titles.waiting for the next run')})`}
                    </p>
                    <p>
                      {t('titles.Last run')}: {data?.last_run}
                    </p>
                    <p>
                      {t('titles.Next execution time')}:{data.next_execution_time}
                    </p>
                    <p>
                      {t('titles.Interval')}: {data?.interval} s
                    </p>
                  </>
                )}
              </Collapse>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default Cron;

export const mapCronProps = ({ integration_action, label }: ICron): CronProps => ({
  action: integration_action,
  label,
});
