import React, { FC, useRef, useEffect, useState, useCallback } from 'react';
import ReactJson from 'react-json-view';
import { Overlay, Popover } from 'react-bootstrap';
// Components
import OptionsList from './options/OptionsList';
// Utils
import fireEvent from './utils/fireEvent';
// Types
import { InteractionProps } from 'react-json-view';
import { IJsonEditorOption } from './options/types';
// Styles
import './styles.scss';
// Consts
import { TEXTAREA_TAG, INPUT_TAG, FILTER_ID } from './utils/consts';

interface IJsonEditorProps {
  src: object;
  enableClipboard?: boolean;
  enableEdit?: boolean;
  enableAdd?: boolean;
  enableDelete?: boolean;
  onChange?: (props: InteractionProps) => any | boolean;
  fieldNamesSuggestion?: IJsonEditorOption[];
  valuesSuggestion?: IJsonEditorOption[];
  className?: string;
}

const JsonEditor: FC<IJsonEditorProps> = ({
  src = {},
  onChange,
  enableEdit = false,
  enableAdd = false,
  enableDelete = false,
  enableClipboard = false,
  valuesSuggestion,
  fieldNamesSuggestion,
  className,
}) => {
  // State
  const ref = useRef(null);

  const [activeInput, setActiveInput] = useState(undefined);

  const isValuesSuggestionEmpty = !valuesSuggestion || !valuesSuggestion.length;
  const isFieldsSuggestionEmpty = !fieldNamesSuggestion || !fieldNamesSuggestion.length;

  const showValuesSuggestion =
    activeInput &&
    // @ts-ignore
    activeInput.tagName.toLowerCase() === TEXTAREA_TAG &&
    enableEdit &&
    !isValuesSuggestionEmpty;

  const showFieldsSuggestion =
    activeInput &&
    // @ts-ignore
    activeInput.tagName.toLowerCase() === INPUT_TAG &&
    enableAdd &&
    !isFieldsSuggestionEmpty;

  // Handlers
  const handleChange = useCallback(
    (props: InteractionProps) => {
      if (onChange) {
        onChange(props);
      }
    },
    [onChange]
  );

  const handleDelete = useCallback(
    (props: InteractionProps) => {
      if (onChange) {
        onChange(props);
      }
    },
    [onChange]
  );

  const handleClick = useCallback(
    (value) => {
      // Update textfield value and trigger onchange event
      if (activeInput) {
        // @ts-ignore
        activeInput.value = activeInput.value + value;
        // @ts-ignore
        fireEvent(activeInput);
      }
    },
    [activeInput]
  );

  const handleFocus = useCallback((event) => {
    // Listen to focus event on container and save its target as a ref.
    // Was focus event on add key modal with input or on edit value textarea ?
    if (
      event.target &&
      (event.target.tagName.toLowerCase() === TEXTAREA_TAG ||
        event.target.tagName.toLowerCase() === INPUT_TAG) &&
      event.target.getAttribute('id') !== FILTER_ID // exclude the search input
    ) {
      setActiveInput(event.target);
    }
  }, []);

  const handleCloseEdit = useCallback((event) => {
    // Listen to dom node removed event on container and hide suggestion
    if (event.target && event.target instanceof HTMLElement) {
      let input = event.target.getElementsByTagName(INPUT_TAG)[0];
      if (!input) {
        input = event.target.getElementsByTagName(TEXTAREA_TAG)[0];
      }
      if (input && input.getAttribute('id') !== FILTER_ID) {
        // exclude the search input
        setActiveInput(undefined);
      }
    }
  }, []);

  useEffect(() => {
    const JsonEditorRef = ref.current;
    // @ts-ignore
    JsonEditorRef.addEventListener('focus', handleFocus, { capture: true });
    // @ts-ignore
    JsonEditorRef.addEventListener('DOMNodeRemoved', handleCloseEdit, { capture: true });
    return () => {
      // @ts-ignore
      JsonEditorRef.removeEventListener('focus', handleFocus, { capture: true });
      // @ts-ignore
      JsonEditorRef.addEventListener('DOMNodeRemoved', handleCloseEdit, {
        capture: true,
      });
    };
  }, [handleCloseEdit, handleFocus]);

  return (
    <div className={`json-editor-container ${className}`}>
      <div ref={ref} className="json-editor">
        <ReactJson
          src={src}
          name={false}
          collapseStringsAfterLength={13}
          onEdit={enableEdit ? handleChange : undefined}
          onAdd={enableAdd ? handleChange : undefined}
          onDelete={enableDelete ? handleDelete : undefined}
          enableClipboard={enableClipboard}
          theme="apathy:inverted"
        />
      </div>
      <Overlay
        show={showValuesSuggestion || showFieldsSuggestion}
        container={ref.current}
        target={activeInput}
      >
        <Popover
          id="suggestion"
          className={`json-editor-tooltip ${showValuesSuggestion ? 'leftIndent' : ''}`}
        >
          {showValuesSuggestion && (
            // @ts-ignore
            <OptionsList onClick={handleClick} options={valuesSuggestion} />
          )}
          {showFieldsSuggestion && (
            // @ts-ignore
            <OptionsList onClick={handleClick} options={fieldNamesSuggestion} />
          )}
        </Popover>
      </Overlay>
    </div>
  );
};

export default JsonEditor;
