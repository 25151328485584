import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@blueprintjs/core';
import './styles.scss';

interface ICustomSwitchProps {
  checked: boolean;
  onChange: (e: any) => void;
  name: string;
  className?: string;
}

const CustomSwitch: FC<ICustomSwitchProps> = ({ checked, onChange, name, className }) => {
  const { t } = useTranslation(['switch']);

  return (
    <div className={`switch-bordered-container ${className}`}>
      <span>{name}</span>
      <Switch
        checked={checked}
        className="switch-styles"
        innerLabel={t('buttons.Off')}
        innerLabelChecked={t('buttons.On')}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomSwitch;
