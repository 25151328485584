import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Row, Col } from 'react-bootstrap';
import { Card } from '@blueprintjs/core';
// Selectors
import { selectCompaniesListState } from '../../../../../redux/slices/companies/list/selectors';
import { selectMarketplaceState } from '../../../../../redux/slices/marketplace/selectors';
// Actions
import { selectIntegration } from '../../../../../redux/slices/marketplace/marketplaceSlice';
import { buildIntegration } from '../../../../../redux/slices/marketplace/thunk';
// Components
import MarketplaceIntegrationTeaserSmall from '../MarketplaceIntegrationTeaserSmall';
import Button from '../../../../../components/button/Button';
import FormActions from '../../../../../components/form-actions/FormActions';
import Input from '../../../../../components/input/Input';
import GenericSelector, {
  IValueKey,
} from 'src/components/generic-selector/GenericSelector';
// Types
import { IMarketPlaceIntegration } from '../../../../../services/MarketplaceService';
import { IBuildIntegrationFormData } from '../../../../../redux/slices/marketplace/types';
import { RequestStatus } from 'src/redux/slices/types';
// Validation
import validationSchema from './validationSchema';
// Utils
import stringifyStateError from 'src/utils/stringifyStateError';

interface IMarketplaceIntegrationTeaserProps {
  integration: IMarketPlaceIntegration;
}

const MarketplaceIntegrationTeaserSelected: FC<IMarketplaceIntegrationTeaserProps> = ({
  integration,
}) => {
  const { t } = useTranslation(['home']);

  const history = useHistory();

  const dispatch = useDispatch();

  const { data: companies } = useSelector(selectCompaniesListState);

  const { submit, error, createdIntegrationSlug } = useSelector(selectMarketplaceState);

  const selectOptions = useMemo(
    () => companies.map(({ nid, title }) => ({ Key: nid, Value: title } as IValueKey)),
    [companies]
  );

  // Handlers

  const onCancel = useCallback(() => {
    dispatch(selectIntegration(undefined));
  }, [dispatch]);

  const onSubmit = useCallback(
    (data) => {
      dispatch(
        buildIntegration({
          ...data,
          product_id: integration.nid,
          market_id: integration.market_id,
        })
      );
    },
    [integration, dispatch]
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    isValid,
  } = useFormik<IBuildIntegrationFormData>({
    validationSchema,
    onSubmit,
    isInitialValid: false,
    initialValues: { name: '', company: selectOptions[0].Key },
  });

  const selectedCompany = useMemo(
    () => selectOptions.filter((option) => option.Key === values.company),
    [values, selectOptions]
  );

  const handleCompanySelect = useCallback(
    (res: IValueKey[]) => {
      setFieldValue('company', res[0]?.Key ?? undefined);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (error && submit === RequestStatus.rejected) {
      toastr.error(
        t('alert.Error'),
        t('alert.Error submit', { error: stringifyStateError(error) })
      );
    }
  }, [error, submit, t]);

  useEffect(() => {
    if (submit === RequestStatus.fulfilled && createdIntegrationSlug) {
      history.push('/dashboard/' + createdIntegrationSlug);
    }
  }, [createdIntegrationSlug, submit, history]);

  return (
    <Card className="marketplace-integration-teaser selected">
      <div className="wrapper">
        <MarketplaceIntegrationTeaserSmall integration={integration} />
      </div>
      <Card className="add-integration-form custom-card custom-card--border">
        <form onSubmit={handleSubmit}>
          <Row>
            {selectOptions.length > 1 ? (
              <>
                <Col xs={6}>
                  <Input
                    placeholder={t('inputs.Name')}
                    onChange={handleChange}
                    name="name"
                    validationError={touched.name && errors.name}
                    value={values.name}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col xs={6}>
                  <GenericSelector
                    items={selectOptions}
                    selectorText={t(['inputs.Company'])}
                    isMultiSelector={false}
                    onValueChanged={handleCompanySelect}
                    selectedItem={selectedCompany}
                  />
                </Col>
              </>
            ) : (
              <Col xs={12}>
                <Input
                  placeholder={t('inputs.Name')}
                  onChange={handleChange}
                  name="name"
                  validationError={touched.name && errors.name}
                  value={values.name}
                  onBlur={handleBlur}
                />
              </Col>
            )}
          </Row>
          <FormActions>
            <Button
              disabled={!isValid || submit === RequestStatus.pending}
              type="submit"
              loading={submit === RequestStatus.pending}
            >
              {t('buttons.Build')}
            </Button>
            <Button onClick={onCancel}>{t('buttons.Cancel')}</Button>
          </FormActions>
        </form>
      </Card>
    </Card>
  );
};
export default MarketplaceIntegrationTeaserSelected;
