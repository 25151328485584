import React from 'react';

const CloseIcon = () => (
  <svg viewBox="0 0 21 21" width="21" height="21">
    <g>
      <g>
        <path stroke="#54ada8" strokeWidth="3" d="M18 17.96L3.87 3.83" />
        <path stroke="#54ada8" strokeWidth="3" d="M5.16 16.67L16.71 5.12" />
      </g>
    </g>
  </svg>
);

export default CloseIcon;
