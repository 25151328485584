import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import MarketplaceService from '../../../services/MarketplaceService';

// Types
import {
  GetMarketplaceActionResult,
  IBuildIntegrationPayload,
  IBuildIntegrationSuccessResult,
} from './types';

export const getMarketPlaces = createAsyncThunk<GetMarketplaceActionResult>(
  'marketplace/getMarketPlaces',
  async (_, { rejectWithValue }) => {
    try {
      const data = await MarketplaceService.getMarketPlaces();
      return {
        data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.message);
    }
  }
);

export const buildIntegration = createAsyncThunk<
  IBuildIntegrationSuccessResult,
  IBuildIntegrationPayload
>('marketplace/buildIntegration', async (params, { rejectWithValue }) => {
  try {
    const { data } = await MarketplaceService.buildIntegration(params);
    if (data?.error) {
      return rejectWithValue(data.error);
    }
    if (data.build.integration_created) {
      return { data: data.build.integration_created, company_id: params.company };
    }
    return rejectWithValue('');
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.message);
  }
});
