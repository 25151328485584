import React, { FC, useState, useMemo } from 'react';
import { InputGroup, Icon } from '@blueprintjs/core';
// Components
import Option from './Option';
import Sbrollbars from '../../custom-scrollbars/CustomScrollbars';
// Types
import { IJsonEditorOption } from './types';
// Utils
import { filterString } from 'src/utils/genUtils';
import { FILTER_ID } from '../utils/consts';

export interface IOptionsListProps {
  options: IJsonEditorOption[];
  onClick: (value: string) => void;
}

const OptionsList: FC<IOptionsListProps> = ({ options, onClick }) => {
  const [filterValue, setFilterValue] = useState<string>('');

  const filteredOptions = useMemo(() => {
    if (filterValue) {
      return options.filter((f) => filterString(f.label, filterValue));
    }

    return options;
  }, [filterValue, options]);

  const handleFilterChange = (e) => {
    setFilterValue(e.currentTarget.value);
  };

  return (
    <>
      <div className="json-editor-options">
        <div className="json-editor-options-panel">
          <p>Click on the input then choose a tag to copy its value</p>
          <InputGroup
            id={FILTER_ID} // added id to ignore this input on focus/dom node removed events
            rightElement={
              filterValue.length ? (
                <Icon
                  className="clear-input"
                  icon="cross"
                  onClick={() => {
                    setFilterValue('');
                  }}
                />
              ) : (
                <Icon icon="search" />
              )
            }
            onChange={handleFilterChange}
            value={filterValue}
            placeholder="Search..."
          />
        </div>
        <div className="json-editor-options-list">
          <Sbrollbars autoHeight hideTracksWhenNotNeeded>
            {filteredOptions.map((option) => (
              <Option onClick={onClick} {...option} key={option.label} />
            ))}
          </Sbrollbars>
        </div>
      </div>
    </>
  );
};

export default OptionsList;
