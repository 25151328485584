import React, { FC, useMemo, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { NonIdealState, Button, IconName } from '@blueprintjs/core';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  Bar,
  Tooltip,
  Legend,
  LineChart,
  CartesianGrid,
  YAxis,
  Line,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
// Components
import Loader from '../../../../components/loader/Loader';
// Utils
import { getFormattedProcessingTimeDate } from './utils';
import useConnectorActions from '../../utils/useConnectorActions';
// Types
import { ILogsDiagramProps } from './types';
import { IDateRange } from '../../../../redux/slices/dashboard/charts/types';
import { RequestStatus } from 'src/redux/slices/types';
// Consts
import { colors } from './consts';

enum IChartView {
  line = 'line',
  bar = 'bar',
}

const chartViewIcons = {
  [IChartView.bar]: 'timeline-line-chart' as IconName,
  [IChartView.line]: 'timeline-bar-chart' as IconName,
};

interface IProcessingTimeChartProps extends ILogsDiagramProps {
  dateRange: IDateRange;
}

const ProcessingTimeChart: FC<IProcessingTimeChartProps> = ({
  data,
  isLoading,
  dateRange,
}) => {
  const [view, setView] = useState<IChartView>(IChartView.line);

  const { t } = useTranslation(['dashboard']);

  const { actions, actionsLoading } = useConnectorActions();

  const chartData = useMemo(() => getFormattedProcessingTimeDate(data, dateRange), [
    data,
    dateRange,
  ]);

  const actionsList = useMemo(() => {
    return actions.length
      ? Object.keys(groupBy(data, 'action')).map((action) => ({
          action,
          colorIndex: actions.findIndex(({ Value }) => Value === action),
        }))
      : [];
  }, [data, actions]);

  const handleChangeView = useCallback(() => {
    setView((currentView) => {
      switch (currentView) {
        case IChartView.line:
          return IChartView.bar;
        case IChartView.bar:
          return IChartView.line;
        default:
          return IChartView.line;
      }
    });
  }, []);

  return (
    <>
      <Row className={`shadow-card diagrams-container`}>
        <Loader
          loading={isLoading || actionsLoading === RequestStatus.pending}
          withShadow
        />
        {(!chartData.length || !actions.length) &&
          actionsLoading !== RequestStatus.pending &&
          !isLoading && (
            <NonIdealState
              icon="error"
              title={t('No logs found')}
              className="logs-non-ideal-state"
            />
          )}
        {!!chartData.length && !!actions.length && (
          <Col sm={12} className="diagram">
            <Button
              className="action-button"
              icon={chartViewIcons[view]}
              onClick={handleChangeView}
              style={{
                position: 'absolute',
                right: '15px',
                zIndex: 1,
              }}
            />
            {view === IChartView.line ? (
              <ResponsiveContainer>
                <LineChart
                  margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                  data={chartData}
                >
                  <CartesianGrid />
                  <XAxis
                    dataKey="date"
                    domain={['auto', 'auto']}
                    interval="preserveStartEnd"
                  />
                  <YAxis width={30} />
                  <Tooltip cursor={{ fill: 'rgba(167, 182, 194, 0.3)' }} />
                  {!!actionsList.length &&
                    actionsList.map(({ action, colorIndex }, index) => {
                      return (
                        <Line
                          dataKey={action}
                          stroke={colors[colorIndex][0]}
                          key={index}
                          strokeWidth={2}
                        />
                      );
                    })}
                  <Legend wrapperStyle={{ height: 'max-content' }} />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <ResponsiveContainer>
                <BarChart data={chartData}>
                  <defs>
                    <defs>
                      {actionsList.map(({ colorIndex }, index) => {
                        return (
                          <linearGradient
                            id={`processingDiagramColor${colorIndex}`}
                            x1="92%"
                            y1="100%"
                            x2="8%"
                            y2="0%"
                            key={index}
                          >
                            <stop offset="0%" stopColor={colors[colorIndex][1]} />
                            <stop offset="100%" stopColor={colors[colorIndex][2]} />
                          </linearGradient>
                        );
                      })}
                    </defs>
                  </defs>
                  <XAxis
                    dataKey="date"
                    domain={['auto', 'auto']}
                    interval="preserveStartEnd"
                  />
                  <Tooltip cursor={{ fill: 'rgba(167, 182, 194, 0.3)' }} />
                  {actionsList.map(({ action, colorIndex }, index) => {
                    return (
                      <Bar
                        dataKey={action}
                        stroke={colors[colorIndex][0]}
                        fill={`url(#processingDiagramColor${colorIndex})`}
                        key={index}
                        stackId="date"
                      />
                    );
                  })}
                  <Legend wrapperStyle={{ height: 'max-content' }} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default ProcessingTimeChart;
