import { IDynamicLayout } from '../../../types/internal-Types';
import {
  PopoverPosition,
  PopoverInteractionKind,
  Position,
  Intent,
} from '@blueprintjs/core';

export enum BlockTypes {
  'block' = 'block',
  'column' = 'column',
  'row' = 'row',
  'text' = 'text',
  'layout' = 'layout',
  'fluidBlock' = 'fluid-block',
  'tabs' = 'tabs',
  'tabsItem' = 'tabs-item',
  'img' = 'img',
  'collapse' = 'collapse',
  'tooltip' = 'tooltip',
  'jsonViewer' = 'json-viewer',
  'contextualBlock' = 'contextual-block',
  'popup' = 'popup',
  'link' = 'link',
  'drawer' = 'drawer',
  'callout' = 'callout',
}

interface IBlockElement {
  type: string;
  id: string;
  classes?: string[];
}

interface IBlockElementWithChildren extends IBlockElement {
  children: IDynamicLayout;
}

export interface IBlock extends IBlockElementWithChildren {
  type: BlockTypes.block;
}

export interface IRow extends IBlockElementWithChildren {
  type: BlockTypes.row;
}

export interface IColumn extends IBlockElementWithChildren {
  type: BlockTypes.column;
  size: number;
}

export interface ITextBlock extends IBlockElement {
  type: BlockTypes.text;
  markup: string;
}

export interface IFluidBlock extends IBlockElementWithChildren {
  i: string;
  type: BlockTypes.fluidBlock;
}

export interface ILayoutCoordinates {
  i: string;
  w: number;
  h: number;
  x: number;
  y: number;
  static?: boolean;
  isNotDraggable?: boolean;
  isNotResizable?: boolean;
  minW?: number;
  maxW?: number;
  minH?: number;
  maxH?: number;
}
export interface ILayoutBreakpoints {
  lg: ILayoutCoordinates[];
  md?: ILayoutCoordinates[];
  sm?: ILayoutCoordinates[];
  xs?: ILayoutCoordinates[];
  xxs?: ILayoutCoordinates[];
}
export interface ILayout extends IBlockElementWithChildren {
  type: BlockTypes.layout;
  children: IFluidBlock[];
  grid: ILayoutBreakpoints;
}

export interface ITabItem extends IBlockElementWithChildren {
  type: BlockTypes.tabsItem;
  disabled: boolean;
  label: string;
}
export interface ITabs extends IBlockElementWithChildren {
  type: BlockTypes.tabs;
  children: ITabItem[];
  selectedItemId: string;
  vertical: boolean;
  useUrlParams: boolean;
}

export interface IImage extends IBlockElement {
  type: BlockTypes.img;
  src: string;
  alt: string;
}

export interface ICollapse extends IBlockElementWithChildren {
  type: BlockTypes.collapse;
  isOpen: boolean;
  label: string;
  icon?: string;
}

export interface ITooltip extends IBlockElementWithChildren {
  type: BlockTypes.tooltip;
  content: string | string[];
  interactionKind: PopoverInteractionKind;
  enforceFocus: boolean;
  placement: PopoverPosition;
  defaultIsOpen: boolean;
}

export interface IJsonViewer extends IBlockElement {
  type: BlockTypes.jsonViewer;
  value: object;
}

export interface IContextualBlock extends IBlockElementWithChildren {
  type: BlockTypes.contextualBlock;
  context: IDynamicLayout;
}

export interface IPopup extends IBlockElement {
  type: BlockTypes.popup;
  popup_content: IDynamicLayout;
  label: string;
  icon?: string;
}

export interface ILink extends IBlockElement {
  type: BlockTypes.link;
  label: string;
  icon?: string;
  href: string;
  target: string;
}

export interface IDrawer extends IBlockElementWithChildren {
  type: BlockTypes.drawer;
  size?: number;
  icon?: string;
  title?: string;
  position?: Position;
  overlay_close?: boolean;
  button_text?: string;
}

export interface ICallout extends IBlockElementWithChildren {
  type: BlockTypes.callout;
  title?: string;
  content: string;
  intent?: Intent;
}
