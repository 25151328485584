import React, { FC, useCallback, useState } from 'react';
// Components
import EditorRow from './EditorRow';
import EditorValue from './EditorValue';
import { Col } from 'react-bootstrap';
// Types
import { IValueKey } from '../../generic-selector/GenericSelector';

interface EditorCreateRowProps {
  path?: string;
  onAdd: (newFieldName: string, value: string | object) => void;
  onCancelShowCreateField: () => void;
  handleAddOptionKey: (newValue: any) => void;
  handleAddOptionValue: (newValue: any) => void;
  optionsKeys: IValueKey[];
  optionsValues: IValueKey[];
}

const EditorCreateRow: FC<EditorCreateRowProps> = ({
  path = '',
  onAdd,
  onCancelShowCreateField,
  handleAddOptionKey,
  handleAddOptionValue,
  optionsKeys,
  optionsValues,
}) => {
  const [fieldName, setFieldName] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const handleChangeFieldName = (newFieldName) => {
    setFieldName(newFieldName);
  };

  const handleChangeValue = (newValue) => {
    setValue(newValue);
  };

  const handleAddRowValues = () => {
    addValues(fieldName, value);
  };

  const addValues = useCallback(
    (newFieldName, newValue) => {
      if (newFieldName) {
        onAdd(newFieldName, newValue);
      }
    },
    [onAdd]
  );

  return (
    <>
      <EditorRow
        optionsKeys={optionsKeys}
        path={path}
        fieldName={fieldName}
        onFieldNameChange={handleChangeFieldName}
        onCancel={onCancelShowCreateField}
        onAddRowValues={handleAddRowValues}
        handleAddOptionKey={handleAddOptionKey}
      >
        <Col xs={6} className="source">
          <EditorValue
            value={value}
            onEditValue={handleChangeValue}
            handleAddOptionValue={handleAddOptionValue}
            optionsValues={optionsValues}
          />
        </Col>
      </EditorRow>
    </>
  );
};

export default EditorCreateRow;
