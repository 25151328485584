import { LanguagesKeys, DEFAULT_LANG_KEY } from 'src/redux/slices/language/languages';

export const LANGUAGE_KEY = 'lang';

class LanguageService {
  get = (): LanguagesKeys => {
    return (localStorage.getItem(LANGUAGE_KEY) as LanguagesKeys) ?? DEFAULT_LANG_KEY;
  };

  set = (language: LanguagesKeys) => {
    localStorage.setItem(LANGUAGE_KEY, language);
  };
}

export default new LanguageService();
