import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useHistory, useLocation, useParams } from 'react-router';
// Components
import Input from '../../../../components/input/Input';
import Switch from '../../../../components/custom-switch/CustomSwitch';
import ExpressionInput from './ExpressionInput';
import GenericSelector from '../../../../components/generic-selector/GenericSelector';
import { NonIdealState, H4, Icon } from '@blueprintjs/core';
// Hooks
import useAsGlobal from './useAsGlobal';
// Types
import {
  CreateConnectorFormData,
  EConnectorAuthParams,
  EConnectorAuthTypes,
} from 'src/types/internal-Types';
// Utils
import { getHelperText, getPrevPath } from '../../utils';

const AuthScheme: FC = () => {
  const {
    handleBlur,
    handleChange,
    touched,
    errors,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<CreateConnectorFormData>();

  const { schemeId } = useParams<{ schemeId: string }>();

  const index = Number.parseInt(schemeId, 10);

  const { t } = useTranslation(['connectors']);

  const history = useHistory();
  const location = useLocation();

  const authValues = values.securitySchemes[index];
  const variables = useMemo(() => values.form.map((field) => field.key), [values]);
  const { isChecked, toggle } = useAsGlobal(index);

  const typeOptions = useMemo(
    () => [
      { Key: EConnectorAuthTypes.api_key, Value: t('selects.API key') },
      { Key: EConnectorAuthTypes.basic_auth, Value: t('selects.Basic') },
      { Key: EConnectorAuthTypes.bearer, Value: t('selects.Bearer') },
    ],
    [t]
  );
  const selectedType = useMemo(
    () => typeOptions.filter(({ Key }) => Key === authValues?.type),
    [authValues, typeOptions]
  );

  const placeOptions = useMemo(
    () => [
      { Key: EConnectorAuthParams.header, Value: t('selects.Header') },
      { Key: EConnectorAuthParams.query, Value: t('selects.Query') },
    ],
    [t]
  );

  const selectedPlace = useMemo(
    () => placeOptions.filter(({ Key }) => Key === authValues?.in),
    [authValues, placeOptions]
  );

  const handleSelectType = useCallback(
    (type) => {
      const [{ Key } = { Key: undefined }] = type;

      setFieldValue(`securitySchemes.${index}.type`, Key);
      setFieldTouched(`securitySchemes.${index}.type`);
    },
    [setFieldValue, setFieldTouched, index]
  );

  const handleSelectPlace = useCallback(
    (place) => {
      const [{ Key } = { Key: undefined }] = place;

      setFieldValue(`securitySchemes.${index}.in`, Key);
      setFieldTouched(`securitySchemes.${index}.in`);
    },
    [setFieldValue, setFieldTouched, index]
  );

  const handleExpressionFieldBlure = useCallback(
    (field) => {
      setFieldTouched(`securitySchemes.${index}.${field}`, true);
    },
    [setFieldTouched, index]
  );

  const handleExpressionFieldChange = useCallback(
    (value, field) => {
      const [currentId] = window.location.pathname.split('/').reverse();

      setFieldValue(`securitySchemes.${currentId}.${field}`, value);
    },
    [setFieldValue]
  );

  const handleGoBack = useCallback(() => {
    history.push(getPrevPath(location.pathname, 1));
  }, [history, location]);

  if (!authValues) {
    return (
      <>
        <div className="connector-section-title">
          <Icon icon="circle-arrow-left" onClick={handleGoBack} iconSize={20} />
        </div>
        <div className="non-ideal-state-container">
          <NonIdealState icon="search" title={t('Scheme not found')} />
        </div>
      </>
    );
  }

  return (
    <div className="auth-scheme">
      <div className="connector-section-title">
        <Icon icon="circle-arrow-left" onClick={handleGoBack} iconSize={20} />
        <H4>{authValues.title ? authValues.title : t('titles.New scheme')}</H4>
      </div>

      <div className="auth-scheme-form">
        <div className="margin-bottom-xs ">
          <Switch
            checked={isChecked}
            onChange={toggle}
            name={t('inputs.Use as global')}
          />
        </div>
        <Input
          label={t('inputs.Scheme name')}
          placeholder={t('inputs.New scheme')}
          value={authValues.title}
          name={`securitySchemes.${index}.title`}
          onBlur={handleBlur}
          onChange={handleChange}
          validationError={getHelperText(
            touched,
            errors,
            `securitySchemes.${index}.title`
          )}
        />
        <div className="margin-bottom-xs">
          <GenericSelector
            selectedItem={selectedType}
            isMultiSelector={false}
            onValueChanged={handleSelectType}
            items={typeOptions}
            selectorText={t('inputs.Authentication type')}
          />
        </div>
        {authValues.type === EConnectorAuthTypes.basic_auth && (
          <>
            <ExpressionInput
              label={t('inputs.Username')}
              placeholder={t('inputs.Username')}
              value={authValues?.username ?? ''}
              name="username"
              onChange={handleExpressionFieldChange}
              onBlur={handleExpressionFieldBlure}
              validationError={getHelperText(
                touched,
                errors,
                `securitySchemes.${index}.username`
              )}
              options={variables}
            />

            <ExpressionInput
              label={t('inputs.Password')}
              placeholder={t('inputs.Password')}
              name="password"
              value={authValues?.password ?? ''}
              onChange={handleExpressionFieldChange}
              onBlur={handleExpressionFieldBlure}
              validationError={getHelperText(
                touched,
                errors,
                `securitySchemes.${index}.password`
              )}
              options={variables}
            />
          </>
        )}

        {authValues.type === EConnectorAuthTypes.api_key && (
          <>
            <Input
              label={t('inputs.Parameter name')}
              placeholder={t('inputs.Parameter name')}
              value={authValues.name}
              name={`securitySchemes.${index}.name`}
              onBlur={handleBlur}
              onChange={handleChange}
              validationError={getHelperText(
                touched,
                errors,
                `securitySchemes.${index}.name`
              )}
            />
            <ExpressionInput
              name="value"
              label={t('inputs.Value')}
              placeholder={t('inputs.Value')}
              value={authValues?.value ?? ''}
              onChange={handleExpressionFieldChange}
              onBlur={handleExpressionFieldBlure}
              validationError={getHelperText(
                touched,
                errors,
                `securitySchemes.${index}.value`
              )}
              options={variables}
            />

            <div className="margin-bottom-xs">
              <GenericSelector
                selectedItem={selectedPlace}
                isMultiSelector={false}
                onValueChanged={handleSelectPlace}
                items={placeOptions}
                selectorText={t('inputs.In')}
              />
            </div>
          </>
        )}

        {authValues.type === EConnectorAuthTypes.bearer && (
          <>
            <ExpressionInput
              name="value"
              label={t('inputs.Value')}
              placeholder={t('inputs.Value')}
              value={authValues?.value ?? ''}
              onChange={handleExpressionFieldChange}
              onBlur={handleExpressionFieldBlure}
              validationError={getHelperText(
                touched,
                errors,
                `securitySchemes.${index}.value`
              )}
              options={variables}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AuthScheme;
