import React, { FC, useState, useCallback } from 'react';
import { Collapse, Icon } from '@blueprintjs/core';
// Components
import TreeNode from './TreeNode';
// Utils
import { getNewPath } from '../utils';

interface ITreeNodesListProps {
  label: string;
  path: string;
  onClick?: (path: string) => void;
  nodes: object;
}

const TreeNodesList: FC<ITreeNodesListProps> = ({ label, path, onClick, nodes }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (onClick && path) {
        onClick(path);
      }
    },
    [onClick, path]
  );

  const handleOpen = useCallback(
    (e) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  return (
    <ul className="tree-node-list">
      <li className={`tree-node ${isOpen ? 'open' : ''}`} onClick={handleClick}>
        <Icon icon="chevron-right" iconSize={15} onClick={handleOpen} />
        <span className="tree-node-label">
          <strong>{label}</strong>
        </span>
        <Collapse isOpen={isOpen} keepChildrenMounted>
          {Object.entries(nodes).map(([field, value]) => {
            if (value === null) {
              value = '';
            }
            if (typeof value === 'object' && !Array.isArray(value)) {
              return (
                <TreeNodesList
                  key={getNewPath(path, field)}
                  onClick={onClick}
                  label={field}
                  path={getNewPath(path, field)}
                  nodes={value}
                />
              );
            }
            return (
              <ul className="tree-node-list" key={field}>
                <TreeNode
                  key={getNewPath(path, field)}
                  onClick={onClick}
                  label={field}
                  value={value}
                  path={getNewPath(path, field)}
                />
              </ul>
            );
          })}
        </Collapse>
      </li>
    </ul>
  );
};

export default TreeNodesList;
