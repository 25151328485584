import React, { FC, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import EndpointTeaser from './EndpointTeaser';
import { H3, Icon, NonIdealState } from '@blueprintjs/core';
import { Col, Row } from 'react-bootstrap';
// Types
import { CreateConnectorFormData } from 'src/types/internal-Types';

const EndpointsList: FC = () => {
  const { values, errors, setFieldValue } = useFormikContext<CreateConnectorFormData>();
  const { t } = useTranslation(['connectors']);

  const history = useHistory();
  const match = useRouteMatch();

  const handleAdd = useCallback(() => {
    const currentValue = [...values.endpoints];
    const id = currentValue.length;
    currentValue.push({
      path: '',
      requests: [],
    });
    setFieldValue('endpoints', currentValue);
    history.push(`${match.url}/${id}`);
  }, [setFieldValue, values, history, match.url]);

  const handleRemove = useCallback(
    (index: number) => {
      const currentValue = [...values.endpoints];
      currentValue.splice(index, 1);
      setFieldValue('endpoints', currentValue);
    },
    [setFieldValue, values]
  );

  return (
    <Row>
      <Col xs={12}>
        <>
          <div className="connector-section-title">
            <H3 className="margin-bottom-sm">{t('titles.Endpoints')}</H3>
            <Icon icon="plus" className="edit-list-icon" onClick={handleAdd} />
          </div>
          {!!errors?.endpoints?.length && !!values.endpoints.length && (
            <p>{t('validation.Incomplete endpoint')}</p>
          )}

          {values.endpoints.length ? (
            values.endpoints.map((value, index) => (
              <EndpointTeaser
                index={index}
                onRemove={handleRemove}
                value={value}
                key={index}
                isComplete={errors?.endpoints ? !errors?.endpoints[index] : true}
              />
            ))
          ) : (
            <div className="non-ideal-state-container">
              <NonIdealState icon="error" title={t('No endpoint found')} />
            </div>
          )}
        </>
      </Col>
    </Row>
  );
};

export default EndpointsList;
