import { createAsyncThunk } from '@reduxjs/toolkit';
import PersistTokenService from '../../../services/PersistTokenService';

// Services
import UserService from '../../../services/UserService';
import IntegrationDebugSettingsService from '../../../services/IntegrationDebugSettingsService';

// Types
import { GetUserSuccessResult, UpdateUserActionPayload } from './types';

export const getUser = createAsyncThunk<GetUserSuccessResult>(
  'user/getUser',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await UserService.getCurrent();

      return {
        data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);
export const updateUser = createAsyncThunk<null, UpdateUserActionPayload>(
  'user/updateUser',
  async ({ id, user }, { rejectWithValue }) => {
    try {
      await UserService.update(id, user);

      return null;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue }) => {
    try {
      await UserService.logout();

      return null;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    } finally {
      PersistTokenService.clear();
      IntegrationDebugSettingsService.clear();
    }
  }
);
