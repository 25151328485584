import React, { FC, useState } from 'react';
import { Card, Collapse } from '@blueprintjs/core';
import { Col } from 'react-bootstrap';
import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';
import { useDispatch } from 'react-redux';
// Types
import { IDashboardLog } from '../../../../types/internal-Types';
import ArrowIcon from '../../../../components/icons/ArrowIcon';

// Components
import LogState from '../LogState';
import Checkbox from '../../../../components/checkbox/Checkbox';
import componentsMap from '../../../../components/dynamic-layout/components/componentsMap';

// Actions
import { setTaskId } from '../../../../redux/slices/dashboard/logs/logsSlice';

// Styles
import './log-item-styles.scss';

export interface ILogItemProps {
  data: IDashboardLog;
  selected?: boolean;
  onSelect: (id: string) => void;
  actions?: React.ReactNode | React.ReactNode[];
}

const getClassNames = (name: string, isOpen = false) =>
  [name, ...(isOpen ? ['opened'] : [])].join(' ');

const LogItem: FC<ILogItemProps> = ({ data, onSelect, selected = false, actions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { id, state, action, output, date, detail, error } = data;

  const isDetailEmpty =
    !detail || (Array.isArray(detail) && !detail.length) || !Object.keys(detail).length;

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setTaskId(id));
  };

  return (
    <>
      <Card className={getClassNames('log-item table-row', isOpen)}>
        <Col xs={1}>
          <span className={getClassNames('collapse-control-button', isOpen)}>
            <ArrowIcon onClick={() => setIsOpen(!isOpen)} />
          </span>
          <Checkbox checked={selected} large onChange={() => onSelect(id)} />
        </Col>
        <Col xs={2}>
          <LogState state={state} />
        </Col>
        <Col xs={2}>{action}</Col>
        <Col xs={4}>
          <span>{output ?? error}</span>
        </Col>
        <Col xs={2}>{date}</Col>
        <div className="logs-actions table-row-actions">{actions}</div>
      </Card>
      <Collapse className="collapse-custom" isOpen={isOpen}>
        {!isDetailEmpty && (
          <StructureErrorBoundary>
            <DynamicLayout structure={detail} componentsMap={componentsMap} />
          </StructureErrorBoundary>
        )}
        <span onClick={handleClick} className="logs-id">
          #{id}
        </span>
      </Collapse>
    </>
  );
};

export default LogItem;
