import React, { FC, useMemo } from 'react';
// Components
import ExpressionInput from '../../expression-input/ExpressionInput';
// Utils
import { formatValue } from '../../expression-input/utils';
import { checkIsValidFunction } from '../utils';
// Hooks
// import useMappingTooolContext from '../context/useMappingToolContext';

interface IEditValueFormProps {
  value: string;
  field: string;
}

const MappingValue: FC<IEditValueFormProps> = ({ value = '', field }) => {
  const formattedValue = useMemo(() => formatValue(value), [value]);

  // const { checkIsValidVariable } = useMappingTooolContext();

  return (
    <ExpressionInput
      // @ts-ignore
      value={formattedValue}
      readOnly
      name={field}
      validateFunction={checkIsValidFunction}
      // validateVariable={checkIsValidVariable} // We skip validating the variable for the moment
    />
  );
};

export default MappingValue;
