import { groupBy, sumBy } from 'lodash';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import closestTo from 'date-fns/closestTo';
import format from 'date-fns/format';
import add from 'date-fns/add';
import isWithinInterval from 'date-fns/isWithinInterval';
import * as locale from 'date-fns/locale';
import i18n from 'src/i18n';
// Types
import { IDateRange } from '../../../../redux/slices/dashboard/charts/types';
import { IDashboardLogsData } from 'src/services/ChartsService';
import { ActivityBarsData, ActionsPieData, ProcessingTimeData } from './types';
import { ConnectorAction } from 'src/types/internal-Types';

type GroupedLogsData = { date: string; values: IDashboardLogsData[] }[];

const tickFormatter = (dateRange: IDateRange, interval: number): string => {
  const [start, end] = dateRange;
  const formatStr = interval < 1440 ? 'HH:mm' : 'do MMM';
  return `${format(start, formatStr, {
    locale: locale[i18n.language],
  })} - ${format(end, formatStr, {
    locale: locale[i18n.language],
  })}`;
};

const getInterval = (dateRange: IDateRange): number => {
  const [start, end] = dateRange;

  const distanceInMinutes = differenceInMinutes(end, start);

  switch (true) {
    case distanceInMinutes <= 60:
      return 10;
    case distanceInMinutes > 60 && distanceInMinutes <= 360:
      return 60;
    case distanceInMinutes > 360 && distanceInMinutes <= 720:
      return 120;
    case distanceInMinutes > 720 && distanceInMinutes <= 1440:
      return 240;
    case distanceInMinutes > 1440:
      return 1440;
    default:
      return 60;
  }
};

const groupByTimestamp = (
  logs: IDashboardLogsData[],
  dateRange: IDateRange
): GroupedLogsData =>
  Object.values(
    logs.reduce((result, log) => {
      const interval = getInterval(dateRange);

      const dates = Object.keys(result).length
        ? Object.keys(result).map((date) => new Date(Number.parseInt(date, 10)))
        : [];

      const logDate = new Date(log.date);

      const closestDate = dates.length ? closestTo(logDate, dates) : undefined;

      if (closestDate && differenceInMinutes(logDate, closestDate) <= interval) {
        const oldData = result[closestDate.getTime()].values;

        result[closestDate.getTime()] = {
          date: tickFormatter([closestDate, logDate], interval),
          values: [...oldData, log],
        };
      } else {
        result[log.date] = {
          date: tickFormatter([logDate, add(logDate, { minutes: interval })], interval),
          values: [log],
        };
      }

      return result;
    }, {})
  );

export const filterByDateRange = (logs: IDashboardLogsData[], dateRange: IDateRange) =>
  logs.filter((log) =>
    isWithinInterval(new Date(log.date), { start: dateRange[0], end: dateRange[1] })
  );

export const getFormattedPieData = (
  logs: IDashboardLogsData[],
  actions: ConnectorAction[]
): ActionsPieData[] => {
  if (!logs?.length || !actions?.length) return [];
  return Object.entries(groupBy(logs, 'action')).map(([name, logsByAction]) => ({
    name,
    colorIndex: actions.findIndex(({ Value }) => Value === name),
    // @ts-ignore
    number: logsByAction.length,
  })) as ActionsPieData[];
};

export const getFormattedBarsData = (
  logs: IDashboardLogsData[],
  dateRange: IDateRange
): ActivityBarsData[] => {
  if (!logs || !logs.length) return [];

  return groupByTimestamp(logs, dateRange).map(({ date, values }) => {
    const tasksByState = values.reduce(
      (result, log) => ({
        ...result,
        [log.state]: result[log.state] ? ++result[log.state] : 1,
      }),
      {}
    );
    return { date, ...tasksByState };
  }) as ActivityBarsData[];
};

export const getFormattedProcessingTimeDate = (
  logs: IDashboardLogsData[],
  dateRange: IDateRange
): ProcessingTimeData[] => {
  if (!logs || !logs.length) return [];

  return groupByTimestamp(logs, dateRange).map(({ date, values }) => {
    const avProcessingTimeByAction = Object.entries(groupBy(values, 'action')).reduce(
      (result, value) => {
        const [action, logsByAction] = value;
        return {
          ...result,
          [action]: (
            sumBy(logsByAction, 'processing_time') / logsByAction.length
          ).toFixed(2),
        };
      },
      {}
    );
    return { date, ...avProcessingTimeByAction };
  }) as ProcessingTimeData[];
};
