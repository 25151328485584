import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import ReactQuill from 'react-quill';
import { ReactQuillProps, Value } from 'react-quill/lib/index';
// Utils
import { getValue, validateInput } from './utils';
import { QUILL_MODULES, QUILL_FORMATS } from './quill-config';
// Styles
import './styles.scss';
import 'react-quill/dist/quill.snow.css';
import './pills-style.scss';
import { FormGroup } from '@blueprintjs/core';

export type Sources = 'api' | 'user' | 'silent';
export interface IExpressionInputProps
  extends Pick<
    ReactQuillProps,
    'value' | 'modules' | 'formats' | 'readOnly' | 'placeholder' | 'className' | 'onFocus'
  > {
  validateFunction?: (name: string) => boolean;
  validateVariable?: (name: string) => boolean;
  onChange?: (content: Value, field: string) => void;
  onBlur?: (field: string) => void;
  name: string;
  helperText?: string;
  validationError?: string | false | undefined;
  label?: string;
}

const ExpressionInput = forwardRef(
  (
    {
      modules,
      formats,
      onChange,
      validateVariable,
      validateFunction,
      readOnly,
      onBlur,
      onFocus,
      placeholder,
      value,
      className = '',
      name,
      validationError,
      helperText,
      label = '',
    }: IExpressionInputProps,
    ref
  ) => {
    const editorRef = useRef<ReactQuill | null>(null);

    useImperativeHandle(ref, () => ({
      getEditor: () => editorRef.current?.getEditor(),
    }));

    const markInvalidExpressions = useCallback(
      (input: any) => {
        return validateInput(input, validateFunction, validateVariable);
      },
      [validateVariable, validateFunction]
    );

    const handleCopy = useCallback((e) => {
      const editor = editorRef.current?.getEditor();
      if (editor) {
        e.preventDefault();
        const range = editor.getSelection();
        if (range) {
          const content = getValue(editor.getContents(range.index, range.length));
          e?.clipboardData?.setData('text/plain', content);
        }
      }
    }, []);

    const handleCut = useCallback(
      (e) => {
        const editor = editorRef.current?.getEditor();
        if (editor) {
          e.preventDefault();
          const range = editor.getSelection();
          if (range) {
            handleCopy(e);
            editor.deleteText(range.index, range.length);
          }
        }
      },
      [handleCopy]
    );

    const handleChange = useCallback(
      (text, delta, evSource, editor) => {
        if (onChange && !readOnly) {
          const content = editor.getContents();
          onChange(content, name);

          // force editor focus
          setTimeout(() => {
            const selection = editor.getSelection();
            editorRef.current
              ?.getEditor()
              ?.setSelection(selection ?? { index: editor.getLength(), length: 0 });
            editorRef.current?.getEditor()?.focus();
          }, 0);
        }
      },
      [onChange, editorRef, readOnly, name]
    );

    const handleBlur = useCallback(() => {
      if (onBlur) {
        onBlur(name);
      }
    }, [name, onBlur]);

    useEffect(() => {
      const editor = editorRef.current?.getEditor();
      if (editor) {
        editor.root.addEventListener('copy', handleCopy);
        editor.root.addEventListener('cut', handleCut);
        return () => {
          editor.root.removeEventListener('copy', handleCopy);
          editor.root.removeEventListener('cut', handleCut);
        };
      }
      return;
    }, [handleCopy, handleCut]);

    return (
      <FormGroup
        label={label}
        helperText={validationError || helperText}
        className="input-form-group"
      >
        <ReactQuill
          className={`${className} ${validationError ? 'invalid' : ''}`}
          readOnly={readOnly}
          value={markInvalidExpressions(value) as Value}
          modules={modules ?? QUILL_MODULES}
          formats={formats ?? QUILL_FORMATS}
          ref={(el: any) => (editorRef.current = el)}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          placeholder={placeholder}
        />
      </FormGroup>
    );
  }
);

export default ExpressionInput;
