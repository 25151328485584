import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { RequestStatus } from '../../types';
import { ConnectorsListState } from './types';
// Utils
import { createThunkReducers } from '../../utils';
// Thunks
import { getConnectors } from './thunks';
import { editConnector } from '../edit/thunks';
import { IConnectorDescription } from 'src/types/api-Types';
import { createConnector } from '../create/thunks';

const initialState: ConnectorsListState = {
  error: null,
  loading: RequestStatus.idle,
  data: [],
};

const connectorsListSlice = createSlice({
  name: 'connectors.list',
  initialState,
  reducers: {
    reset: (): ConnectorsListState => initialState,
  },
  extraReducers: {
    ...createThunkReducers(getConnectors),
    [String(editConnector.fulfilled)]: (
      state,
      action: PayloadAction<IConnectorDescription>
    ) => ({
      ...state,

      data: [
        ...state.data.filter(
          (connector) => connector.nid + '' !== action.payload.nid + ''
        ),
        action.payload,
      ],
    }),
    [String(createConnector.fulfilled)]: (
      state,
      action: PayloadAction<IConnectorDescription>
    ) => ({
      ...state,

      data: [...state.data, action.payload],
    }),
  },
});

export const { reset } = connectorsListSlice.actions;

export default connectorsListSlice.reducer;
