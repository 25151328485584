import React, {
  useCallback,
  useRef,
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';
// Components
import Popover from '../popover/Popover';
import ExpressionInput, { IExpressionInputProps } from './ExpressionInput';
import FormActions from '../../components/form-actions/FormActions';
import Button from '../../components/button/Button';
// Consts
import { QUILL_FORMATS } from './quill-config';
// Types
import { Value } from 'react-quill/lib';

interface IEditFunctionPopover
  extends Pick<IExpressionInputProps, 'value' | 'validateVariable'> {
  onChange: (content: Value) => void;
  onCancel: () => void;
  isOpen: boolean;
}

const EditFunctionPopover = forwardRef(
  (
    { onChange, validateVariable, value = '', isOpen, onCancel }: IEditFunctionPopover,
    ref
  ) => {
    const [args, setArgs] = useState<Value>(value);

    const argsEditorRef = useRef<ReactQuill | null>(null);

    const { t } = useTranslation(['expression-edit']);

    useImperativeHandle(ref, () => ({
      getEditor: () => argsEditorRef.current?.getEditor(),
    }));

    const handleChangeArgs = useCallback((newArgs) => {
      setArgs(newArgs);
    }, []);

    const handleSubmit = useCallback(() => {
      if (onChange) {
        // @ts-ignore
        onChange({ ops: args.ops.slice(0, -1) });
      }
    }, [args, onChange]);

    const handleCancel = useCallback(() => {
      onCancel();
    }, [onCancel]);

    useEffect(() => {
      setArgs(value);
    }, [value]);

    return (
      <>
        <Popover isOpen={isOpen} placement="bottom">
          <></>
          <div className="edit-function-modal">
            <ExpressionInput
              name="function-args"
              value={args}
              ref={argsEditorRef}
              onChange={handleChangeArgs}
              formats={QUILL_FORMATS.slice(0, 1)}
              validateVariable={validateVariable}
            />
            <FormActions>
              <Button onClick={handleCancel}>{t('buttons.Cancel')}</Button>
              <Button onClick={handleSubmit}>{t('buttons.Apply')}</Button>
            </FormActions>
          </div>
        </Popover>
      </>
    );
  }
);

export default EditFunctionPopover;
