import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Services
import { RequestType } from '../../../../services/BaseApiService';
// Types
import { ICompany } from '../../../../types/api-Types';
import { RequestStatus } from '../../types';
import { EditCompanyFormValues, EditCompanyState } from './types';
// Utils
import { createThunkReducers } from '../../utils';
// Thunks
import { editCompany } from './thunks';

const initialData: EditCompanyFormValues = {
  title: '',
  field_invoice_email: '',
  field_company_vat: '',
  field_company_logo: {
    file: '',
    filename: '',
  },
  field_company_address: {
    country: '',
    locality: '',
    postal_code: '',
    thoroughfare: '',
    administrative_area: '',
  },
};

const initialState: EditCompanyState = {
  error: null,
  submit: RequestStatus.idle,
  data: initialData,
};

const editCompanySlice = createSlice({
  name: 'companies.edit',
  initialState,
  reducers: {
    reset: (): EditCompanyState => initialState,
    setCompanyData: (state, action: PayloadAction<ICompany>): EditCompanyState => {
      const data = {
        ...action.payload,
        field_company_logo: {
          file: action.payload.field_company_logo?.teaser || '',
          filename: action.payload.field_company_logo?.filename || '',
        },
      };

      return {
        ...state,
        data: data as EditCompanyFormValues,
        submit: RequestStatus.idle,
        error: null,
      };
    },
  },
  extraReducers: {
    ...createThunkReducers(editCompany, RequestType.put),
  },
});

export const { reset, setCompanyData } = editCompanySlice.actions;

export default editCompanySlice.reducer;
