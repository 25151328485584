import { IFieldIntegration } from '../../../../types/api-Types';

const updateIntegration = (integrations: IFieldIntegration[], id, data = {}) => {
  if (!integrations) {
    return [];
  }

  return integrations.map((integration) => {
    if (integration.nid === id) {
      return {
        ...integration,
        ...data,
      };
    }

    return integration;
  });
};

export default updateIntegration;
