import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';
import { Icon } from '@blueprintjs/core';
// Components
import Loader from '../../components/loader/Loader';
import componentsMap from './componentsMap';
// Image
import backgroundImage from '../../images/bg-dashboard.svg';
// Selectors
import { selectConnectorState } from '../../redux/slices/dashboard/connector/selectors';
import { selectCompaniesListState } from '../../redux/slices/companies/list/selectors';
import { selectConnectorIdBySlug } from '../../redux/slices/dashboard/connector-id-slug-map/slectors';
// Actions
import { getConnector } from '../../redux/slices/dashboard/connector/thunks';
import { reset } from '../../redux/slices/dashboard/connector/connectorSlice';
// Utils
import useDashboardLayout from './utils/useDashboardLayout';
// Types
import { RequestStatus } from '../../redux/slices/types';
// Constants
import { DASHBOARD_ROUTE } from '../../routes/Routes';
// Styles
import './styles/dashboard-styles.scss';
import './styles/setup-form.scss';

export const DashboardPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard', 'dynamicForm']);

  // State
  const { data: connector, loading } = useSelector(selectConnectorState);
  const isLoading = loading === RequestStatus.pending;

  const slug = history.location.pathname.replace(DASHBOARD_ROUTE, '');
  const integrationId = useSelector(selectConnectorIdBySlug(slug));

  const { loading: companiesLoading } = useSelector(selectCompaniesListState);
  const isCompaniesLoading = companiesLoading === RequestStatus.pending;

  const {
    layout,
    loading: layoutLoading,
    handleDynamicFormSubmit,
    errorMessage,
  } = useDashboardLayout(integrationId);

  const isLayoutEmpty =
    !layout || (Array.isArray(layout) && !layout?.length) || !Object.keys(layout).length;

  const homeRedirect = useCallback(() => {
    history.goBack();
  }, [history]);

  // Effects
  useEffect(() => {
    if (integrationId) {
      dispatch(getConnector(integrationId));
    }
    // Component unmount
    return () => {
      dispatch(reset());
    };
  }, [dispatch, integrationId]);

  useEffect(() => {
    if (companiesLoading === RequestStatus.fulfilled && !integrationId) {
      history.push('/');
      toastr.error(t('alert.Not Found'), t('alert.Integration not found'));
    }
  }, [companiesLoading, integrationId, history, t]);

  return (
    <Loader loading={isLoading || isCompaniesLoading || layoutLoading || !integrationId}>
      <Row className="dashboard-content-container">
        <Icon
          tagName="button"
          className="back-button"
          icon="circle-arrow-left"
          iconSize={28}
          onClick={homeRedirect}
        />
        {connector && !isLayoutEmpty && !layout.status ? (
          <StructureErrorBoundary>
            <DynamicLayout
              structure={layout}
              componentsMap={componentsMap}
              callService={handleDynamicFormSubmit}
            />
          </StructureErrorBoundary>
        ) : (
          errorMessage && (
            <Col sm={12}>
              <div className="error-message margin-top-xs">
                <h1>{errorMessage}</h1>
              </div>
            </Col>
          )
        )}
      </Row>
    </Loader>
  );
};

DashboardPage.backgroundImage = backgroundImage;

export default DashboardPage;
