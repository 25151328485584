import React, { FC, useCallback, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// Actions
import { testConnector } from '../../../../redux/slices/connectors/test/thunks';
// Selectors
import { selectTestConnectorState } from '../../../../redux/slices/connectors/test/selectors';
// Components
import FormActions from 'src/components/form-actions/FormActions';
import Button, { ButtonSize } from 'src/components/button/Button';
import CustomScrollbars from 'src/components/custom-scrollbars/CustomScrollbars';
import { H3, NonIdealState, FormGroup, H4, Icon } from '@blueprintjs/core';
import Input from 'src/components/input/Input';
import RequestTypeTag from 'src/components/Wedoio/Connector/request-type-tag/RequestTypeTag';
import AuthorizationForm from './AuthorizationForm';
import TestResult from './TestResult';
import JsonEditor from './JsonEditor';

// Types
import { RequestStatus } from '../../../../redux/slices/types';
import { TestRequestData } from 'src/redux/slices/connectors/test/types';
import { CreateConnectorFormData } from '../../../../types/internal-Types';
import { RequestType } from 'src/services/BaseApiService';
// Utils
import { getHelperText } from '../../utils';
import yup from 'src/translations/yup/yupLocale';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';

interface ITestRequestFormProps {
  connectorId: string;
  connectorSettings: CreateConnectorFormData;
}

const TestRequestForm: FC<ITestRequestFormProps> = ({
  connectorId,
  connectorSettings,
}) => {
  const [view, setView] = useState<'form' | 'code'>('form');
  const {
    submit,
    initialValues,
    authInitialValues,
    selectedRequest,
    testingResult,
  } = useSelector(selectTestConnectorState);

  const isSubmitting = submit === RequestStatus.pending;

  const hasBody =
    selectedRequest?.method === RequestType.post ||
    selectedRequest?.method === RequestType.put ||
    selectedRequest?.method === RequestType.patch;

  const dispatch = useDispatch();
  const { t } = useTranslation(['connectors']);
  const history = useHistory();

  const handleSwitchView = useCallback(() => {
    setView((currentView) => (currentView === 'form' ? 'code' : 'form'));
  }, []);

  const onSubmit = useCallback(
    (testData) => {
      dispatch(
        testConnector({
          id: connectorId,
          testData,
        })
      );
    },
    [connectorId, dispatch]
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          action: yup.string().required(),
          data: hasBody
            ? yup.string().required().label(t('inputs.Request body'))
            : yup.string().nullable(),
          ...mapValues(
            groupBy(selectedRequest?.parameters, 'name'),
            ([{ name, required }]) =>
              required ? yup.string().required().label(name) : yup.string().label(name)
          ),
        })
        .noUnknown(),
    [selectedRequest, t, hasBody]
  );

  // Formik
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
    setFieldTouched,
    dirty,
    submitForm,
    setValues,
  } = useFormik<TestRequestData>({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handleBodyEditorChange = useCallback(
    (newValue) => {
      setFieldValue('data', newValue);
      setFieldTouched('data');
    },
    [setFieldValue, setFieldTouched]
  );

  const setFormValues = useCallback(
    (formValues) => {
      setValues({ ...formValues, action: selectedRequest?.operationId });
    },
    [setValues, selectedRequest]
  );

  const { action, ...editableValues } = values;

  if (!selectedRequest) {
    return (
      <div className="non-ideal-state-container" style={{ minHeight: '60vh' }}>
        <NonIdealState icon="error" title={t('No request selected')} />
        <FormActions>
          <Button disabled={isSubmitting} size={ButtonSize.small} onClick={handleCancel}>
            {t('buttons.Cancel')}
          </Button>
        </FormActions>
      </div>
    );
  }

  return (
    <div>
      <H3 className="connector-section-title">
        <span>
          {selectedRequest.method && <RequestTypeTag type={selectedRequest.method} />}
          {selectedRequest.operationId}
        </span>
        <AuthorizationForm
          initialValues={authInitialValues}
          connectorId={connectorId}
          form={connectorSettings?.form}
        />
      </H3>
      <CustomScrollbars hideTracksWhenNotNeeded autoHeightMin="50vh" autoHeight>
        <div className="padding-xs">
          <H4 className="connector-section-title">
            {t('titles.Parameters')}{' '}
            <Icon icon={view === 'form' ? 'code' : 'form'} onClick={handleSwitchView} />
          </H4>
          {view === 'form' && (
            <form onSubmit={handleSubmit}>
              {selectedRequest?.parameters?.map((param) => (
                <Input
                  key={param.name}
                  name={param.name}
                  label={param.name}
                  value={values[param.name] ?? ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  validationError={getHelperText(touched, errors, param.name)}
                />
              ))}
              {hasBody && (
                <FormGroup
                  helperText={getHelperText(touched, errors, 'data')}
                  label={t('inputs.Request body')}
                >
                  <JsonEditor
                    initialValue={values.data}
                    onBlur={handleBodyEditorChange}
                  />
                </FormGroup>
              )}
              {!selectedRequest?.parameters?.length && !hasBody && (
                <p>{t('This request takes no parameters')}</p>
              )}
            </form>
          )}
          {view === 'code' && (
            <JsonEditor initialValue={editableValues} onBlur={setFormValues} />
          )}
          {testingResult && <TestResult data={testingResult} />}
        </div>
      </CustomScrollbars>
      <FormActions>
        <Button disabled={isSubmitting} size={ButtonSize.small} onClick={handleCancel}>
          {t('buttons.Cancel')}
        </Button>
        <Button
          loading={isSubmitting}
          size={ButtonSize.small}
          disabled={
            !isValid ||
            isSubmitting ||
            (!dirty &&
              (hasBody ||
                !!selectedRequest.parameters?.filter(({ required }) => required).length))
          }
          onClick={submitForm}
        >
          {t('buttons.Test')}
        </Button>
      </FormActions>
    </div>
  );
};

export default TestRequestForm;
