import i18n from '../../i18n';
import * as yup from 'yup';

export const yupLocale = {
  mixed: {
    // my own rule in dynamic forms
    confirm: ({ path, field }) => i18n.t('validation:mixed:confirm', { path, field }),

    default: ({ path }) => i18n.t('validation:mixed:default', { path }),
    required: ({ path }) => i18n.t('validation:mixed:required', { path }),
    oneOf: ({ path, values }) => i18n.t('validation:mixed:oneOf', { path, values }),
    notOneOf: ({ path, values }) => i18n.t('validation:mixed:notOneOf', { path, values }),
    notType: ({ path, type }) => i18n.t('validation:mixed:notType', { path, type }),
    defined: ({ path }) => i18n.t('validation:mixed:defined', { path }),
  },
  string: {
    email: ({ path }) => i18n.t('validation:string:email', { path }),
    length: ({ path, length }) => i18n.t('validation:string:length', { path, length }),
    min: ({ path, min }) => i18n.t('validation:string:min', { path, min }),
    max: ({ path, max }) => i18n.t('validation:string:max', { path, max }),
    matches: ({ path, regex }) => i18n.t('validation:string:matches', { path, regex }),
    url: ({ path }) => i18n.t('validation:string:url', { path }),
    uuid: ({ path }) => i18n.t('validation:string:uuid', { path }),
    trim: ({ path }) => i18n.t('validation:string:trim', { path }),
    lowercase: ({ path }) => i18n.t('validation:string:lowercase', { path }),
    uppercase: ({ path }) => i18n.t('validation:string:uppercase', { path }),
  },
  number: {
    min: ({ path, min }) => i18n.t('validation:number:min', { path, min }),
    max: ({ path, max }) => i18n.t('validation:number:max', { path, max }),
    lessThan: ({ path, less }) => i18n.t('validation:number:lessThan', { path, less }),
    moreThan: ({ path, more }) => i18n.t('validation:number:moreThan', { path, more }),
    notEqual: ({ path, notEqual }) =>
      i18n.t('validation:number:notEqual', { path, notEqual }),
    positive: ({ path }) => i18n.t('validation:number:positive', { path }),
    negative: ({ path }) => i18n.t('validation:number:negative', { path }),
    integer: ({ path }) => i18n.t('validation:number:negative', { path }),
  },
  date: {
    min: ({ path, min }) => i18n.t('validation:date:min', { path, min }),
    max: ({ path, max }) => i18n.t('validation:date:max', { path, max }),
  },
  boolean: {},
  object: {
    noUnknown: ({ path }) => i18n.t('validation:object:noUnknown', { path }),
  },
  array: {
    min: ({ path, min }) => i18n.t('validation:array:min', { path, min }),
    max: ({ path, max }) => i18n.t('validation:array:max', { path, max }),
  },
} as yup.LocaleObject;

yup.setLocale(yupLocale);

export default yup;
