import React, { FC, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Popover } from '@blueprintjs/core';
import {
  DateRange,
  DateRangePicker as BlueprintDateRangePicker,
  TimePrecision,
} from '@blueprintjs/datetime';
// Utils
import { jsTimeToString } from 'src/utils/genUtils';
// Style
import './date-picker-style.scss';
interface IDatePickerProps {
  onChange: (selectedDates: DateRange) => void;
}

const DateRangePicker: FC<IDatePickerProps> = ({ onChange }) => {
  const [date, setDate] = useState<DateRange>([null, null]);
  const [startTime, endTime] = date;

  const { t } = useTranslation(['datepicker']);

  const datepickerLabel = useMemo<string>(() => {
    if (!startTime) {
      return t('inputs.Select Date');
    }

    return `${jsTimeToString(startTime.getTime() / 1000)} - ${
      endTime ? jsTimeToString(endTime.getTime() / 1000) : t('inputs.Today')
    }`;
  }, [endTime, startTime, t]);

  const handleChange = useCallback(() => {
    onChange(date);
  }, [date, onChange]);

  return (
    <Popover
      placement="bottom"
      enforceFocus={false}
      canEscapeKeyClose
      popoverClassName="date-picker-popover"
      targetClassName="date-picker-target"
      onClose={handleChange}
    >
      <Button text={datepickerLabel} rightIcon="calendar" />
      <BlueprintDateRangePicker
        allowSingleDayRange
        shortcuts={false}
        singleMonthOnly
        value={date}
        timePickerProps={{
          precision: TimePrecision.MINUTE,
          showArrowButtons: true,
        }}
        onChange={setDate}
      />
    </Popover>
  );
};

export default DateRangePicker;
