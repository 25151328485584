import { Quill } from 'react-quill';

const Embed = Quill.import('blots/embed');

export interface IVariable {
  name: string;
  isValid: boolean;
}

export default class Variable extends Embed {
  static create(params: IVariable | string) {
    const { name, isValid = true } =
      typeof params === 'string' ? JSON.parse(params) : params;
    const node = super.create();
    node.setAttribute('data-name', name);
    node.setAttribute('class', 'variable pill');
    node.setAttribute('data-valid', isValid);
    node.textContent = name;
    return node;
  }

  static value(node) {
    return {
      name: node.dataset.name,
      isValid: node.dataset.valid === 'true',
    };
  }
}
Variable.blotName = 'variable';
Variable.tagName = 'span';
