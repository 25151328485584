import React, { FC, ReactNode, useState } from 'react';
import { Collapse as BlueprintjsCollapse, IconName, Button } from '@blueprintjs/core';
// Types
import { ICollapse } from '../block-types';
// Components
import ArrowIcon from '../../../icons/ArrowIcon';
// Utils
import getClassName from '../../utils/getClassName';
// Styles
import './styles.scss';

interface ICollapseProps {
  children: ReactNode;
  isInitialyOpen: boolean;
  className?: string;
  icon: IconName;
  label: string;
}

const Collapse: FC<ICollapseProps> = ({
  children,
  isInitialyOpen,
  icon,
  label,
  className,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialyOpen);

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        minimal
        fill={false}
        small
        rightIcon={<span className="right-icon">{icon ?? <ArrowIcon />}</span>}
        className={`collapse-control ${isOpen ? 'opened' : ''}`}
      >
        {label}
      </Button>
      <BlueprintjsCollapse isOpen={isOpen} className={className}>
        {children}
      </BlueprintjsCollapse>
    </>
  );
};

export const mapCollapseProps = (
  { children, isOpen, icon, label, classes }: ICollapse,
  builder
) => ({
  children: builder(children),
  isInitialyOpen: isOpen,
  className: getClassName(classes),
  icon,
  label,
});

export default Collapse;
