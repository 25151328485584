import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IComponent } from '../../../../types/api-Types';
import { EDashboardComponents } from '../../../../types/enums';
import { getConnector } from '../connector/thunks';
import { IGetConnectorSuccessPayload } from '../connector/types';

// Types
import forwardActionToComponent from './forwardActionToComponent';
import { IGetComponentSettingsResult, IComponentsState } from './types';

// Thunks
import { getComponentSettings, updateComponentSettings } from './thunks';
import componentItemReducer, { setComponent } from './componentItemSlice';

const initialState: IComponentsState = {};

const componentsSlice = createSlice({
  name: 'dashboard.components',
  initialState,
  reducers: {
    reset: () => initialState,
    unsetComponent: (
      state: IComponentsState,
      action: PayloadAction<EDashboardComponents>
    ) => {
      const newState = { ...state };

      delete newState[action.payload];

      return newState;
    },
  },
  extraReducers: {
    [String(getComponentSettings.rejected)]: forwardActionToComponent(),
    [String(getComponentSettings.fulfilled)]: forwardActionToComponent<
      IGetComponentSettingsResult
    >(),
    [String(updateComponentSettings.pending)]: forwardActionToComponent(),
    [String(updateComponentSettings.fulfilled)]: forwardActionToComponent(),
    [String(updateComponentSettings.rejected)]: forwardActionToComponent(),
    [String(getConnector.fulfilled)]: (
      state: IComponentsState,
      action: PayloadAction<IGetConnectorSuccessPayload>
    ): IComponentsState => {
      const { data } = action.payload;
      const components = _.pick(data.components, Object.values(EDashboardComponents));

      return (Object.values(components) as IComponent[]).reduce((acc, component) => {
        return {
          ...acc,
          [component.key]: componentItemReducer(
            undefined,
            setComponent({ connectorId: data.nid, component })
          ),
        };
      }, {}) as IComponentsState;
    },
  },
});

export const { reset, unsetComponent } = componentsSlice.actions;

export default componentsSlice.reducer;
