import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Switch from '../../../components/custom-switch/CustomSwitch';
// Services
import IntegrationDebugSettingsService from '../../../services/IntegrationDebugSettingsService';

interface IDebugToggleProps {
  id: string;
}

const DebugToggle: FC<IDebugToggleProps> = ({ id }) => {
  const [debug, setDebug] = useState(IntegrationDebugSettingsService.get(id));

  const { t } = useTranslation(['dashboard']);

  const handleChange = (e) => {
    IntegrationDebugSettingsService.set(id, e.target.checked);
    setDebug(e.target.checked);
  };

  return (
    <Switch name={t('buttons.Debug mode')} checked={debug} onChange={handleChange} />
  );
};

export default DebugToggle;
