import { TimePrecision } from '@blueprintjs/datetime';

// Base Types
export enum InputTypes {
  'select' = 'select',
  'textfield' = 'textfield',
  'textarea' = 'textarea',
  'password' = 'password',
  'checkbox' = 'checkbox',
  'submit' = 'submit',
  'autosubmit' = 'auto-submit',
  'button' = 'button',
  'jsonEditorField' = 'json-editor-field',
  'autocompleteTextField' = 'autocomplete-textfield',
  'mappingTool' = 'mapping-tool',
  'dateInput' = 'dateinput',
  'dateRangeInput' = 'daterange_input',
  'tagsInput' = 'tags_input',
  'simpleArrayEditor' = 'simple-array-editor',
  'onChangeSubmit' = 'onchange-submit',
  'switch' = 'switch',
}

export interface IInput {
  id: string;
  classes?: string[];
  className?: string;
  type: InputTypes;
  label: string;
  key: string;
  name: string;
  validation: string;
  description?: string;
  value: any;
}

export interface ISelectOptions {
  [key: string]: string | number;
}
export interface IAutocompleteOptions {
  [key: string]: string;
}

export type SelectValueTypes = string | number;

export interface ISelect extends IInput {
  type: InputTypes.select;
  value: SelectValueTypes | SelectValueTypes[];
  isMultiSelector: boolean;
  options: ISelectOptions[];
}

export interface ITextField extends IInput {
  type: InputTypes.textfield;
}

export interface IAutocompleteTextField extends IInput {
  type: InputTypes.autocompleteTextField;
  callback: string;
  payload: string;
  value: string;
  options?: IAutocompleteOptions[];
  min_length: number;
}

export interface IPassword extends IInput {
  type: InputTypes.password;
}

export interface ITextArea extends IInput {
  type: InputTypes.textarea;
}

export interface ICheckbox extends IInput {
  value: boolean;
  type: InputTypes.checkbox;
}

export interface ISubmit {
  value: string;
  type: InputTypes.submit;
  callback: string;
  payload: string;
  wrapper: string;
  asLink: boolean;
  classes: string[];
}

export interface IButton {
  value: string;
  type: InputTypes.button;
  callback: string;
  payload: string;
  wrapper: string;
  asLink: boolean;
  classes: string[];
}

export interface IAutosubmit {
  type: InputTypes.autosubmit;
  callback: string;
  payload: string;
  wrapper: string;
  timeout: number;
}

interface IJsonEditorOption {
  label: string;
  value: any;
}
export interface IJsonEditorField extends IInput {
  type: InputTypes.jsonEditorField;
  label: string;
  key: string;
  description?: string;
  value: object;
  enableEdit: boolean;
  enableAdd: boolean;
  enableDelete: boolean;
  enableClipboard: boolean;
  values_suggestion: IJsonEditorOption[];
  field_suggestion: IJsonEditorOption[];
}

export interface IMappingTool extends IInput {
  type: InputTypes.mappingTool;
  label: string;
  key: string;
  description?: string;
  value: object;
  source: object;
  destination: object;
}

export interface IDateInput extends IInput {
  type: InputTypes.dateInput;
  value: number | null; // timestamp
  timePrecision?: TimePrecision;
  minDate?: number;
  maxDate?: number;
  locale?: string;
  format?: string;
}

export interface IDateRangeInput extends IInput {
  type: InputTypes.dateRangeInput;
  value: [number | null, number | null]; // timestamp
  timePrecision?: TimePrecision;
  minDate?: number;
  maxDate?: number;
  locale?: string;
  format?: string;
}

export interface ITagsInput extends IInput {
  type: InputTypes.tagsInput;
  value: React.ReactNode[];
}

export interface ISimpleArrayEditor extends IInput {
  type: InputTypes.simpleArrayEditor;
  label: string;
  key: string;
  description?: string;
  value: object;
  availableValues: ISelectOptions[];
  availableKeys: ISelectOptions[];
}

export interface IOnChangeSubmit {
  type: InputTypes.onChangeSubmit;
  callback: string;
  payload: string;
  wrapper: string;
  fields: string[];
}

export interface ISwitch extends IInput {
  value: boolean;
  type: InputTypes.switch;
}
