import React from 'react';

const BackupIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 22" width="25" height="22">
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
          <path d="M-1009 -580L911 -580L911 697L-1009 697Z" />
        </clipPath>
      </defs>
      <g id="Web 1920 – 29" clipPath="url(#cp1)">
        <g id="reload">
          <g id="backup">
            <g id="Groupe 1308">
              <path
                id="Tracé 1408"
                className="shp0"
                d="M5.81 20.61C10.04 22.9 15.28 22.14 18.68 18.73C21.26 16.13 22.37 12.41 21.64 8.82C21.53 8.44 21.35 8.1 21.1 7.8L23.81 10.52L24.84 9.49L20.73 5.39L16.63 9.49L17.65 10.52L19.97 8.2C21.04 11.56 20.16 15.23 17.7 17.76C13.97 21.44 7.98 21.44 4.25 17.76C0.58 14.03 0.58 8.04 4.25 4.31C7.24 1.39 11.78 0.75 15.47 2.73L16.16 1.45C10.87 -1.41 4.26 0.56 1.4 5.85C-1.46 11.15 0.52 17.75 5.81 20.61Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BackupIcon;
