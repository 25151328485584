import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
// Selectors
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
// Types
import { SerializedError } from '../../../redux/slices/types';
import { ISubmitForm } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
// Service
import DashboardLayoutService from '../../../services/DashboardLayoutService';
// Utils
import { stringifyFormData } from '../../../utils/stringifyFormData';
import stringifyStateError from '../../../utils/stringifyStateError';
import useFormNotification from '../../../utils/useFormNotification';
import { useQuery } from 'react-query';

const useDynamicLayout = (params: any) => {
  const { data: connector } = useSelector(selectConnectorState);

  const { t } = useTranslation(['dynamicLayout, dynamicForm']);
  const { showNotification } = useFormNotification();

  const { data: layout, isLoading: loading, error, isError } = useQuery<
    any,
    SerializedError
  >(
    [
      connector?.nid,
      {
        id: connector?.nid,
        params,
      },
    ],
    DashboardLayoutService.getWidgetLayout,
    {
      enabled: connector?.nid && params,
    }
  );

  // Handlers
  const handleDynamicFormSubmit = useCallback(
    async ({ data: formData, callbackUrl, submitPayload }: ISubmitForm) => {
      try {
        if (connector?.nid) {
          const { data } = await DashboardLayoutService.submitForm(
            callbackUrl,
            stringifyFormData(formData, submitPayload),
            connector.nid
          );
          if (data?.output?.error) {
            throw new Error(data.output.error);
          }
          if (data?.output?.notification) {
            showNotification(data?.output?.notification);
            if (data?.output?.layout) {
              return data.output.layout;
            }
          } else return data.output;
        }
      } catch (error) {
        toastr.error(
          t('dynamicForm:alert.Failed'),
          t('dynamicForm:alert.Error update', { error: stringifyStateError(error) })
        );
      }
    },
    [t, showNotification, connector]
  );

  useEffect(() => {
    if (isError) {
      toastr.error(
        t('alert.Failed'),
        t('alert.Error fetch', { error: stringifyStateError(error) })
      );
    }
  }, [error, isError, t]);

  return { layout, loading, handleDynamicFormSubmit };
};

export default useDynamicLayout;
