import React, { FC, useCallback, createContext } from 'react';
// Utils
import get from 'lodash/get';

interface ISimpleArrayEditorContextProps {
  result: object;
}

export interface ISimpleArrayEditorContext extends ISimpleArrayEditorContextProps {
  checkIsValidFieldName: (field: string, parentPath: string) => boolean;
}

export const SimpleArrayEditorContext = createContext<ISimpleArrayEditorContext>(
  {} as ISimpleArrayEditorContext
);

const SimpleArrayEditorContextProvider: FC<ISimpleArrayEditorContextProps> = ({
  children,
  result,
}) => {
  const checkIsValidFieldName = useCallback(
    (field, parentPath) => {
      return !(parentPath
        ? // @ts-ignore
          get(result, parentPath)?.hasOwnProperty(field)
        : result.hasOwnProperty(field));
    },
    [result]
  );

  return (
    <SimpleArrayEditorContext.Provider
      value={{
        checkIsValidFieldName,
        result,
      }}
    >
      {children}
    </SimpleArrayEditorContext.Provider>
  );
};

export default SimpleArrayEditorContextProvider;
