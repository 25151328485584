import React, { FC, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import FilePicker from './FilePicker';
import CodeEditor from '../../code-editor/CodeEditor';
import Button from 'src/components/button/Button';
import { FormGroup } from '@blueprintjs/core';

// Style
import './style.scss';

const formatValue = (value) => {
  try {
    return JSON.stringify(JSON.parse(value), undefined, 2);
  } catch (err) {
    return '';
  }
};

interface IFileImportProps {
  onChange: (content: object | null) => void;
  initialValue: string;
}

const FileImport: FC<IFileImportProps> = ({ onChange, initialValue }) => {
  const [error, setError] = useState<string | undefined>();
  const [value, setValue] = useState<string>(formatValue(initialValue));

  const { t } = useTranslation(['mapping']);

  const isChanged = useMemo(() => value !== formatValue(initialValue), [
    value,
    initialValue,
  ]);

  const handleAdd = (newFile: File) => {
    if (newFile.name.match(/.json$/)) {
      const reader = new FileReader();
      reader.addEventListener('load', (e) => {
        if (typeof e.target?.result === 'string') {
          setValue(e.target.result);
          setError(undefined);
        }
      });
      reader.readAsText(newFile);
    } else {
      setValue('');
      setError(t('validation.Please, choose a file of type JSON'));
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleValidate = (errors) => {
    if (errors.length) {
      setError(t('validation.An input provided is not a valid JSON'));
    } else {
      setError(undefined);
    }
  };

  const handleSubmit = useCallback(() => {
    if (!error) {
      onChange(JSON.parse(value ? value : '{}'));
    }
  }, [value, onChange, error]);

  return (
    <div className="file-import-wrp">
      <FilePicker onChange={handleAdd} />

      <FormGroup helperText={error}>
        <CodeEditor
          value={value}
          onChange={handleChange}
          mode="json"
          onLint={handleValidate}
        />
      </FormGroup>

      <Button
        className="margin-top-xxs"
        type="button"
        onClick={handleSubmit}
        disabled={!isChanged || !!error}
      >
        {t('buttons.Submit')}
      </Button>
    </div>
  );
};

export default FileImport;
