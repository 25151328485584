export interface ISize {
  width: string;
  height: string;
}

const createSize = (width: number, height: number, unit = 'px'): ISize => ({
  width: width + unit,
  height: height + unit,
});

export default createSize;
