import React, { FC, useCallback, createContext } from 'react';
// Utils
import get from 'lodash/get';

interface IMappingToolContextProps {
  source: object;
  destination: object;
  result: object;
  checkIsValidVariable: (variablePath: string) => boolean;
}

export interface IMappingToolContext extends IMappingToolContextProps {
  checkIsValidFieldName: (field: string, parentPath: string) => boolean;
}

export const MappingToolContext = createContext<IMappingToolContext>(
  {} as IMappingToolContext
);

const MappingToolContextProvider: FC<IMappingToolContextProps> = ({
  children,
  source,
  result,
  destination,
  checkIsValidVariable,
}) => {
  const checkIsValidFieldName = useCallback(
    (field, parentPath) => {
      return !(parentPath
        ? // @ts-ignore
          get(result, parentPath)?.hasOwnProperty(field)
        : result.hasOwnProperty(field));
    },
    [result]
  );

  return (
    <MappingToolContext.Provider
      value={{
        checkIsValidVariable,
        checkIsValidFieldName,
        source,
        destination,
        result,
      }}
    >
      {children}
    </MappingToolContext.Provider>
  );
};

export default MappingToolContextProvider;
