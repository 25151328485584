import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, DrawerSize } from '@blueprintjs/core';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
// Components
import Button from './ConfigDrawerButton';
import CloseIcon from '../../../components/icons/CloseIcon';
import IntegrationStatusSwitch from '../../../components/Wedoio/Company/CompanyIntegrations/Integration/IntegrationStatusSwitch';
import DebugToggle from '../debug-toggle/DebugToggle';
import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';
import CustomScrollbars from 'src/components/custom-scrollbars/CustomScrollbars';
// Selectors
import { selectConfigState } from '../../../redux/slices/dashboard/connector-config/selectors';
// Actions
import {
  getConfigForm,
  updateConfig,
} from '../../../redux/slices/dashboard/connector-config/thunks';
import { reset as resetState } from '../../../redux/slices/dashboard/connector-config/connectorConfigSlice';
// Types
import { IConnector } from '../../../types/api-Types';
import { RequestStatus } from '../../../redux/slices/types';
import { ISubmitForm } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
import { UserPermissions } from '../../../types/enums';
// Utils
import stringifyStateError from '../../../utils/stringifyStateError';
import { dashboardFormsComponentsMap as componentsMap } from '../componentsMap';
import usePermissions from '../../../utils/check-permission/usePermissions';
import useFormVisibilityContext from 'src/components/dynamic-layout/form-visibility-context/useFormVisibilityContext';
import useFormNotification from 'src/utils/useFormNotification';
// Styles
import './styles.scss';

export interface IConfigDrawerProps {
  connector: IConnector;
}

const ConfigDrawer: FC<IConfigDrawerProps> = ({ connector }) => {
  // State
  const { data, loading, submit, error, notification } = useSelector(selectConfigState);

  const isLoading = loading === RequestStatus.pending;
  const isFormDataEmpty = !data.form || !data.form?.length;
  const connectorId = connector.nid;
  const [formStructure, setFormStructure] = useState<any>(null);

  const { isFormVisible, toggleIsFormVisible } = useFormVisibilityContext();

  const [canDebug] = usePermissions(UserPermissions.integrations_debug);

  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard']);
  const { showNotification } = useFormNotification();

  // Handlers
  const handleSubmit = useCallback(
    async ({ data: formData }: ISubmitForm) => {
      if (connectorId) {
        await dispatch(updateConfig({ connectorId, data: formData }));
      }
    },
    [connectorId, dispatch]
  );

  const handleDrawerToggle = useCallback(
    (state: boolean) => () => {
      toggleIsFormVisible(state);
    },
    [toggleIsFormVisible]
  );

  // Components did mount
  useEffect(() => {
    if (connectorId) {
      dispatch(getConfigForm(connectorId));
    }

    // Components did unmount
    return () => {
      dispatch(resetState());
    };
  }, [connectorId, dispatch]);

  useEffect(() => {
    const message = stringifyStateError(error);
    if (error && submit === RequestStatus.rejected) {
      toastr.error(
        t('alert.Failed'),
        t(`alert.Failed to save configurations`, { message })
      );
    }

    if (error && loading === RequestStatus.rejected) {
      toastr.error(
        t('alert.Failed'),
        t(`alert.Failed to fetch configurations`, { message })
      );
    }

    if (submit === RequestStatus.fulfilled) {
      if (notification) {
        showNotification(notification);
      } else {
        toastr.success(
          t('alert.Success'),
          t('alert.Configurations successfully updated')
        );
      }

      if (connectorId) dispatch(getConfigForm(connectorId));
      toggleIsFormVisible(false);
    }
  }, [
    error,
    loading,
    submit,
    notification,
    showNotification,
    connectorId,
    dispatch,
    t,
    toggleIsFormVisible,
  ]);

  useEffect(() => {
    toggleIsFormVisible(false);
  }, [connector.status, toggleIsFormVisible]);

  useEffect(() => {
    setFormStructure({
      type: 'form',
      children: [
        // @ts-ignore
        ...(isFormDataEmpty ? [] : data.form),
        { type: 'form-actions' },
      ],
    });
  }, [data, isFormDataEmpty]);

  return (
    <>
      <Button
        disabled={isFormDataEmpty}
        loading={isLoading}
        onClick={handleDrawerToggle(true)}
      />
      <Drawer
        className="integration-config-drawer"
        usePortal
        position="right"
        size={DrawerSize.STANDARD}
        isCloseButtonShown={false}
        isOpen={isFormVisible}
        title={t('titles.Configuration')}
        onClose={handleDrawerToggle(false)}
      >
        <div className="dialog-close-button" onClick={handleDrawerToggle(false)}>
          <CloseIcon />
        </div>
        <div className="drawer-switch">
          <div className="status-switch">
            <IntegrationStatusSwitch
              status={connector.status}
              name={connector.name}
              id={connector.nid}
            />
          </div>
          {canDebug && <DebugToggle id={connector.nid} />}
        </div>
        <CustomScrollbars hideTracksWhenNotNeeded>
          <StructureErrorBoundary>
            {!isFormDataEmpty && (
              <DynamicLayout
                callService={handleSubmit}
                componentsMap={componentsMap}
                structure={formStructure}
              />
            )}
          </StructureErrorBoundary>
        </CustomScrollbars>
      </Drawer>
    </>
  );
};

export default ConfigDrawer;
