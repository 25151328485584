import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
// Actions
import { updateIntegrationStatus } from '../redux/actions/system-actions';
// Types
import { RequestStatus } from '../redux/slices/types';
// Utils
import stringifyStateError from './stringifyStateError';
// Services
import ConnectorService from '../services/ConnectorService';

export type ChangeConnectorStatus = (status?: boolean) => void;

const useChangeIntegrationStatus = (
  id: string
): [RequestStatus, ChangeConnectorStatus] => {
  const [submit, setSubmit] = useState<RequestStatus>(RequestStatus.idle);

  const dispatch = useDispatch();

  const changeStatus = async (status = false) => {
    setSubmit(RequestStatus.pending);

    try {
      const { data } = await ConnectorService.changeStatus(id, status);

      setSubmit(RequestStatus.fulfilled);

      dispatch(
        updateIntegrationStatus({ connectorId: id, status: !!data.output?.Enabled })
      );
    } catch (error) {
      const message = stringifyStateError(error);

      toastr.error('Failed to change integration status', message);
      setSubmit(RequestStatus.rejected);
    }
  };

  return [submit, changeStatus];
};

export default useChangeIntegrationStatus;
