import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import ConnectorService from '../../../../services/ConnectorService';

// Types
import { IGetConnectorActionsSuccessPayload, IGetConnectorSuccessPayload } from './types';
import { RootState } from 'src/redux/rootReducer';
import { RequestStatus } from '../../types';

export const getConnector = createAsyncThunk<IGetConnectorSuccessPayload, string>(
  'dashboard.connector/getConnector',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await ConnectorService.get(id);

      return {
        data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const getConnectorActions = createAsyncThunk<
  IGetConnectorActionsSuccessPayload,
  string
>(
  'dashboard.connector/getConnectorActions',
  async (id, { rejectWithValue }) => {
    try {
      const actions = await ConnectorService.getActions(id);

      return {
        actions,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  },
  {
    condition: (id, { getState }) => {
      const { dashboard } = getState() as RootState;

      if (
        dashboard.connector?.data?.nid === id &&
        dashboard.connector.actionsLoading !== RequestStatus.idle
      ) {
        return false;
      }
      return true;
    },
  }
);
