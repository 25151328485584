import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import Input from '../../../../components/input/Input';
import GenericSelector from '../../../../components/generic-selector/GenericSelector';
import { Alert, Icon, Intent } from '@blueprintjs/core';
import Collapse from '../../../../components/collapse/Collapse';
// Types
import { InputTypes } from '../../../../components/dynamic-layout/components/input-types';
import { CreateConnectorFormData, IConnectorFormField } from 'src/types/internal-Types';
// Utils
import { getHelperText } from '../../utils';
import useAlert from 'src/utils/useAlert';

interface IInputEditorProps {
  fieldValue: IConnectorFormField;
  path: string;
  onRemove: () => void;
}

const InputEditor: FC<IInputEditorProps> = ({ fieldValue, path, onRemove }) => {
  const {
    handleBlur,
    handleChange,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<CreateConnectorFormData>();

  const { t } = useTranslation(['connectors']);

  const typeOptions = useMemo(
    () => [
      { Key: InputTypes.textfield, Value: t('selects.Textfield') },
      { Key: InputTypes.password, Value: t('selects.Password') },
    ],
    [t]
  );

  const selectedType = useMemo(
    () => typeOptions.find(({ Key }) => Key === fieldValue.type),
    [fieldValue, typeOptions]
  );

  const handleSelectType = useCallback(
    (types) => {
      const [{ Key } = { Key: undefined }] = types;

      setFieldValue(`${path}.type`, Key);
      setFieldTouched(`${path}.type`);
    },
    [setFieldValue, setFieldTouched, path]
  );

  const deleteAlert = useAlert({ callback: onRemove });

  return (
    <div className="input-editor">
      <Collapse
        header={
          <div className="input-editor-title">
            <span>{fieldValue?.label ? fieldValue.label : t('titles.New field')}</span>
            <Icon
              className="pull-right"
              icon="trash"
              onClick={(e) => {
                e.stopPropagation();
                deleteAlert.onOpen();
              }}
            />
          </div>
        }
      >
        <div className="input-editor-form">
          <Input
            label={t('inputs.Label')}
            placeholder={t('inputs.Label')}
            value={fieldValue.label}
            name={`${path}.label`}
            onBlur={handleBlur}
            onChange={handleChange}
            validationError={getHelperText(touched, errors, `${path}.label`)}
          />
          <Input
            label={t('inputs.Key')}
            placeholder={t('inputs.Key')}
            value={fieldValue.key}
            name={`${path}.key`}
            onBlur={handleBlur}
            onChange={handleChange}
            validationError={getHelperText(touched, errors, `${path}.key`)}
          />
          <div className="margin-bottom-xs">
            <GenericSelector
              selectedItem={selectedType ? [selectedType] : undefined}
              isMultiSelector={false}
              onValueChanged={handleSelectType}
              items={typeOptions}
              selectorText={t('inputs.Type')}
            />
          </div>
        </div>
      </Collapse>
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={deleteAlert.isOpen}
        onCancel={deleteAlert.onClose}
        onConfirm={deleteAlert.onSubmit}
      >
        <p>{t('alert.Delete field')}</p>
      </Alert>
    </div>
  );
};

export default InputEditor;
