import React, { FC } from 'react';
import { useSelector } from 'react-redux';
// Selectors
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
// Types
import { IDashboardTitle } from '../types';

const DashboardTitle: FC = () => {
  const { data: connector } = useSelector(selectConnectorState);

  return <div className="connector-name">{connector?.name}</div>;
};

export default DashboardTitle;

export const mapDashboardTitleProps = (props: IDashboardTitle) => props;
