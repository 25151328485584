import React, { FC, useCallback, useMemo } from 'react';
import { Button, MenuItem, Menu, OverflowList, Popover } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import closestTo from 'date-fns/closestTo';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import * as locale from 'date-fns/locale';
import isEqual from 'date-fns/isEqual';
import _ from 'lodash';
import sub from 'date-fns/sub';
// Components
import ArrowIcon from '../../../../components/icons/ArrowIcon';
// Types
import { IDateRange } from 'src/redux/slices/dashboard/charts/types';
// Styles
import './styles.scss';

interface ITimeFrameSelectProps {
  onChange: (dateRange: IDateRange) => void;
  options: Duration[];
  selectedDate: Date;
}

const TimeFrameSelect: FC<ITimeFrameSelectProps> = ({
  options,
  onChange,
  selectedDate,
}) => {
  const { i18n } = useTranslation();

  const getLabel = useCallback(
    (distance: Duration) =>
      formatDistanceStrict(new Date(), sub(new Date(), distance), {
        locale: locale[i18n.language],
      }),
    [i18n]
  );

  const selectedItem = useMemo(() => {
    const dateNow = new Date();
    const optionsDate = options.map((option) => sub(dateNow, option));
    return (
      options.find(
        (option) =>
          !!selectedDate &&
          isEqual(closestTo(selectedDate, optionsDate), sub(dateNow, option))
      ) ?? options[0]
    );
  }, [options, selectedDate]);

  return (
    <OverflowList
      collapseFrom="end"
      className="time-frame-select"
      items={options}
      minVisibleItems={1}
      visibleItemRenderer={(distance, index) => (
        <Button
          text={getLabel(distance)}
          key={index}
          active={_.isEqual(distance, selectedItem)}
          onClick={() => onChange([sub(new Date(), distance), new Date()])}
        />
      )}
      overflowRenderer={(overflowItems) => (
        <Popover popoverClassName="time-frame-select-list">
          <Button rightIcon={<ArrowIcon />} className="time-frame-select-dropdown" />
          <Menu>
            {overflowItems.map((distance, index) => (
              <MenuItem
                text={getLabel(distance)}
                key={index}
                active={_.isEqual(distance, selectedItem)}
                onClick={() => onChange([sub(new Date(), distance), new Date()])}
              />
            ))}
          </Menu>
        </Popover>
      )}
    />
  );
};

export default TimeFrameSelect;
