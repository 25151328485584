import { useSelector } from 'react-redux';
import { selectRedirectParamsState } from '../../redux/slices/auth/redirect-params/selectors';

const useRedirectSearchParams = () => {
  const redirectParameters = useSelector(selectRedirectParamsState);

  return redirectParameters;
};

export default useRedirectSearchParams;
