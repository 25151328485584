import { IValueKey } from '../../components/generic-selector/GenericSelector';
import { useTranslation } from 'react-i18next';
import countries from '../../translations/countries/english.json';

export const useCountriesList = (): IValueKey[] => {
  const { t } = useTranslation(['countries']);

  return Object.entries(countries).map(([Key]) => ({
    Key,
    Value: t(Key),
  }));
};
