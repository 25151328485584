import React, { FC, ReactNode } from 'react';
// Components
import { Intent, Callout as CalloutBlueprintJS } from '@blueprintjs/core';
// Styles
import './callout-styles.scss';

export interface ICalloutProps {
  title?: string;
  content: string;
  intent?: Intent;
  className?: string;
  children?: ReactNode;
}

const Callout: FC<ICalloutProps> = ({
  title = '',
  content,
  intent = Intent.NONE,
  className = '',
  children,
}) => {
  return (
    <CalloutBlueprintJS title={title} intent={intent} className={'callout ' + className}>
      <>
        {content}
        {children}
      </>
    </CalloutBlueprintJS>
  );
};

export default Callout;
