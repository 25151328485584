import { createAsyncThunk } from '@reduxjs/toolkit';
import LogsService from '../../../../services/LogsService';
import {
  IGetLogsActionPayload,
  IGetLogsSuccessResult,
  UpdateLogActionPayload,
} from './types';

export const getLogs = createAsyncThunk<IGetLogsSuccessResult, IGetLogsActionPayload>(
  'dashboard.logs/getLogs',
  async (params, { rejectWithValue }) => {
    try {
      const data = await LogsService.get(params);

      return {
        data,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const deleteLogs = createAsyncThunk<null, UpdateLogActionPayload>(
  'dashboard.logs/deleteLogs',
  async (params, { rejectWithValue }) => {
    try {
      await LogsService.delete(params);

      return null;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const reprocessLogs = createAsyncThunk<null, UpdateLogActionPayload>(
  'dashboard.logs/reprocessLogs',
  async (params, { rejectWithValue }) => {
    try {
      await LogsService.reprocess(params);

      return null;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);
