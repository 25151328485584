import { createAsyncThunk } from '@reduxjs/toolkit';
import sub from 'date-fns/sub';
// Services
import ChartsService, { IGetChartsDataParams } from '../../../../services/ChartsService';
// Types
import { IGetChartsSuccessResult } from './types';
import { RequestStatus } from '../../types';
import { RootState } from 'src/redux/rootReducer';

export const getData = createAsyncThunk<IGetChartsSuccessResult, IGetChartsDataParams>(
  'dashboard.charts/getData',
  async ({ connectorId }, { rejectWithValue }) => {
    try {
      const { data, totalCount } = await ChartsService.get({
        connectorId,
        startTime: sub(new Date(), { months: 1 }),
        endTime: new Date(),
      });

      return {
        data: {
          totalCount,
          logs: data,
        },
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  },
  {
    condition: ({ connectorId }, { getState }) => {
      const { dashboard } = getState() as RootState;

      if (
        dashboard.connector?.data?.nid === connectorId &&
        (dashboard.charts.loading === RequestStatus.pending ||
          dashboard.charts.loading === RequestStatus.rejected)
      ) {
        return false;
      }
      return true;
    },
  }
);
