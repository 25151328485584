import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// Types
import { UserPermissions } from '../../types/enums';
// Selectors
import { selectCurrentUser } from '../../redux/slices/user/selectors';
// Utils
import checkPermission, { ICheckPermissions } from './checkPermission';

type IUsePermissions = UserPermissions[] | UserPermissions | undefined;

const usePermissions = (requiredPermissions: IUsePermissions): ICheckPermissions => {
  const { data } = useSelector(selectCurrentUser);

  const permission = useMemo((): ICheckPermissions => {
    if (data && requiredPermissions) {
      return checkPermission(
        Array.isArray(requiredPermissions) ? requiredPermissions : [requiredPermissions],
        data
      );
    }
    return [undefined];
  }, [data, requiredPermissions]);

  return permission;
};

export default usePermissions;
