import React, { FC } from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import Scrollbars from '../../custom-scrollbars/CustomScrollbars';
import Notification from './Notification';
// Utils
import { getRandomArray } from 'src/utils/getRandom';
// Selectors
import { selectNotificationsList } from '../../../redux/slices/notifications/list/selectors';
import { ITEMS_PER_PAGE } from '../../../redux/slices/notifications/consts';
// Types
import { RequestStatus } from 'src/redux/slices/types';
import { INotification } from 'src/types/internal-Types';
// Style
import './styles.scss';

interface INotificationListProps {
  onItemClick: (data: INotification) => void;
}

const NotificationsList: FC<INotificationListProps> = ({ onItemClick }) => {
  const { loading, data } = useSelector(selectNotificationsList);
  const { t } = useTranslation(['notifications']);

  return (
    <div className="notifications-list">
      {loading !== RequestStatus.pending && !data?.length && (
        <NonIdealState title={t('You have no unread notifications')} icon="clean" />
      )}
      {(loading === RequestStatus.pending || !!data.length) && (
        <Scrollbars hideTracksWhenNotNeeded>
          {loading === RequestStatus.pending &&
            (!data.length
              ? getRandomArray(ITEMS_PER_PAGE).map((id) => (
                  <div className="notification-skeleton bp3-skeleton" key={id} />
                ))
              : getRandomArray(ITEMS_PER_PAGE - data.length).map((id) => (
                  <div className="notification-skeleton bp3-skeleton" key={id} />
                )))}
          {!!data.length &&
            data.map((item, i) => (
              <Notification data={item} key={i} onClick={onItemClick} />
            ))}
        </Scrollbars>
      )}
    </div>
  );
};

export default NotificationsList;
