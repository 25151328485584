import { AxiosPromise } from 'axios';
import BaseApiService, { RequestType } from './BaseApiService';

// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';

// Types
import { IConnector } from '../types/api-Types';
import { ConnectorAction } from '../types/internal-Types';

class ConnectorService extends BaseApiService {
  get = (id: string): AxiosPromise<IConnector> =>
    this.request({
      url: id,
      type: RequestType.get,
    });

  getActions = async (id: string): Promise<ConnectorAction[]> => {
    const { data } = await this.request({
      url: id + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'meta',
        },
        silent: !IntegrationDebugSettingsService.get(id),
      },
    });

    return Object.entries(data?.output?.meta?.actions || {}).map(
      ([Key, Value]) => ({ Key, Value } as ConnectorAction)
    );
  };

  changeStatus = (id: string, status: boolean) => {
    const url = `${id}/${status ? 'enable' : 'disable'}`;

    return this.request({
      url,
      type: RequestType.put,
    });
  };
}

export default new ConnectorService('rest/connector');
