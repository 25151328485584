import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

// Components
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
// import IntercomWidget from '../../components/intercom/IntercomWidget';

// Styles
import './app-layout-styles.scss';

interface IAppLayout {
  children: ReactNode;
}

const DISPLAY_BACKGROUND_POINT = 50;

const getBackgroundImage = (children) => children?.type?.backgroundImage;

const AppLayout: FC<IAppLayout> = ({ children }) => {
  const [headerWithBackground, setHeaderWithBackground] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const backgroundImage = getBackgroundImage(
    Array.isArray(children) ? children[0] : children
  );

  const handleScroll = useCallback(() => {
    // @ts-ignore
    const scrollPosition = contentRef.current.scrollTop;

    if (scrollPosition > DISPLAY_BACKGROUND_POINT && !headerWithBackground) {
      setHeaderWithBackground(true);
    }

    if (scrollPosition < DISPLAY_BACKGROUND_POINT && headerWithBackground) {
      setHeaderWithBackground(false);
    }
  }, [headerWithBackground]);

  useEffect(() => {
    contentRef.current?.addEventListener('scroll', handleScroll);
  }, [headerWithBackground, handleScroll]);

  return (
    <div className="app-container">
      <Header withBackground={headerWithBackground} />
      <div className="app-content" ref={contentRef}>
        {children}
      </div>
      <Footer />
      {backgroundImage && <img src={backgroundImage} alt="background" />}
      {/* <IntercomWidget waitUserData /> */}
    </div>
  );
};

export default AppLayout;
