import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import UserConnectorsService from '../../../../services/UserConnectorsService';

// Types
import { EditConnectorActionPayload, EditConnectorActionSuccessResult } from './types';

export const editConnector = createAsyncThunk<
  EditConnectorActionSuccessResult,
  EditConnectorActionPayload
>('connectors.edit/editConnector', async ({ id, connector }, { rejectWithValue }) => {
  try {
    const { data } = await UserConnectorsService.edit(id, connector);

    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response);
  }
});
