import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import LogsTable from './LogsTable';
import DashboardPanel from './DashboardPanel';
import SearchForm from 'src/components/search-field/SearchForm';
import Loader from 'src/components/loader/Loader';
// Selectors
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
import { selectDashboardLogs } from '../../../redux/slices/dashboard/logs/selectors';
// Actions
import {
  deleteLogs,
  getLogs,
  reprocessLogs,
} from '../../../redux/slices/dashboard/logs/thunks';
import {
  toggleSelectedLog,
  toggleSelectAllLogs,
  toggleSelectVisibleLogs,
  LOGS_PER_PAGE,
  setCurrentPage,
  reset as resetState,
  clearSelectedLogs,
  setTaskId,
  setSort,
} from 'src/redux/slices/dashboard/logs/logsSlice';
// Styles
import './dashboard-logs-styles.scss';
// Types
import { IDashboardLogs } from '../types';

const DashboardLogs: FC = () => {
  const { data: connector, loading: connectorLoading } = useSelector(
    selectConnectorState
  );
  const {
    currentPage,
    selectedAction,
    dateRange,
    taskState,
    taskId,
    sortBy,
    sortDirection,
  } = useSelector(selectDashboardLogs);

  const dispatch = useDispatch();

  const [startTime, endTime] = dateRange;

  const fetchLogs = () => {
    dispatch(clearSelectedLogs());
    if (connector && connector.nid) {
      dispatch(
        getLogs({
          connectorId: connector.nid,
          page: currentPage,
          limit: LOGS_PER_PAGE,
          action: selectedAction,
          taskState,
          startTime: startTime ? startTime : undefined,
          endTime: startTime && endTime ? endTime : undefined,
          taskId: taskId !== '' ? taskId : undefined,
          sortBy: sortBy ? sortBy : undefined,
          sortDirection: sortDirection ? sortDirection : undefined,
        })
      );
    }
  };

  // Handlers
  const handleLogSelect = (id: string) => {
    dispatch(toggleSelectedLog(id));
  };

  const handleLogDelete = async (ids: string[] = []) => {
    if (connector?.nid) {
      await dispatch(
        deleteLogs({
          connectorId: connector.nid,
          taskIds: ids,
          action: selectedAction,
          taskState,
          startTime: startTime ? startTime : undefined,
          endTime: startTime && endTime ? endTime : undefined,
        })
      );
      fetchLogs();
    }
  };

  const handleLogsReprocess = async (ids: string[] = []) => {
    if (connector?.nid) {
      await dispatch(
        reprocessLogs({
          connectorId: connector.nid,
          taskIds: ids,
          action: selectedAction,
          taskState,
          startTime: startTime ? startTime : undefined,
          endTime: startTime && endTime ? endTime : undefined,
        })
      );
      fetchLogs();
    }
  };

  const handleSelectAll = () => {
    dispatch(toggleSelectAllLogs());
  };

  const handleSelectVisible = () => {
    dispatch(toggleSelectVisibleLogs());
  };

  const handlePageClick = async (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleRefresh = async () => {
    if (currentPage !== 1) {
      await dispatch(setCurrentPage(1));
    } else {
      fetchLogs();
    }
  };

  const handleSearch = (value) => {
    dispatch(setTaskId(value));
  };

  const handleSort = (field, direction) => {
    dispatch(setSort({ sortBy: field, sortDirection: direction }));
  };

  /* eslint  react-hooks/exhaustive-deps: 0 */
  useEffect(() => {
    fetchLogs();
  }, [
    connector?.nid,
    currentPage,
    selectedAction,
    startTime,
    endTime,
    taskState,
    taskId,
    sortBy,
    sortDirection,
    dispatch,
  ]);

  // Component did unmount
  useEffect(
    () => () => {
      dispatch(resetState());
    },
    []
  );

  return (
    <div className="logs-table">
      <SearchForm onSubmit={handleSearch} initialValue={taskId} />
      <Loader loading={!connectorLoading}>
        <DashboardPanel
          onUpdate={handleRefresh}
          selectedAction={selectedAction}
          selectedState={taskState}
        />

        <LogsTable
          onLogDelete={handleLogDelete}
          onLogReprocess={handleLogsReprocess}
          onLogSelect={handleLogSelect}
          onPageClick={handlePageClick}
          onSelectAll={handleSelectAll}
          onSelectVisible={handleSelectVisible}
          onSort={handleSort}
        />
      </Loader>
    </div>
  );
};

export default DashboardLogs;

export const mapDashboardLogsProps = (props: IDashboardLogs) => props;
