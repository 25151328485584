import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
// Components
import { Alert, Intent } from '@blueprintjs/core';
import ListItem from '../list-item/ListItem';
// Hooks
import useAlert from '../../../../utils/useAlert';
// Types
import { IConnectorEndpoint } from '../../../../types/internal-Types';

interface IEndpointTeaserProps {
  index: number;
  value: IConnectorEndpoint;
  onRemove: (index: number) => void;
  isComplete?: boolean;
}

const EndpointTeaser: FC<IEndpointTeaserProps> = ({
  value,
  index,
  onRemove,
  isComplete = false,
}) => {
  const { t } = useTranslation(['connectors']);
  const history = useHistory();
  const match = useRouteMatch();

  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [onRemove, index]);

  const handleEdit = useCallback(() => {
    history.push(`${match.url}/${index}`);
  }, [history, index, match.url]);

  const deleteAlert = useAlert({ callback: handleRemove });

  return (
    <div className="endpoint-teaser">
      <ListItem
        intent={isComplete ? Intent.SUCCESS : Intent.WARNING}
        title={value.path ? value.path : t('titles.New endpoint')}
        onEdit={handleEdit}
        onRemove={(e) => {
          e.stopPropagation();
          deleteAlert.onOpen();
        }}
      />
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={deleteAlert.isOpen}
        onCancel={deleteAlert.onClose}
        onConfirm={deleteAlert.onSubmit}
      >
        <p>{t('alert.Delete endpoint')}</p>
      </Alert>
    </div>
  );
};

export default EndpointTeaser;
