import React, { FC } from 'react';
// Components
import CalloutComponent from 'src/components/callout/Callout';
// Types
import { ICalloutProps } from 'src/components/callout/Callout';
import { ICallout } from './block-types';
// Utils
import getClassName from '../utils/getClassName';

const Callout: FC<ICalloutProps> = ({ title, content, intent, className, children }) => {
  return (
    <CalloutComponent
      title={title}
      content={content}
      intent={intent}
      className={className}
    >
      {children}
    </CalloutComponent>
  );
};

export default Callout;

export const mapCalloutProps = (
  { title, content, intent, classes, children }: ICallout,
  builder
) => ({
  title,
  content,
  intent,
  className: getClassName(classes),
  children: builder(children),
});
