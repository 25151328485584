import { DateRange } from '@blueprintjs/datetime';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { RequestType } from '../../../../services/BaseApiService';
import { EState } from '../../../../types/api-Types';
import { RequestStatus } from '../../types';
// Utils
import { createThunkReducers } from '../../utils';
// Thunks
import { deleteLogs, getLogs, reprocessLogs } from './thunks';
import { ILogsState } from './types';
import { ELogsSortParams } from 'src/services/LogsService';
import { ESort } from 'src/types/enums';

export const LOGS_PER_PAGE = 10;

const initialState: ILogsState = {
  data: {
    logs: [],
    totalCount: 0,
  },
  dateRange: [null, null],
  selectedLogs: [],
  isSelectAll: false,
  selectedAction: undefined,
  currentPage: 1,
  taskState: undefined,
  sortBy: ELogsSortParams.date,
  sortDirection: ESort.desc,
  loading: RequestStatus.idle,
  error: null,
  deleteLoading: RequestStatus.idle,
  reprocessLoading: RequestStatus.idle,
};

const logsSlice = createSlice({
  name: 'dashboard.logs',
  initialState,
  reducers: {
    toggleSelectedLog: (state: ILogsState, action: PayloadAction<string>): ILogsState => {
      const { payload: id } = action;

      const selectedLogs = state.selectedLogs.includes(id)
        ? state.selectedLogs.filter((selectedId) => selectedId !== id)
        : [...state.selectedLogs, id];

      return {
        ...state,
        selectedLogs,
        isSelectAll:
          state.data.logs.length === selectedLogs.length ? state.isSelectAll : false,
      };
    },
    toggleSelectVisibleLogs: (state: ILogsState): ILogsState => {
      const { selectedLogs, data } = state;

      return {
        ...state,
        selectedLogs:
          selectedLogs.length === data.logs.length ? [] : data.logs.map(({ id }) => id),
      };
    },

    toggleSelectAllLogs: (state: ILogsState): ILogsState => ({
      ...state,
      isSelectAll: !state.isSelectAll,
    }),

    setDateRange: (state: ILogsState, action: PayloadAction<DateRange>): ILogsState => {
      return {
        ...state,
        selectedLogs: [],
        isSelectAll: false,
        currentPage: 1,
        dateRange: action.payload,
      };
    },
    setSelectedAction: (
      state: ILogsState,
      action: PayloadAction<string | undefined>
    ): ILogsState => ({
      ...state,
      selectedLogs: [],
      isSelectAll: false,
      currentPage: 1,
      selectedAction: action.payload,
    }),
    setSelectedState: (
      state: ILogsState,
      action: PayloadAction<EState | undefined>
    ): ILogsState => ({
      ...state,
      selectedLogs: [],
      isSelectAll: false,
      currentPage: 1,
      taskState: action.payload,
    }),
    setCurrentPage: (state: ILogsState, action: PayloadAction<number>): ILogsState => {
      return {
        ...state,
        selectedLogs: [],
        isSelectAll: false,
        currentPage: action.payload,
      };
    },
    clearSelectedLogs: (state: ILogsState): ILogsState => ({
      ...state,
      selectedLogs: [],
      isSelectAll: false,
    }),
    setTaskId: (state: ILogsState, action: PayloadAction<string>) => ({
      ...state,
      taskId: action.payload,
    }),
    setSort: (
      state: ILogsState,
      action: PayloadAction<{ sortBy: ELogsSortParams; sortDirection: ESort }>
    ) =>
      state.sortBy === action.payload.sortBy &&
      state.sortDirection === action.payload.sortDirection
        ? {
            ...state,
            sortBy: initialState.sortBy,
            sortDirection: initialState.sortDirection,
          }
        : {
            ...state,
            sortBy: action.payload.sortBy,
            sortDirection: action.payload.sortDirection,
          },
    reset: (): ILogsState => initialState,
  },
  extraReducers: {
    ...createThunkReducers<ILogsState>(getLogs, RequestType.get),
    ...createThunkReducers<ILogsState>(deleteLogs, RequestType.get, 'deleteLoading'),
    ...createThunkReducers<ILogsState>(
      reprocessLogs,
      RequestType.get,
      'reprocessLoading'
    ),
  },
});

export const {
  setSelectedAction,
  setSelectedState,
  toggleSelectedLog,
  toggleSelectAllLogs,
  toggleSelectVisibleLogs,
  setDateRange,
  setCurrentPage,
  clearSelectedLogs,
  reset,
  setTaskId,
  setSort,
} = logsSlice.actions;

export default logsSlice.reducer;
