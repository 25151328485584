import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import authReducer from './slices/auth';
import companiesReducer from './slices/companies';
import dashboardReducer from './slices/dashboard';
import userReducer from './slices/user/userSlice';
import languageReducer from './slices/language/languageSlice';
import homepageReducer from './slices/homepage/homepageSlice';
import marketplaceReducer from './slices/marketplace/marketplaceSlice';
import notificationsReducer from './slices/notifications';
import connectorReduser from './slices/connectors';

const rootReducer = combineReducers({
  auth: authReducer,
  companies: companiesReducer,
  dashboard: dashboardReducer,
  language: languageReducer,
  toastr: toastrReducer,
  user: userReducer,
  homepage: homepageReducer,
  marketplace: marketplaceReducer,
  notifications: notificationsReducer,
  connectors: connectorReduser,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
