import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card } from '@blueprintjs/core';
// Components
import MarketplaceIntegrationTeaserSmall from './MarketplaceIntegrationTeaserSmall';
import Button, { ButtonSize } from '../../../../components/button/Button';
// Types
import { IMarketPlaceIntegration } from '../../../../services/MarketplaceService';
import { Row, Col } from 'react-bootstrap';
// Actions
import { selectIntegration } from '../../../../redux/slices/marketplace/marketplaceSlice';

interface IMarketplaceIntegrationTeaserProps {
  integration: IMarketPlaceIntegration;
}

const MarketplaceIntegrationTeaser: FC<IMarketplaceIntegrationTeaserProps> = ({
  integration,
}) => {
  const { t } = useTranslation(['home']);

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(selectIntegration(integration.nid));
  }, [integration, dispatch]);

  return (
    <Card className="marketplace-integration-teaser">
      <Row className="wrapper">
        <MarketplaceIntegrationTeaserSmall integration={integration} />
        <Col md={2} className="select-integration">
          <Button size={ButtonSize.small} onClick={handleClick}>
            {t('buttons.Select')}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
export default MarketplaceIntegrationTeaser;
