import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestType } from '../../../../services/BaseApiService';
// Types
import { RequestStatus } from '../../types';
import { SignupState } from './types';
// Utils
import { createThunkReducers } from '../../utils';
// Thunk
import { submitSignup, resendCode, getSignupText } from './thunk';

const initialState: SignupState = {
  error: null,
  formErrors: [],
  submit: RequestStatus.idle,
  loading: RequestStatus.idle,
  currentStep: 0,
  registration_key: '',
  textLoading: RequestStatus.idle,
  email: '',
  cvr: '',
  first_name: '',
  last_name: '',
};

const signupSlice = createSlice({
  name: 'auth.signup',
  initialState,
  reducers: {
    setIntegrationName: (state: SignupState, action: PayloadAction<string>) => ({
      ...state,
      integrationName: action.payload,
    }),
    setFormValues: (
      state: SignupState,
      action: PayloadAction<{
        email?: string;
        cvr?: string;
        first_name?: string;
        last_name?: string;
        company_name?: string;
      }>
    ): SignupState => {
      const { email, cvr, first_name, last_name, company_name } = action.payload;

      return {
        ...state,
        email: email || state.email,
        cvr: cvr || state.cvr,
        first_name: first_name || state.first_name,
        last_name: last_name || state.last_name,
        company: company_name ? { company_name, vat_number: '' } : state.company,
      };
    },
    reset: (): SignupState => initialState,
  },
  extraReducers: {
    ...createThunkReducers<SignupState>(submitSignup, RequestType.post),
    ...createThunkReducers<SignupState>(resendCode, RequestType.post, 'loading'),
    ...createThunkReducers<SignupState>(getSignupText, RequestType.put, 'textLoading'),
    [String(getSignupText.rejected)]: (state: SignupState): SignupState => ({
      ...state,
      textLoading: RequestStatus.rejected,
    }),
    [String(submitSignup.rejected)]: (state, action) => {
      return {
        ...state,
        submit: RequestStatus.rejected,
        formErrors: action.payload.errors,
        error: action.error,
      };
    },
    [String(submitSignup.fulfilled)]: (state, action) => {
      const { registration_key, company, integration } = action.payload;
      return {
        ...state,
        formErrors: [],
        submit: RequestStatus.fulfilled,
        currentStep: state.currentStep + 1,
        registration_key,
        company,
        integration,
      };
    },
  },
});

export const { reset, setIntegrationName, setFormValues } = signupSlice.actions;

export default signupSlice.reducer;
