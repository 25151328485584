import { RequestType } from 'src/services/BaseApiService';
import { IValueKey } from '../components/generic-selector/GenericSelector';
import { EState, IImageUploadType } from './api-Types';
import { ENotificationTypes } from './enums';

export interface IDashboardLog {
  // componentType:string;
  id: string;
  state: EState;
  action: string;
  date: string;
  error: string;
  selected: boolean;
  output: string;
  detail: any;
}

export type IDynamicLayout = object | object[] | string[] | string;

export interface IDashboardTableRow {
  id: string;
  values: {
    [key: string]: any;
  };
}

// tslint:disable-next-line:no-empty-interface
export interface ConnectorAction extends IValueKey {}
// tslint:disable-next-line:no-empty-interface
export interface LinkTypesOptions extends IValueKey {}

//#endregion

export interface INotification {
  id: string;
  read: boolean;
  title: string;
  description: string;
  date: string;
  timestamp: number;
  link?: string;
  task_id?: string;
  type: ENotificationTypes;
}

export interface IFormNotification {
  content: string;
  type: ENotificationTypes;
}

export enum EConnectorAuthTypes {
  basic_auth = 'basic',
  api_key = 'apiKey',
  oauth2 = 'oauth2',
  bearer = 'Bearer',
}

export enum EConnectorAuthParams {
  'header' = 'header',
  'query' = 'query',
  'path' = 'path',
}

export interface IConnectorFormField {
  type: string;
  key: string;
  label: string;
  validation: string;
}

export interface ISecurityScheme {
  type: EConnectorAuthTypes;
  title: string;
  in?: EConnectorAuthParams;

  name?: string;
  value?: string;

  username?: string;
  password?: string;
}

export interface ISchema {
  type: 'string';
}
export interface IConnectorResponse {
  statusCode: number;
  description: string;
}

export interface IConnectorRequestParameter {
  name: string;
  in: EConnectorAuthParams.query | EConnectorAuthParams.path;
  required: boolean;
  schema: ISchema;
}

export interface IRequestContentType {
  type: string;
  schema: ISchema;
}
export interface IConnectorRequest {
  method?: RequestType;
  tags: string[];
  operationId: string;
  responses: IConnectorResponse[];
  parameters?: IConnectorRequestParameter[];
  requestBody?: {
    content: IRequestContentType[];
  };
  security: number[];
  useGlobalSecurity: boolean;
}

export type IConnectorEndpoint = {
  requests: IConnectorRequest[];
  path: string;
};

export interface IConnectorServer {
  url: string;
}

export interface IConnectorTag {
  name: string;
}
export interface CreateConnectorFormData {
  title: string;
  description: string;
  logo?: IImageUploadType;
  default_namespace: string;
  namespace: string;
  form: IConnectorFormField[];
  servers: IConnectorServer[];
  tags: IConnectorTag[];
  securitySchemes: ISecurityScheme[];
  security: number[];
  endpoints: IConnectorEndpoint[];
}
