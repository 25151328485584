import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { toastr } from 'react-redux-toastr';
import { Alert, Icon, Intent } from '@blueprintjs/core';
import { Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Icons
import GarbageIcon from '../../../../components/icons/GarbageIcon';

// Components
import useAlert from '../../../../utils/useAlert';
import LinkItem from './link-item/LinkItem';
import GenericSelector, {
  IValueKey,
} from '../../../../components/generic-selector/GenericSelector';
import Button, { ButtonSize } from '../../../../components/button/Button';

// Types
import {
  LINKS_PER_PAGE,
  toggleSelectAllLinks,
} from '../../../../redux/slices/dashboard/links/linksSlice';
import { EMassActions, ILinkItem } from '../../../../redux/slices/dashboard/links/types';
import { RequestStatus } from '../../../../redux/slices/types';

import {
  setCurrentPage,
  setSelectedAction,
  toggleSelectedLink,
  toggleSelectVisibleLinks,
  setSort,
} from '../../../../redux/slices/dashboard/links/linksSlice';

// Selectors
import { selectLinks } from '../../../../redux/slices/dashboard/links/selectors';

// Utils
import stringifyStateError from '../../../../utils/stringifyStateError';
import { ITableRowProps } from 'src/components/table/TableRow';
import Table from 'src/components/table/Table';

interface ILinksTableProps {
  onLinkDelete: (id: string[]) => void;
  onLinksUpdate: () => void;
  onLinkEdit: (link: ILinkItem) => void;
}

const LinksTable: FC<ILinksTableProps> = ({
  onLinkDelete,
  onLinksUpdate,
  onLinkEdit,
}) => {
  const {
    data,
    table,
    loading,
    selectedLinks,
    isSelectAll,
    currentPage,
    selectedAction,
    optionsLoading,
    massActionLoading,
    error,
    sortBy,
    sortDirection,
  } = useSelector(selectLinks);

  const isSelected = !!selectedLinks.length;
  const isLoading =
    loading === RequestStatus.pending || optionsLoading === RequestStatus.pending;

  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();

  const options = Object.entries(EMassActions).map(([Key, Value]) => ({
    Key,
    Value: t(`selects.${Value.charAt(0).toUpperCase() + Value.slice(1)}`),
  })) as IValueKey[];

  const selectedActionDisplay = useMemo(
    () => options.filter((option) => option.Key === selectedAction),
    [selectedAction, options]
  );

  // Handlers
  const handleLinkSelect = (id: string) => {
    dispatch(toggleSelectedLink(id));
  };

  const handleActionSelected = ([option]: IValueKey[]) => {
    dispatch(setSelectedAction((option?.Key as EMassActions) ?? undefined));
  };

  const handleLinkDelete = async (ids: string[] = []) => {
    onLinkDelete(ids);
  };

  const handleActionSubmit = useCallback(async () => {
    onLinksUpdate();
  }, [onLinksUpdate]);

  const handleLinkEdit = async (link: ILinkItem) => {
    onLinkEdit(link);
  };

  const handleSelectVisible = () => {
    dispatch(toggleSelectVisibleLinks());
  };

  const handleSelectAll = () => {
    dispatch(toggleSelectAllLinks());
  };

  const handlePageClick = (page) => {
    dispatch(setCurrentPage(page.selected + 1));
  };

  const handleSort = (field, direction) => {
    dispatch(setSort({ sortBy: field, sortDirection: direction }));
  };

  // Alert States
  const deleteOneAlert = useAlert<string[]>({ callback: handleLinkDelete });
  const updateManyAlert = useAlert<string[]>({ callback: handleActionSubmit });

  useEffect(() => {
    if (error && massActionLoading === RequestStatus.rejected) {
      toastr.error(
        t('alert.Failed'),
        t('alert.Error action', {
          action: selectedAction,
          error: stringifyStateError(error),
        })
      );
    }
  }, [t, error, massActionLoading, selectedAction]);

  const renderRow = (props: ITableRowProps) => {
    return (
      <LinkItem
        {...props}
        actions={
          <>
            <span
              onClick={() => {
                handleLinkEdit({ ...props.data });
              }}
            >
              <Icon icon="edit" iconSize={Icon.SIZE_LARGE} />
            </span>
            <span onClick={() => deleteOneAlert.onOpen([props.data.id])}>
              <Icon icon={<GarbageIcon />} iconSize={Icon.SIZE_STANDARD} />
            </span>
          </>
        }
      />
    );
  };

  return (
    <div className="links-table">
      <Row hidden={!table.columns?.length} className="links-table-mass-actions">
        <GenericSelector
          isMultiSelector={false}
          disabled={!isSelected || massActionLoading === RequestStatus.pending}
          items={options}
          selectorText={t('inputs.Mass actions')}
          onValueChanged={handleActionSelected}
          selectedItem={selectedActionDisplay}
        />

        <Button
          disabled={
            !selectedAction || !isSelected || massActionLoading === RequestStatus.pending
          }
          onClick={() => {
            updateManyAlert.onOpen();
          }}
          size={ButtonSize.small}
        >
          {t('buttons.Submit')}
        </Button>
      </Row>
      <Table
        isLoading={isLoading}
        onSelectVisible={handleSelectVisible}
        onSelectAll={handleSelectAll}
        onSelect={handleLinkSelect}
        selected={selectedLinks}
        isSelectAll={isSelectAll}
        rows={data.links}
        columns={table.columns ?? []}
        onPageClick={handlePageClick}
        onSortChange={handleSort}
        total={data?.totalCount ?? 0}
        itemsPerPage={LINKS_PER_PAGE}
        currentPage={currentPage - 1}
        rowComponent={renderRow}
        nonIDealStateTitle={t('No links found')}
        activeSort={
          sortBy && sortDirection ? { sortBy, direction: sortDirection } : undefined
        }
      />
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={updateManyAlert.isOpen}
        onCancel={updateManyAlert.onClose}
        onConfirm={updateManyAlert.onSubmit}
      >
        <p>
          {t(
            'alert.Mass action',
            selectedLinks.length > 1 || isSelectAll
              ? {
                  count: isSelectAll ? data.totalCount : selectedLinks.length,
                  target: 'links',
                  action: selectedAction,
                }
              : { target: 'link', action: selectedAction }
          )}
        </p>
      </Alert>
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={deleteOneAlert.isOpen}
        onCancel={deleteOneAlert.onClose}
        onConfirm={deleteOneAlert.onSubmit}
      >
        <p>{t('alert.Delete', { target: 'link' })}</p>
      </Alert>
    </div>
  );
};

export default LinksTable;
