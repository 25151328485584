import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateIntegrationStatus } from '../../../actions/system-actions';
// Types
import { RequestStatus } from '../../types';
import { CompaniesListState } from './types';
import { IConnectorResultSuccess } from '../selected-company/types';
import { IBuildIntegrationSuccessResult } from '../../marketplace/types';
// Utils
import { createThunkReducers } from '../../utils';
// Thunks
import { getCompanies } from './thunks';
// Actions
import updateIntegration from '../selected-company/updateIntegration';
import { buildIntegration } from '../../marketplace/thunk';

const initialState: CompaniesListState = {
  error: null,
  loading: RequestStatus.idle,
  data: [],
};

const companiesListSlice = createSlice({
  name: 'companies.list',
  initialState,
  reducers: {
    reset: (): CompaniesListState => initialState,
  },
  extraReducers: {
    ...createThunkReducers(getCompanies),
    [String(updateIntegrationStatus)]: (
      state: CompaniesListState,
      action: PayloadAction<IConnectorResultSuccess>
    ): CompaniesListState => {
      const data = state.data.map((company) => {
        const { status, connectorId } = action.payload;

        return {
          ...company,
          field_integrations: updateIntegration(company.field_integrations, connectorId, {
            status,
          }),
        };
      });

      return {
        ...state,
        data,
      };
    },
    [String(buildIntegration.fulfilled)]: (
      state: CompaniesListState,
      action: PayloadAction<IBuildIntegrationSuccessResult>
    ): CompaniesListState => {
      const { data: newIntegration, company_id } = action.payload;

      const data = state.data.map((company) => {
        if (company.nid === company_id) {
          return {
            ...company,
            field_integrations: [...company.field_integrations, newIntegration],
          };
        }
        return company;
      });
      return { ...state, data };
    },
  },
});

export const { reset } = companiesListSlice.actions;

export default companiesListSlice.reducer;
