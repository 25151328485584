import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

// Selectors
import {
  selectComponent,
  selectAvailableComponentsData,
} from '../../../redux/slices/dashboard/components/selectors';
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';

// Actions
import { getComponentSettings } from '../../../redux/slices/dashboard/components/thunks';
import { unsetComponent } from 'src/redux/slices/dashboard/components/componentsSlice';

import { initialState } from '../../../redux/slices/dashboard/components/componentItemSlice';

// Types
import { EDashboardComponents } from '../../../types/enums';
import { IComponentWithSettings } from '../../../redux/slices/dashboard/components/types';
import { RequestStatus } from '../../../redux/slices/types';

// Utils
import stringifyStateError from '../../../utils/stringifyStateError';
import useFormNotification from 'src/utils/useFormNotification';

const useComponentData = (componentKey: EDashboardComponents): IComponentWithSettings => {
  const componentsWithSetting = useSelector(selectComponent(componentKey));

  const data = useSelector(selectAvailableComponentsData(componentKey));

  const { data: connector } = useSelector(selectConnectorState);

  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard']);
  const { showNotification } = useFormNotification();

  useEffect(() => {
    if (data && connector?.nid) {
      dispatch(
        getComponentSettings({
          connectorId: connector.nid,
          component: data,
        })
      );
    }

    /* eslint react-hooks/exhaustive-deps: 0*/
    return () => {
      if (componentsWithSetting?.component?.key) {
        dispatch(unsetComponent(componentsWithSetting.component.key));
      }
    };
  }, [connector?.nid]);

  useEffect(() => {
    if (
      componentsWithSetting?.error &&
      componentsWithSetting?.submit === RequestStatus.rejected
    ) {
      const message = stringifyStateError(componentsWithSetting?.error);

      toastr.error(
        t('alert.Failed'),
        t('alert.An error occurred while fetching component', {
          component: componentsWithSetting?.component?.name,
          message,
        })
      );
    }

    if (
      !componentsWithSetting?.error &&
      componentsWithSetting?.submit === RequestStatus.fulfilled
    ) {
      if (componentsWithSetting?.notification) {
        showNotification(componentsWithSetting.notification);
      } else {
        toastr.success(
          t('alert.Success'),
          t('alert.Component updated successfully', {
            component: componentsWithSetting?.component?.name,
          })
        );
      }
    }
  }, [componentsWithSetting?.error, componentsWithSetting?.submit, t]);

  return componentsWithSetting ?? initialState;
};

export default useComponentData;
