import yup from '../../translations/yup/yupLocale';

const validationSchema = yup.object({
  title: yup.string().min(3).required().label('Company Name'),
  field_invoice_email: yup.string().email().required().label('Invoice Email'),
  field_company_vat: yup.string().required().label('VAT Number'),
  field_company_logo: yup
    .object({
      file: yup.string().label('Company Logo'),
    })
    .nullable(),
  field_company_address: yup.object({
    country: yup.string().required().label('Country'),
    locality: yup.string().required().min(3).label('City'),
    postal_code: yup.string().required().min(3).label('Zip Code'),
    thoroughfare: yup.string().min(3).required().label('Address'),
  }),
});

export default validationSchema;
