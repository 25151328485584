import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import { Button } from '@blueprintjs/core';
import sub from 'date-fns/sub';
// Components
import Panel from '../../../components/panel/Panel';
import GenericSelector, {
  IValueKey,
} from 'src/components/generic-selector/GenericSelector';
import TimeFrameSelect from './time-frame-select/TimeFrameSelect';
import ActionsPieChart from './charts/ActionsPieChart';
import BackupIcon from 'src/components/icons/BackupIcon';
// Actions
import { getData } from '../../../redux/slices/dashboard/charts/thunk';
import { reset as resetState } from '../../../redux/slices/dashboard/charts/chartsSlice';
// Selectors
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
import { selectDashboardCharts } from '../../../redux/slices/dashboard/charts/selectors';
// Types
import { RequestStatus } from '../../../redux/slices/types';
import { IDateRange } from 'src/redux/slices/dashboard/charts/types';
// Utils
import useTaskStates from '../utils/useTaskStates';
import { filterByDateRange } from './charts/utils';
import './styles.scss';

const options = [
  { hours: 1 },
  { hours: 6 },
  { hours: 12 },
  { days: 1 },
  { weeks: 1 },
  { months: 1 },
];

const ActionsPieChartContainer: FC = () => {
  const { data: connector } = useSelector(selectConnectorState);
  const { data, loading } = useSelector(selectDashboardCharts);

  const [dateRange, setDateRange] = useState<IDateRange>([
    sub(new Date(), { hours: 1 }),
    new Date(),
  ]);

  const [selectedState, setSelectedState] = useState<IValueKey | undefined>(undefined);

  const pieData = useMemo(
    () =>
      filterByDateRange(data.logs, dateRange).filter((log) =>
        selectedState?.Key ? log.state === selectedState?.Key : true
      ),
    [data, dateRange, selectedState]
  );

  const states = useTaskStates();
  const isLoading = loading === RequestStatus.pending;
  const [startTime] = dateRange;

  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();

  // Handlers
  const handleSateSelect = ([value]: IValueKey[] = []) => {
    setSelectedState(value);
  };
  const handleDateSelect = (date) => {
    setDateRange(date);
  };

  const handleUpdate = useCallback(() => {
    if (connector?.nid) {
      dispatch(getData({ connectorId: connector.nid }));
    }
  }, [dispatch, connector]);

  useEffect(() => {
    if (connector?.nid) {
      dispatch(getData({ connectorId: connector.nid }));
    }
  }, [connector, dispatch]);

  // Component did unmount
  useEffect(
    () => () => {
      dispatch(resetState());
    },
    [dispatch]
  );

  return (
    <>
      <Row className="diagrams-panel">
        <Panel>
          <TimeFrameSelect
            onChange={handleDateSelect}
            selectedDate={startTime ?? undefined}
            options={options}
          />
          <GenericSelector
            selectorText={t('inputs.All States')}
            isMultiSelector={false}
            onValueChanged={handleSateSelect}
            items={states}
            selectedItem={selectedState ? [selectedState] : undefined}
          />
          <Button
            className="action-button"
            icon={<BackupIcon />}
            onClick={handleUpdate}
          />
        </Panel>
      </Row>
      <ActionsPieChart isLoading={isLoading} data={pieData} />
    </>
  );
};

export const mapActionsPieChartProps = (props) => props;

export default ActionsPieChartContainer;
