import BaseApiService, { RequestType } from './BaseApiService';
import { ID } from '../types/api-Types';
import { LinkTypesOptions } from 'src/types/internal-Types';
import { ESort } from 'src/types/enums';

// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';

class RelationshipManagerService extends BaseApiService {
  getLinks = async ({
    connectorId,
    page = 1,
    limit = 10,
    link_type,
    keyword,
    sortBy,
    sortDirection,
  }: IGetLinksParams) => {
    const sort =
      sortBy && sortDirection
        ? {
            [sortBy]: sortDirection,
          }
        : undefined;

    const input = {
      op: 'link',
      ...(keyword?.length ? { action: 'search' } : {}),
      page,
      limit,
      query: {
        link_type,
        ...(keyword?.length ? { keyword } : {}),
      },
      sort,
    };

    const { data } = await this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input,
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
    try {
      const links = data.output.links.map((link) => {
        const { _id, ...rest } = link;
        const { $oid } = _id as ID;
        return { id: $oid, ...rest };
      });
      return { links, totalCount: data.output.total, error: data.output.error };
    } catch (error) {
      return { links: [], totalCount: 0, error };
    }
  };

  getLinksTypeOptions = async (connectorId: string) => {
    const { data } = await this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'link_types',
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });

    if (data?.output?.status) {
      return { error: data.output.status };
    }

    const mapLinkTypes = (output, prop: string) => {
      const result = {};
      for (const key in output) {
        if (output.hasOwnProperty(key)) {
          result[key] = output[key][prop];
        }
      }
      return result;
    };

    const options = Object.entries((data?.output as IGetLinkTypes) || {}).map(
      ([Key, Value]) => {
        return { Key, Value: Value?.label } as LinkTypesOptions;
      }
    );

    const linksTypes = mapLinkTypes(data.output, 'columns');

    const forms = mapLinkTypes(data.output, 'form');

    return { options, error: data.output.error, linksTypes, forms };
  };

  updateLinks = ({
    connectorId,
    linkIds,
    action,
    link_type,
    keyword,
  }: IUpdateLinksParams) => {
    const query = {
      ...(linkIds.length
        ? { _id_in: linkIds }
        : {
            ...(link_type ? { link_type } : {}),
            ...(keyword?.length && { keyword }),
          }),
    };
    return this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'link',
          action,
          query,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
  };

  getEditForm = ({ connectorId, linkId, link_type, columns }: IGetEditFormParams) =>
    this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'link_form',
          link_type,
          _id: linkId,
          ...columns,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });

  submitForm = (callback: string = '', data: any, connectorId: string) =>
    this.request({
      url: callback,
      type: RequestType.put,
      data: { ...data, silent: !IntegrationDebugSettingsService.get(connectorId) },
    });
}

export interface IGetLinksParams {
  connectorId: string;
  page?: number;
  limit?: number;
  link_type: string;
  keyword?: string;
  sortBy?: string;
  sortDirection?: ESort;
}

export interface IUpdateLinksParams {
  connectorId: string;
  linkIds: string[];
  action: string;
  link_type?: string;
  keyword?: string;
}

export interface IGetEditFormParams {
  connectorId: string;
  linkId: string;
  link_type: string;
  columns: {
    [key: string]: any;
  };
}

export interface IGetLinksResult {
  links: any[];
  totalCount: number;
}

interface IGetLinkTypes {
  [key: string]: {
    label: string;
    columns: IColumn;
    form: any[];
  };
}
interface IColumn {
  [key: string]: {
    label: string;
  };
}
export interface ILinkTypes {
  [key: string]: IColumn;
}
export interface IFormTypes {
  [key: string]: any; // dynamic layout
}
export default new RelationshipManagerService('rest/connector');
