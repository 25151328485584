import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { RequestType } from '../../../../services/BaseApiService';
import { RequestStatus } from '../../types';
import { INotificationsTableState, IChangeTableNotificationStatusResult } from './types';
// Utils
import { createThunkReducers } from '../../utils';
// Thunks
import {
  getNotifications,
  deleteNotifications,
  changeNotificationsStatus,
} from './thunks';
import { changeNotificationsStatus as changeNotificationsStatusFromList } from '../list/thunks';

const initialState: INotificationsTableState = {
  data: {
    notifications: [],
    total: 0,
  },
  selected: [],
  isSelectAll: false,
  error: null,
  deleteError: null,
  changeStatusError: null,
  loading: RequestStatus.idle,
  submit: RequestStatus.idle,
  deleteLoading: RequestStatus.idle,
  currentPage: 1,
  unreadFilter: false,
};

const notificationsTableSlice = createSlice({
  name: 'notifications.table',
  initialState,
  reducers: {
    resetTable: (): INotificationsTableState => initialState,
    toggleSelectedNotitfication: (
      state: INotificationsTableState,
      action: PayloadAction<string>
    ): INotificationsTableState => {
      const { payload: id } = action;

      const selected = state.selected.includes(id)
        ? state.selected.filter((selectedId) => selectedId !== id)
        : [...state.selected, id];

      return {
        ...state,
        selected,
        isSelectAll:
          state.data.notifications.length === selected.length ? state.isSelectAll : false,
      };
    },
    toggleSelectAllVisibleNotifications: (
      state: INotificationsTableState
    ): INotificationsTableState => {
      const { selected, data } = state;

      return {
        ...state,
        selected:
          selected.length === data.notifications.length
            ? []
            : data.notifications.map(({ id }) => id),
      };
    },
    toggleSelectAllNotifications: (
      state: INotificationsTableState
    ): INotificationsTableState => ({ ...state, isSelectAll: !state.isSelectAll }),
    clearSelectedNotifications: (
      state: INotificationsTableState
    ): INotificationsTableState => ({
      ...state,
      selected: [],
      isSelectAll: false,
    }),
    setCurrentPage: (
      state: INotificationsTableState,
      action: PayloadAction<number>
    ): INotificationsTableState => {
      return {
        ...state,
        selected: [],
        currentPage: action.payload,
      };
    },
    setShearchKey: (
      state: INotificationsTableState,
      action: PayloadAction<string>
    ): INotificationsTableState => {
      return { ...state, searchKey: action.payload, currentPage: 1 };
    },
    toggleFilter: (state: INotificationsTableState): INotificationsTableState => {
      return {
        ...state,
        unreadFilter: !state.unreadFilter,
        currentPage: 1,
        submit: RequestStatus.idle,
      };
    },
    resetFilters: (state: INotificationsTableState): INotificationsTableState => {
      return {
        ...state,
        searchKey: undefined,
        currentPage: 1,
        selected: [],
        unreadFilter: false,
      };
    },
  },
  extraReducers: {
    ...createThunkReducers<INotificationsTableState>(getNotifications, RequestType.get),
    ...createThunkReducers(
      deleteNotifications,
      RequestType.put,
      'deleteLoading',
      'deleteError'
    ),
    ...createThunkReducers<INotificationsTableState>(
      changeNotificationsStatus,
      RequestType.put,
      'submit',
      'changeStatusError'
    ),
    [String(changeNotificationsStatus.fulfilled)]: (
      state: INotificationsTableState,
      action: PayloadAction<IChangeTableNotificationStatusResult>
    ) => {
      return {
        ...state,
        submit: RequestStatus.fulfilled,
        changeStatusError: null,
        data: {
          total: state.data.total,
          notifications: state.data.notifications.map((notification) =>
            !action.payload.updatedItems.length ||
            action.payload.updatedItems.find((item) => item.id === notification.id)
              ? { ...notification, read: action.payload.isRead }
              : { ...notification }
          ),
        },
      };
    },
    [String(changeNotificationsStatusFromList.fulfilled)]: (
      state: INotificationsTableState,
      action: PayloadAction<IChangeTableNotificationStatusResult>
    ) => {
      return {
        ...state,
        changeStatusError: null,
        data: {
          total: state.data.total,
          notifications: state.data.notifications.map((notification) =>
            action.payload.updatedItems.find((item) => item.id === notification.id)
              ? { ...notification, read: true }
              : { ...notification }
          ),
        },
      };
    },
  },
});

export const {
  resetTable,
  resetFilters,
  setCurrentPage,
  setShearchKey,
  toggleFilter,
  toggleSelectAllNotifications,
  toggleSelectAllVisibleNotifications,
  toggleSelectedNotitfication,
  clearSelectedNotifications,
} = notificationsTableSlice.actions;

export default notificationsTableSlice.reducer;
