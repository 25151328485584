import { EState } from '../types/api-Types';
import { ESort } from 'src/types/enums';
import { IDashboardLog } from '../types/internal-Types';
import { jsTimeToString } from '../utils/genUtils';
import BaseApiService, { IQuery, RequestType } from './BaseApiService';

// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';

class LogsService extends BaseApiService {
  get = async ({
    connectorId,
    page = 1,
    limit = 10,
    startTime,
    endTime,
    action,
    taskState,
    taskId,
    sortBy,
    sortDirection,
  }: IGetLogsParams): Promise<IGetLogsResult> => {
    const query: IQuery = {
      ...(action ? { 'task.action': action } : {}),
      ...(startTime && { created_gte: startTime.getTime() / 1000 }),
      ...(endTime && { created_lte: endTime.getTime() / 1000 }),
      ...(taskState && { 'task.state': taskState }),
      ...(taskId && { 'task.id': taskId }),
    };

    const sort =
      sortBy && sortDirection
        ? {
            [sortBy]: sortDirection,
          }
        : undefined;

    const { data } = await this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'logs',
          page,
          limit,
          query,
          sort,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });

    const logs: IDashboardLog[] = data.output.logs.map(({ task, created }) => ({
      id: task.id,
      state: task.state,
      action: task.action,
      date: jsTimeToString(created),
      error: task.error,
      output: typeof task.output === 'string' ? task.output : null,
      detail: task.detail,
    }));

    return {
      logs,
      totalCount: +data.output.total,
    };
  };

  reprocess = ({
    connectorId,
    startTime,
    endTime,
    action,
    taskState,
    taskIds,
  }: IUpdateLogsParams) => {
    const query: IQuery = {
      ...(taskIds.length
        ? { 'task.id_in': taskIds }
        : {
            ...(action ? { 'task.action': action } : {}),
            ...(startTime && { created_gte: startTime.getTime() / 1000 }),
            ...(endTime && { created_lte: endTime.getTime() / 1000 }),
            ...(taskState && { 'task.state': taskState }),
          }),
    };
    return this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'logs',
          action: 'reprocess',
          query,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
  };

  delete = ({
    connectorId,
    startTime,
    endTime,
    action,
    taskState,
    taskIds,
  }: IUpdateLogsParams) => {
    const query: IQuery = {
      ...(taskIds.length
        ? { 'task.id_in': taskIds }
        : {
            ...(action ? { 'task.action': action } : {}),
            ...(startTime && { created_gte: startTime.getTime() / 1000 }),
            ...(endTime && { created_lte: endTime.getTime() / 1000 }),
            ...(taskState && { 'task.state': taskState }),
          }),
    };
    return this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'logs',
          action: 'delete',
          query,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
  };
}

export enum ELogsSortParams {
  state = 'task.state',
  date = 'created',
  action = 'task.action',
  error = 'task.error',
}

export interface IGetLogsParams {
  connectorId: string;
  page?: number;
  limit?: number;
  startTime?: Date;
  endTime?: Date;
  action?: string;
  taskState?: EState;
  taskId?: string;
  sortBy?: ELogsSortParams;
  sortDirection?: ESort;
}

export interface IUpdateLogsParams {
  connectorId: string;
  startTime?: Date;
  endTime?: Date;
  action?: string;
  taskState?: EState;
  taskIds: string[];
}

export interface IGetLogsResult {
  logs: IDashboardLog[];
  totalCount: number;
}

export default new LogsService('rest/connector');
