import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import ComponentService from '../../../../services/ComponentService';
import { IComponent } from '../../../../types/api-Types';

// Types
import { ComponentActions, ENotificationTypes } from '../../../../types/enums';
import {
  IAppendComponentActionPayload,
  IGetComponentSettingsResult,
  IUpdateComponentSettingsPayload,
} from './types';
import { IFormNotification } from 'src/types/internal-Types';

export const getComponentSettings = createAsyncThunk<
  IGetComponentSettingsResult,
  IAppendComponentActionPayload
>(
  'dashboard.components/getComponentSettings',
  async ({ connectorId, component }, { rejectWithValue }) => {
    try {
      const { data } = await ComponentService.get(
        connectorId,
        component.key,
        ComponentActions.settings
      );

      const { data: statusData } = await ComponentService.get(
        connectorId,
        component.key,
        ComponentActions.check
      );

      return {
        connectorId,
        component,
        settings: data.output as any,
        componentStatus: statusData.output,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue({ component, error: error.response });
    }
  }
);

export const updateComponentSettings = createAsyncThunk<
  { component: IComponent; notification?: IFormNotification },
  IUpdateComponentSettingsPayload
>(
  'dashboard.components/updateComponentSettings',
  async ({ connectorId, component, settings }, { rejectWithValue }) => {
    try {
      const { data } = await ComponentService.update(
        connectorId,
        component.key,
        settings
      );

      if (data?.output?.error) {
        return rejectWithValue({ component, data: data?.output?.error });
      }

      if (data?.output?.notification?.type === ENotificationTypes.error) {
        return rejectWithValue({ component, data: data?.output?.notification.content });
      }

      if (data?.output?.notification) {
        return {
          component,
          notification: data?.output?.notification,
        };
      }

      return {
        component,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue({ component, ...error.response });
    }
  }
);
