import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { Popover, Card, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
// Selectors
import { selectNotificationsList } from '../../redux/slices/notifications/list/selectors';
import { selectCurrentUser } from 'src/redux/slices/user/selectors';
import { ITEMS_PER_PAGE } from '../../redux/slices/notifications/consts';
import { selectNotifications } from 'src/redux/slices/notifications/table/selectors';
// Actions
import { getNotificationsList } from 'src/redux/slices/notifications/list/thunks';
// Components
import User from '../Wedoio/User/User';
import NotificationsList from './list/NotificationsList';
import CloseIcon from '../icons/CloseIcon';
import IntentIcon from '../intent-icon/IntentIcon';
// Utils
import stringifyStateError from '../../utils/stringifyStateError';
import useBrowserNotifications from './useBrowserNotifications';
import useChangeNotificationStatus from './useChangeNotificationStatus';
// Types
import { RequestStatus } from '../../redux/slices/types';
import { EUser } from '../../types/enums';
// Styles
import './styles.scss';

const UPDATE_INTERVAL = 300000;

const UserNotifications: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { loading, data, changeStatusError, total, submit, lastRecieved } = useSelector(
    selectNotificationsList
  );
  const { deleteLoading, submit: tableUpdated } = useSelector(selectNotifications);
  const user = useSelector(selectCurrentUser);

  const statusUpdates =
    tableUpdated === RequestStatus.fulfilled ||
    submit === RequestStatus.fulfilled ||
    deleteLoading === RequestStatus.fulfilled;

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const isLoaded = useRef<number>(0);

  const dispatch = useDispatch();
  const { t } = useTranslation(['notifications']);

  const { showNotification } = useBrowserNotifications();
  const handleClickItem = useChangeNotificationStatus();

  const removeInterval = () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  const fetch = useCallback(() => {
    if (user?.data?.uid) {
      dispatch(getNotificationsList({ userId: user.data.uid }));
    }
  }, [user, dispatch]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (changeStatusError) {
      toastr.error(
        t('alert.Failed'),
        t('alert.Failed to update', {
          error: stringifyStateError(changeStatusError),
        })
      );
    }
  }, [changeStatusError, t]);

  useEffect(() => {
    if (submit === RequestStatus.fulfilled) {
      toastr.success(t('alert.Success'), t('alert.Success update'));
    }
  }, [submit, t]);

  useEffect(() => {
    if (loading === RequestStatus.fulfilled) {
      isLoaded.current++;
    }
  }, [loading]);

  useEffect(() => {
    if (user?.data?.uid) {
      fetch();
      removeInterval();
      interval.current = setInterval(() => {
        fetch();
      }, UPDATE_INTERVAL);
    }
  }, [dispatch, user, fetch]);

  useEffect(() => {
    if (
      statusUpdates &&
      user.data?.uid &&
      data.length < (ITEMS_PER_PAGE < total ? ITEMS_PER_PAGE : total)
    ) {
      dispatch(getNotificationsList({ userId: user.data.uid }));
    }
  }, [statusUpdates, user, dispatch, data.length, total]);

  useEffect(() => {
    if (lastRecieved.length && isLoaded.current >= 2) {
      lastRecieved.forEach((item) => {
        showNotification(
          item.title,
          {
            body: `${item.description}\n${item.date}`,
            timestamp: item.timestamp,
            tag: item.id,
          },
          () => {
            window.parent.focus();
            handleClickItem(item);
          }
        );
      });
    }
  }, [lastRecieved, showNotification, handleClickItem]);

  // Component unmount
  useEffect(() => removeInterval, []);

  return (
    <Popover
      isOpen={isOpen}
      placement="bottom-end"
      canEscapeKeyClose
      minimal
      popoverClassName="user-notifications-popover"
      onInteraction={(isPopoverOpen) => {
        setIsOpen(isPopoverOpen);
      }}
    >
      <div className="user-notifications">
        {!!data.length && (
          <IntentIcon intent={Intent.DANGER} className="notification-unread-indicator" />
        )}
        <User view={EUser.TEASER_XS} />
      </div>
      <Card className="notifications custom-card">
        <h4>{t('titles.Notifications')}</h4>
        <span onClick={handleClose} className="notifications-close">
          <CloseIcon />
        </span>
        <NotificationsList onItemClick={handleClickItem} />
        <Link to="/notifications" className="notifications-link" onClick={handleClose}>
          {t('buttons.See all')}
        </Link>
      </Card>
    </Popover>
  );
};

export default UserNotifications;
