import React, { FC } from 'react';
import { Card } from '@blueprintjs/core';

import './styles.scss';

const Panel: FC = ({ children }) => {
  return (
    <div className="panel-container">
      <Card className="panel-card">{children}</Card>
    </div>
  );
};

export default Panel;
