import React, { FC, useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
// Types
import { ILayout, ILayoutBreakpoints, IFluidBlock } from '../block-types';
// Components
import useEditModeContext from '../../edit-mode-context/useEditModeContext';
import CustomSwitch from '../../../../components/custom-switch/CustomSwitch';
import EditModeContextProvider from '../../../../components/dynamic-layout/edit-mode-context/EditModeContextProvider';
// Styles
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles.scss';
// Utils
import getClassName from '../../utils/getClassName';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface ILayoutProps {
  children: IFluidBlock[];
  builder: any;
  grid: ILayoutBreakpoints;
  className?: string;
}

const Layout: FC<ILayoutProps> = ({ children, builder, grid, className }) => {
  const [layoutsWithBreakepoints, setLayouts] = useState(grid);
  const [mounted, setMounted] = useState(false);

  const { isEdit, toggleEdit } = useEditModeContext();

  const { t } = useTranslation(['dynamicForm']);

  const onLayoutChange = (layout, layouts) => {
    setLayouts(layouts);
  };

  // Component did mount
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <div className="edit-layout">
        <CustomSwitch
          name={t('buttons.Edit mode')}
          onChange={() => {
            toggleEdit(!isEdit);
          }}
          checked={isEdit}
        />
      </div>
      <div className={`layout-container ${isEdit ? 'edit-active' : 'edit-inactive'}`}>
        <ResponsiveReactGridLayout
          layouts={layoutsWithBreakepoints}
          breakpoints={{ lg: 1169, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          measureBeforeMount
          useCSSTransforms={mounted}
          compactType="vertical"
          rowHeight={40}
          isDraggable={isEdit}
          isResizable={isEdit}
          onLayoutChange={onLayoutChange}
          className={className}
        >
          {children.map((props) => {
            return (
              <div key={props.i} className={getClassName(props.classes)}>
                {builder(props)}
              </div>
            );
          })}
        </ResponsiveReactGridLayout>
      </div>
    </>
  );
};

const LayoutWithEditMode: FC<ILayoutProps> = (props) => {
  return (
    <EditModeContextProvider>
      <Layout {...props} />
    </EditModeContextProvider>
  );
};

export default LayoutWithEditMode;

export const mapLayoutProps = ({ children, grid, classes }: ILayout, builder) => ({
  children,
  builder,
  grid: editGridItemProps({ lg: children }), // it will be just grid
  className: getClassName(classes),
});

const editGridItemProps = (layout) => {
  // we can`t set isDraggable,isResizable : true in json - it causes an error : when edit mode is off, we still can move the elements
  for (const key in layout) {
    if (layout.hasOwnProperty(key)) {
      layout[key] = layout[key].map(({ isNotDraggable, isNotResizable, ...rest }) => ({
        isDraggable: isNotDraggable ? false : undefined,
        isResizable: isNotResizable ? false : undefined,
        ...rest,
      }));
    }
  }
  return layout;
};
