import React, { FC } from 'react';
// Icons
import iconIntegration from '../../../../images/icon-integration.svg';
// Styles
import './styles.scss';

export enum ConnectorLogoSizes {
  small = 'small',
  middle = 'middle',
  large = 'large',
}

export interface IConnectorProps {
  src?: string;
  name: string;
  loading?: boolean;
  size?: ConnectorLogoSizes;
}

const ConnectorLogo: FC<IConnectorProps> = ({
  loading = false,
  size = ConnectorLogoSizes.small,
  src,
  name,
}) => {
  if (loading) {
    return <div className={`connector-teaser-logo ${size} bp3-skeleton`} />;
  }

  return (
    <div className={`connector-teaser-logo ${size}`}>
      <img src={src || iconIntegration} alt={name} />
    </div>
  );
};

export default ConnectorLogo;
