import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconName } from '@blueprintjs/icons';
import { EState } from 'src/types/api-Types';
import { Icon } from '@blueprintjs/core';

export interface ILogStateProps {
  state: EState;
}

interface ViewProps {
  icon: IconName;
  color: string;
  text: string;
}

const createProps = (icon, color, text): ViewProps => ({ icon, color, text });

const props = new Map<EState, ViewProps>([
  [EState.TaskCompleted, createProps('tick-circle', '#16DB93', 'Success')],
  [EState.TaskReprocessed, createProps('delete', '#E6D72D', 'Reprocessed')],
  [EState.TaskCreated, createProps('new-object', '#2EE6D6', 'Created')],
  [EState.TaskRunning, createProps('refresh', '#48AFF0', 'Running')],
  [EState.TaskFailed, createProps('error', '#F55656', 'Failed')],
]);

const defaultProps = createProps('error', '#F2952C', 'Warning');

const LogState: FC<ILogStateProps> = ({ state }) => {
  const { icon, color, text } = props.get(state) || defaultProps;

  const { t } = useTranslation(['dashboard']);

  return (
    <span>
      <Icon icon={icon} color={color} />
      {t(`titles.${text}`)}
    </span>
  );
};

export default LogState;
