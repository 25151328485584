import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Grid } from 'react-bootstrap';
import { Tabs, Tab, TabId, H2 } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
// Selectors
import { selectCurrentUser } from '../../redux/slices/user/selectors';
// Components
import User from '../..//components/Wedoio/User/User';
import CompanyListTeaserMedium from '../../components/Wedoio/Company/CompanyList/CompanyListTeaserMedium';
import CompanyCreatePage from '../company/create/CompanyCreatePage';
import CompanyEditPage from '../company/edit/CompanyEditPage';
import TabForm from './TabForm';
import usePermissions from '../../utils/check-permission/usePermissions';
import Loader from '../../components/loader/Loader';
// Types
import { EUser, UserPermissions } from '../../types/enums';
import { RequestStatus } from 'src/redux/slices/types';

// Styles
import './settings-styles.scss';

export interface ISettingsPageProps {
  tabId?: string;
  companyId?: string;
}

const SettingsPage: FC<ISettingsPageProps> = ({ companyId, tabId = 'user-edit' }) => {
  const history = useHistory();

  const { loading: userLoading, data } = useSelector(selectCurrentUser);

  const { t } = useTranslation(['settings']);

  const [canCreate] = usePermissions(UserPermissions.create_company);

  const handleTabChange = useCallback(
    (tab: TabId) => {
      history.push('/settings/' + tab);
    },
    [history]
  );

  return (
    <Loader loading={userLoading === RequestStatus.pending && !data}>
      <Grid className="settings-page-container">
        <Row className={'main-row'}>
          <Col md={8} mdOffset={2}>
            <H2>{t('titles.Settings')}</H2>
            <Tabs
              className="Tabs"
              id="TabsSettings"
              selectedTabId={tabId}
              vertical
              onChange={handleTabChange}
            >
              <Tab
                className="Tab"
                id="user-edit"
                panel={
                  <TabForm>
                    <User view={EUser.EDIT} />
                  </TabForm>
                }
                title={t('tabs.Account Details')}
              />
              <Tab
                id="companies"
                className="Tab"
                panel={
                  <TabForm>
                    {!!companyId ? (
                      <CompanyEditPage companyId={companyId} />
                    ) : (
                      <CompanyListTeaserMedium />
                    )}
                  </TabForm>
                }
                title={t('tabs.Companies')}
              />
              {canCreate && (
                <Tab
                  id="companies/create"
                  title={t('tabs.Create a company')}
                  panel={
                    <TabForm>
                      <CompanyCreatePage />
                    </TabForm>
                  }
                />
              )}
            </Tabs>
          </Col>
        </Row>
      </Grid>
    </Loader>
  );
};

export default SettingsPage;
