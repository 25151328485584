import translations from './danish.json';
import validation from './yup/danish.json';
import countriesDA from './countries/danish.json';
import connectors from './pages/connectors/danish.json';
import dashboard from './pages/dashboard/danish.json';
import notifications from './pages/notifications/danish.json';
import home from './pages/home/danish.json';
import companies from './pages/companies/danish.json';
import company from './pages/company/danish.json';
import login from './pages/login/danish.json';
import signup from './pages/signup/danish.json';
import settings from './pages/settings/danish.json';
import user from './pages/user/danish.json';

export default {
  ...translations,
  validation,
  countries: countriesDA,
  connectors,
  dashboard,
  notifications,
  home,
  companies,
  company,
  login,
  signup,
  settings,
  user,
};
