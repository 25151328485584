import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy, differenceBy } from 'lodash';
// Types
import { RequestType } from '../../../../services/BaseApiService';
import { RequestStatus } from '../../types';
import {
  INotificationsListSate,
  IChangeListNotificationStatusResult,
  IGetNotificationsListResult,
} from './types';
// Actions
import { getNotificationsList, changeNotificationsStatus } from './thunks';
import {
  changeNotificationsStatus as changeNotificationsStatusFromTable,
  deleteNotifications,
} from '../table/thunks';
// Utils
import { createThunkReducers } from '../../utils';
import {
  IChangeTableNotificationStatusResult,
  IDeleteNotificationsResult,
} from '../table/types';
import { ITEMS_PER_PAGE } from '../consts';

const initialState: INotificationsListSate = {
  data: [],
  error: null,
  loading: RequestStatus.idle,
  changeStatusError: null,
  total: 0,
  lastRecieved: [],
};

const notificationsListSlice = createSlice({
  name: 'notifications.table',
  initialState,
  reducers: {
    reset: (): INotificationsListSate => initialState,
  },
  extraReducers: {
    ...createThunkReducers<INotificationsListSate>(getNotificationsList, RequestType.get),
    ...createThunkReducers<INotificationsListSate>(
      changeNotificationsStatus,
      RequestType.put,
      'submit',
      'changeStatusError'
    ),
    [String(getNotificationsList.fulfilled)]: (
      state: INotificationsListSate,
      action: PayloadAction<IGetNotificationsListResult>
    ) => {
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
        loading: RequestStatus.fulfilled,
        error: null,
        lastRecieved:
          action.payload.total > state.total
            ? differenceBy(action.payload.data, state.data, 'id')
            : [],
      };
    },

    [String(changeNotificationsStatus.fulfilled)]: (
      state: INotificationsListSate,
      action: PayloadAction<IChangeListNotificationStatusResult>
    ) => {
      const newItems = state.data.filter((notification) =>
        action.payload.updatedItems.find((item) => item.id !== notification.id)
      );
      return {
        ...state,
        data: newItems,
        total: state.total - (state.data.length - newItems.length),
        submit: RequestStatus.fulfilled,
        changeStatusError: null,
      };
    },
    [String(deleteNotifications.fulfilled)]: (
      state: INotificationsListSate,
      action: PayloadAction<IDeleteNotificationsResult>
    ) => {
      const newItems = state.data.filter(
        (notification) => !action.payload.ids.includes(notification.id)
      );
      return {
        ...state,
        data: newItems,
        total: state.total - (state.data.length - newItems.length),
      };
    },
    [String(changeNotificationsStatusFromTable.fulfilled)]: (
      state: INotificationsListSate,
      action: PayloadAction<IChangeTableNotificationStatusResult>
    ): INotificationsListSate => {
      const { updatedItems, isRead, total } = action.payload;
      if (!updatedItems.length) {
        return {
          ...state,
          data: [],
          total: isRead ? 0 : total,
        };
      }
      if (!isRead) {
        const newItems = uniqBy([...updatedItems, ...state.data], 'id').slice(
          0,
          ITEMS_PER_PAGE
        );
        return {
          ...state,
          data: newItems,
          total: state.total + differenceBy(updatedItems, state.data, 'id').length,
        };
      } else if (isRead) {
        const newItems = state.data.filter(
          (item) => !updatedItems.find((notification) => item.id === notification.id)
        );
        return {
          ...state,
          data: newItems,
          total: state.total - (state.data.length - newItems.length),
        };
      }
      return { ...state };
    },
  },
});

export const { reset } = notificationsListSlice.actions;

export default notificationsListSlice.reducer;
