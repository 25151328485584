import React, { FC } from 'react';

// Icon
import CheckIcon from './CheckIcon';

// Styles
import './checkbox-styles.scss';

export interface IProps {
  id: string;
  checked?: boolean;
  label?: string;
  onChange: () => void;
}

const CheckboxRounded: FC<IProps> = ({
  checked = false,
  id,
  label,
  onChange,
  ...props
}: IProps) => {
  return (
    <>
      <input {...props} type="checkbox" id={id} className="rounded-checkbox" />
      <label className="custom-control-label" htmlFor={id}>
        <span>{label}</span>
        <CheckIcon />
      </label>
    </>
  );
};

export default CheckboxRounded;
