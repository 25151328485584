import { IConnectorDescription } from 'src/types/api-Types';
import { RootState } from '../../../rootReducer';
import { ConnectorsListState } from './types';

export const selectConnectorsListState = (state: RootState): ConnectorsListState => {
  const { data, loading, error } = state.connectors.list;

  return {
    data,
    loading,
    error,
  };
};

export const selectConnectorById = (id: string) => (
  state: RootState
): IConnectorDescription | undefined => {
  return state.connectors.list.data.find(({ nid }) => nid + '' === id);
};
