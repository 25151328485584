import BaseApiService, { RequestType } from './BaseApiService';
import { IValueKey } from '../components/generic-selector/GenericSelector';
class AutocompleteServise extends BaseApiService {
  private canceler;
  private cancelRequestOptions = () => {
    if (this.canceler) {
      this.canceler('');
    }
  };
  requestOptions = async (callback: string = '', query: object) => {
    this.cancelRequestOptions();

    const { data } = await this.request({
      url: callback,
      type: RequestType.put,
      data: query,
      cancelToken: new BaseApiService.cancelToken((canceler) => {
        this.canceler = canceler;
      }),
    });
    if (data?.output?.error) {
      return [];
    }
    return Object.entries(data?.output || {}).map(([Key, Value]) => {
      return { Key, Value } as IValueKey;
    });
  };
}

export default new AutocompleteServise('');
