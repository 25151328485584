import { Spinner } from '@blueprintjs/core';
import React, { FC } from 'react';

// Styles
import './styles.scss';

export interface IButtonLinkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  disabled?: boolean;
  children: string;
  className?: string;
  onClick: () => void;
}

const ButtonLink: FC<IButtonLinkProps> = ({
  loading = false,
  disabled = false,
  children,
  className = '',
  onClick,
}) => {
  const handleClick = () => {
    if (!disabled && !loading) {
      onClick();
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`button-link ${className} ${disabled ? 'button-link--disabled' : ''}`}
    >
      {loading && <Spinner size={15} />}
      <span>{children}</span>
    </button>
  );
};

export default ButtonLink;
