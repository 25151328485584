import React, { FC } from 'react';
import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';
import { Collapse } from '@blueprintjs/core';
// Components
import Loader from 'src/components/loader/Loader';
import componentsMap from '../../../../components/dynamic-layout/components/componentsMap';
// Types
import { IDynamicLayout } from '../../../../types/internal-Types';
import { ISubmitForm } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
import CloseIcon from 'src/components/icons/CloseIcon';
interface ITableFormProps {
  structure?: IDynamicLayout;
  onSubmit: (data: ISubmitForm) => void;
  isOpen: boolean;
  onClose?: () => void;
  isLoading?: boolean;
}

const TableForm: FC<ITableFormProps> = ({
  structure,
  onSubmit,
  onClose,
  isOpen,
  isLoading = false,
}) => {
  return (
    <Collapse className="table-manager-form" isOpen={isOpen}>
      <div>
        {onClose && (
          <span className="close-icon" onClick={onClose}>
            <CloseIcon />
          </span>
        )}
        <Loader loading={isLoading}>
          {structure && (
            <StructureErrorBoundary>
              <DynamicLayout
                structure={structure}
                componentsMap={componentsMap}
                callService={onSubmit}
              />
            </StructureErrorBoundary>
          )}
        </Loader>
      </div>
    </Collapse>
  );
};

export default TableForm;
