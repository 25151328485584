import React, { FC, ReactNode, useState } from 'react';
import {
  Drawer as BlueprintjsDrawer,
  IconName,
  Position,
  DrawerSize,
  Button,
} from '@blueprintjs/core';
// Types
import { IDrawer } from '../block-types';
// Utils
import getClassName from '../../utils/getClassName';
// Styles
import './style.scss';

interface IDrawerProps {
  children: ReactNode;
  size?: number;
  icon?: IconName;
  title?: string;
  position?: Position;
  overlay_close?: boolean;
  button_text?: string;
}

const Drawer: FC<IDrawerProps> = ({
  children,
  size,
  icon,
  title,
  position,
  overlay_close,
  button_text,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Button
        type="button"
        onClick={onOpen}
        minimal
        small
        fill={false}
        icon={icon}
        text={button_text || ''}
        className="drawer-btn"
      />
      <BlueprintjsDrawer
        onClose={onClose}
        isOpen={isOpen}
        position={position || Position.RIGHT}
        usePortal
        size={size || DrawerSize.STANDARD}
        title={title || ''}
        vertical={false}
        canOutsideClickClose={overlay_close || false}
      >
        <div className="drawer-content">{children}</div>
      </BlueprintjsDrawer>
    </>
  );
};

export const mapDrawerProps = (
  { children, size, icon, title, position, overlay_close, button_text, classes }: IDrawer,
  builder
) => ({
  children: builder(children),
  size,
  className: getClassName(classes),
  icon,
  title,
  position,
  overlay_close,
  button_text,
});

export default Drawer;
