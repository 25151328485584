import React, { FC } from 'react';
// Components
import {
  LineChart as LineChartRecharts,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
// Types
import { ILineChart, IChartKey, LineType } from '../types';
// Utils
import getClassName from 'src/components/dynamic-layout/utils/getClassName';
// Styles
import './charts-styles.scss';

interface ILineChartProps {
  keys: IChartKey[];
  xAxis?: string;
  yAxis?: string;
  data: any;
  lineType?: LineType;
  className?: string;
}

const LineChart: FC<ILineChartProps> = ({
  xAxis,
  yAxis,
  keys,
  data,
  className,
  lineType,
}) => {
  return (
    <div className={className ? `chart-container ${className}` : 'chart-container'}>
      <ResponsiveContainer>
        <LineChartRecharts data={data} height={20}>
          <XAxis dataKey={xAxis ? xAxis : 'name'} />
          <YAxis dataKey={yAxis} />
          <Tooltip />
          <Legend />
          {keys.map(({ key, color }, index) => {
            return (
              <Line
                dataKey={key}
                stroke={color}
                key={index}
                type={lineType ?? 'linear'}
              />
            );
          })}
        </LineChartRecharts>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;

export const mapLineChartProps = ({
  xAxis,
  yAxis,
  keys,
  data,
  lineType,
  classes,
}: ILineChart) => ({
  xAxis,
  yAxis,
  keys,
  data,
  lineType,
  className: getClassName(classes),
});
