import React from 'react';

const GarbageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 26" width="22" height="26">
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
          <path d="M-1079 -578L841 -578L841 699L-1079 699Z" />
        </clipPath>
      </defs>
      <g id="Web 1920 – 29" clipPath="url(#cp1)">
        <g id="clear filter">
          <g id="garbage">
            <path
              id="Tracé 1409"
              className="shp0"
              d="M11 21.64C11.22 21.64 11.4 21.46 11.4 21.23L11.4 7.93C11.4 7.71 11.22 7.53 11 7.53C10.78 7.53 10.6 7.71 10.6 7.93L10.6 21.23C10.6 21.46 10.78 21.64 11 21.64Z"
            />
            <path
              id="Tracé 1410"
              className="shp0"
              d="M6.97 21.64C7.19 21.64 7.37 21.46 7.37 21.23L7.37 7.93C7.37 7.71 7.19 7.53 6.97 7.53C6.75 7.53 6.57 7.71 6.57 7.93L6.57 21.23C6.57 21.46 6.75 21.64 6.97 21.64Z"
            />
            <path
              id="Tracé 1411"
              className="shp0"
              d="M15.03 21.64C15.25 21.64 15.43 21.46 15.43 21.23L15.43 7.93C15.43 7.71 15.25 7.53 15.03 7.53C14.81 7.53 14.63 7.71 14.63 7.93L14.63 21.23C14.63 21.46 14.81 21.64 15.03 21.64Z"
            />
            <path
              id="Tracé 1412"
              fillRule="evenodd"
              className="shp0"
              d="M20.67 3.9C20.67 4.13 20.49 4.31 20.27 4.31L19.45 4.31L18.67 22.86C18.66 23.55 18.23 24.86 16.7 24.86L5.3 24.86C3.77 24.86 3.34 23.55 3.32 22.85L2.55 4.31L1.73 4.31C1.51 4.31 1.33 4.13 1.33 3.9C1.33 3.68 1.51 3.5 1.73 3.5L6.58 3.5C6.63 3 6.78 2.15 7.31 1.6C7.65 1.26 8.08 1.08 8.58 1.08L12.61 1.08C13.11 1.08 13.54 1.26 13.88 1.6C14.41 2.15 14.56 3 14.61 3.5L20.27 3.5C20.49 3.5 20.67 3.68 20.67 3.9ZM7.39 3.5L13.8 3.5C13.75 3.08 13.62 2.49 13.3 2.16C13.12 1.98 12.89 1.89 12.61 1.89L8.58 1.89C8.3 1.89 8.07 1.98 7.89 2.16C7.57 2.49 7.44 3.08 7.39 3.5ZM18.64 4.31L3.36 4.31L4.13 22.83C4.13 22.96 4.2 24.06 5.3 24.06L16.7 24.06C17.81 24.06 17.87 22.96 17.87 22.84L18.64 4.31Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GarbageIcon;
