import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import { Row, Col } from 'react-bootstrap';
import { Card, NonIdealState } from '@blueprintjs/core';
import TestRequestsList from './TestRequestsList';
import TestRequestForm from './TestRequestForm';
// Style
import './style.scss';
// Selectors
import { selectEditConnectorState } from 'src/redux/slices/connectors/edit/selectors';

const ConnectorTestPage: FC<{ connectorId: string }> = ({ connectorId }) => {
  const { data: connectorSettings } = useSelector(selectEditConnectorState);

  const { t } = useTranslation(['connectors']);

  if (!connectorSettings) {
    return (
      <div className="non-ideal-state-container">
        <NonIdealState icon="search" title={t('Connector not found', { connectorId })} />
      </div>
    );
  }

  return (
    <div>
      <Row className="connector-test-wrp">
        <Col xs={4} className="connector-menu">
          <Card className="custom-card padding-sm">
            <TestRequestsList endpoints={connectorSettings.endpoints} />
          </Card>
        </Col>
        <Col xs={8} className="connector-test">
          <Card className="custom-card padding-sm">
            <TestRequestForm
              connectorId={connectorId}
              connectorSettings={connectorSettings}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ConnectorTestPage;
