import { combineReducers } from 'redux';

// Reducers
import createCompanyReducer from './create/createCompanySlice';
import companiesListReducer from './list/companiesListSlice';
import selectedCompanyReducer from './selected-company/selectedCompanySlice';
import editCompanyReducer from './edit/editCompanySlice';

export { reset as resetCreateCompanyState } from './create/createCompanySlice';
export { reset as resetCompaniesListState } from './create/createCompanySlice';
export {
  reset as resetEditCompanyState,
  setCompanyData as setEditCompanyData,
} from './edit/editCompanySlice';

export default combineReducers({
  create: createCompanyReducer,
  list: companiesListReducer,
  selectedCompany: selectedCompanyReducer,
  edit: editCompanyReducer,
});
