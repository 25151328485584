import * as React from 'react';
import { useState } from 'react';

export interface IUseAlertResult<TData> {
  isOpen: boolean;
  onOpen: (withData?: TData) => void;
  onClose: (evt?: React.SyntheticEvent<HTMLElement>) => void;
  onSubmit: (evt?: React.SyntheticEvent<HTMLElement>) => void;
  data?: TData;
}

export interface IUseAlertParams<TData> {
  callback: (data?: TData) => void;
  initialData?: TData;
  initialOpen?: boolean;
}

const useAlert = <TData = null>({
  callback,
  initialData,
  initialOpen = false,
}: IUseAlertParams<TData>): IUseAlertResult<TData> => {
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);
  const [data, setData] = useState<TData | undefined>(initialData);

  const onOpen = (withData?: TData) => {
    setIsOpen(true);
    setData(withData);
  };

  const onClose = (event?: React.SyntheticEvent<HTMLElement>) => {
    event?.stopPropagation();

    setIsOpen(false);
    setData(initialData);
  };

  const onSubmit = (event?: React.SyntheticEvent<HTMLElement>) => {
    event?.stopPropagation();

    onClose();
    callback(data);
  };

  return { isOpen, onOpen, onClose, onSubmit, data };
};

export default useAlert;
