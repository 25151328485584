import {
  IGetLinksParams,
  IGetEditFormParams,
  IUpdateLinksParams,
  IGetLinksResult,
  ILinkTypes,
  IFormTypes,
} from '../../../../services/LinksManagerService';
import { DefaultState, RequestStatus } from '../../types';
import { LinkTypesOptions, IDashboardTableRow } from '../../../../types/internal-Types';
import { ITableCol } from 'src/components/table/TableHeaderCell';
import { ESort } from 'src/types/enums';

export interface ILinksState extends DefaultState {
  data: {
    links: ILinkItem[];
    totalCount: number;
    linksTypes: ILinkTypes | null;
    formTypes: IFormTypes | null;
  };
  table: {
    columns: ITableCol[] | null;
  };
  optionsLoading: RequestStatus;
  formLoading: RequestStatus;
  typeOptions: LinkTypesOptions[];
  form: any; // DynamicLayout
  selectedLinks: string[];
  isSelectAll: boolean;
  selectedType: LinkTypesOptions | undefined;
  currentPage: number;
  selectedAction: EMassActions | undefined;
  searchKeyword?: string;
  sortBy?: string;
  sortDirection?: ESort;
  massActionLoading: RequestStatus;
}

// tslint:disable-next-line:no-empty-interface
export interface ILinkItem extends IDashboardTableRow {}

export interface IGetLinksSuccessResult {
  data: IGetLinksResult;
}

export interface IGetLinkTypesSuccessResult {
  typeOptions?: LinkTypesOptions[];
  linksTypes?: ILinkTypes;
  forms?: IFormTypes;
}

export interface IGetEditFromSuccessResult {
  form: any;
}

export enum EMassActions {
  delete = 'delete',
}

// tslint:disable-next-line:no-empty-interface
export interface IGetLinksActionPayload extends IGetLinksParams {}
// tslint:disable-next-line:no-empty-interface
export interface IUpdateLinksActionPayload extends IUpdateLinksParams {}
// tslint:disable-next-line:no-empty-interface
export interface IGetEditFormActionPayload extends IGetEditFormParams {}

export interface ISubmitEditFormActionPayload {
  callback: string;
  connectorId: string;
  data: any;
}
