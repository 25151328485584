export enum ECompany {
  TEASER = 'TEASER',
  TEASER_MEDIUM = 'TEASER_MEDIUM',
  EDIT = 'EDIT',
}

export enum EUser {
  TEASER_XS = 'TEASER_XS',
  TEASER = 'TEASER',
  EDIT = 'EDIT',
}

// Dashboard components

export enum EDashboardComponents {
  INTEMPUS = 'intempus', // has to be lower case, due to to key from the server
  UNICONTA = 'uniconta',
  WEBCRM = 'webcrm',
  NOVEMBERFIRST = 'novemberfirst',
  ORDRESTYRING = 'ordrestyring',
  SHOPIFY = 'shopify',
  FENERUM = 'fenerum',
  WOOCOMMERCE = 'woocommerce',
  HOSTEDSHOP = 'hostedshop',
  MAGENTO1 = 'magento1',
  MAGENTO2 = 'magento2',
  POSONE = 'posone',
  EMAIL = 'email',
  FTP = 'ftp',
}

export enum ComponentActions {
  check = 'status_check',
  settings = 'settings',
}

export enum UserRoles {
  authenticated_user = 'authenticated user',
  administrator = 'administrator',
  account_manager = 'account manager',
}

export enum UserPermissions {
  integrations_debug = 'integrations_debug',
  create_company = 'create_company',
  test_page = 'test_page',
}

export enum EJobOperations {
  start = 'start',
  pause = 'pause',
  stop = 'stop',
  restart = 'restart',
  requeue_failed = 'requeue_failed',
}

export enum EJobState {
  JobPending = 'JOB PENDING',
  JobPreparing = 'JOB PREPARING',
  JobRunning = 'JOB RUNNING',
  JobPaused = 'JOB PAUSED',
  JobStopped = 'JOB STOPPED',
}

export enum ENotificationTypes {
  error = 'error',
  status = 'status',
  warning = 'warning',
}

export enum EDashboardTableColTypes {
  date = 'date',
  string = 'string',
  integer = 'integer',
  markup = 'markup',
  operation = 'operation',
  line_color = 'line_color',
}

export enum ESort {
  asc = 1,
  desc = -1,
}
