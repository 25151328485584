import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import CompaniesService from '../../../../services/CompaniesService';

// Types
import { EditCompanyActionSuccessResult, EditCompanyActionPayload } from './types';

export const editCompany = createAsyncThunk<
  EditCompanyActionSuccessResult,
  EditCompanyActionPayload
>('companies.edit/putEditCompany', async ({ id, company }, { rejectWithValue }) => {
  try {
    await CompaniesService.edit(id, company);

    return {};
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response);
  }
});
