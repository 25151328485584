import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
// Actions
import { setFormValues } from '../../../redux/slices/auth/signup/signupSlice';
// Utils
import { getQueryParameters } from '../../../utils/genUtils';

const useInitialFormValues = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { email, cvr, company, first_name, last_name } = getQueryParameters(
      location.search
    );

    const initialFormValues = {
      ...(typeof email === 'string' ? { email } : {}),
      ...(typeof cvr === 'string' ? { cvr } : {}),
      ...(typeof company === 'string' ? { company_name: company } : {}),
      ...(typeof first_name === 'string' ? { first_name } : {}),
      ...(typeof last_name === 'string' ? { last_name } : {}),
    };

    if (Object.keys(initialFormValues).length) {
      dispatch(setFormValues(initialFormValues));
    }
  }, [dispatch, location]);
};

export default useInitialFormValues;
