import { createAsyncThunk } from '@reduxjs/toolkit';
import NotificationsService from '../../../../services/NotificationsService';
import {
  IGetNotificationsResult,
  IGetNotificationsActionPayload,
  IDeleteNotificationsActionPayload,
  IDeleteNotificationsResult,
  IChangeTableNotificationStatusActionPayload,
  IChangeTableNotificationStatusResult,
} from './types';

export const getNotifications = createAsyncThunk<
  IGetNotificationsResult,
  IGetNotificationsActionPayload
>('notifications.table/getNotifications', async (params, { rejectWithValue }) => {
  try {
    const { data } = await NotificationsService.get(params);

    return {
      data,
    };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response);
  }
});

export const deleteNotifications = createAsyncThunk<
  IDeleteNotificationsResult,
  IDeleteNotificationsActionPayload
>(
  'notifications.table/deleteNotifications',
  async ({ ids, userId, read }, { rejectWithValue }) => {
    try {
      const { data } = await NotificationsService.delete(ids, userId, read);

      return { ids: data };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const changeNotificationsStatus = createAsyncThunk<
  IChangeTableNotificationStatusResult,
  IChangeTableNotificationStatusActionPayload
>(
  'notifications.table/changeNotificationsStatus',
  async ({ ids, userId, isRead }, { rejectWithValue }) => {
    try {
      const { data, total } = await NotificationsService.update(ids, userId, isRead);

      return { updatedItems: data, isRead, total };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);
