import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { RequestStatus } from '../../../redux/slices/types';
import { RequestType } from 'src/services/BaseApiService';
import { MarketplaceState, IBuildIntegrationSuccessResult } from './types';
// Utils
import { createThunkReducers } from '../utils';
// Thunks
import { getMarketPlaces, buildIntegration } from './thunk';

const initialState: MarketplaceState = {
  error: null,
  loading: RequestStatus.idle,
  data: undefined,
  tags: [],
};

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    selectMarketPlace: (state: MarketplaceState, action: PayloadAction<string>) => ({
      ...state,
      selectedMarketplaceId: action.payload,
      selectedIntegrationId: undefined,
    }),
    selectIntegration: (
      state: MarketplaceState,
      action: PayloadAction<string | undefined>
    ) => ({
      ...state,
      selectedIntegrationId: action.payload,
      error: null,
      submit: RequestStatus.idle,
    }),
    setFilterTag: (state: MarketplaceState, action: PayloadAction<string>) => ({
      ...state,
      tags: state.tags.includes(action.payload)
        ? [...state.tags]
        : [...state.tags, action.payload],
      selectedIntegrationId: undefined,
    }),
    removeFilterTag: (state: MarketplaceState, action: PayloadAction<string>) => ({
      ...state,
      tags: state.tags.filter((tag) => tag !== action.payload),
    }),
    resetFilters: (state: MarketplaceState) => ({
      ...state,
      selectedIntegrationId: undefined,
      error: null,
      tags: [],
      createdIntegrationSlug: undefined,
    }),
    reset: () => initialState,
  },
  extraReducers: {
    ...createThunkReducers(getMarketPlaces, RequestType.get),
    ...createThunkReducers(buildIntegration, RequestType.put),
    [String(buildIntegration.fulfilled)]: (
      state: MarketplaceState,
      action: PayloadAction<IBuildIntegrationSuccessResult>
    ) => ({
      ...state,
      createdIntegrationSlug: action.payload.data.slug,
      submit: RequestStatus.fulfilled,
    }),
  },
});

export const {
  selectMarketPlace,
  selectIntegration,
  setFilterTag,
  removeFilterTag,
  reset,
  resetFilters,
} = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
