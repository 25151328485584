import PersistTokenService from '../services/PersistTokenService';
import IntegrationDebugSettingsService from '../services/IntegrationDebugSettingsService';
import { login } from './slices/auth';
import { clearToken } from './slices/auth/token/tokenSlice';

export const authControlMiddleware = (store) => (next) => (action) => {
  const { type, payload } = action;

  if (type !== String(login.rejected) && payload?.status === 401) {
    PersistTokenService.clear();
    IntegrationDebugSettingsService.clear();

    store.dispatch(clearToken());
  } else {
    return next(action);
  }
};
