import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
// Types
import { CreateConnectorFormData } from 'src/types/internal-Types';

interface IUseAsGlobal {
  isChecked: boolean;
  toggle: () => void;
  remove: () => void;
}

const useAsGlobal = (schemeId: number): IUseAsGlobal => {
  const { values, setFieldValue } = useFormikContext<CreateConnectorFormData>();

  const isChecked = useMemo(() => values.security.includes(schemeId), [values, schemeId]);

  const remove = useCallback(() => {
    setFieldValue(
      'security',
      values.security.filter((id) => id !== schemeId)
    );
  }, [values.security, schemeId, setFieldValue]);

  const toggle = useCallback(() => {
    setFieldValue(
      'security',
      isChecked
        ? values.security.filter((id) => id !== schemeId)
        : [...values.security, schemeId]
    );
  }, [setFieldValue, values.security, schemeId, isChecked]);

  return { toggle, remove, isChecked };
};

export default useAsGlobal;
