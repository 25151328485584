import { NonIdealState } from '@blueprintjs/core';
import React, { FC, useMemo } from 'react';
// Components
import IntegrationTeaser from './Integration/IntegrationTeaser';
import CustomScrollbars from '../../../custom-scrollbars/CustomScrollbars';
// Types
import { IFieldIntegration } from 'src/types/api-Types';
// Utils
import { filterString } from 'src/utils/genUtils';
// Icon
import iconIntegration from '../../../../images/icon-integration.svg';

export interface ICompanyIntegrationListProps {
  integrations: IFieldIntegration[];
  filterValue?: string;
  filterTags?: string[];
  onTagClick: (tag: string) => void;
  loading: boolean;
}

const CompanyIntegrationList: FC<ICompanyIntegrationListProps> = ({
  filterValue,
  filterTags,
  integrations,
  loading,
  onTagClick,
}) => {
  const filteredIntegrations = useMemo(() => {
    return integrations.filter(
      (f) =>
        (filterValue ? filterString(f.title, filterValue) : true) &&
        (filterTags?.length ? f.tags?.some((tag) => filterTags.includes(tag)) : true)
    );
  }, [filterValue, filterTags, integrations]);

  if (!loading && !filteredIntegrations.length) {
    return (
      <NonIdealState
        icon={
          filterValue ? 'search' : <img src={iconIntegration} alt="integration icon" />
        }
        title="No integrations found"
      />
    );
  }

  return (
    <div className="integrations-list">
      <CustomScrollbars autoHeight autoHeightMax={'30vh'} style={{ width: '100%' }}>
        {filteredIntegrations.map((x, i) => (
          <IntegrationTeaser integration={x} key={i} onTagClick={onTagClick} />
        ))}
        {loading && <div className="integrations-list-item-skeleton bp3-skeleton" />}
      </CustomScrollbars>
    </div>
  );
};

export default CompanyIntegrationList;
