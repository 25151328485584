import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

// Styles
import './style.scss';

interface IPaginationProps {
  forcePage: number;
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const Pagination: FC<IPaginationProps> = ({ forcePage, pageCount, onPageChange }) => {
  const { t } = useTranslation(['pagination']);
  return (
    <ReactPaginate
      forcePage={forcePage}
      previousLabel={t('buttons.Prev')}
      nextLabel={t('buttons.Next')}
      breakLabel="..."
      breakClassName="break-me"
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName="table-pagination"
      subContainerClassName="table-pagination-pages"
      activeClassName="active"
    />
  );
};

export default Pagination;
