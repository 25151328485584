import yup from '../../../../../translations/yup/yupLocale';
import i18n from '../../../../../i18n';

const validationSchema = yup.object({
  first_name: yup.string().required().label('First Name'),
  last_name: yup.string().required().label('Last Name'),
  company_name: yup.string().required().label('Company Name'),
  password: yup
    .string()
    .required()
    .min(8)
    .matches(/(?=^.*)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/, {
      message: ({ value }) => {
        const signs: string[] = [];
        if (!/[A-Z]+/.test(value)) signs.push(i18n.t('signup:validation:capital'));
        if (!/[a-z]+/.test(value)) signs.push(i18n.t('signup:validation:small'));
        if (!/\d+/.test(value)) signs.push(i18n.t('signup:validation:digit'));
        return `${i18n.t('signup:validation:password')} ${signs.join(', ')}`;
      },
    })
    .label('Password'),
  confirm_password: yup
    .string()
    .required()
    .label('Password')
    .test('passwords-match', i18n.t('signup:validation:passwordsMatch'), function (
      value
    ) {
      return this.parent.password === value;
    }),
  agreement: yup.bool().required().oneOf([true], i18n.t('signup:validation:Terms')),
});

export default validationSchema;
