import { RequestType } from '../../services/BaseApiService';
import { RequestStatus } from './types';

const loadingStateKey = new Map<RequestType, 'loading' | 'submit'>([
  [RequestType.get, 'loading'],
  [RequestType.post, 'submit'],
  [RequestType.delete, 'submit'],
  [RequestType.patch, 'submit'],
  [RequestType.put, 'submit'],
]);

const getStateLoadingKey = (
  thunkName: string,
  requestType: RequestType = RequestType.get
): 'submit' | 'loading' => {
  if (thunkName.includes('post')) {
    return 'submit';
  }

  return loadingStateKey.get(requestType) || 'loading';
};

export const createThunkReducers = <TState>(
  thunk,
  requestType?: RequestType,
  loadingKey?: string,
  errorKey: string = 'error'
) => {
  const key = loadingKey || getStateLoadingKey(String(thunk.pending), requestType);
  return {
    [String(thunk.pending)]: (state): TState => ({
      ...state,
      submit: RequestStatus.idle,
      loading: RequestStatus.idle,
      [key]: RequestStatus.pending,
      [errorKey]: null,
    }),
    [String(thunk.fulfilled)]: (state, { payload }): TState => ({
      ...state,
      [key]: RequestStatus.fulfilled,
      [errorKey]: null,
      ...payload,
    }),
    [String(thunk.rejected)]: (state, action): TState => {
      const { payload, error } = action;

      return {
        ...state,
        [key]: RequestStatus.rejected,
        [errorKey]: {
          ...error,
          ...(payload && { message: payload }),
        },
      };
    },
  };
};
