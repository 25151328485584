// Types
import { EState } from '../../../../types/api-Types';

export const labels = new Map<EState, string>([
  [EState.TaskCompleted, 'Success'],
  [EState.TaskReprocessed, 'Reprocessed'],
  [EState.TaskCreated, 'Created'],
  [EState.TaskRunning, 'Running'],
  [EState.TaskFailed, 'Failed'],
  [EState.TaskWarning, 'Warning'],
]);

export const taskColors = {
  [EState.TaskCompleted]: ['#16DB93', '#4FEEB4', '#96F5D2'],
  [EState.TaskReprocessed]: ['#E6D72D', '#EDE269', '#F4EEA5'],
  [EState.TaskRunning]: ['#48AFF0', '#7CC6F4', '#B0DDF9'],
  [EState.TaskCreated]: ['#2EE6D6', '#69EDE2', '#A5F4EE'],
  [EState.TaskFailed]: ['#F55656', '#F88787', '#FAABAB'],
  [EState.TaskWarning]: ['#F2952C', '#F5B468', '#F8CA96'],
};

export const colors = [
  ['#88F2B4', '#AAF6C9', '#C3F8D9'],
  ['#88CBF5', '#ABDAF8', '#C4E5FA'],
  ['#A4E67A', '#BEEDA0', '#D1F2BD'],
  ['#FAE31B', '#FCEC5B', '#FDF18C'],
  ['#A3EE79', '#BDF29E', '#D1F6BB'],
  ['#E4F221', '#ECF661', '#F2F990'],
  ['#D5F65F', '#E1F88D', '#EAFAAF'],
  ['#D2839B', '#DFA7B8', '#E9C1CD'],
  ['#94A6F2', '#B1BEF5', '#C9D1F8'],
  ['#D5FC58', '#E2FD89', '#EAFDAD'],
  ['#FA922C', '#FBB36A', '#FCC997'],
  ['#FAE20A', '#FBEA50', '#FDF185'],
  ['#9CF779', '#B8F99F', '#CEFBBC'],
  ['#EBEA1B', '#F1F15B', '#F5F58C'],
  ['#A799EB', '#C0B6F1', '#D3CCF5'],
  ['#BBF55F', '#CFF88E', '#DEFAB0'],
  ['#F9A41A', '#FBBE5C', '#FCD18D'],
  ['#90E67A', '#AFEDA0', '#C7F2BD'],
  ['#9899EB', '#B6B7F1', '#CCCDF5'],
  ['#8BEEE6', '#ADF3ED', '#C5F6F2'],
  ['#B1F779', '#C7F99F', '#D8FBBC'],
  ['#91EAE6', '#B7F1EE', '#C7F4F2'],
  ['#F6C305', '#FBD549', '#FCE180'],
  ['#F4D405', '#FBE449', '#FCEC80'],
  ['#89DEF5', '#ABE7F8', '#C4EEFA'],
  ['#8EE9A8', '#B0EFC2', '#C8F4D4'],
  ['#8EE897', '#ADEEB4', '#C6F3CA'],
  ['#89B9F5', '#ABCEF8', '#C4DCFA'],
  ['#D996CD', '#E4B4DB', '#ECCBE6'],
  ['#F5CB05', '#FBDB49', '#FCE680'],
  ['#C394F2', '#D3B1F5', '#E0C9F8'],
  ['#FA8441', '#FCA878', '#FDC2A1'],
  ['#88F2D8', '#AAF6E3', '#C3F8EB'],
  ['#8AADF0', '#ABC5F4', '#C4D6F7'],
  ['#CD94DE', '#DCB4E8', '#E6CAEF'],
  ['#F9B115', '#FBC758', '#FCD88A'],
  ['#ECFB26', '#F2FC65', '#F6FD94'],
  ['#91E9ED', '#B1EFF2', '#C8F4F6'],
  ['#88F1C5', '#AAF5D6', '#C4F8E2'],
  ['#F08A64', '#F4AC92', '#F7C5B2'],
];
