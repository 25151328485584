import fromUnixTime from 'date-fns/fromUnixTime';
import BaseApiService, { RequestType } from './BaseApiService';
// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';
// Types
import { SerializedError } from 'src/redux/slices/types';

class CronsService extends BaseApiService {
  getStatus = async (
    key,
    { connectorId, action }
  ): Promise<IGetCronStatusSuccessResult> => {
    const { data } = await this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'integration',
          data: {
            action,
            input: {
              operation: 'status',
            },
          },
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });

    if (data?.output?.error) {
      return Promise.reject({ message: data.output.error } as SerializedError);
    }

    if (data?.output?.output?.status) {
      const {
        status,
        last_run,
        last_run_timestamp,
        next_execution_time,
        next_execution_time_timestamp,
        interval,
      } = data?.output?.output?.status;

      return {
        status: status.toLowerCase() as ECronStatus,
        last_run,
        last_run_date: fromUnixTime(last_run_timestamp),
        next_execution_time,
        next_execution_date: fromUnixTime(next_execution_time_timestamp),
        interval,
      };
    } else throw new Error();
  };

  changeStatus = async ({ connectorId, action, operation }) =>
    this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'integration',
          data: {
            action,
            input: {
              operation,
            },
          },
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
  execute = async ({ connectorId, action }) =>
    this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'integration',
          data: {
            action,
            input: {
              operation: 'execute',
            },
          },
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
}

export default new CronsService('rest/connector');

export interface IGetCronStatusSuccessResult {
  status: ECronStatus;
  last_run: string;
  last_run_date: Date;
  next_execution_time: string;
  next_execution_date: Date;
  interval: number; // in seconds
}

export enum ECronStatus {
  enabled = 'enabled',
  disabled = 'disabled',
}

export enum ECronOperation {
  enable = 'enable',
  disable = 'disable',
}
