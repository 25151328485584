import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
// Selectors
import { selectSignupState } from '../../../redux/slices/auth/signup/selectors';
import { selectLanguageState } from '../../../redux/slices/language/selectors';
// Actions
import { resetSignupState } from '../../../redux/slices/auth';
import { setIntegrationName } from '../../../redux/slices/auth/signup/signupSlice';
import { getSignupText } from '../../../redux/slices/auth/signup/thunk';
// Utils
import stringifyStateError from '../../../utils/stringifyStateError';
import { getQueryParameterByName } from '../../../utils/genUtils';
import useInitialFormValues from './useInitialFormValues';

// Components
import SignupLogo from './SignupLogo/SignupLogo';
import SignupFrom from './SignupFrom/SignupFrom';
import Step1 from './steps/step1/Step1';
import Step2 from './steps/step2/Step2';
import Step3 from './steps/step3/Step3';

// Styles
import './styles.scss';

const IntegrationSignupPage: FC = () => {
  const { currentStep, formErrors, error, status, integrationName, text } = useSelector(
    selectSignupState
  );
  const { selectedLanguage } = useSelector(selectLanguageState);

  const dispatch = useDispatch();

  const { t } = useTranslation(['signup']);

  const urlParams = useLocation().search;

  useInitialFormValues();

  // Component did unmount
  useEffect(
    () => () => {
      dispatch(resetSignupState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (formErrors?.length) {
      formErrors.map((message) =>
        toastr.error(t('alert.Error'), t('alert.Validation error', { error: message }))
      );
    }
  }, [formErrors, t]);

  useEffect(() => {
    if (error) {
      toastr.error(
        t('alert.Failed'),
        t('alert.Fail message', { error: stringifyStateError(error) })
      );
    }
  }, [error, t]);

  useEffect(() => {
    if (status && status !== '') {
      toastr.info(t('alert.Status'), status ?? '');
    }
  }, [status, t]);

  useEffect(() => {
    const param = getQueryParameterByName('integration', urlParams);
    if (param && typeof param === 'string') {
      dispatch(setIntegrationName(param));
    }
  }, [dispatch, urlParams]);

  useEffect(() => {
    if (integrationName) {
      dispatch(getSignupText({ step: currentStep, integration: integrationName }));
    }
  }, [currentStep, integrationName, dispatch, selectedLanguage.Key]);

  return (
    <>
      {text?.header_img && <SignupLogo logo={text.header_img} />}
      <SignupFrom>
        {!currentStep && <Step1 />}
        {currentStep === 1 && <Step2 />}
        {currentStep === 2 && <Step3 />}
      </SignupFrom>
    </>
  );
};

export default IntegrationSignupPage;
