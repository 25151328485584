import React, { FC } from 'react';

const CheckIcon: FC = () => {
  return (
    <svg width="20" height="20">
      <path d="M7 14.17L2.83 10l-1.41 1.41L7 17 19 5l-1.41-1.42L7 14.17z" />
    </svg>
  );
};

export default CheckIcon;
