import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Icon } from '@blueprintjs/core';
// Types
import { INotification } from '../../../types/internal-Types';
import { IChangeStatus } from './NotificationsTable';
// Actions
import { setTaskId } from '../../../redux/slices/dashboard/logs/logsSlice';
// Icons
import TickIcon from '../../../components/icons/TickIcon';
import GarbageIcon from 'src/components/icons/GarbageIcon';
// Components
import Checkbox from '../../../components/checkbox/Checkbox';

interface INotificationProps {
  data: INotification;
  selected: boolean;
  onSelect: (id: string) => void;
  onUpdate: (data: IChangeStatus) => void;
  onDelete: (ids: string[]) => void;
}

const NotificationRow: FC<INotificationProps> = ({
  data,
  onSelect,
  onUpdate,
  onDelete,
  selected,
}) => {
  const { t } = useTranslation(['notifications']);
  const dispatch = useDispatch();

  const handleUpdateStatus = useCallback(() => {
    onUpdate({ ids: [data.id], isRead: !data.read });
  }, [data, onUpdate]);

  const handleSelect = useCallback(() => {
    onSelect(data.id);
  }, [data, onSelect]);

  const handleDelete = useCallback(() => {
    onDelete([data.id]);
  }, [data, onDelete]);

  const handleClick = useCallback(() => {
    if (data.task_id) {
      dispatch(setTaskId(data.task_id));
    }
  }, [data, dispatch]);

  return (
    <Row className="notification notification-row">
      <Col xs={1}>
        <Checkbox checked={selected} large onChange={handleSelect} />
      </Col>
      <Col xs={4} className="notification-title">
        {data?.link ? (
          <Link to={'/dashboard/' + data.link} onClick={handleClick}>
            {data.title}
          </Link>
        ) : (
          data.title
        )}
      </Col>
      <Col xs={4} className="notification-description">
        {data.description}
      </Col>
      <Col xs={2} className="notification-date">
        {data.date}
      </Col>
      <Col xs={2} className="notification-actions">
        <span
          className={`notification-status${data.read ? ' active' : ''}`}
          onClick={handleUpdateStatus}
          title={data.read ? t('buttons.Mark as unread') : t('buttons.Mark as read')}
        >
          <TickIcon />
        </span>
        <span onClick={handleDelete} className="notification-delete">
          <Icon icon={<GarbageIcon />} iconSize={Icon.SIZE_STANDARD} />
        </span>
      </Col>
    </Row>
  );
};

export default NotificationRow;
