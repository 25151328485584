import { MarketplaceState } from './types';
import { RootState } from '../../rootReducer';
import { IMarketPlaceIntegration } from '../../../services/MarketplaceService';

export const selectMarketplaceState = (state: RootState): MarketplaceState => {
  return state.marketplace;
};

export const selectSelectedIntegration = (
  state: RootState
): IMarketPlaceIntegration | undefined => {
  return state.marketplace.data?.find(
    (integration) => integration.nid === state.marketplace.selectedIntegrationId
  );
};
