import { Button, H2, InputGroup, NonIdealState } from '@blueprintjs/core';
import * as React from 'react';
import { FC, useMemo, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Types
import { ICompany } from 'src/types/api-Types';
import { UserPermissions } from 'src/types/enums';
import { RootState } from '../../../../redux/rootReducer';
// Utils
import { filterString } from 'src/utils/genUtils';
import { getRandomArray } from '../../../../utils/getRandom';
import usePermissions from '../../../../utils/check-permission/usePermissions';
// Selectors
import { selectCompaniesListState } from '../../../../redux/slices/companies/list/selectors';
import { selectSelectedCompanyState } from '../../../../redux/slices/companies/selected-company/selectors';
// Actions
import { selectCompany } from '../../../../redux/slices/companies/selected-company/selectedCompanySlice';
// Components
import CompanyTeaser from '../CompanyMain/CompanyTeaser';

const CompanyListTeaser: FC = () => {
  const { data, isReady } = useSelector(selectCompaniesListState);
  const selectedCompany = useSelector<RootState, ICompany | undefined>(
    selectSelectedCompanyState
  );

  const [filterValue, setFilterValue] = useState<string>('');

  const history = useHistory();

  const dispatch = useDispatch();

  const { t } = useTranslation(['home']);

  const [canCreate] = usePermissions(UserPermissions.create_company);

  const filteredCompanies = useMemo(() => {
    if (filterValue) {
      return data.filter((f) => filterString(f.title, filterValue));
    }

    return data;
  }, [filterValue, data]);

  const handleFilterChange = (e) => {
    setFilterValue(e.currentTarget.value);
  };

  const createCompanyRedirect = React.useCallback(() => {
    history.push('/settings/companies/create');
  }, [history]);

  React.useEffect(() => {
    if (!selectedCompany && data.length) {
      dispatch(selectCompany(data[0]));
    }
  }, [selectedCompany, data, dispatch]);

  return (
    <div className="companies-list-widget teaser">
      <Grid fluid>
        <Row className="search-wrapper">
          <Col>
            <InputGroup
              leftIcon="search"
              onChange={handleFilterChange}
              placeholder={t('inputs.Search')}
            />
          </Col>
        </Row>
        <Row className="title-wrapper">
          {canCreate && (
            <>
              <Col md={9}>
                <H2>{t('titles.Companies')}</H2>
              </Col>
              <Col md={3}>
                <Button icon="plus" onClick={createCompanyRedirect} />
              </Col>
            </>
          )}
          {!canCreate && (
            <Col md={12}>
              <H2>{t('titles.Companies')}</H2>
            </Col>
          )}
        </Row>
        <hr />
        <Row className="companies-wrapper">
          <div className="wrapper">
            {/* Skeletons */}
            {((!selectedCompany && isReady && data.length) || !isReady) &&
              getRandomArray(5).map((id) => (
                <div key={id} className="company-list-skeleton">
                  <div className="bp3-skeleton" />
                  <div className="bp3-skeleton" />
                  <div className="bp3-skeleton" />
                </div>
              ))}

            {/* Companies list */}
            {((!data.length && isReady) ||
              (!filteredCompanies.length && filterValue)) && (
              <NonIdealState
                icon={filterValue ? 'search' : 'error'}
                title={t('No companies found')}
              />
            )}
            {selectedCompany &&
              filteredCompanies &&
              filteredCompanies.map((x, i) => (
                <CompanyTeaser company={x} key={i} selected={selectedCompany} />
              ))}
          </div>
        </Row>
      </Grid>
    </div>
  );
};

export default CompanyListTeaser;
