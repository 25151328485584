import React, { FC, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
// Types
import { IAutosubmit } from './input-types';

interface IAutosubmitProps {
  payload: string;
  callback: string;
  wrapper: string;
  id: string;
  type: string;
  timeout: number;
}

const Autosubmit: FC<IAutosubmitProps> = ({
  payload,
  callback = '',
  wrapper = '',
  id = '',
  type = 'autosubmit',
  timeout = 0,
}) => {
  const { setValues, values, submitForm, submitCount } = useFormikContext();

  const submit = useCallback(() => {
    setValues({
      payload,
      callback,
      wrapper,
      triggering_element: { type, id, callback, payload, wrapper },
      // @ts-ignore
      ...values,
    });
    submitForm();
  }, [type, id, callback, payload, wrapper, values, setValues, submitForm]);

  useEffect(() => {
    if (submitCount < 1) {
      const timer = setTimeout(submit, timeout * 1000);
      return () => clearTimeout(timer);
    } else {
      return;
    }
  }, [submit, timeout, submitCount]);

  return <></>;
};

export const mapAutosubmitProps = (props: IAutosubmit) => props;

export default Autosubmit;
