import { Quill } from 'react-quill';
import { extractVariableName, variableRegex } from './utils';
import Variable from './Variable';

const Embed = Quill.import('blots/embed');

export interface IFunctionBlock {
  name: string;
  isValid: boolean;
  args: string[];
  validArgs: string[];
}

export default class FunctionBlock extends Embed {
  static create(params: IFunctionBlock | string) {
    const { name, isValid = true, args = [], validArgs = [] } =
      typeof params === 'string' ? JSON.parse(params) : params;
    const node = super.create();
    node.setAttribute('class', 'function pill');

    node.setAttribute('data-args', args.join(' '));
    node.setAttribute('data-validargs', validArgs.join(' '));

    node.setAttribute('data-name', name);
    node.setAttribute('data-valid', isValid);

    node.innerHTML = `${name} `;
    node.append('(');

    args.forEach((arg, index) => {
      let argElem = arg;
      if (variableRegex.test(arg)) {
        const isValidArg = validArgs.includes(arg);
        argElem = Variable.create({
          name: extractVariableName(arg),
          isValid: isValidArg,
        });
      }
      node.append(argElem);
      if (index < args.length - 1) {
        node.append(' ');
      }
    });

    node.append(')');

    return node;
  }

  static value(node) {
    return {
      name: node.dataset.name,
      args: node.dataset.args?.split(' '),
      validArgs: node.dataset.validargs?.split(' '),
      isValid: node.dataset?.valid === 'true',
    };
  }
}
FunctionBlock.blotName = 'function';
FunctionBlock.tagName = 'span';
