import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { setSelectedRequest } from '../../../../redux/slices/connectors/test/testConnectorSlice';
// Components
import CustomScrollbars from 'src/components/custom-scrollbars/CustomScrollbars';
import EndpointTeaser from './EndpointTeaser';
// Selectors
import { selectTestConnectorState } from '../../../../redux/slices/connectors/test/selectors';
// Types
import { IConnectorEndpoint } from 'src/types/internal-Types';

interface ITestRequestsListProps {
  endpoints: IConnectorEndpoint[];
}

const TestRequestsList: FC<ITestRequestsListProps> = ({ endpoints }) => {
  const dispatch = useDispatch();
  const { selectedRequest } = useSelector(selectTestConnectorState);

  const active = useMemo(
    () =>
      endpoints.findIndex((endpoint) =>
        endpoint.requests.find(
          (request) => request.operationId === selectedRequest?.operationId
        )
      ),
    [endpoints, selectedRequest]
  );

  useEffect(() => {
    if (!selectedRequest) {
      const firstSelected = endpoints[0]?.requests[0];
      if (firstSelected) {
        dispatch(setSelectedRequest(firstSelected));
      }
    }
  }, [dispatch, selectedRequest, endpoints]);

  return (
    <div>
      <CustomScrollbars
        hideTracksWhenNotNeeded
        autoHeightMin="calc(70vh - 30px)"
        autoHeight
      >
        {endpoints.map((endpoint, index) => (
          <EndpointTeaser
            active={index === active}
            key={endpoint.path}
            endpoint={endpoint}
          />
        ))}
      </CustomScrollbars>
    </div>
  );
};

export default TestRequestsList;
