import React, { FC } from 'react';
// Components
import HookStatusList from './components/HookStatusList';
// Utils
import useHooksWidgetData from './useHooksWidgetData';
import { RequestStatus } from 'src/redux/slices/types';
import { IUnicontaHooksManager } from '../../types';
// Types
import { IHookStatus } from '../../../../types/api-Types';

const HOOKS_WIDGET_KEY = 'uniconta_hooks_widget'; // maybe HooksWidget will be re-used for different integrations

const UnicontaHooksManager: FC = () => {
  const { data, loading } = useHooksWidgetData(HOOKS_WIDGET_KEY);
  return (
    <div className="hooks-manager">
      <HookStatusList
        loading={loading === RequestStatus.pending}
        widgetKey={HOOKS_WIDGET_KEY}
        data={data as IHookStatus}
      />
    </div>
  );
};

export const mapUniontaHooksManagerProps = (props: IUnicontaHooksManager) => ({
  ...props,
});

export default UnicontaHooksManager;
