import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import { H3, Icon, NonIdealState } from '@blueprintjs/core';
import { Col } from 'react-bootstrap';

import InputEditor from './InputEditor';
import FormPreview from './preview/FormPreview';
// Types
import { CreateConnectorFormData } from 'src/types/internal-Types';
import { InputTypes } from '../../../../components/dynamic-layout/components/input-types';
// Style
import './style.scss';

const FormEditor: FC = () => {
  const { values, setFieldValue } = useFormikContext<CreateConnectorFormData>();

  const { t } = useTranslation(['connectors']);

  const handleAdd = useCallback(() => {
    const currentValue = [...values.form];
    currentValue.push({
      type: InputTypes.textfield,
      label: t('titles.New field'),
      key: `new_field`,
      validation: 'string|required',
    });
    setFieldValue('form', currentValue);
  }, [setFieldValue, values, t]);

  const handleRemove = useCallback(
    (index: number) => {
      const currentValue = [...values.form];
      currentValue.splice(index, 1);
      setFieldValue('form', currentValue);
    },
    [setFieldValue, values]
  );

  return (
    <div>
      <div className="connector-section-title">
        <H3>{t('titles.Form')}</H3>
        <Icon icon="plus" className="edit-list-icon" onClick={handleAdd} />
      </div>

      {!!values.form.length ? (
        <>
          <Col xs={6}>
            {values.form.map((value, index) => (
              <InputEditor
                path={`form.${index}`}
                key={index}
                fieldValue={value}
                onRemove={() => {
                  handleRemove(index);
                }}
              />
            ))}
          </Col>
          <Col xs={6}>
            <FormPreview />
          </Col>
        </>
      ) : (
        <Col xs={12}>
          <div className="non-ideal-state-container large">
            <NonIdealState icon="form" title={t('Form is empty')} />
          </div>
        </Col>
      )}
    </div>
  );
};

export default FormEditor;
