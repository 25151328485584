export const DEBUG_SETTINGS_KEY = 'wedoio-IntegrationDebug';

interface IIntegrationDebugSettingsService {
  get: (integration: string) => boolean;
  set: (integration: string, debug: boolean) => void;
}

class IntegrationDebugSettingsService implements IIntegrationDebugSettingsService {
  private getSettings = () => {
    const strSettings = localStorage.getItem(DEBUG_SETTINGS_KEY);
    if (strSettings) {
      return JSON.parse(strSettings);
    } else return [];
  };

  get = (integration) => {
    return this.getSettings().includes(integration);
  };

  set = (integration, debug) => {
    if (debug) {
      this.add(integration);
    } else {
      this.remove(integration);
    }
  };

  private add = (integration) => {
    if (!this.get(integration)) {
      const settings = this.getSettings();
      settings.push(integration);
      localStorage.setItem(DEBUG_SETTINGS_KEY, JSON.stringify(settings));
    }
  };

  private remove = (integration) => {
    if (this.get(integration)) {
      const settings = this.getSettings();
      localStorage.setItem(
        DEBUG_SETTINGS_KEY,
        JSON.stringify(settings.filter((id) => id !== integration))
      );
    }
  };

  clear = () => {
    localStorage.removeItem(DEBUG_SETTINGS_KEY);
  };
}

export default new IntegrationDebugSettingsService();
