import React, { FC } from 'react';
import { useField } from 'formik';

// Component
import Autocomplete from '../../../components/autocomplete/Autocomplete';

// Types
import { IAutocompleteTextField } from './input-types';
import { IValueKey } from '../../generic-selector/GenericSelector';

// Utils
import { getFormattedOptions } from '../utils/getFormatedOptions';
import getClassName from '../utils/getClassName';

const AutocompleteInput: FC<IAutocompleteTextField> = ({
  label,
  name,
  callback,
  payload,
  options,
  min_length,
  className,
}) => {
  const [field, meta, helpers] = useField({ name });

  const formattedItems = getFormattedOptions(options);

  const preselectedItem = formattedItems.find(({ Key }) => Key === field.value);

  const handleChange = (option: IValueKey | undefined) => {
    helpers.setTouched(true);
    helpers.setValue(option?.Key);
  };

  return (
    <Autocomplete
      options={formattedItems}
      placeholder={label}
      callback={callback}
      payload={payload}
      onChange={handleChange}
      preSelectedItem={preselectedItem}
      validationError={meta.touched && meta.error}
      minQueryLength={min_length}
      className={className}
    />
  );
};

export default AutocompleteInput;

export const mapAutocompleteInputProps = ({
  key,
  label,
  classes,
  callback,
  payload,
  options,
  min_length,
}: IAutocompleteTextField) => ({
  name: `['${key}']`,
  label,
  callback,
  payload,
  options,
  min_length,
  className: getClassName(classes),
});
