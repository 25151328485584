import { PayloadAction } from '@reduxjs/toolkit';

// Component item reducer
import componentItemReducer from './componentItemSlice';

// Types
import { IComponentForwardedActionPayload, IComponentsState } from './types';

const forwardActionToComponent = <
  ActionPayload extends IComponentForwardedActionPayload
>() => (
  state: IComponentsState,
  action: PayloadAction<ActionPayload>
): IComponentsState => {
  // @ts-ignore
  const component = action.payload?.component || action.meta?.arg?.component;

  if (component) {
    return {
      ...state,
      [component.key]: componentItemReducer(state[component.key], {
        ...action,
        payload: { ...action.payload, component },
      }),
    };
  }

  return {
    ...state,
  };
};

export default forwardActionToComponent;
