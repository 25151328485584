import React, { FC, useState, ReactNode } from 'react';

// Components
import { Dialog, Button, IconName } from '@blueprintjs/core';
import CloseIcon from '../../../icons/CloseIcon';

// Types
import { IPopup } from '../block-types';

// Utils
import getClassName from '../../utils/getClassName';

// Styles
import './style.scss';

interface IPopupProps {
  children: ReactNode;
  icon: IconName;
  label: string;
  className: string;
}

const Popup: FC<IPopupProps> = ({ children, icon, label, className }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <Button
        type="button"
        minimal
        small
        className={`popup-button ${className}`}
        onClick={() => {
          setIsOpen(true);
        }}
        icon={icon}
      >
        {label}
      </Button>
      <Dialog
        className="dialogMenu popup"
        isCloseButtonShown={false}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div
          className="dialog-close-button"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <CloseIcon />
        </div>
        <div className="margin-top-xs">{children}</div>
      </Dialog>
    </>
  );
};

export default Popup;

export const mapPopupProps = (
  { popup_content, label = '', classes = [], icon = '' }: IPopup,
  builder
) => {
  return {
    children: builder(popup_content),
    label,
    icon,
    className: getClassName(classes),
  };
};
