import React, { FC } from 'react';
// Components
import ArrowIcon from '../icons/ArrowIcon';
// Types
import { ESort } from '../../types/enums';

interface ITableSortLabelProps {
  direction?: ESort;
  onClick: (direction: ESort) => void;
}

const TableSortLabel: FC<ITableSortLabelProps> = ({ direction, onClick }) => {
  return (
    <div className="table-sort">
      <div
        className={`table-sort-label${direction === ESort.asc ? ' active' : ''}`}
        onClick={() => {
          onClick(ESort.asc);
        }}
      >
        <ArrowIcon />
      </div>
      <div
        className={`table-sort-label${direction === ESort.desc ? ' active' : ''}`}
        onClick={() => {
          onClick(ESort.desc);
        }}
      >
        <ArrowIcon />
      </div>
    </div>
  );
};

export default TableSortLabel;
