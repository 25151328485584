import React, { FC, ReactElement, useState } from 'react';
// Components
import { Collapse as BlueprintCollapse } from '@blueprintjs/core';
import ArrowIcon from 'src/components/icons/ArrowIcon';

// Styles
import './styles.scss';

interface ICollapseProps {
  header: ReactElement;
}

const Collapse: FC<ICollapseProps> = ({ header, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`collapse-component ${isOpen ? 'opened' : ''}`}>
      <div onClick={handleOpen} className="collapse-header">
        <ArrowIcon />
        {header}
      </div>
      <BlueprintCollapse isOpen={isOpen}>{children}</BlueprintCollapse>
    </div>
  );
};

export default Collapse;
