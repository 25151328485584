import React, { FC, useCallback, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';
// Components
import ExpressionEditor from '../../expression-input/ExpressionEditor';
import Button, { ButtonSize } from 'src/components/button/Button';
import Tree from '../tree/Tree';
// Hooks
import useMappingTooolContext from '../context/useMappingToolContext';
// Utils
import { functionNames, checkIsValidFunction } from '../utils';
import {
  getValue,
  formatValue,
  // validateTemplateString,
} from '../../expression-input/utils';
// Style
import './edit-modal-style.scss';

interface IEditValueFormProps {
  onSubmit: (value: string) => void;
  initialValue: string;
  field: string;
}

const EditValueForm: FC<IEditValueFormProps> = ({
  onSubmit,
  initialValue = '',
  field,
}) => {
  const [currenValue, setCurrentValue] = useState<any>(formatValue(initialValue));
  // const { source, checkIsValidVariable } = useMappingTooolContext();
  const { source } = useMappingTooolContext();

  const isChanged = useMemo(() => initialValue !== getValue(currenValue).trim(), [
    initialValue,
    currenValue,
  ]);

  // const isValid = useMemo(
  //   () =>
  //     validateTemplateString(
  //       getValue(currenValue),
  //       checkIsValidFunction,
  //       checkIsValidVariable
  //     ),
  //   [currenValue, checkIsValidVariable]
  // );

  const editorRef = useRef<ReactQuill | null>(null);

  const { t } = useTranslation(['mapping']);

  const handleChange = useCallback((content) => {
    setCurrentValue(content);
  }, []);

  const handleSelectVariable = useCallback(
    (path) => {
      const editor = editorRef.current?.getEditor();
      if (editor) {
        editor.focus();

        const range = editor.getSelection();
        editor.insertEmbed(
          range?.index ?? editor.getLength(),
          'variable',
          { name: path },
          'user'
        );
        editor.setSelection((range?.index ?? editor.getLength()) + 1, 0);
      }
    },
    [editorRef]
  );

  const handleSelectFunction = useCallback(
    (value) => {
      if (editorRef.current) {
        const editor = editorRef.current.getEditor();
        editor.focus();
        const range = editor.getSelection();
        if (range) {
          editor.insertEmbed(
            range?.index ?? editor.getLength(),
            'function',
            { name: value },
            'user'
          );
        }
        editor.setSelection((range?.index ?? editor.getLength()) + 1, 0);
      }
    },
    [editorRef]
  );

  const handleSubmit = useCallback(() => {
    onSubmit(getValue(currenValue));
  }, [currenValue, onSubmit]);

  return (
    <Row className="mapping-value-edit-drawer">
      <Col xs={12}>
        <div className="title">{t('titles.Current value')}</div>
        <ExpressionEditor
          ref={editorRef}
          value={currenValue}
          name={field}
          onChange={handleChange}
          validateFunction={checkIsValidFunction}
          // validateVariable={checkIsValidVariable} // We skip validating the variable for the moment
        />
      </Col>
      <Col xs={12} className="available-fields">
        <div className="title">{t('titles.Available fields')}</div>
        <Tree source={source} onSelect={handleSelectVariable} />
      </Col>
      <Col xs={12}>
        <div className="title">{t('titles.Functions')}</div>
        <div className="pills-list">
          {functionNames.map((name) => (
            <span
              className="function pill"
              key={name}
              onClick={() => {
                handleSelectFunction(name);
              }}
            >
              {name}
            </span>
          ))}
        </div>
      </Col>
      <Button
        className="save-mapping-button"
        size={ButtonSize.default}
        disabled={!isChanged}
        onClick={handleSubmit}
      >
        {t('buttons.Save')}
      </Button>
    </Row>
  );
};

export default EditValueForm;
