import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import ServerItem from './ServerItem';
import { H4, Icon, NonIdealState } from '@blueprintjs/core';
// Types
import { CreateConnectorFormData } from 'src/types/internal-Types';
// Style
import './styles.scss';

const ServersList: FC = () => {
  const { values, setFieldValue } = useFormikContext<CreateConnectorFormData>();
  const { t } = useTranslation(['connectors']);

  const handleAdd = useCallback(() => {
    const currentValue = [...values.servers];
    currentValue.push({
      url: '',
    });
    setFieldValue('servers', currentValue);
  }, [setFieldValue, values]);

  const handleRemove = useCallback(
    (id: number) => {
      const currentValue = [...values.servers].slice(id, 1);
      setFieldValue('servers', currentValue);
    },
    [setFieldValue, values]
  );

  return (
    <>
      <div className="connector-section-title">
        <H4 className="margin-bottom-sm">{t('titles.Servers')}</H4>
        <Icon icon="plus" className="edit-list-icon" onClick={handleAdd} />
      </div>

      {values.servers?.length ? (
        values.servers.map((data, index) => (
          <ServerItem
            onRemove={() => {
              handleRemove(index);
            }}
            data={data}
            key={index}
            path={`servers.${index}.url`}
          />
        ))
      ) : (
        <NonIdealState
          icon={<Icon icon="error" iconSize={50} />}
          description={t('No servers found') as string}
        />
      )}
    </>
  );
};

export default ServersList;
