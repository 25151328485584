import React, { FC } from 'react';

interface ITreeNodeProps {
  label: string;
  value: string;
  path: string;
  onClick?: (path: string) => void;
}

const TreeNode: FC<ITreeNodeProps> = ({ label, value, path, onClick }) => {
  return (
    <li
      className="tree-node"
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick(path);
        }
      }}
    >
      <span className="tree-node-label">
        <strong>{label}</strong> : {JSON.stringify(value)}
      </span>
    </li>
  );
};

export default TreeNode;
