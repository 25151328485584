import React, { FC, useCallback } from 'react';
import { useFormikContext } from 'formik';
// Types
import { ISubmit } from './input-types';
/// Components
import Button from '../../button/Button';
import ButtonLink from '../../button-link/ButtonLink';
// Utils
import getClassName from '../utils/getClassName';

interface ISubmitProps {
  payload: string;
  callback: string;
  value: string;
  wrapper: string;
  id: string;
  type: string;
  asLink: boolean;
  className?: string;
}

const Submit: FC<ISubmitProps> = ({
  payload,
  callback = '',
  value = 'Submit',
  wrapper = '',
  id = '',
  type = 'submit',
  asLink = false,
  className,
}) => {
  const { setValues, values, isValid, isSubmitting } = useFormikContext();

  const onClick = useCallback(() => {
    setValues({
      // @ts-ignore
      ...values,
      payload,
      callback,
      wrapper,
      triggering_element: { type, id, value, callback, payload, wrapper },
    });
  }, [type, id, callback, payload, wrapper, value, values, setValues]);

  return (
    <>
      {!asLink && (
        <Button
          disabled={!isValid || isSubmitting}
          type="submit"
          onClick={onClick}
          loading={isSubmitting}
          className={className}
        >
          {value}
        </Button>
      )}
      {asLink && (
        <ButtonLink
          disabled={!isValid || isSubmitting}
          onClick={onClick}
          loading={isSubmitting}
        >
          {value}
        </ButtonLink>
      )}
    </>
  );
};

export const mapSubmitProps = ({ classes, ...props }: ISubmit) => ({
  ...props,
  className: getClassName(classes),
});

export default Submit;
