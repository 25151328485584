import React, { FC } from 'react';

const TickIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 259" width="27" height="15">
      <g>
        <path
          fill="#54ADA8"
          d="M333.5 1.82C331.9 2.82 297.8 36.52 257.9 76.52C180.3 154.32 182.9 151.42 185.1 159.22C186.6 164.72 191.1 167.92 197.3 167.92L202.5 167.92L277.2 93.22C358.1 12.42 354.8 16.02 351.8 8.12C349.1 0.92 340 -2.28 333.5 1.82Z"
        />
        <path
          fill="#54ADA8"
          d="M434 0.62C433.2 0.92 381.5 52.02 319.3 114.22L206 227.42L161.8 183.22C123.4 144.92 117 139.02 113.8 138.42C107.5 137.22 102.2 139.92 99.5 145.62C96.1 152.72 97.1 154.42 118.8 176.22L138.5 195.92L122.7 211.72L107 227.42L62.8 183.22C33.2 153.72 17.6 138.82 15.7 138.42C11 137.22 6.4 138.72 3.1 142.52C0.5 145.42 0 146.82 0 151.22L0 156.42L50.3 206.62C87.7 244.22 101.3 257.22 103.8 257.82C110.1 259.62 112.4 258.02 134.7 235.72L156 214.52L177.2 235.62C200 258.12 202.3 259.82 209.3 257.82C211.7 257.22 241.6 227.82 331.7 137.72C427.8 41.42 450.9 17.82 451.5 15.22C453.1 7.52 447.1 -0.18 439.7 0.02C437.4 0.02 434.8 0.32 434 0.62Z"
        />
      </g>
    </svg>
  );
};

export default TickIcon;
