import React, { FC, ReactNode } from 'react';

// Icons
import CloseIcon from '../icons/CloseIcon';

// Styles
import './image-picker-styles.scss';

interface IImagePickerProps {
  canReset?: boolean;
  children: ReactNode;
  onChange: (file: File) => void;
  onReset?: () => void;
}

const ImagePicker: FC<IImagePickerProps> = ({
  canReset,
  children,
  onChange,
  onReset,
}) => {
  const handleChange = (event) => {
    const [file] = event.target.files;

    if (file) {
      onChange(file);
    }
  };

  const handleReset = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (canReset && onReset) {
      onReset();
    }
  };

  return (
    <label className="image-picker">
      {children}
      {canReset && (
        <span onClick={handleReset}>
          <CloseIcon />
        </span>
      )}
      <input
        type="file"
        accept=".png, .jpg, .jpeg, .gif"
        value=""
        onChange={handleChange}
      />
    </label>
  );
};

export default ImagePicker;
