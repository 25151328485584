import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { NonIdealState } from '@blueprintjs/core';
import { ResponsiveContainer, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
// Components
import Loader from '../../../../components/loader/Loader';
// Utils
import { getFormattedPieData } from './utils';
import useConnectorActions from '../../utils/useConnectorActions';
// Types
import { ILogsDiagramProps } from './types';
import { RequestStatus } from 'src/redux/slices/types';
// Consts
import { colors } from './consts';

const ActionsPieChart: FC<ILogsDiagramProps> = ({ data, isLoading }) => {
  const { t } = useTranslation(['dashboard']);

  const { actions, actionsLoading } = useConnectorActions();

  const pieData = useMemo(() => getFormattedPieData(data, actions), [data, actions]);

  return (
    <>
      <Row className={`shadow-card diagrams-container`}>
        <Loader
          loading={isLoading || actionsLoading === RequestStatus.pending}
          withShadow
        />
        {!pieData.length && !isLoading && actionsLoading !== RequestStatus.pending && (
          <NonIdealState
            icon="error"
            title={t('No logs found')}
            className="logs-non-ideal-state"
          />
        )}
        {!!pieData.length && (
          <Col sm={12} className="diagram">
            <ResponsiveContainer>
              <PieChart>
                <defs>
                  {pieData.map(({ colorIndex }, index) => {
                    return (
                      <linearGradient
                        id={`actionsDiagramColor${colorIndex}`}
                        x1="92%"
                        y1="100%"
                        x2="8%"
                        y2="0%"
                        key={index}
                      >
                        <stop offset="0%" stopColor={colors[colorIndex][1]} />
                        <stop offset="100%" stopColor={colors[colorIndex][2]} />
                      </linearGradient>
                    );
                  })}
                </defs>
                <Pie dataKey="number" data={pieData} fill="#8884d8" labelLine label>
                  {pieData.map(({ colorIndex }, index) => {
                    return (
                      <Cell
                        stroke={colors[colorIndex][0]}
                        fill={`url(#actionsDiagramColor${colorIndex})`}
                        key={index}
                      />
                    );
                  })}
                </Pie>
                <Tooltip />
                <Legend wrapperStyle={{ height: 'max-content' }} />
              </PieChart>
            </ResponsiveContainer>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ActionsPieChart;
