import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { IConnectorEndpoint } from '../../../../types/internal-Types';
interface IEndpointTeaserSmallProps {
  value: IConnectorEndpoint;
}

const EndpointTeaserSmall: FC<IEndpointTeaserSmallProps> = ({ value }) => {
  const { t } = useTranslation(['connectors']);

  return (
    <div className={`endpoint-teaser --small`}>
      <div className="endpoint-teaser-title">
        {value.path ? value.path : t('titles.New endpoint')}
      </div>
    </div>
  );
};

export default EndpointTeaserSmall;
