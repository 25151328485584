import { ReactNode } from 'react';
// Types
import { BlockTypes } from './block-types';
import { InputTypes } from './input-types';
import { IComponentsMapPropsCallback } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
// Components
import Block, { mapBlockProps } from './Block';
import Column, { mapColumnProps } from './Column';
import Row, { mapRowProps } from './Row';
import Checkbox, { mapCheckboxProps } from './Checkbox';
import Password, { mapPasswordProps } from './Password';
import Select, { mapSelectProps } from './Select';
import TextArea, { mapTextAreaProps } from './TextArea';
import TextBlock, { mapTextBlockProps } from './TextBlock';
import TextField, { mapTextFieldProps } from './TextField';
import Submit, { mapSubmitProps } from './Submit';
import Autosubmit, { mapAutosubmitProps } from './Autosubmit';
import Button, { mapButtonProps } from './Button';
import Layout, { mapLayoutProps } from './layout/Layout';
import FluidBlock, { mapFluidBlockProps } from './fluid-block/FluidBlock';
import Tabs, { mapTabsProps } from './Tabs';
import JsonEditorField, { mapJsonEditorFieldProps } from './JsonEditorField';
import JsonViewer, { mapJsonViewerProps } from './JsonViewer';
import Image, { mapImageProps } from './Image';
import Collapse, { mapCollapseProps } from './collapse/Collapse';
import Tooltip, { mapTooltipProps } from './tooltip/Tooltip';
import AutocompleteInput, { mapAutocompleteInputProps } from './AutocompleteInput';
import Popup, { mapPopupProps } from './popup/Popup';
import ContextualBlock, {
  mapContextualBlockProps,
} from './contextual-block/ContextualBlock';
import Link, { mapLinkProps } from './link/Link';
import Drawer, { mapDrawerProps } from './drawer/Drawer';
import MappingTool, { mapMappingToolProps } from './MappingTool';
import DateInput, { mapDateInputProps } from './DateInput';
import DateRangeInput, { mapDateRangeInputProps } from './DateRangeInput';
import TagsInput, { mapTagsInputProps } from './TagsInput';
import SimpleArrayEditor, { mapSimpleArrayEditorProps } from './SimpleArrayEditor';
import OnChangeSubmit, { mapOnChangeSubmitProps } from './OnChangeSubmit';
import Switch, { mapSwitchProps } from './Switch';
import Callout, { mapCalloutProps } from './Callout';

// Available components for render
const componentsMap = new Map<string, [ReactNode, IComponentsMapPropsCallback]>([
  // Blocks
  [BlockTypes.block, [Block, mapBlockProps]],
  [BlockTypes.column, [Column, mapColumnProps]],
  [BlockTypes.row, [Row, mapRowProps]],
  [BlockTypes.text, [TextBlock, mapTextBlockProps]],
  [BlockTypes.fluidBlock, [FluidBlock, mapFluidBlockProps]],
  [BlockTypes.layout, [Layout, mapLayoutProps]],
  [BlockTypes.tabs, [Tabs, mapTabsProps]],
  [BlockTypes.img, [Image, mapImageProps]],
  [BlockTypes.collapse, [Collapse, mapCollapseProps]],
  [BlockTypes.tooltip, [Tooltip, mapTooltipProps]],
  [BlockTypes.jsonViewer, [JsonViewer, mapJsonViewerProps]],
  [BlockTypes.popup, [Popup, mapPopupProps]],
  [BlockTypes.contextualBlock, [ContextualBlock, mapContextualBlockProps]],
  [BlockTypes.link, [Link, mapLinkProps]],
  [BlockTypes.drawer, [Drawer, mapDrawerProps]],
  [BlockTypes.callout, [Callout, mapCalloutProps]],

  // Inputs
  [InputTypes.textfield, [TextField, mapTextFieldProps]],
  [InputTypes.select, [Select, mapSelectProps]],
  [InputTypes.textarea, [TextArea, mapTextAreaProps]],
  [InputTypes.checkbox, [Checkbox, mapCheckboxProps]],
  [InputTypes.password, [Password, mapPasswordProps]],
  [InputTypes.submit, [Submit, mapSubmitProps]],
  [InputTypes.autosubmit, [Autosubmit, mapAutosubmitProps]],
  [InputTypes.button, [Button, mapButtonProps]],
  [InputTypes.jsonEditorField, [JsonEditorField, mapJsonEditorFieldProps]],
  [InputTypes.autocompleteTextField, [AutocompleteInput, mapAutocompleteInputProps]],
  [InputTypes.mappingTool, [MappingTool, mapMappingToolProps]],
  [InputTypes.dateInput, [DateInput, mapDateInputProps]],
  [InputTypes.dateRangeInput, [DateRangeInput, mapDateRangeInputProps]],
  [InputTypes.tagsInput, [TagsInput, mapTagsInputProps]],
  [InputTypes.simpleArrayEditor, [SimpleArrayEditor, mapSimpleArrayEditorProps]],
  [InputTypes.onChangeSubmit, [OnChangeSubmit, mapOnChangeSubmitProps]],
  [InputTypes.switch, [Switch, mapSwitchProps]],
]);

export default componentsMap;
