import React, { FC } from 'react';
import Button from 'src/components/button/Button';
import { useTranslation } from 'react-i18next';

interface IFilepickerProps {
  onChange: (file: File) => void;
}

const FilePicker: FC<IFilepickerProps> = ({ onChange }) => {
  const { t } = useTranslation(['mapping']);

  const handleChange = (event) => {
    const [file] = event.target.files;

    if (file) {
      onChange(file);
    }
  };

  return (
    <label className="file-picker">
      <Button>{t('buttons.Browse')}</Button>
      <input type="file" accept=".json" value="" onChange={handleChange} title="" />
    </label>
  );
};

export default FilePicker;
