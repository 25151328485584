import React, { FC, useCallback, useState } from 'react';
// Component
import Input from '../../../../../components/input/Input';

// Types
import { ITextField } from '../../../../../components/dynamic-layout/components/input-types';

// Utils
import getClassName from '../../../../../components/dynamic-layout/utils/getClassName';

const PreviewTextfield: FC<ITextField> = ({ label, name, className }) => {
  const [value, setValue] = useState<string>('');
  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <Input
      value={value}
      onChange={handleChange}
      label={label}
      placeholder={label}
      name={name}
      className={className}
    />
  );
};

export default PreviewTextfield;

export const mapTextFieldProps = ({ key, label, classes }: ITextField) => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
});
