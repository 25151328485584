import React, { FC, ReactNode } from 'react';

// Types
import { IBlock } from './block-types';

// Utils
import getClassName from '../utils/getClassName';

export interface IBlockProps {
  children: ReactNode;
  className?: string;
}

const Block: FC<IBlockProps> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default Block;

export const mapBlockProps = ({ children, classes = [] }: IBlock, builder) => {
  return {
    children: builder(children),
    className: getClassName(classes),
  };
};
