import React, { FC, useCallback, useMemo } from 'react';
import { FormGroup, Intent } from '@blueprintjs/core';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

// Components
import DateRangeInputComponent from '../../date-range-input/DateRangeInput';

// Types
import { IDateRangeInput } from './input-types';
import { DateRange, TimePrecision } from '@blueprintjs/datetime';

// Utils
import getClassName from '../utils/getClassName';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

interface IDateRangeInputProps {
  timePrecision?: TimePrecision;
  minDate?: Date;
  maxDate?: Date;
  locale?: string;
  label: string;
  name: string;
  className: string;
  format?: string;
  validationString?: string;
}

const DateRangeInput: FC<IDateRangeInputProps> = ({
  label,
  name,
  className,
  locale,
  minDate,
  maxDate,
  timePrecision,
  format,
  validationString,
}) => {
  const { t } = useTranslation(['dateinput']);

  const validate = useCallback(
    (values) => {
      const [start, end] = values ?? [null, null];
      if (validationString?.match('required')) {
        if (!start && start !== 0) {
          return t('validation.Start date is requered') as string;
        }
        if (!end && end !== 0) {
          return t('validation.End date is requered') as string;
        }
      }
      if (
        (maxDate &&
          (isAfter(fromUnixTime(start), maxDate) ||
            isAfter(fromUnixTime(end), maxDate))) ||
        (minDate &&
          (isBefore(fromUnixTime(start), minDate) ||
            isBefore(fromUnixTime(end), minDate)))
      ) {
        return t('validation.Out of range') as string;
      }
      return undefined;
    },
    [t, maxDate, minDate, validationString]
  );
  const [field, meta, helpers] = useField({
    name,
    validate,
  });

  const handleChange = (value: DateRange) => {
    const [start, end] = value ?? [null, null];
    helpers.setValue([start ? getUnixTime(start) : null, end ? getUnixTime(end) : null]);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  const formattedValue = useMemo(() => {
    const [start, end] = field.value ?? [null, null];
    return [
      start || start === 0 ? fromUnixTime(start) : null,
      end || end === 0 ? fromUnixTime(end) : null,
    ] as DateRange;
  }, [field.value]);

  return (
    <FormGroup helperText={meta.touched && meta.error} label={label}>
      <DateRangeInputComponent
        className={className}
        value={formattedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        locale={locale}
        maxDate={maxDate}
        minDate={minDate}
        timePrecision={timePrecision}
        format={format}
        intent={meta.touched && meta.error ? Intent.DANGER : undefined}
      />
    </FormGroup>
  );
};

export default DateRangeInput;

export const mapDateRangeInputProps = ({
  key,
  label,
  classes,
  locale,
  maxDate,
  minDate,
  timePrecision,
  format,
  validation,
}: IDateRangeInput): IDateRangeInputProps => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
  locale,
  minDate: minDate || minDate === 0 ? fromUnixTime(minDate) : undefined,
  maxDate: maxDate || maxDate === 0 ? fromUnixTime(maxDate) : undefined,
  timePrecision,
  format,
  validationString: validation,
});
