import React, { FC, useCallback } from 'react';
// Components
import TagsInputComponent from 'src/components/tags-input/TagsInput';
// Types
import { ITagsInput } from './input-types';
// Utils
import getClassName from '../utils/getClassName';
import { useField } from 'formik';

const TagsInput: FC<ITagsInput> = ({ validation, label, className, name }) => {
  const validate = useCallback(
    (value) => {
      if (validation?.includes('required') && value.length === 0) {
        return `${label} is required!`;
      }
      return undefined;
    },
    [validation, label]
  );

  const [field, meta, helpers] = useField({ name, validate });

  const handleAdd = (newValue) => {
    helpers.setValue([...field.value, newValue]);
    helpers.setTouched(true);
  };

  const handleRemove = (value, index) => {
    const newValues = [...(field.value ?? [])];
    newValues.splice(index, 1);
    helpers.setValue(newValues);
    helpers.setTouched(true);
  };

  return (
    <TagsInputComponent
      values={field.value}
      placeholder={label}
      className={className}
      label={label}
      onAdd={handleAdd}
      onRemove={handleRemove}
      validationError={meta.touched && meta.error}
      name={name}
    />
  );
};

export default TagsInput;

export const mapTagsInputProps = ({ key, label, classes, validation }: ITagsInput) => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
  validation,
});
