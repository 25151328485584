import React, { FC, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import get from 'lodash/get';
// Components
import Checkbox from '../checkbox/Checkbox';
import TableCell from './TableCell';
// Types
import { ITableCol } from './TableHeaderCell';
import { IDashboardTableRow } from '../../types/internal-Types';
import getColSize from './getColSize';

export interface ITableRowProps {
  columns: ITableCol[];
  data: IDashboardTableRow;
  isSelected: boolean;
  color?: string;
  onSelect: (id: string) => void;
  key: React.Key;
}

const TableRow: FC<ITableRowProps> = ({ data, columns, onSelect, isSelected, color }) => {
  const colSize = useMemo(() => getColSize(columns), [columns]);

  return (
    <Row className="table-row" style={{ background: color }}>
      <Col xs={1} className="table-checkbox">
        <Checkbox
          checked={isSelected}
          onChange={() => {
            onSelect(data.id);
          }}
        />
      </Col>
      {columns?.map(({ field, type, size, className }) => {
        const cell = get(data.values, field);
        return (
          <TableCell
            className={className}
            key={field}
            type={type}
            value={cell}
            size={size ?? colSize}
          />
        );
      })}
    </Row>
  );
};

export default TableRow;
