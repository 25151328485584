import { validateTemplateString } from '../expression-input/utils';
import has from 'lodash/has';

export const functionNames = ['uppercase', 'lowercase'];

export const checkIsValidFunction = (functionName) => {
  return functionNames.includes(functionName);
};

export const checkIsValidVar = (source: object, field: string) => {
  return has(source, field);
};

export const getParentPath = (path: string) => path.replace(/\.*?([A-Za-z0-9_-]*?)$/, '');

export const replaceLastPathLevel = (path: string, newField) =>
  getNewPath(getParentPath(path), newField);

export const getNewPath = (path: string, newField) =>
  `${path ? path + '.' : ''}${newField}`;

export const validate = (input: object = {}, source: object = {}) => {
  return Object.values(input).every(function validateInput(value: any) {
    if (Array.isArray(value) || !value) {
      return true;
    }

    if (typeof value !== 'object') {
      return validateTemplateString(String(value), checkIsValidFunction, (field) =>
        checkIsValidVar(source, field)
      );
    }

    if (typeof value === 'object') {
      return Object.values(value).every((subFieldValue) => {
        return validateInput(subFieldValue);
      });
    }
    return true;
  });
};
