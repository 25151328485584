import BaseApiService, { RequestType } from './BaseApiService';

class AuthService extends BaseApiService {
  login = (data: LoginData) =>
    this.request({
      type: RequestType.post,
      url: 'rest/auth/generate.json',
      auth: data,
    });

  resetPassword = (data: ResetPasswordData) =>
    this.request({
      type: RequestType.post,
      url: 'rest/user/request_new_password',
      data: { name: data.username },
    });

  signup = (data: SignupStep1 | SignupStep2 | SignupStep3 | SignupResendCode) =>
    this.request({
      type: RequestType.post,
      url: 'core/signup',
      data,
    });
  getSignupText = ({ integration, step }: IGetText) =>
    this.request({
      type: RequestType.put,
      url: `core/signup/${step + 1}`,
      data: {
        input: {
          data: {
            integration,
          },
        },
      },
    });
}

export interface LoginData {
  username: string;
  password: string;
}

export interface ResetPasswordData {
  username: string;
}

export interface SignupStep1 {
  email: string;
  cvr: string;
  signup?: string;
  step: number;
}
export interface SignupStep2 {
  first_name: string;
  last_name: string;
  company_name: string;
  password: string;
  confirm_password: string;
  step: number;
  registration_key: string;
}

export interface SignupStep3 {
  code: string;
  step: number;
  registration_key: string;
}

export interface SignupResendCode {
  step: number;
  registration_key: string;
  resend_code: boolean;
}

export interface IGetText {
  integration?: string;
  step: number;
}

export default new AuthService();
