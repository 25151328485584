import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Services
import AuthService from '../../../../services/AuthService';
import { RequestType } from '../../../../services/BaseApiService';
import PersistTokenService from '../../../../services/PersistTokenService';
// Types
import { RequestStatus } from '../../types';
// Utils
import { createThunkReducers } from '../../utils';
import { LoginActionPayload, LoginActionSuccessResult, LoginState } from './types';

export const login = createAsyncThunk<LoginActionSuccessResult, LoginActionPayload>(
  'auth/login',
  async (data: LoginActionPayload, { rejectWithValue }) => {
    try {
      const response = await AuthService.login(data);

      const { token } = response.data;

      PersistTokenService.set(token);

      return {
        token,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

const initialState: LoginState = {
  error: null,
  submit: RequestStatus.idle,
  initialValues: {
    username: '',
    password: '',
  },
};

const loginSlice = createSlice({
  name: 'auth.login',
  initialState,
  reducers: {
    reset: (): LoginState => initialState,
  },
  extraReducers: {
    ...createThunkReducers<LoginState>(login, RequestType.post),
  },
});

export const { reset } = loginSlice.actions;

export default loginSlice.reducer;
