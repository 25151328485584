import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestType } from '../../../services/BaseApiService';

// Utils
import { createThunkReducers } from '../utils';

// Types
import { EditUserFormValues, GetUserSuccessResult, UserState } from './types';
import { RequestStatus } from '../types';

// Thunks
import { getUser, updateUser } from './thunks';

const initialValues: EditUserFormValues = {
  field_first_name: '',
  field_last_name: '',
  field_user_picture: {
    file: '',
    filename: '',
  },
  mail: '',
  field_mobile_phone: '',
  field_invoice_email: '',
  current_password: '',
  password: '',
  language: '',
  confirm_password: '',
};

export const ALTER_USER_NAME = 'Jonh';
export const ALTER_USER_LAST_NAME = 'Doe';

const initialState: UserState = {
  error: null,
  loading: RequestStatus.idle,
  submit: RequestStatus.idle,
  data: undefined,
  initialValues,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (): UserState => initialState,
  },
  extraReducers: {
    ...createThunkReducers(getUser),
    ...createThunkReducers(updateUser, RequestType.put),
    [String(getUser.fulfilled)]: (
      state,
      action: PayloadAction<GetUserSuccessResult>
    ): UserState => {
      const {
        field_user_picture,
        field_first_name,
        field_last_name,
        mail,
        field_mobile_phone,
        field_invoice_email,
        language,
      } = action.payload.data;

      return {
        ...state,
        loading: RequestStatus.fulfilled,
        submit: RequestStatus.idle,
        error: null,
        data: action.payload.data,
        initialValues: {
          ...state.initialValues,
          field_first_name: field_first_name || '',
          field_last_name: field_last_name || '',
          field_user_picture: {
            file: field_user_picture?.teaser || '',
            filename: field_user_picture?.filename || '',
          },
          mail: mail || '',
          field_mobile_phone: field_mobile_phone || '',
          field_invoice_email: field_invoice_email || '',
          language: language || '',
        },
      };
    },
  },
});

export const { reset } = userSlice.actions;

export default userSlice.reducer;
