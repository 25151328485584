import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// Components
import { Row, Col } from 'react-bootstrap';
import { Card, NonIdealState } from '@blueprintjs/core';
import SwaggerUI from 'swagger-ui-react';
import CustomScrollbars from 'src/components/custom-scrollbars/CustomScrollbars';
// Styles
import 'swagger-ui-react/swagger-ui.css';
import './connector-doc-style.scss';
// Selectors
import { selectConnectorById } from '../../../../redux/slices/connectors/list/selectors';
import FormActions from 'src/components/form-actions/FormActions';
import Button, { ButtonSize } from 'src/components/button/Button';

import { plugin } from './swagger-ui-confg';

const DocumentationPage: FC<{ connectorId: string }> = ({ connectorId }) => {
  const connectorSettings = useSelector(selectConnectorById(connectorId));

  const { t } = useTranslation(['connectors']);
  const history = useHistory();

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  if (!connectorSettings) {
    return (
      <div className="non-ideal-state-container">
        <NonIdealState icon="search" title={t('Connector not found', { connectorId })} />
      </div>
    );
  }

  return (
    <div>
      <Row className="connector-doc-wrp">
        <Col xs={12} className="connector-doc">
          <FormActions>
            <Button size={ButtonSize.small} onClick={handleCancel}>
              {t('buttons.Cancel')}
            </Button>
          </FormActions>
          <Card className="custom-card padding-sm">
            <CustomScrollbars hideTracksWhenNotNeeded autoHeightMin="65vh" autoHeight>
              <SwaggerUI
                plugins={[plugin]}
                spec={{ openapi: '3.0.0', ...connectorSettings }}
              />
            </CustomScrollbars>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DocumentationPage;
