import { Spinner } from '@blueprintjs/core';
import React, { FC } from 'react';

// Styles
import './button-styles.scss';

export enum ButtonSize {
  small = 'small',
  default = '',
}

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  children: string;
  size?: ButtonSize;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
}

const getClassNames = (initial: string, outlined: boolean, size: ButtonSize) => {
  return [initial, ...(outlined ? ['outlined'] : []), size].join(' ');
};

const Button: FC<IButtonProps> = ({
  loading = false,
  outlined = false,
  disabled = false,
  children,
  size = ButtonSize.default,
  type = 'button',
  className = '',
  ...props
}) => {
  return (
    <button
      {...props}
      type={type}
      className={getClassNames('button-custom ' + className, outlined, size)}
      disabled={disabled || loading}
    >
      {loading && <Spinner size={20} />}
      <span>{children}</span>
    </button>
  );
};

export default Button;
