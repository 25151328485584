import axios, { AxiosBasicCredentials, AxiosPromise, CancelToken } from 'axios';
import PersistTokenService from './PersistTokenService';
import i18n from '../i18n';
import { DEFAULT_LANG_KEY } from '../redux/slices/language/languages';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST || 'https://ipaas.flow5.io/';

i18n.on('languageChanged', (lang) => {
  axios.defaults.baseURL =
    process.env.REACT_APP_API_HOST ||
    `https://ipaas.flow5.io/${lang === DEFAULT_LANG_KEY ? '' : lang + '/'}`;
});

export interface ParamsInterface {
  [key: string]: any;
}

interface HeadersInterface {
  [key: string]: string;
}

export interface IQuery {
  [key: string]: any;
}

export enum RequestType {
  get = 'get',
  post = 'post',
  delete = 'delete',
  patch = 'patch',
  put = 'put',
}

interface DataType {
  [key: string]: any;
}

interface RequestParamsInterface {
  url: string;
  type?: RequestType;
  params?: ParamsInterface;
  data?: DataType;
  headers?: HeadersInterface;
  auth?: AxiosBasicCredentials;
  cancelToken?: CancelToken;
}

class BaseApiService {
  constructor(private readonly baseUrl: string = '') {}

  static getHeaders(headers: HeadersInterface = {}) {
    const token = PersistTokenService.get();

    return {
      ...headers,
      'Content-Type': 'application/json',
      ...(token && { Authorization: 'Basic ' + btoa(token + ':') }),
    };
  }

  static cancelToken = axios.CancelToken;

  getUrl(uri = ''): string {
    const divider = '?/'.includes(uri[0]) || !uri ? '' : '/';

    return `${this.baseUrl}${divider}${uri}`;
  }

  request(params: RequestParamsInterface): AxiosPromise {
    return axios.request({
      ...params,
      url: this.getUrl(params.url),
      headers: BaseApiService.getHeaders(params.headers),
      method: params.type,
    });
  }

  mockRequest(
    params: RequestParamsInterface,
    isFailed = false,
    timeout = 1000,
    successData?: any,
    errorData?: any
  ): AxiosPromise {
    return new Promise((resolve, reject) => {
      if (isFailed) {
        setTimeout(
          () =>
            reject({
              config: {},
              code: 500,
              request: { ...params },
              ...errorData,
              response: {
                data: params.data,
                status: 500,
                statusText: 'Failed',
                headers: params.headers,
                config: {},
                ...errorData?.response,
              },
              isAxiosError: true,
            }),
          timeout
        );
      } else {
        setTimeout(
          () =>
            resolve({
              data: params.data,
              status: 200,
              statusText: 'Success',
              headers: params.headers,
              config: {},
              ...successData,
            }),
          timeout
        );
      }
    });
  }
}

export default BaseApiService;
