import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../components/button/Button';
import { Row, Col, Grid } from 'react-bootstrap';
import { Callout, H2, H4 } from '@blueprintjs/core';
import ConnectorsList from '../../components/Wedoio/Connector/list/ConnectorsList';
// Selectors
// Styles
import './connectors-styles.scss';

const ConnectorsPage: FC = () => {
  const history = useHistory();

  const { t } = useTranslation(['connectors']);

  const handleClick = useCallback(() => {
    history.push('/connectors/create/settings');
  }, [history]);

  return (
    <Grid>
      <Row className={'main-row'}>
        <Col md={12}>
          <Callout className="margin-top-sm margin-bottom-sm">
            <Row className="padding-sm">
              <Col sm={8}>
                <H4>{t('titles.Build a new connector')}</H4>
              </Col>
              <Col sm={4}>
                <Button onClick={handleClick}>{t('buttons.Start')}</Button>
              </Col>
            </Row>
          </Callout>
          <H2>{t('titles.Connectors')}</H2>
          <ConnectorsList />
        </Col>
      </Row>
    </Grid>
  );
};

export default ConnectorsPage;
