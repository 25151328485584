import React, { FC, useCallback, useState } from 'react';
// Components
import { Col } from 'react-bootstrap';
import { Icon, Collapse } from '@blueprintjs/core';
import EditorRow from './EditorRow';
import EditorValue from './EditorValue';
import EditorCreateRow from './EditorCreateRow';
// Utils
import { getNewPath, getParentPath } from '../utils';
// Types
import { IValueKey } from '../../generic-selector/GenericSelector';

interface IEditorResultRowProps {
  onEditValueRequest: (path: string) => void;
  onFieldNameChange: (path: string, newFieldName: string) => void;
  onAddField: (path: string, newFieldName: string, value: string | object) => void;
  onRemoveField: (path: string) => void;
  currentValue: string | object;
  fieldName: string;
  path: string;
  onEditValue: (value: string) => void;
  handleAddOptionKey: (newValue: any) => void;
  handleAddOptionValue: (newValue: any) => void;
  optionsKeys: IValueKey[];
  optionsValues: IValueKey[];
}

const EditorResultRow: FC<IEditorResultRowProps> = ({
  fieldName,
  onFieldNameChange,
  onAddField,
  onRemoveField,
  onEditValueRequest,
  handleAddOptionKey,
  handleAddOptionValue,
  path,
  currentValue,
  onEditValue,
  optionsKeys,
  optionsValues,
}) => {
  const [showCreateNestedField, setShowCreateNestedField] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    onEditValueRequest(path);
  }, [path, onEditValueRequest]);

  const handleChange = useCallback(
    (newFieldName) => {
      onFieldNameChange(path, newFieldName);
    },
    [path, onFieldNameChange]
  );

  const handleAddNested = useCallback(
    (newFieldName, newValue) => {
      onAddField(path, newFieldName, newValue);
      setShowCreateNestedField(false);
    },
    [path, onAddField]
  );

  const handleRemove = useCallback(() => {
    onRemoveField(path);
  }, [path, onRemoveField]);

  const cancelShowCreateNestedField = () => {
    setShowCreateNestedField(false);
  };

  return (
    <EditorRow
      optionsKeys={optionsKeys}
      path={getParentPath(path)}
      fieldName={fieldName}
      onFieldNameChange={handleChange}
      handleAddOptionKey={handleAddOptionKey}
      controls={
        <>
          {!showCreateNestedField && fieldName && (
            <Icon
              className="add-field-button"
              icon="circle-arrow-right"
              onClick={() => setShowCreateNestedField(true)}
            />
          )}
          <Icon className="remove-field-button" icon="remove" onClick={handleRemove} />
        </>
      }
    >
      {!currentValue ||
      typeof currentValue !== 'object' ||
      Array.isArray(currentValue) ||
      !Object.keys(currentValue).length
        ? !showCreateNestedField && (
            <>
              <Col xs={6} className="source" onClick={handleClick}>
                <EditorValue
                  optionsValues={optionsValues}
                  handleAddOptionValue={handleAddOptionValue}
                  value={
                    currentValue && typeof currentValue !== 'object'
                      ? String(currentValue)
                      : ''
                  }
                  onEditValue={onEditValue}
                />
              </Col>
            </>
          )
        : Object.entries(currentValue).map(([subFieldName, subFieldValue]) => (
            <EditorResultRow
              optionsKeys={optionsKeys}
              optionsValues={optionsValues}
              handleAddOptionKey={handleAddOptionKey}
              handleAddOptionValue={handleAddOptionValue}
              key={getNewPath(path, subFieldName)}
              fieldName={subFieldName}
              path={getNewPath(path, subFieldName)}
              currentValue={subFieldValue}
              onEditValueRequest={onEditValueRequest}
              onFieldNameChange={onFieldNameChange}
              onAddField={onAddField}
              onRemoveField={onRemoveField}
              onEditValue={onEditValue}
            />
          ))}{' '}
      <Collapse isOpen={showCreateNestedField} className="add-field-collapse">
        <EditorCreateRow
          optionsKeys={optionsKeys}
          optionsValues={optionsValues}
          handleAddOptionKey={handleAddOptionKey}
          handleAddOptionValue={handleAddOptionValue}
          path={path}
          onAdd={handleAddNested}
          onCancelShowCreateField={cancelShowCreateNestedField}
        />
      </Collapse>
    </EditorRow>
  );
};

export default EditorResultRow;
