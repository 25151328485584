import React, { FC, ReactNode, useEffect } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
// Components
import Header from '../../components/header/Header';
// import IntercomWidget from '../../components/intercom/IntercomWidget';

// Actions
import { setParameters } from 'src/redux/slices/auth/redirect-params/redirectParamsSlice';

// Selectors
import { selectSignupState } from '../../redux/slices/auth/signup/selectors';

// Consts
import { DASHBOARD_ROUTE } from '../../routes/Routes';

// Images
import loginPicture from '../../images/backgroundwedoio.svg';

// Styles
import './auth-layout-styles.scss';

// Hooks
import useRedirectIfAuthenticated from '../../utils/useRedirectIfAuthenticated';
import useRedirectSearchParams from './useRedirectSearchParams';
// Utils
import { getRedirectSearchParams } from './utils';

export interface IAuthLayoutProps {
  children: ReactNode;
  background?: boolean;
  size?: number;
}

const AuthLayout: FC<IAuthLayoutProps> = ({ children, size = 6, background = true }) => {
  const { integration } = useSelector(selectSignupState);
  const redirectParameters = useRedirectSearchParams();
  const location = useLocation();

  const dispatch = useDispatch();

  useRedirectIfAuthenticated(
    integration?.slug ? `${DASHBOARD_ROUTE}${integration.slug}` : `/${redirectParameters}`
  );

  useEffect(() => {
    dispatch(setParameters(getRedirectSearchParams(location.search)));
  }, [location.search, dispatch]);

  return (
    <div className="auth-container">
      <Header />
      <Grid>
        <Row>
          <Col md={size}>{children}</Col>
        </Row>
      </Grid>
      {background && (
        <div className="background">
          <img src={loginPicture} alt="background" />
        </div>
      )}
      {/* <IntercomWidget /> */}
    </div>
  );
};

export default AuthLayout;
