import React, { FC, useState, useCallback, useMemo, ReactNode } from 'react';
import { Icon, Popover } from '@blueprintjs/core';
import { Row, Col } from 'react-bootstrap';
// Hooks
import useSimpleArrayEditorContex from '../context/useSimpleArrayEditorContext';
import GenericSelector, {
  IValueKey,
} from 'src/components/generic-selector/GenericSelector';

interface IEditorRowProps {
  onFieldNameChange: (newFieldName: string) => void;
  onCancel?: () => void;
  onAddRowValues?: () => void;
  handleAddOptionKey: (newValue: any) => void;
  fieldName: string;
  path: string;
  controls?: ReactNode;
  children?: ReactNode;
  optionsKeys: IValueKey[];
}

const EditorRow: FC<IEditorRowProps> = ({
  fieldName,
  onFieldNameChange,
  handleAddOptionKey,
  onCancel,
  onAddRowValues,
  children,
  path,
  controls,
  optionsKeys,
}) => {
  const [inputValue, setInputValue] = useState<string>(fieldName);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { checkIsValidFieldName } = useSimpleArrayEditorContex();

  const selectedItem = useMemo(() => optionsKeys.filter(({ Key }) => Key === fieldName), [
    optionsKeys,
    fieldName,
  ]);

  const isValid = useMemo(
    () =>
      inputValue !== '' &&
      inputValue !== fieldName &&
      checkIsValidFieldName(inputValue, path),
    [checkIsValidFieldName, inputValue, fieldName, path]
  );

  const handleEdit = useCallback(() => {
    if (onAddRowValues) {
      onAddRowValues();
    }
    if (isValid) {
      onFieldNameChange(inputValue);
    } else {
      setInputValue(fieldName);
    }
  }, [inputValue, onFieldNameChange, isValid, fieldName, onAddRowValues]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    setInputValue(fieldName);
  }, [onCancel, fieldName]);

  const handleChange = (newValue) => {
    const [{ Key } = { Key: undefined }] = newValue;

    if (onFieldNameChange && Key) {
      onFieldNameChange(Key);
    }
  };

  return (
    <Row className="editor-row">
      <Col xs={6} className="destination">
        <div className="field-controls">
          {!controls || fieldName !== inputValue ? (
            <>
              {fieldName && (
                <Icon icon="confirm" className="add-field-button" onClick={handleEdit} />
              )}
              <Icon
                icon="circle-arrow-left"
                className="remove-field-button"
                onClick={handleCancel}
              />
            </>
          ) : (
            controls
          )}
        </div>
        <Popover
          minimal
          isOpen={isOpen}
          placement="bottom-start"
          enforceFocus={false}
          autoFocus={false}
          popoverClassName="options-list"
          onInteraction={(isPopoverOpen) => {
            setIsOpen(isPopoverOpen);
          }}
        >
          <GenericSelector
            items={optionsKeys}
            isMultiSelector={false}
            selectedItem={selectedItem}
            onValueChanged={handleChange}
            onAddOption={handleAddOptionKey}
            allowCreate
          />
        </Popover>
      </Col>
      {children}
    </Row>
  );
};

export default EditorRow;
