import { useContext } from 'react';

import {
  ISimpleArrayEditorContext,
  SimpleArrayEditorContext,
} from './SimpleArrayEditorContextProvider';

const useSimpleArrayEditorContext = (): ISimpleArrayEditorContext => {
  return useContext(SimpleArrayEditorContext);
};

export default useSimpleArrayEditorContext;
