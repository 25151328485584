import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
// Components
import Button, { ButtonSize } from '../../../../../components/button/Button';
// Utils
import useChangeHookStatus from './useChangeHookStatus';
// Types
import { RequestStatus } from '../../../../../redux/slices/types';

interface IHookStatusListProps {
  event: string;
  status: boolean;
  entity?: string;
  widgetKey: string;
}

const HookStatus: FC<IHookStatusListProps> = ({
  event,
  status: initialStatus,
  entity,
  widgetKey,
}) => {
  const { t } = useTranslation(['dashboard']);

  const [status, submit, cangeStatus] = useChangeHookStatus({
    event,
    entity,
    widgetKey,
    initialStatus,
  });
  return (
    <Row key={event} className="margin-bottom-xxs margin-top-xxs">
      <Col sm={6} className="event-title">
        <strong>{event}</strong>
      </Col>
      <Col sm={6}>
        <Button
          size={ButtonSize.default}
          onClick={() => {
            cangeStatus();
          }}
          loading={submit === RequestStatus.pending}
        >
          {status ? t('buttons.Enabled') : t('buttons.Disabled')}
        </Button>
      </Col>
    </Row>
  );
};

export default HookStatus;
