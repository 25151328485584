import React, { FC, useCallback, useState, useEffect } from 'react';
import { InputGroup, Card } from '@blueprintjs/core';
import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from '../../../redux/slices/types';
import { IMarketplaceBrowser } from '../componentsMap';
// Selectors
import {
  selectMarketplaceState,
  selectSelectedIntegration,
} from '../../../redux/slices/marketplace/selectors';
// Actions
import {
  selectIntegration,
  removeFilterTag,
  resetFilters,
} from '../../../redux/slices/marketplace/marketplaceSlice';
// Components
import Tag from '../../../components/tag/Tag';
import MarketplaceIntegrationsList from './integrations/MarketplaceIntegrationsList';
import MarketplaceIntegrationTeaserSelected from './integrations/selected/MarketplaceIntegrationTeaserSelected';

// Style
import './styles.scss';

const MarketplaceBrowser: FC = () => {
  const [filterValue, setFilterValue] = useState<string>('');

  const { data, loading, tags } = useSelector(selectMarketplaceState);

  const selectedIntegration = useSelector(selectSelectedIntegration);

  const { t } = useTranslation(['home']);

  const dispatch = useDispatch();

  const handleFilterChange = useCallback(
    (e) => {
      setFilterValue(e.currentTarget.value);
      dispatch(selectIntegration(undefined));
    },
    [dispatch]
  );

  const onTagClick = useCallback(
    (tagName) => {
      dispatch(removeFilterTag(tagName));
    },
    [dispatch]
  );

  // Component unmount
  useEffect(
    () => () => {
      dispatch(resetFilters());
    },
    [dispatch]
  );

  return (
    <Row className="main-row">
      <Col xs={12} className="marketplace-integrations-wrapper">
        <Card className="custom-card marketplace-browser">
          <Row className="marketplace-browser-search">
            <Col md={12}>
              <InputGroup
                placeholder={t('inputs.Filter')}
                onChange={handleFilterChange}
                leftIcon="search"
              />
            </Col>
          </Row>
          <Row>
            {!!tags.length && (
              <Col md={12}>
                <div className="marketplace-filter-tags-list">
                  {tags.map((tag) => (
                    <Tag
                      intent="primary"
                      large
                      className="marketplace-integration-tag"
                      key={tag}
                      onClick={() => onTagClick(tag)}
                      icon="cross"
                    >
                      {tag}
                    </Tag>
                  ))}
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={12}>
              {!selectedIntegration && (
                <MarketplaceIntegrationsList
                  integrations={data}
                  filterValue={filterValue}
                  filterTags={tags}
                  loading={loading === RequestStatus.pending}
                />
              )}
              {selectedIntegration && (
                <MarketplaceIntegrationTeaserSelected integration={selectedIntegration} />
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export const mapMarketplaceBrowserProps = (props: IMarketplaceBrowser) => props;

export default MarketplaceBrowser;
