import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { RequestStatus } from '../../types';
import { TestConnectorState, TestRequestData } from './types';
import { RequestType } from 'src/services/BaseApiService';
import { IConnectorRequest } from 'src/types/internal-Types';
// Thunks
import { testConnector, authorizeConnector } from './thunks';
// Utils
import { createThunkReducers } from '../../utils';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

export const initialValues: TestRequestData = {
  action: '',
};

const initialState: TestConnectorState = {
  error: null,
  submit: RequestStatus.idle,
  authRequest: RequestStatus.idle,
  initialValues,
  authInitialValues: {},
};

const createConnectorSlice = createSlice({
  name: 'connectors.test',
  initialState,
  reducers: {
    reset: (): TestConnectorState => initialState,
    setSelectedRequest: (
      state,
      action: PayloadAction<IConnectorRequest>
    ): TestConnectorState => {
      const { operationId, parameters, method } = action.payload;

      return {
        ...state,
        submit: RequestStatus.idle,
        error: null,
        selectedRequest: action.payload,
        testingResult: undefined,
        initialValues: {
          action: operationId,
          ...mapValues(groupBy(parameters, 'name'), () => ''),
          ...(method === RequestType.patch ||
          method === RequestType.post ||
          method === RequestType.put
            ? { data: {} }
            : {}),
        },
      };
    },
  },
  extraReducers: {
    ...createThunkReducers(testConnector, RequestType.post),
    ...createThunkReducers(authorizeConnector, RequestType.put, 'authRequest'),
  },
});

export const { reset, setSelectedRequest } = createConnectorSlice.actions;

export default createConnectorSlice.reducer;
