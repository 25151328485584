import React, { FC, ReactNode } from 'react';
import { Tabs, TabId } from '@blueprintjs/core';

import './styles.scss';

interface ITabsProps {
  className?: string;
  id: string;
  selectedTabId?: TabId;
  vertical?: boolean;
  children: ReactNode;
  onChange?: (newTabId: TabId) => void;
}

const CustomTabs: FC<ITabsProps> = ({
  className,
  id,
  selectedTabId,
  vertical = false,
  children,
  onChange,
}) => {
  return (
    <Tabs
      className={`custom-tabs ${className ?? ''}`}
      animate
      id={id}
      selectedTabId={selectedTabId}
      vertical={vertical}
      onChange={onChange}
    >
      {children}
    </Tabs>
  );
};

export default CustomTabs;
