import React, { FC, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RouteChildrenProps, useHistory } from 'react-router';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import AppLayout from '../layouts/app/AppLayout';
import { toastr } from 'react-redux-toastr';
// Types
import { RootState } from '../redux/rootReducer';
// Selectors
import { selectToken } from '../redux/slices/auth/token/selectors';
import usePermissions from 'src/utils/check-permission/usePermissions';
// Utils
import { UserPermissions } from '../types/enums';

interface IPrivateRouteProps extends RouteProps {
  redirectTo?: string;
  layout?: React.ElementType;
  requiredPermissions?: UserPermissions[] | UserPermissions;
}

const PrivateRoute: FC<IPrivateRouteProps> = ({
  layout: Layout = AppLayout,
  redirectTo = '/login',
  requiredPermissions,
  children,
  ...props
}) => {
  const token = useSelector<RootState>(selectToken);

  const [hasPermissions] = usePermissions(requiredPermissions);

  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    if (requiredPermissions && hasPermissions !== undefined && !hasPermissions) {
      toastr.error(t('alert:Error'), t('alert:Permission'));
      history.push(redirectTo);
    }
  }, [requiredPermissions, hasPermissions, t, history, redirectTo]);

  if (!token) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Route {...props}>
      {(childrenProps) => (
        <Layout>
          {typeof children === 'function'
            ? (children as (props: RouteChildrenProps<any>) => ReactNode)(childrenProps)
            : children}
        </Layout>
      )}
    </Route>
  );
};

export default PrivateRoute;
