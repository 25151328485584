import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import CompaniesService from '../../../../services/CompaniesService';

// Types
import { GetCompaniesActionSuccessResult } from './types';

export const getCompanies = createAsyncThunk<GetCompaniesActionSuccessResult>(
  'companies.list/getCompaniesList',
  async (_, { rejectWithValue }) => {
    try {
      const result = await CompaniesService.getList();

      return {
        data: result.data || [],
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);
