import React, { FC } from 'react';

// Types
import { IImage } from './block-types';

// Utils
import getClassName from '../utils/getClassName';

export interface IImageProps {
  src: string;
  alt: string;
  className?: string;
}

const Image: FC<IImageProps> = ({ className, src = '', alt = '' }) => {
  return <img className={className} src={src} alt={alt} />;
};

export default Image;

export const mapImageProps = ({ classes = [], ...props }: IImage) => {
  return {
    ...props,
    className: getClassName(classes),
  };
};
