import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Services
import { RequestType } from '../../../../services/BaseApiService';
// Types
import { RequestStatus } from '../../types';
import { EditConnectorState } from './types';
// Utils
import { createThunkReducers } from '../../utils';
// Thunks
import { editConnector } from './thunks';

import { initialValues } from '../create/createConnectorSlice';
import { CreateConnectorFormData } from 'src/types/internal-Types';

const initialState: EditConnectorState = {
  error: null,
  submit: RequestStatus.idle,
  data: initialValues,
};

const editConnectorSlice = createSlice({
  name: 'connectors.edit',
  initialState,
  reducers: {
    reset: (): EditConnectorState => initialState,
    setConnectorData: (
      state,
      action: PayloadAction<CreateConnectorFormData>
    ): EditConnectorState => {
      return {
        ...state,
        data: action.payload,
        submit: RequestStatus.idle,
        error: null,
      };
    },
  },
  extraReducers: {
    ...createThunkReducers(editConnector, RequestType.put),
  },
});

export const { reset, setConnectorData } = editConnectorSlice.actions;

export default editConnectorSlice.reducer;
