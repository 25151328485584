import { useQueryCache } from 'react-query';
import { useEffect, useRef } from 'react';
const UPDATE_INTERVAL = 60000;

const useRefreshInterval = (
  queryKey: string,
  isActive = false,
  refreshInterval = UPDATE_INTERVAL
) => {
  const queryCache = useQueryCache();
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (isActive) {
      interval.current = setInterval(() => {
        queryCache.invalidateQueries(queryKey);
      }, refreshInterval);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [queryCache, queryKey, refreshInterval, isActive]);
};

export default useRefreshInterval;
