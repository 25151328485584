import React, { FC, useState } from 'react';

// Types
import { IButton } from './input-types';
import { RequestStatus } from '../../../redux/slices/types';
// Components
import ButtonComponent from '../../button/Button';
import ButtonLink from '../../button-link/ButtonLink';

// Utils
import { useDynamicLayoutContext } from 'wedoio-dynamic-layout';
import getClassName from '../utils/getClassName';

interface IButtonProps {
  payload: string;
  callback: string;
  value: string;
  wrapper: string;
  id: string;
  type: string;
  asLink: boolean;
  className?: string;
}

const Button: FC<IButtonProps> = ({
  payload,
  callback = '',
  value = 'Button',
  wrapper = '',
  id = '',
  type = 'button',
  asLink = false,
  className,
}) => {
  const { updateLayoutPart, callService } = useDynamicLayoutContext();
  const [loading, setLoading] = useState<RequestStatus>(RequestStatus.idle);
  // Handlers
  const onClick = async () => {
    try {
      setLoading(RequestStatus.pending);
      if (callService) {
        const response = await callService({
          data: { type, id, value, callback, payload, wrapper },
          submitPayload: payload,
          callbackUrl: callback,
          updateLayoutPart,
        });
        if (!response) {
          throw new Error('Error');
        }
        if (response) {
          setLoading(RequestStatus.fulfilled);
          updateLayoutPart({ id: wrapper, newStructure: response });
        }
      }
    } catch (error) {
      setLoading(RequestStatus.rejected);
    }
  };
  return (
    <>
      {!asLink && (
        <ButtonComponent
          disabled={loading === RequestStatus.pending}
          type="button"
          onClick={onClick}
          outlined
          loading={loading === RequestStatus.pending}
          className={className}
        >
          {value}
        </ButtonComponent>
      )}
      {asLink && (
        <ButtonLink
          disabled={loading === RequestStatus.pending}
          onClick={onClick}
          loading={loading === RequestStatus.pending}
        >
          {value}
        </ButtonLink>
      )}
    </>
  );
};

export const mapButtonProps = ({ classes, ...props }: IButton) => ({
  ...props,
  className: getClassName(classes),
});

export default Button;
