import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import { H5, Icon, NonIdealState } from '@blueprintjs/core';
import RequestForm, { requestTypes } from './RequestForm';
// Types
import { CreateConnectorFormData, IConnectorRequest } from 'src/types/internal-Types';

interface IRequestsListProps {
  values: IConnectorRequest[];
  path: string;
}

const RequestsList: FC<IRequestsListProps> = ({ values, path }) => {
  const { setFieldValue } = useFormikContext<CreateConnectorFormData>();
  const { t } = useTranslation(['connectors']);

  const requestMethodsLeft = useMemo(
    () =>
      requestTypes.filter((type) => !values.find(({ method }) => type.Key === method)),
    [values]
  );

  const handleAdd = useCallback(() => {
    const currentValue = [...values];
    currentValue.push({
      operationId: t('inputs.newRequest'),
      responses: [],
      tags: [],
      parameters: [],
      requestBody: {
        content: [],
      },
      security: [],
      useGlobalSecurity: true,
    });
    setFieldValue(`${path}.requests`, currentValue);
  }, [setFieldValue, values, path, t]);

  const handleRemove = useCallback(
    (index: number) => {
      const currentValue = [...values];
      currentValue.splice(index, 1);

      setFieldValue(`${path}.requests`, currentValue);
    },
    [setFieldValue, values, path]
  );

  return (
    <>
      <div className="connector-section-title">
        <H5 className="margin-bottom-sm">{t('titles.Requests')}</H5>
        {!!requestMethodsLeft.length && (
          <Icon icon="plus" className="edit-list-icon" onClick={handleAdd} />
        )}
      </div>
      {values.length ? (
        values.map((request, i) => (
          <RequestForm
            key={i}
            data={request}
            path={`${path}.requests.${i}`}
            onRemove={() => handleRemove(i)}
            types={requestMethodsLeft}
          />
        ))
      ) : (
        <div>
          <NonIdealState icon="error" title={t('No requests found')} />
        </div>
      )}
    </>
  );
};

export default RequestsList;
