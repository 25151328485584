import React, { FC, useCallback, useState } from 'react';
// Component
import Password from '../../../../../components/password/Password';

// Types
import { IPassword } from '../../../../../components/dynamic-layout/components/input-types';

// Utils
import getClassName from '../../../../../components/dynamic-layout/utils/getClassName';

const PreviewPassword: FC<IPassword> = ({ label, name, className }) => {
  const [value, setValue] = useState<string>('');
  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <Password
      value={value}
      onChange={handleChange}
      label={label}
      placeholder={label}
      name={name}
      className={className}
    />
  );
};

export default PreviewPassword;

export const mapPasswordProps = ({ key, label, classes }: IPassword) => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
});
