import React, { FC } from 'react';
import Tag from '../../tag/Tag';
// Types
import { IJsonEditorOption } from './types';

interface IJsonEditorOptionProps extends IJsonEditorOption {
  onClick: (value: string) => void;
}

const Option: FC<IJsonEditorOptionProps> = ({ onClick, label, value }) => {
  return (
    <Tag
      className="json-editor-option"
      large
      minimal
      intent="primary"
      onClick={() => {
        onClick(value);
      }}
    >
      {label}
    </Tag>
  );
};

export default Option;
