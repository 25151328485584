import React, { FC, useState, useCallback } from 'react';
import _ from 'lodash';
// Components
import Suggest from './Suggest';
// Services
import AutocompleteServise from '../../services/AutocompleteServise';
// Utils
import { stringifyFormData } from '../../utils/stringifyFormData';
// Types
import { IValueKey } from '../generic-selector/GenericSelector';

interface IAutocompleteProps {
  callback: string;
  payload: string;
  placeholder?: string;
  preSelectedItem?: IValueKey;
  onChange?: (value: IValueKey | undefined) => void;
  validationError?: string | false | undefined;
  minQueryLength?: number;
  options?: IValueKey[];
  className?: string;
}

const Autocomplete: FC<IAutocompleteProps> = ({
  callback,
  payload,
  onChange,
  placeholder,
  preSelectedItem,
  validationError,
  minQueryLength = 1,
  options: initialOptions,
  className,
}) => {
  const [options, setOptions] = useState<IValueKey[]>(initialOptions ?? []);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchOptions = useCallback(
    _.debounce(async (query: string) => {
      setLoading(true);
      const data = await AutocompleteServise.requestOptions(
        callback,
        stringifyFormData(query, payload, '$query')
      );

      setOptions(data ?? []);
      setLoading(false);
    }, 300),
    [callback]
  );

  // Handlers
  const handleValueChange = useCallback(
    (value: IValueKey | undefined) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  const handleInputChange = useCallback(
    (value: string) => {
      if (value.length > minQueryLength) {
        fetchOptions(value);
      }
    },
    [fetchOptions, minQueryLength]
  );

  return (
    <div className="autocomplete">
      <Suggest
        placeholder={placeholder}
        preSelectedItem={preSelectedItem}
        items={options}
        onValueSelected={handleValueChange}
        onInputValueChange={handleInputChange}
        validationError={validationError}
        loading={loading}
        className={className}
      />
    </div>
  );
};

export default Autocomplete;
