import React, { FC, useState, useCallback, useEffect } from 'react';
// Components
import { Row, Col } from 'react-bootstrap';
import { Card } from '@blueprintjs/core';
import EditorTable from './editor-table/EditorTable';
// Context
import SimpleArrayEditorContextProvider from './context/SimpleArrayEditorContextProvider';
// Styles
import './style.scss';
// Utils
import { replaceLastPathLevel } from './utils';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
// Types
import { IValueKey } from '../generic-selector/GenericSelector';

interface ISimpleArrayEditorProps {
  initialValue: object;
  onChange: (result: object) => void;
  className?: string;
  availableValues: IValueKey[];
  availableKeys: IValueKey[];
}

const SimpleArrayEditor: FC<ISimpleArrayEditorProps> = ({
  onChange,
  initialValue,
  className = '',
  availableValues,
  availableKeys,
}) => {
  const [result, setResult] = useState<object>(initialValue);
  const [editingPath, setEditingPath] = useState<string | undefined>(undefined);

  const handleEditValueRequest = useCallback((path: string) => {
    setEditingPath(path);
  }, []);

  const handleEditValue = useCallback(
    (value: string) => {
      if (editingPath) {
        const newResult = cloneDeep(result ?? {});
        set(newResult, editingPath, value);
        setResult(newResult);
        setEditingPath(undefined);
      }
    },
    [result, editingPath]
  );

  const handleEditFieldName = useCallback(
    (path: string, newFieldName: string) => {
      const newResult = cloneDeep(result ?? {});
      const valueByPath = get(newResult, path) ?? '';
      const newPath = replaceLastPathLevel(path, newFieldName);
      unset(newResult, path);
      set(newResult, newPath, valueByPath);
      setResult(newResult);
    },
    [result]
  );

  const handleAddField = useCallback(
    (path: string, newFieldName: string, value: string | object) => {
      let newResult = cloneDeep(result ?? {});
      if (path) {
        const valueByPath = get(newResult, path) ?? '';
        set(newResult, path, {
          ...(typeof valueByPath === 'object' && !Array.isArray(valueByPath)
            ? valueByPath
            : {}),
          [newFieldName]: value,
        });
      } else {
        newResult = { ...newResult, [newFieldName]: value };
      }
      setResult(newResult);
    },
    [result]
  );

  const handleRemoveField = useCallback(
    (path: string) => {
      const newResult = cloneDeep(result ?? {});
      unset(newResult, path);
      setResult(newResult);
    },
    [result]
  );

  useEffect(() => {
    if (result !== initialValue) {
      onChange(result);
    }
  }, [result, initialValue, onChange]);

  const [optionsValues, setOptionsValues] = useState<IValueKey[]>(availableValues);
  const [optionsKeys, setOptionsKeys] = useState<IValueKey[]>(availableKeys);

  const [createdKeys, setCreatedKeys] = useState<IValueKey[]>([]);
  const [createdValues, setCreatedValues] = useState<IValueKey[]>([]);

  const onDeleteItem = (
    items: IValueKey[],
    created: IValueKey[],
    newValue: IValueKey
  ) => {
    const wasCreatedByUser = created.some((item) => item.Key === newValue?.Key);
    return {
      createdItems: wasCreatedByUser
        ? created.filter((item) => item.Key !== newValue.Key)
        : created,
      options: wasCreatedByUser
        ? items.filter((item) => item.Key !== newValue.Key)
        : items,
    };
  };

  const onAddItem = (items: IValueKey[], created: IValueKey[], newValue: IValueKey) => {
    const isAlreadyCreated = items.some((item) => item.Key === newValue.Key);
    return {
      createdItems: isAlreadyCreated ? created : [...created, newValue],
      options: isAlreadyCreated ? items : [...items, newValue],
    };
  };

  const handleAddOptionKey = (newValue) => {
    const step1 = onDeleteItem(optionsKeys, createdKeys, newValue);
    const step2 = onAddItem(step1.options, step1.createdItems, newValue);
    setCreatedKeys(step2.createdItems);
    setOptionsKeys(step2.options);
  };

  const handleAddOptionValue = (newValue) => {
    const step1 = onDeleteItem(optionsValues, createdValues, newValue);
    const step2 = onAddItem(step1.options, step1.createdItems, newValue);
    setCreatedValues(step2.createdItems);
    setOptionsValues(step2.options);
  };

  return (
    <SimpleArrayEditorContextProvider result={result}>
      <Card className={`custom-card ${className}`}>
        <Row>
          <Col md={12}>
            <EditorTable
              optionsKeys={optionsKeys}
              optionsValues={optionsValues}
              handleAddOptionKey={handleAddOptionKey}
              handleAddOptionValue={handleAddOptionValue}
              editorValue={result}
              onEditValueRequest={handleEditValueRequest}
              onFieldNameChange={handleEditFieldName}
              onAddField={handleAddField}
              onRemoveField={handleRemoveField}
              onEditValue={handleEditValue}
            />
          </Col>
        </Row>
      </Card>
    </SimpleArrayEditorContextProvider>
  );
};

export default SimpleArrayEditor;
