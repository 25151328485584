import { EDashboardComponents } from '../../../../types/enums';
import { RootState } from '../../../rootReducer';
import { IComponentWithSettings } from './types';
import { IComponent } from '../../../../types/api-Types';

export const selectComponent = (key: string) => (
  state: RootState
): IComponentWithSettings => {
  return state.dashboard.components[key];
};

export const selectAvailableComponents = (state: RootState): EDashboardComponents[] => {
  return Object.keys(state.dashboard.components) as EDashboardComponents[];
};

export const selectAvailableComponentsData = (key: string) => (
  state: RootState
): IComponent | undefined => {
  return state.dashboard.connector.data?.components[key];
};
