import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@blueprintjs/core';
// Icons
import Gear from '../../../images/gear.svg';

interface IConfigDrawerButtonProps {
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
}

const ConfigDrawerButton: FC<IConfigDrawerButtonProps> = ({
  disabled,
  loading,
  onClick,
}) => {
  const { t } = useTranslation(['dashboard']);

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <div className={`gear-icon ${disabled ? 'disabled' : ''}`} onClick={handleClick}>
      {!loading && (
        <img
          alt="integration-config-icon"
          title={t('buttons.Integration Settings')}
          src={Gear}
        />
      )}
      {loading && <Spinner size={80} />}
    </div>
  );
};

export default ConfigDrawerButton;
