import getUnixTime from 'date-fns/getUnixTime';
import compareAsc from 'date-fns/compareAsc';
import BaseApiService, { IQuery, RequestType } from './BaseApiService';
// Types
import { IDashboardLog } from 'src/types/internal-Types';
// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';

export interface IDashboardLogsData extends Pick<IDashboardLog, 'action' | 'state'> {
  date: number;
  processing_time: number;
}

export const getFormattedData = (logs): IDashboardLogsData[] => {
  if (!logs || !logs.length) return [];

  return logs
    .map(({ task, created }) => ({
      state: task.state,
      action: task.action,
      date: created * 1000,
      processing_time: task.processing_time,
    }))
    .sort((prev, next) => compareAsc(new Date(prev.date), new Date(next.date)));
};

class ChartsService extends BaseApiService {
  get = async ({ connectorId, startTime, endTime }: IGetChartsDataParams) => {
    const query: IQuery = {
      ...(startTime && { created_gte: getUnixTime(startTime) }),
      ...(endTime && { created_lte: getUnixTime(endTime) }),
    };
    // @ts-ignore
    const { data } = await this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'logs',
          page: 1,
          limit: 5000,
          query,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });

    return {
      data: getFormattedData(data.output.logs),
      totalCount: +data.output.total,
    };
  };
}

export interface IGetChartsDataParams {
  connectorId: string;
  startTime?: Date;
  endTime?: Date;
}

export default new ChartsService('rest/connector');
