import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';
// Actions
import { authorizeConnector } from '../../../../redux/slices/connectors/test/thunks';
// Components
import { Dialog, Button } from '@blueprintjs/core';
import CloseIcon from '../../../../components/icons/CloseIcon';
import componentsMap from '../../../../components/dynamic-layout/components/componentsMap';
// Types
import { IConnectorFormField } from '../../../../types/internal-Types';
import { ISubmitForm } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
import { ParamsInterface } from 'src/services/BaseApiService';

interface IAuthorizationFromProps {
  form?: IConnectorFormField[];
  initialValues: ParamsInterface;
  connectorId: string;
}

const AuthorizationForm: FC<IAuthorizationFromProps> = ({
  form,
  connectorId,
  initialValues,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isFormDataEmpty = !form?.length;

  const dispatch = useDispatch();
  const { t } = useTranslation(['connectors']);

  const handleSubmit = useCallback(
    async ({ data: formData }: ISubmitForm) => {
      await dispatch(authorizeConnector({ id: connectorId, data: formData }));
      setIsOpen(false);
    },
    [dispatch, connectorId]
  );

  const formStructure = useMemo(
    () => ({
      type: 'form',
      children: [
        ...(isFormDataEmpty
          ? []
          : // @ts-ignore
            form.map((field) => ({ ...field, value: initialValues[field.key] ?? '' }))),
        { type: 'submit' },
      ],
    }),
    [form, isFormDataEmpty, initialValues]
  );

  return !isFormDataEmpty ? (
    <>
      <Button
        className="connector-authorization-button"
        minimal
        icon="lock"
        disabled={isFormDataEmpty}
        onClick={() => setIsOpen(true)}
      />
      <Dialog
        className="dialogMenu"
        isCloseButtonShown={false}
        isOpen={isOpen}
        title={t('titles.Authorization')}
        onClose={() => setIsOpen(false)}
      >
        <div className="dialog-close-button" onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </div>
        <StructureErrorBoundary>
          <DynamicLayout
            callService={handleSubmit}
            componentsMap={componentsMap}
            structure={formStructure}
          />
        </StructureErrorBoundary>
      </Dialog>
    </>
  ) : (
    <></>
  );
};

export default AuthorizationForm;
