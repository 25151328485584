import BaseApiService, { RequestType } from './BaseApiService';

// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';

class DashboardLayoutService extends BaseApiService {
  get = async (key, { id, page }) => {
    const { data } = await this.request({
      url: id + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: 'layout',
          page,
        },
        silent: !IntegrationDebugSettingsService.get(id),
      },
    });
    if (data.output.error) {
      throw new Error(data.output.error);
    }
    if (data.output.status) {
      throw new Error(data.output.status);
    }
    return data.output;
  };
  getWidgetLayout = async (key, { id, params }) => {
    const { data } = await this.request({
      url: id + '/dashboard',
      type: RequestType.put,
      data: { ...params, silent: !IntegrationDebugSettingsService.get(id) },
    });
    if (data.output.error) {
      throw new Error(data.output.error);
    }
    if (data.output.status) {
      throw new Error(data.output.status);
    }
    return data.output;
  };

  submitForm = (callback: string = '', data: any, connectorId) =>
    this.request({
      url: callback,
      type: RequestType.put,
      data: { ...data, silent: !IntegrationDebugSettingsService.get(connectorId) },
    });
}

export default new DashboardLayoutService('rest/connector');
