import React, { FC, useCallback, useEffect, useState } from 'react';
import CodeEditor from '../../../../components/code-editor/CodeEditor';

interface IJsonEditorProps {
  initialValue: object;
  onBlur: (newValue: object) => void;
}

const JsonEditor: FC<IJsonEditorProps> = ({ initialValue, onBlur }) => {
  const [value, setValue] = useState(JSON.stringify(initialValue, undefined, 2));
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleChange = useCallback((newValue) => {
    setValue(newValue);
  }, []);

  const handleBlur = useCallback(() => {
    const parsed = JSON.parse(value ? value : '{}');
    if (isValid && typeof parsed === 'object') {
      onBlur(parsed);
    }
  }, [onBlur, isValid, value]);

  const handleValidation = useCallback((errors) => {
    setIsValid(!errors.length);
  }, []);

  useEffect(() => {
    setValue(JSON.stringify(initialValue, undefined, 2));
  }, [initialValue]);

  return (
    <CodeEditor
      className="connector-test-json-editor"
      value={value}
      mode="json"
      onChange={handleChange}
      onBlur={handleBlur}
      onLint={handleValidation}
    />
  );
};

export default JsonEditor;
