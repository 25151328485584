import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, IconName } from '@blueprintjs/core';

interface IConnectorNavigation {
  routes: { path: string; title: string; iconType?: IconName }[];
}

const Navigation: FC<IConnectorNavigation> = ({ routes }) => {
  return (
    <div className="connector-navigation">
      {routes.map(({ title, path, iconType }) => (
        <NavLink
          replace
          key={path}
          className="connector-navigation-link"
          activeClassName="active"
          to={path}
        >
          {iconType && <Icon icon={iconType} />} {title}
        </NavLink>
      ))}
    </div>
  );
};

export default Navigation;
