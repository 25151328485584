import { IconName } from '@blueprintjs/core';
import { IDynamicLayout } from 'src/types/internal-Types';
import { EDashboardComponents, EDashboardTableColTypes } from '../../types/enums';

export enum DashboardTypes {
  'form-actions' = 'form-actions',
  'dashboardTitle' = 'dashboard-title',
  'integrationConfig' = 'integration-config',
  'componentConfig' = 'component-config',
  'dashboardLogs' = 'dashboard-logs',
  'linksManager' = 'links-manager',
  'jobDirector' = 'job-director',
  'activityBarChart' = 'activity-bar-chart',
  'actionsPieChart' = 'actions-pie-chart',
  'tableManager' = 'table-manager',
  'processingTimeChart' = 'processing-time-chart',
  'dashboardCharts' = 'dashboard-charts',
  'cron' = 'cron',
  'barChart' = 'barchart',
  'lineChart' = 'linechart',
  'pieChart' = 'piechart',

  'componentPrototype' = 'component-prototype',

  // Integration-specific components
  'webcrmHooksManager' = 'webcrm-hooks-manager',
  'unicontaHooksManager' = 'uniconta-hooks-manager',
}

export interface IComponentConfig {
  type: DashboardTypes.componentConfig;
  component: EDashboardComponents;
  size?: number;
  classes: string[];
  html: string;
}

export interface IIntegrationConfig {
  type: DashboardTypes.integrationConfig;
}

export interface IDashboardTitle {
  type: DashboardTypes.dashboardTitle;
}

export interface IDashboardLogs {
  type: DashboardTypes.dashboardLogs;
}

export interface IActivityBarChart {
  type: DashboardTypes.activityBarChart;
}

export interface IOperationsPieChart {
  type: DashboardTypes.actionsPieChart;
}
export interface IProcessingTimeChart {
  type: DashboardTypes.processingTimeChart;
}

export interface IDashboardCharts {
  type: DashboardTypes.dashboardCharts;
}

export interface ILinksManager {
  type: DashboardTypes.linksManager;
}

export interface IComponentPrototype {
  type: DashboardTypes.componentPrototype;
  init_call: { [key: string]: any }; // ??
}
export interface IWebcrmHooksManager {
  type: DashboardTypes.webcrmHooksManager;
}

export interface IUnicontaHooksManager {
  type: DashboardTypes.unicontaHooksManager;
}

export interface IJobDirector {
  type: DashboardTypes.jobDirector;
  object: string;
  label?: string;
}

export interface IDashboardTableCols {
  [field: string]: {
    label: string;
    type: EDashboardTableColTypes;
    filter: boolean;
    sort: boolean;
    size?: number;
    classes?: string[];
  };
}

export interface IDashboardTableRowOperation {
  icon?: IconName;
  classes?: string[];
  label?: string;
  action: string;
  open_row?: boolean;
  type: 'button' | 'action';
}

export interface IDashboardTableActons {
  [action: string]: { label: string };
}
export interface ITableManager {
  type: DashboardTypes.tableManager;
  operation: string;
  columns: IDashboardTableCols;
  actions: IDashboardTableActons;
  enable_creation_form: boolean;
  items_per_page: number;
  children: IDynamicLayout;
  initial_query: { [key: string]: any };
}

export interface ICron {
  type: DashboardTypes.cron;
  integration_action: string;
  label?: string;
}

export interface IChartKey {
  key: string;
  color: string;
}

export type IBarOrLineChartData = { name: string } & {
  [value: string]: number;
};

export interface IBarChart {
  type: DashboardTypes.barChart;
  keys: IChartKey[];
  xAxis?: string;
  yAxis?: string;
  data: IBarOrLineChartData[];
  classes?: string[];
}

export type LineType =
  | 'basis'
  | 'basisClosed'
  | 'basisOpen'
  | 'linear'
  | 'linearClosed'
  | 'natural'
  | 'monotoneX'
  | 'monotoneY'
  | 'monotone'
  | 'step'
  | 'stepBefore'
  | 'stepAfter';

export interface ILineChart {
  type: DashboardTypes.lineChart;
  keys: IChartKey[];
  xAxis?: string;
  yAxis?: string;
  data: IBarOrLineChartData[];
  classes?: string[];
  lineType?: LineType;
}

export type IPieChartElement = { name: string; cellColor?: string } & {
  [value: string]: number;
};

export interface IPieChartData {
  pieData: IPieChartElement[];
  color: string;
  outerRadius: number;
  innerRadius: number;
  hasLabel?: boolean;
}

export interface IPieChart {
  type: DashboardTypes.pieChart;
  dataKey: string;
  data: IPieChartData[];
  classes?: string[];
}
