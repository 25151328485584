import React, { FC } from 'react';

// Components
import { Row, Col } from 'react-bootstrap';

import ConnectorFormRoutes from './ConnectorFormRoutes';
import CustomScrollbars from 'src/components/custom-scrollbars/CustomScrollbars';
import FormNavigation from './FormNavigation';
import { Card } from '@blueprintjs/core';

const ConnectorForm: FC = () => {
  return (
    <div>
      <Row className="connector-wrp">
        <Col xs={4} className="connector-menu">
          <FormNavigation />
        </Col>
        <Col xs={8} className="connector-form-wrp">
          <Card className="custom-card padding-md">
            <CustomScrollbars hideTracksWhenNotNeeded autoHeightMin="60vh" autoHeight>
              <ConnectorFormRoutes />
            </CustomScrollbars>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ConnectorForm;
