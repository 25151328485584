import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';
// Styles
import './style.scss';

interface ITextCollapseProps {
  maxHeight: number;
}

const TextCollapse: FC<ITextCollapseProps> = ({ maxHeight, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [offsetHeight, setOffsetHeight] = useState<number | undefined>();
  const container = useRef<HTMLDivElement>(null);

  const toggleCollapse = useCallback(() => {
    if (offsetHeight && offsetHeight > maxHeight) {
      setIsOpen((open) => !open);
    }
  }, [offsetHeight, maxHeight]);

  const onResize = useCallback(() => {
    if (container.current?.offsetParent) {
      setOffsetHeight(container.current?.scrollHeight);
    } else {
      setOffsetHeight(undefined);
    }
  }, [container]);

  useEffect(() => {
    setOffsetHeight(container.current?.scrollHeight);
  }, [container]);

  useEffect(() => {
    setIsOpen(!(offsetHeight && offsetHeight > maxHeight));
  }, [offsetHeight, maxHeight]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return (
    <div className="text-collapse-wrapper">
      <div
        className={`text-collapse ${isOpen ? 'open' : ''}`}
        style={
          offsetHeight
            ? {
                height: isOpen ? offsetHeight + 'px' : maxHeight + 'px',
              }
            : { height: 'auto' }
        }
        ref={container}
        onClick={toggleCollapse}
      >
        {children}
      </div>
      {!!offsetHeight && offsetHeight > maxHeight && (
        <Icon icon="more" className="show-more" onClick={toggleCollapse} />
      )}
    </div>
  );
};

export default TextCollapse;
