import React, { FC } from 'react';
import {
  Alert,
  Card,
  Elevation,
  Icon,
  Intent,
  NonIdealState,
  Button,
  Tooltip,
} from '@blueprintjs/core';
import ArrowIcon from '../../../components/icons/ArrowIcon';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Icons
import BackupIcon from '../../../components/icons/BackupIcon';
import GarbageIcon from '../../../components/icons/GarbageIcon';

// Components
import Loader from '../../../components/loader/Loader';
import useAlert from '../../../utils/useAlert';
import LogItem from './log-item/LogItem';
import Checkbox from '../../../components/checkbox/Checkbox';
import Pagination from '../../../components/pagination/Pagination';
import Popover from '../../../components/popover/Popover';
import TableHeaderCell from '../../../components/table/TableHeaderCell';

// Types
import { LOGS_PER_PAGE } from '../../../redux/slices/dashboard/logs/logsSlice';
import { RequestStatus } from '../../../redux/slices/types';
import { ELogsSortParams } from 'src/services/LogsService';
import { ESort } from 'src/types/enums';

// Selectors
import {
  selectDashboardLogs,
  selectIsSelectedAll,
} from '../../../redux/slices/dashboard/logs/selectors';

export interface ILogsTableProps {
  onLogDelete: (id: string[]) => void;
  onLogReprocess: (id: string[]) => void;
  onLogSelect: (id: string) => void;
  onSelectAll: () => void;
  onSelectVisible: () => void;
  onPageClick: (page: number) => void;
  onSort: (field: string, direction: ESort) => void;
}

const LogsTable: FC<ILogsTableProps> = ({
  onLogDelete,
  onLogReprocess,
  onLogSelect,
  onSelectAll,
  onSelectVisible,
  onPageClick,
  onSort,
}) => {
  const {
    data,
    loading,
    selectedLogs,
    currentPage,
    isSelectAll,
    sortDirection,
    sortBy,
  } = useSelector(selectDashboardLogs);
  const isSelectedAll = useSelector(selectIsSelectedAll);
  const { t } = useTranslation(['dashboard']);
  const isSelected = !!selectedLogs.length;
  const isLoading = loading === RequestStatus.pending;
  const pagesCount = data.totalCount / LOGS_PER_PAGE;

  // Handlers
  const handlePageClick = (pageData) => {
    onPageClick(pageData.selected + 1);
  };

  const handleLogReprocess = (id?: string[]) => {
    if (id) onLogReprocess(id);
  };

  const handleLogDelete = (id?: string[]) => {
    if (id) onLogDelete(id);
  };

  // Alert States
  const deleteAlert = useAlert<string[]>({ callback: handleLogDelete });
  const reprocessAlert = useAlert<string[]>({ callback: handleLogReprocess });
  const deleteOneAlert = useAlert<string[]>({ callback: handleLogDelete });
  const reprocessOneAlert = useAlert<string[]>({ callback: handleLogReprocess });

  return (
    <div className="logs-table">
      <Row className="logs-table-header">
        <Card elevation={Elevation.TWO}>
          <Col xs={1}>
            {/* Logs Checkbox */}
            <div className="checkbox-container">
              <Popover disabled={!isSelected} placement="bottom">
                <ArrowIcon disabled={!isSelected} />
                <div className="wrap-action-button">
                  <Button
                    icon={<BackupIcon />}
                    className="action-button"
                    onClick={() => {
                      reprocessAlert.onOpen(isSelectAll ? [] : selectedLogs);
                    }}
                  />
                  <Button
                    className="action-button"
                    icon={<GarbageIcon />}
                    onClick={() => {
                      deleteAlert.onOpen(isSelectAll ? [] : selectedLogs);
                    }}
                  />
                </div>
              </Popover>
              <Checkbox
                disabled={!data.logs?.length}
                checked={isSelectedAll}
                large
                onChange={onSelectVisible}
              />
              {!!selectedLogs.length && (
                <Tooltip
                  disabled={!isSelectedAll || data.logs.length === data.totalCount}
                  content={
                    isSelectAll
                      ? t('table:buttons.Select visible', {
                          count: data.logs.length,
                        })
                      : t('table:buttons.Select all', { count: data.totalCount })
                  }
                  popoverClassName="custom-popover"
                >
                  <Button
                    className="select-all-button"
                    onClick={onSelectAll}
                    disabled={!isSelectedAll || data.logs.length === data.totalCount}
                  >
                    {isSelectAll ? `(${data.totalCount})` : `(${selectedLogs.length})`}
                  </Button>
                </Tooltip>
              )}
            </div>
          </Col>
          <TableHeaderCell
            label={t('titles.Status')}
            size={16.6}
            field={ELogsSortParams.state}
            onSort={onSort}
            sort
            sortDirection={sortBy === ELogsSortParams.state ? sortDirection : undefined}
          />
          <TableHeaderCell
            size={16.6}
            label={t('titles.Action')}
            field={ELogsSortParams.action}
            sort
            onSort={onSort}
            sortDirection={sortBy === ELogsSortParams.action ? sortDirection : undefined}
          />
          <TableHeaderCell
            size={33.3}
            label={t('titles.Status Reason')}
            field={ELogsSortParams.error}
            sort
            onSort={onSort}
            sortDirection={sortBy === ELogsSortParams.error ? sortDirection : undefined}
          />
          <TableHeaderCell
            size={16.6}
            label={t('titles.Date/Time')}
            field={ELogsSortParams.date}
            sort
            onSort={onSort}
            sortDirection={sortBy === ELogsSortParams.date ? sortDirection : undefined}
          />
        </Card>
      </Row>
      <Row className="shadow-card table-rows-container">
        <Loader loading={isLoading} withShadow />
        {data.logs.map((logData) => {
          return (
            <LogItem
              key={logData.id}
              data={logData}
              selected={selectedLogs.includes(logData.id)}
              actions={
                <>
                  <span onClick={() => reprocessOneAlert.onOpen([logData.id])}>
                    <Icon icon={<BackupIcon />} iconSize={Icon.SIZE_STANDARD} />
                  </span>
                  <span onClick={() => deleteOneAlert.onOpen([logData.id])}>
                    <Icon icon={<GarbageIcon />} iconSize={Icon.SIZE_STANDARD} />
                  </span>
                </>
              }
              onSelect={onLogSelect}
            />
          );
        })}
        {!data.logs.length && !isLoading && (
          <NonIdealState
            icon="error"
            title={t('No logs found')}
            className="logs-non-ideal-state"
          />
        )}
        {!!data.logs.length && (
          <Pagination
            forcePage={currentPage - 1}
            pageCount={pagesCount}
            onPageChange={handlePageClick}
          />
        )}
      </Row>

      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={deleteAlert.isOpen}
        onCancel={deleteAlert.onClose}
        onConfirm={deleteAlert.onSubmit}
      >
        <p>
          {t(
            'alert.Delete',
            selectedLogs.length > 1 || isSelectAll
              ? {
                  count: isSelectAll ? data.totalCount : selectedLogs.length,
                  target: 'logs',
                }
              : { target: 'log' }
          )}
        </p>
      </Alert>
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Reprocess')}
        icon="trash"
        intent={Intent.WARNING}
        isOpen={reprocessAlert.isOpen}
        onCancel={reprocessAlert.onClose}
        onConfirm={reprocessAlert.onSubmit}
      >
        <p>
          {t(
            'alert.Reprocess',
            selectedLogs.length > 1 || isSelectAll
              ? {
                  count: isSelectAll ? data.totalCount : selectedLogs.length,
                  target: 'logs',
                }
              : { target: 'log' }
          )}
        </p>
      </Alert>
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={deleteOneAlert.isOpen}
        onCancel={deleteOneAlert.onClose}
        onConfirm={deleteOneAlert.onSubmit}
      >
        <p>{t('alert.Delete', { target: 'log' })}</p>
      </Alert>
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Reprocess')}
        icon="trash"
        intent={Intent.WARNING}
        isOpen={reprocessOneAlert.isOpen}
        onCancel={reprocessOneAlert.onClose}
        onConfirm={reprocessOneAlert.onSubmit}
      >
        <p>{t('alert.Reprocess', { target: 'log' })}</p>
      </Alert>
    </div>
  );
};

export default LogsTable;
