// Types
import { ILinkTypes, IFormTypes } from '../../../../services/LinksManagerService';
import { ITableCol } from 'src/components/table/TableHeaderCell';
import { EDashboardTableColTypes } from 'src/types/enums';

export const getTableColumnsByType = (
  linksTypes: ILinkTypes | null | undefined,
  selectedType: string = ''
): ITableCol[] | null => {
  if (!linksTypes || !linksTypes[selectedType]) return null;

  const selectedTypeDescriptor = linksTypes[selectedType];

  const cols = Object.entries(selectedTypeDescriptor).map(([field, { label }]) => ({
    field,
    label,
    type: EDashboardTableColTypes.string,
    sort: true,
  }));

  return cols as ITableCol[];
};

export const getFormByType = (
  formTypes: IFormTypes | null | undefined,
  selectedType: string = ''
): any => {
  if (!formTypes || !formTypes[selectedType]) return null;

  // Dynamic layout
  return formTypes[selectedType];
};
