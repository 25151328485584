import React, { FC, useCallback, useMemo } from 'react';
import { FormGroup } from '@blueprintjs/core';
import { useField } from 'formik';

// Components
import GenericSelector, {
  IValueKey,
} from '../../../components/generic-selector/GenericSelector';

// Types
import { ISelect } from './input-types';

// Utils
import { getFormattedOptions } from '../utils/getFormatedOptions';
import getClassName from '../utils/getClassName';

const Select: FC<ISelect> = ({
  validation,
  isMultiSelector,
  label,
  name,
  options,
  className,
}) => {
  const validate = useCallback(
    (value) => {
      if (validation?.includes('required') && value.length === 0) {
        return `${label} is required!`;
      }
      return undefined;
    },
    [label, validation]
  );

  const [field, meta, helpers] = useField(
    isMultiSelector ? { name, validate } : { name }
  );

  const formattedItems = getFormattedOptions(options);

  const selectedItems = useMemo(() => {
    if (Array.isArray(field.value)) {
      return field.value?.map((key) => {
        return formattedItems.filter((item) => item.Key === key)[0];
      });
    }
    return formattedItems.filter((item) => item.Key === field.value);
  }, [field.value, formattedItems]);

  const preselectedItem = formattedItems.find(({ Key }) =>
    typeof field.value === 'number'
      ? Number.parseInt(Key, 10) === field.value
      : Key === field.value
  );

  const handleChange = (value: IValueKey[]) => {
    const [selected] = value;

    helpers.setTouched(true);
    helpers.setValue(selected?.Key);
  };

  const handleChangeMultiSelector = (value: IValueKey[]) => {
    helpers.setTouched(true);
    helpers.setValue(value.map(({ Key }) => Key));
  };

  return (
    <FormGroup
      helperText={meta.touched && meta.error}
      label={label}
      className={className}
    >
      {isMultiSelector ? (
        <GenericSelector
          disabled={formattedItems.length === 0}
          items={formattedItems}
          isMultiSelector={isMultiSelector}
          selectedItem={selectedItems}
          onValueChanged={handleChangeMultiSelector}
        />
      ) : (
        <GenericSelector
          disabled={formattedItems.length === 0}
          items={formattedItems}
          isMultiSelector={isMultiSelector}
          selectedItem={preselectedItem ? [preselectedItem] : undefined}
          onValueChanged={handleChange}
        />
      )}
    </FormGroup>
  );
};

export default Select;

export const mapSelectProps = ({
  isMultiSelector,
  options,
  key,
  label,
  classes,
  validation,
}: ISelect) => ({
  isMultiSelector,
  options,
  name: `['${key}']`,
  label,
  className: getClassName(classes),
  validation,
});
