import BaseApiService, { RequestType } from './BaseApiService';

// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';

class HooksManagerService extends BaseApiService {
  changeStatus = async ({ connectorId, widgetKey, entity, event }: IChangeHookStatus) =>
    this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          op: widgetKey,
          action: 'toggle',
          data: {
            hook: entity ? `${entity}.${event}` : event,
          },
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
  getStatus = async ({ connectorId, widgetKey }) =>
    this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          action: 'status',
          op: widgetKey,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
}

export interface IChangeHookStatus {
  connectorId: string;
  widgetKey: string;
  entity?: string;
  event: string;
}
export default new HooksManagerService('rest/connector');
