import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestType } from '../../../../services/BaseApiService';
import { updateIntegrationStatus } from '../../../actions/system-actions';
// Types
import { RequestStatus } from '../../types';
import { IConnectorState } from './types';
// Utils
import { createThunkReducers } from '../../utils';
// Thunks
import { getConnector, getConnectorActions } from './thunks';

const initialState: IConnectorState = {
  error: null,
  loading: RequestStatus.pending,
  data: undefined,
  actions: [],
  actionsLoading: RequestStatus.idle,
};

const connectorSlice = createSlice({
  name: 'dashboard.connector',
  initialState,
  reducers: {
    reset: (): IConnectorState => initialState,
  },
  extraReducers: {
    ...createThunkReducers(getConnector, RequestType.get),
    ...createThunkReducers(getConnectorActions, RequestType.get, 'actionsLoading'),
    [String(updateIntegrationStatus)]: (
      state: IConnectorState,
      action: PayloadAction<{ status: boolean }>
    ): IConnectorState => {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          status: action.payload.status,
        },
      };
    },
  },
});

export const { reset } = connectorSlice.actions;

export default connectorSlice.reducer;
