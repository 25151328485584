import { useEffect, useRef, useState } from 'react';
// Types
import { ECronStatus, IGetCronStatusSuccessResult } from '../../../services/CronsService';

interface IUseCronProgress {
  progress: number;
}

const getInterval = (last: Date, next: Date) => {
  return (next.getTime() - last.getTime()) / 100;
};

const getProgress = (last: Date, next: Date) => {
  const interval = next.getTime() - last.getTime();
  const currentTime = new Date().getTime();
  if (next.getTime() <= currentTime) {
    return 1;
  }

  return interval >= 0 ? (new Date().getTime() - last.getTime()) / interval : 1;
};

const useCronProgress = (
  data: IGetCronStatusSuccessResult | undefined
): IUseCronProgress => {
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (data) {
      const { last_run_date, next_execution_date, status } = data;
      const isEnabled = status === ECronStatus.enabled;
      setProgress(getProgress(last_run_date, next_execution_date));

      if (isEnabled) {
        interval.current = setInterval(() => {
          setProgress(getProgress(last_run_date, next_execution_date));
        }, getInterval(last_run_date, next_execution_date));
      }
    }

    return () => {
      setProgress(0);

      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [data]);

  return {
    progress,
  };
};

export default useCronProgress;
