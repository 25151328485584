import React, { FC } from 'react';
import { useSelector } from 'react-redux';
// Types
import { EUser } from 'src/types/enums';
import { RequestStatus } from '../../../redux/slices/types';
import { selectCurrentUser } from '../../../redux/slices/user/selectors';
import UserEdit from './UserEdit';
import UserTeaser from './UserTeaser';
// Placeholder
import placeholder from '../../../images/UserIcon.png';
// Components
import UserTeaserXs from './UserTeaserXs';

export interface IUserProps {
  view: EUser;
}

const views = new Map<EUser, React.ElementType>([
  [EUser.TEASER_XS, UserTeaserXs],
  [EUser.TEASER, UserTeaser],
  [EUser.EDIT, UserEdit],
]);

const User: FC<IUserProps> = ({ view }) => {
  const user = useSelector(selectCurrentUser);

  const Component = (views.get(view) as React.ElementType) || null;

  return (
    <Component
      src={user.data?.field_user_picture?.teaser || placeholder}
      loading={user.loading === RequestStatus.pending}
      firstName={user.data?.field_first_name}
      lastName={user.data?.field_last_name}
    />
  );
};

export default User;
