import React, { FC, useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import { Row, Col } from 'react-bootstrap';
import { Card, Tab } from '@blueprintjs/core';
import MappingTable from './mapping-table/MappingTable';
import MappingDialog from './MappingDialog';
import EditValueForm from './edit-modal/EditValueForm';
import Button, { ButtonSize } from '../button/Button';
import FileImport from './file-import/FileImport';
import Tabs from '../tabs/Tabs';
// Context
import MappingToolContextProvider from './context/MappingToolContextProvider';
// Styles
import './style.scss';
// Utils
import { replaceLastPathLevel, checkIsValidVar } from './utils';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';

interface IMappingToolProps {
  initialValue: object;
  destination: object;
  source: object;
  onChange: (result: object) => void;
  className?: string;
}

const MappingTool: FC<IMappingToolProps> = ({
  destination: destinationProp,
  source: sourceProp,
  onChange,
  initialValue,
  className = '',
}) => {
  const [result, setResult] = useState<object>(initialValue);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editingPath, setEditingPath] = useState<string | undefined>(undefined);
  const [destination, setDestination] = useState<object>(destinationProp);
  const [source, setSource] = useState<object>(sourceProp);

  const checkIsValidVariable = useCallback((field) => checkIsValidVar(source, field), [
    source,
  ]);

  const editingValue = useMemo(() => {
    if (editingPath) {
      const value = get(result, editingPath);
      return typeof value !== 'object' ? value : '';
    }
    return '';
  }, [editingPath, result]);

  const { t } = useTranslation(['mapping']);

  const handleEditValueRequest = useCallback((path: string) => {
    setEditingPath(path);
  }, []);

  const handleEditCancel = useCallback(() => {
    setEditingPath(undefined);
  }, []);

  const handleEditValue = useCallback(
    (value: string) => {
      if (editingPath) {
        const newResult = cloneDeep(result ?? {});
        set(newResult, editingPath, value);
        setResult(newResult);
        setEditingPath(undefined);
      }
    },
    [result, editingPath]
  );

  const handleEditFieldName = useCallback(
    (path: string, newFieldName: string) => {
      const newResult = cloneDeep(result ?? {});
      const valueByPath = get(newResult, path) ?? '';
      const newPath = replaceLastPathLevel(path, newFieldName);
      unset(newResult, path);
      set(newResult, newPath, valueByPath);
      setResult(newResult);
    },
    [result]
  );

  const handleAddField = useCallback(
    (path: string, newFieldName: string) => {
      let newResult = cloneDeep(result ?? {});
      if (path) {
        const valueByPath = get(newResult, path) ?? '';
        set(newResult, path, {
          ...(typeof valueByPath === 'object' && !Array.isArray(valueByPath)
            ? valueByPath
            : {}),
          [newFieldName]: '',
        });
      } else {
        newResult = { ...newResult, [newFieldName]: '' };
      }
      setResult(newResult);
    },
    [result]
  );

  const handleRemoveField = useCallback(
    (path: string) => {
      const newResult = cloneDeep(result ?? {});
      unset(newResult, path);
      setResult(newResult);
    },
    [result]
  );

  const handleDestinationFileSelect = useCallback(
    (content: object | null) => {
      const newContent = content ?? { ...destinationProp };
      setDestination(newContent);
      setIsOpen(false);
    },
    [destinationProp]
  );

  const handleSourceFileSelect = useCallback(
    (content: object | null) => {
      const newContent = content ?? { ...sourceProp };
      setSource(newContent);
      setIsOpen(false);
    },
    [sourceProp]
  );

  useEffect(() => {
    if (result !== initialValue) {
      onChange(result);
    }
  }, [result, initialValue, onChange]);

  return (
    <MappingToolContextProvider
      source={source}
      destination={destination}
      result={result}
      checkIsValidVariable={checkIsValidVariable}
    >
      <Card className={`custom-card ${className}`}>
        <Button
          className="import-button"
          size={ButtonSize.small}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t('buttons.Import file')}
        </Button>
        <Row>
          <Col md={12}>
            <MappingTable
              mapping={result}
              onEditValueRequest={handleEditValueRequest}
              onFieldNameChange={handleEditFieldName}
              onAddField={handleAddField}
              onRemoveField={handleRemoveField}
            />
          </Col>
        </Row>
      </Card>

      {editingPath && (
        <MappingDialog isOpen onClose={handleEditCancel} title={editingPath}>
          <EditValueForm
            onSubmit={handleEditValue}
            initialValue={editingValue}
            field={editingPath}
          />
        </MappingDialog>
      )}
      <MappingDialog
        title={t('titles.File import')}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Col md={12}>
          <Tabs id="file-import">
            <Tab
              id="source-import"
              title={t('titles.Source')}
              panel={
                <FileImport
                  onChange={handleSourceFileSelect}
                  initialValue={JSON.stringify(source)}
                />
              }
            />
            <Tab
              id="destination-import"
              title={t('titles.Destination')}
              panel={
                <FileImport
                  onChange={handleDestinationFileSelect}
                  initialValue={JSON.stringify(destination)}
                />
              }
            />
          </Tabs>
        </Col>
      </MappingDialog>
    </MappingToolContextProvider>
  );
};

export default MappingTool;
