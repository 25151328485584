import React, { FC, useState, useCallback, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@blueprintjs/core';
import { Col } from 'react-bootstrap';
// Components
import { StructureErrorBoundary, DynamicLayout } from 'wedoio-dynamic-layout';
import componentsMap from '../../../components/dynamic-layout/components/componentsMap';
import Button, { ButtonSize } from '../../../components/button/Button';
// Actions
import {
  clearFormState,
  resetFormState,
} from '../../../redux/slices/dashboard/links/linksSlice';
import { submitForm } from '../../../redux/slices/dashboard/links/thunks';
// Selectors
import { selectLinks } from '../../../redux/slices/dashboard/links/selectors';
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
// Types
import { RequestStatus } from '../../../redux/slices/types';
import { ISubmitForm } from 'wedoio-dynamic-layout/dist/dynamic-layout/utils/types';
// Utils
import { stringifyFormData } from '../../../utils/stringifyFormData';
import stringifyStateError from '../../../utils/stringifyStateError';

interface ILinksEditFormProps {
  onSubmit: () => Promise<void>;
}

const LinksEditForm: FC<ILinksEditFormProps> = ({ onSubmit }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    form,
    formLoading,
    submit,
    massActionLoading,
    error,
    selectedType,
  } = useSelector(selectLinks);

  const { data: connector } = useSelector(selectConnectorState);

  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();

  const isFormEmpty = !form || (Array.isArray(form) && !form.length);

  const resetForm = useCallback(async () => {
    await dispatch(clearFormState());
    await dispatch(resetFormState());
  }, [dispatch]);

  // Handlers

  const handleDynamicFormSubmit = useCallback(
    async ({ data: formData, callbackUrl = '', submitPayload }: ISubmitForm) => {
      if (connector?.nid) {
        await dispatch(
          submitForm({
            callback: callbackUrl,
            data: stringifyFormData(formData, submitPayload),
            connectorId: connector?.nid,
          })
        );
      }
    },
    [dispatch, connector]
  );

  const onCancelEdit = useCallback(async () => {
    resetForm();
    setIsOpen(false);
  }, [resetForm]);

  // Effects

  useEffect(() => {
    if (selectedType) {
      setIsOpen(false);
      resetForm();
    }
  }, [selectedType, resetForm]);

  useEffect(() => {
    if (formLoading === RequestStatus.pending) {
      setIsOpen(false);
    }
    if (formLoading === RequestStatus.fulfilled) {
      setIsOpen(true);
    }
  }, [formLoading]);

  useEffect(() => {
    if (
      massActionLoading === RequestStatus.pending &&
      formLoading === RequestStatus.fulfilled
    ) {
      setIsOpen(false);
      resetForm();
    }
  }, [massActionLoading, formLoading, resetForm]);

  useEffect(() => {
    if (error && submit === RequestStatus.rejected) {
      toastr.error(
        t('alert.Failed'),
        t('alert.Error link update', { error: stringifyStateError(error) })
      );
    }
  }, [t, error, submit]);

  useEffect(() => {
    if (submit === RequestStatus.fulfilled) {
      toastr.success(t('alert.Success'), t('alert.Success link update'));
      resetForm();
      onSubmit();
    }
  }, [dispatch, t, resetForm, submit, onSubmit]);

  return (
    <Col sm={12}>
      {!isOpen && (
        <Button
          className="link-form-burron"
          disabled={isFormEmpty}
          loading={formLoading === RequestStatus.pending}
          size={ButtonSize.default}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t('buttons.Add/Edit Link')}
        </Button>
      )}
      <Collapse isOpen={isOpen} keepChildrenMounted>
        {!isFormEmpty && formLoading !== RequestStatus.pending && (
          <>
            <StructureErrorBoundary>
              <DynamicLayout
                componentsMap={componentsMap}
                structure={form}
                callService={handleDynamicFormSubmit}
              />
            </StructureErrorBoundary>
            <Button
              className="link-form-button"
              disabled={submit === RequestStatus.pending}
              size={ButtonSize.default}
              onClick={onCancelEdit}
            >
              {t('buttons.Cancel')}
            </Button>
          </>
        )}
      </Collapse>
    </Col>
  );
};

export default LinksEditForm;
