import React, { FC } from 'react';
import { Intent } from '@blueprintjs/core';

import './style.scss';

interface IIntentIconProps {
  intent?: Intent;
  className?: string;
  animate?: boolean;
}

const IntentIcon: FC<IIntentIconProps> = ({
  intent = Intent.SUCCESS,
  className = '',
  animate = true,
}) => {
  return (
    <span className={`intent-icon ${intent} ${animate ? 'animate' : ''} ${className}`} />
  );
};

export default IntentIcon;
