import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import UserConnectorsService from '../../../../services/UserConnectorsService';

// Types
import { GetConnectorsActionSuccessResult } from './types';

export const getConnectors = createAsyncThunk<GetConnectorsActionSuccessResult>(
  'connectors.list/getConnectors',
  async (_, { rejectWithValue }) => {
    try {
      const result = await UserConnectorsService.getList();

      return {
        data: result.data || [],
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);
