import { useCallback } from 'react';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
// Types
import { IFormNotification } from '../types/internal-Types';
import { ENotificationTypes } from '../types/enums';

interface IUseFormNotification {
  showNotification: (notification: IFormNotification) => void;
}

const useFormNotification = (): IUseFormNotification => {
  const { t } = useTranslation(['dynamicForm']);

  const showNotification = useCallback(
    (notification) => {
      switch (notification?.type) {
        case ENotificationTypes.error:
          return toastr.error(t(`alert.Error`), notification.content);
        case ENotificationTypes.status:
          return toastr.success(t(`alert.Success`), notification.content);
        case ENotificationTypes.warning:
          return toastr.warning(t(`alert.Warning`), notification.content);
        default:
          return;
      }
    },
    [t]
  );

  return { showNotification };
};

export default useFormNotification;
