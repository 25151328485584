import React, { FC, useCallback } from 'react';
import { Row } from 'react-bootstrap';
import { Card, NonIdealState, Alert, Intent, Button, Tooltip } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Components
import Loader from '../../../components/loader/Loader';
import Pagination from 'src/components/pagination/Pagination';
import NotificationRow from './NotificationRow';
import Checkbox from '../../../components/checkbox/Checkbox';
import BackupIcon from 'src/components/icons/BackupIcon';
// Selectors
import {
  selectNotifications,
  selectIsSelectedAll,
} from '../../../redux/slices/notifications/table/selectors';
// Actions
import { ITEMS_PER_PAGE } from '../../../redux/slices/notifications/consts';
// Types
import { RequestStatus } from 'src/redux/slices/types';
// Utils
import useAlert from 'src/utils/useAlert';

export interface IChangeStatus {
  ids: string[];
  isRead: boolean;
}

export interface ITableProps {
  onDelete: (id?: string[]) => void;
  onChangeStatus: (data?: IChangeStatus) => void;
  onSelect: (id: string) => void;
  onSelectAllVisible: () => void;
  onSelectAll: () => void;
  onPageClick: (page: number) => void;
  onRefresh: () => void;
}

const NotitficationsTable: FC<ITableProps> = ({
  onPageClick,
  onChangeStatus,
  onDelete,
  onSelectAllVisible,
  onSelectAll,
  onSelect,
  onRefresh,
}) => {
  const { data, selected, loading, currentPage, isSelectAll } = useSelector(
    selectNotifications
  );
  const isSelectedAllRows = useSelector(selectIsSelectedAll);

  const pagesCount = data.total / ITEMS_PER_PAGE;

  const { t } = useTranslation(['notifications', 'table']);

  // Handlers
  const handlePageClick = (pageData) => {
    onPageClick(pageData.selected + 1);
  };

  const handleChangeStatus = useCallback(
    ({ ids, isRead }: IChangeStatus) => {
      if (ids) onChangeStatus({ ids: isSelectAll ? [] : ids, isRead });
    },
    [isSelectAll, onChangeStatus]
  );

  const handleDelete = useCallback(
    (id?: string[]) => {
      if (id) onDelete(isSelectAll ? [] : id);
    },
    [isSelectAll, onDelete]
  );

  const handleSelectAll = () => {
    onSelectAllVisible();
  };

  // Alert States
  const deleteAlert = useAlert<string[]>({ callback: handleDelete });
  const deleteOneAlert = useAlert<string[]>({ callback: onDelete });

  return (
    <Row>
      <Row className="notifications-table-header custom-card">
        <div>
          <Checkbox
            checked={isSelectedAllRows}
            disabled={!data.notifications.length}
            large
            onChange={handleSelectAll}
          />
          {!!selected.length && (
            <Tooltip
              disabled={!isSelectedAllRows || data.notifications.length === data.total}
              content={
                isSelectAll
                  ? t('table:buttons.Select visible', {
                      count: data.notifications.length,
                    })
                  : t('table:buttons.Select all', { count: data.total })
              }
              popoverClassName="custom-popover"
            >
              <Button
                title={
                  isSelectAll
                    ? t('table:buttons.Select visible', {
                        count: data.notifications.length,
                      })
                    : t('table:buttons.Select all', { count: data.total })
                }
                className="select-all-button"
                onClick={onSelectAll}
                disabled={!isSelectedAllRows || data.notifications.length === data.total}
              >
                {isSelectAll ? `(${data.total})` : `(${selected.length})`}
              </Button>
            </Tooltip>
          )}
        </div>
        <div>
          <Button
            disabled={!selected.length}
            onClick={() => {
              handleChangeStatus({ ids: selected, isRead: true });
            }}
          >
            {t('buttons.Mark as read')}
          </Button>
          <Button
            disabled={!selected.length}
            onClick={() => {
              handleChangeStatus({ ids: selected, isRead: false });
            }}
          >
            {t('buttons.Mark as unread')}
          </Button>
          <Button
            disabled={!selected.length}
            onClick={() => {
              deleteAlert.onOpen(selected);
            }}
          >
            {t('buttons.Delete')}
          </Button>
          <Button className="refresh-button" icon={<BackupIcon />} onClick={onRefresh} />
        </div>
      </Row>
      <Card className="custom-card notifications-table">
        <Loader loading={loading === RequestStatus.pending} withShadow />
        {!data.notifications.length && loading !== RequestStatus.pending && (
          <NonIdealState
            icon="error"
            title={t('No notifications found')}
            className="logs-non-ideal-state"
          />
        )}
        {!!data.notifications.length &&
          data.notifications.map((item) => (
            <NotificationRow
              data={item}
              key={item.id}
              onDelete={deleteOneAlert.onOpen}
              onUpdate={onChangeStatus}
              onSelect={onSelect}
              selected={selected.includes(item.id)}
            />
          ))}
        {!!data.notifications.length && (
          <Pagination
            forcePage={currentPage - 1}
            pageCount={pagesCount}
            onPageChange={handlePageClick}
          />
        )}
      </Card>
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={deleteAlert.isOpen}
        onCancel={deleteAlert.onClose}
        onConfirm={deleteAlert.onSubmit}
      >
        <p>
          {t(
            'alert.Delete',
            selected.length > 1 || isSelectAll
              ? {
                  count: isSelectAll ? data.total : selected.length,
                  target: 'notifications',
                }
              : { target: 'notification' }
          )}
        </p>
      </Alert>
      <Alert
        cancelButtonText={t('buttons.Cancel')}
        confirmButtonText={t('buttons.Delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={deleteOneAlert.isOpen}
        onCancel={deleteOneAlert.onClose}
        onConfirm={deleteOneAlert.onSubmit}
      >
        <p>{t('alert.Delete', { target: 'notification' })}</p>
      </Alert>
    </Row>
  );
};

export default NotitficationsTable;
