import React, { FC } from 'react';
import { useSelector } from 'react-redux';

// Styles
import './company-data-styles.scss';

// Images
import placeholder from '../../images/defaultCompanyLogo.png';

// Types
import { RootState } from '../../redux/rootReducer';
import { selectSelectedCompanyState } from '../../redux/slices/companies/selected-company/selectors';

export interface ICompanyDataProps {
  loading?: boolean;
}

export interface ICompanyDataStore {
  name?: string;
  logo?: string;
}

const CompanyData: FC<ICompanyDataProps> = ({ loading = false }) => {
  const { name, logo = placeholder } = useSelector<RootState, ICompanyDataStore>(
    (state) => {
      const company = selectSelectedCompanyState(state);
      return {
        name: company?.title,
        logo: company?.field_company_logo?.teaser,
      };
    }
  );

  return (
    <div className="company-data">
      <span className={loading ? 'bp3-skeleton' : ''}>{name}</span>
      <span>
        {loading ? (
          <span className="bp3-skeleton" />
        ) : (
          <img src={logo} alt="Company logo" />
        )}
      </span>
    </div>
  );
};

export default CompanyData;
