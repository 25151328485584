import { Card, Icon } from '@blueprintjs/core';
import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// Types
import { IFieldIntegration } from 'src/types/api-Types';
// Icons
import IntegrationStatusSwitch from './IntegrationStatusSwitch';
// Components
import IntegrationLogo from './IntegrationLogo';
import Tag from '../../../../tag/Tag';
export interface IIntegrationTeaserProps {
  integration: IFieldIntegration;
  onTagClick: (tag: string) => void;
}

const IntegrationTeaser: FC<IIntegrationTeaserProps> = ({ integration, onTagClick }) => {
  const [icon1, icon2] = integration?.icons || [];

  const history = useHistory();

  const handleClick = (nid) => () => {
    history.push('/dashboard/' + nid);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <Card
      className={`integration teaser${!integration.status ? ' teaser-disabled' : ''}`}
      onClick={handleClick(integration.slug)}
      interactive
    >
      <Row className="wrapper">
        <Col xs={2} style={{ textAlign: 'center' }}>
          <div className="integration-icon">
            <IntegrationLogo
              src={icon1?.teaser}
              alt={icon1?.alt ?? 'integration icon'}
              title={icon1?.title ?? integration.title}
            />
          </div>
        </Col>
        <Col xs={2} style={{ textAlign: 'center' }}>
          <div className="integration-icon">
            <IntegrationLogo
              src={icon2?.teaser}
              alt={icon2?.alt ?? 'integration icon'}
              title={icon2?.title ?? integration.title}
            />
          </div>
        </Col>
        <Col xs={6}>
          <div className="integration-title">{integration.title}</div>
          <div className="integration-tags">
            {!!integration.tags?.length &&
              integration.tags.map((tag, index) => (
                <Tag
                  className="integration-tag"
                  key={tag + index}
                  onClick={(e) => {
                    stopPropagation(e);
                    onTagClick(tag);
                  }}
                >
                  {tag}
                </Tag>
              ))}
          </div>
        </Col>
        <Col xs={2} onClick={stopPropagation}>
          <IntegrationStatusSwitch
            status={integration.status}
            name={integration.title}
            id={integration.nid}
          />
        </Col>
        <Col xs={1}>
          <div className="integration-actions">
            <Icon icon="more" />
          </div>
        </Col>
        <span className="integration-nid">#{integration.nid}</span>
      </Row>
    </Card>
  );
};

export default IntegrationTeaser;
