import { createSlice } from '@reduxjs/toolkit';

// Types
import { RequestStatus } from '../../types';
import { CreateConnectorState } from './types';
import { RequestType } from 'src/services/BaseApiService';
import { CreateConnectorFormData } from 'src/types/internal-Types';

// Thunks
import { createConnector } from './thunks';

// Utils
import { createThunkReducers } from '../../utils';

export const initialValues: CreateConnectorFormData = {
  title: '',
  description: '',
  default_namespace: '',
  namespace: '',
  form: [],
  securitySchemes: [],
  endpoints: [],
  security: [],
  servers: [],
  tags: [],
};

const initialState: CreateConnectorState = {
  error: null,
  submit: RequestStatus.idle,
  initialValues,
};

const createConnectorSlice = createSlice({
  name: 'connectors.create',
  initialState,
  reducers: {
    reset: (): CreateConnectorState => initialState,
  },
  extraReducers: {
    ...createThunkReducers(createConnector, RequestType.post),
    [String(createConnector.fulfilled)]: (state, { payload }) => {
      return {
        ...state,
        submit: RequestStatus.fulfilled,
        newConnectorId: payload.nid,
      };
    },
  },
});

export const { reset } = createConnectorSlice.actions;

export default createConnectorSlice.reducer;
