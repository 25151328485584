import React, { FC } from 'react';
import { Popover as BlueprintPopover, IPopoverProps } from '@blueprintjs/core';
// Style
import './style.scss';

const Popover: FC<IPopoverProps> = (props) => {
  return (
    <BlueprintPopover
      {...props}
      canEscapeKeyClose
      popoverClassName={`${props.popoverClassName ?? ''} custom-popover`}
    />
  );
};

export default Popover;
