import BaseApiService, { RequestType } from './BaseApiService';
import { IFieldIntegration } from '../types/api-Types';

class MarketplaceService extends BaseApiService {
  getMarketPlaces = async () => {
    const { data } = await this.request({
      url: '',
      type: RequestType.get,
    });
    return data && Array.isArray(data)
      ? data.reduce((list, marketplace) => {
          const integrations = marketplace?.field_integrations.map((inegration) => ({
            ...inegration,
            market_id: marketplace.nid,
          }));
          list.push(...integrations);
          return list;
        }, [])
      : ([] as IMarketPlaceIntegration[]);
  };

  buildIntegration = ({ market_id, ...params }: IBuildSelectedIntegration) =>
    this.request({
      url: market_id,
      type: RequestType.put,
      data: {
        action: 'build',
        input: {
          ...params,
        },
      },
    });
}

export interface IMarketPlaceIntegration extends IFieldIntegration {
  market_id: string;
}
export interface IBuildSelectedIntegration {
  market_id: string;
  product_id: string;
  company: string;
  name: string;
}
export default new MarketplaceService('rest/marketplace');
