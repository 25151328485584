import React, { FC } from 'react';
// Components
import {
  PieChart as PieChartRecharts,
  Pie,
  ResponsiveContainer,
  Legend,
  Cell,
} from 'recharts';
// Types
import { IPieChart } from '../types';
// Utils
import getClassName from 'src/components/dynamic-layout/utils/getClassName';
// Styles
import './charts-styles.scss';

interface IPieChartProps {
  dataKey: string;
  data: any;
  className?: string;
}

const PieChart: FC<IPieChartProps> = ({ dataKey, data, className }) => {
  return (
    <div className={className ? `chart-container ${className}` : 'chart-container'}>
      <ResponsiveContainer>
        <PieChartRecharts data={data}>
          {data.map(({ pieData, color, innerRadius, outerRadius, hasLabel }, index) => {
            return (
              <Pie
                data={pieData}
                dataKey={dataKey}
                cx={`50%`}
                cy={`50%`}
                fill={color}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                key={index}
                label={hasLabel}
              >
                {pieData.map(({ cellColor }, cellIndex) => {
                  return <Cell fill={cellColor ? cellColor : color} key={cellIndex} />;
                })}
              </Pie>
            );
          })}
          <Legend />
        </PieChartRecharts>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChart;

export const mapPieChartProps = ({ dataKey, data, classes }: IPieChart) => ({
  dataKey,
  data,
  className: getClassName(classes),
});
