// Types
import { IValueKey } from '../../generic-selector/GenericSelector';

export const getFormattedOptions = (options: any): IValueKey[] => {
  if (options === null) {
    return [];
  }

  if (Array.isArray(options)) {
    return options;
  }

  if (typeof options === 'object') {
    return Object.entries(options).map(([Key, Value]) => ({ Key, Value })) as IValueKey[];
  }

  return [];
};
