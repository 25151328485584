import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import 'bootstrap3/dist/css/bootstrap.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';

import Toaster from './components/toaster/Toaster';

import App from './App';

// Translations
import './i18n';
import { yupLocale } from './translations/yup/yupLocale';
import { setLocale as setDynamicFormYupLocale } from 'wedoio-dynamic-layout';

// Markup Config for DynamicLayout
import { setMarkupConfig } from 'wedoio-dynamic-layout';

setMarkupConfig({
  ADD_ATTR: ['target', 'allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  ADD_TAGS: ['iframe'],
});

setDynamicFormYupLocale(yupLocale);

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <App />
      <Toaster />
    </Provider>
  </Router>,
  document.getElementById('root') as HTMLElement
);
