import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import ImagePicker from '../../../components/image-picker/ImagePicker';
import ConnectorLogo, {
  ConnectorLogoSizes,
} from '../../../components/Wedoio/Connector/logo/ConnectorLogo';
import ServersList from './servers/ServersList';
import Input from '../../../components/input/Input';
import TextArea from '../../../components/textarea/TextArea';
import TagsInput from '../../../components/tags-input/TagsInput';
import { H3, H4 } from '@blueprintjs/core';
import { Col, Row } from 'react-bootstrap';
import Collapse from '../../../components/collapse/Collapse';
// Types
import { CreateConnectorFormData } from 'src/types/internal-Types';
// Utils
import getImageAsBase64 from 'src/utils/getImageAsBase64';

const ConnectorCommonSettings: FC = () => {
  const {
    handleBlur,
    handleChange,
    touched,
    errors,
    values,
    isSubmitting,
    setFieldValue,
  } = useFormikContext<CreateConnectorFormData>();
  const { t } = useTranslation(['connectors']);

  const canResetLogo = !isSubmitting && !!values.logo?.file;

  const tags = useMemo(() => values.tags.map(({ name }) => name), [values.tags]);

  const handleTagsChange = useCallback(
    (newTags) => {
      setFieldValue(
        'tags',
        newTags.map((tag) => ({ name: tag }))
      );
    },
    [setFieldValue]
  );

  const handleTeaserChange = async (file: File) => {
    const base64 = await getImageAsBase64(file);

    setFieldValue('logo.file', base64, true);
    setFieldValue('logo.filename', file.name, true);
  };

  const handleTeaserReset = () => {
    setFieldValue('logo', null);
  };

  return (
    <div className="form-holder">
      <H3 className="margin-bottom-sm">{t('titles.Settings')}</H3>
      <Row className="basic-setings-section">
        <Col sm={12} md={4} mdPush={8} className="connector-logo-container">
          <ImagePicker
            canReset={canResetLogo}
            onChange={handleTeaserChange}
            onReset={handleTeaserReset}
          >
            <ConnectorLogo
              src={values.logo?.file}
              size={ConnectorLogoSizes.middle}
              name={values.title ?? ''}
            />
          </ImagePicker>
        </Col>
        <Col sm={12} md={8} mdPull={4}>
          <Input
            label={t('inputs.Connector Name')}
            placeholder={t('inputs.Connector Name')}
            value={values.title ?? ''}
            name="title"
            validationError={touched.title && errors.title}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <TextArea
            onChange={handleChange}
            onBlur={handleBlur}
            label={t('inputs.Description')}
            placeholder={t('inputs.Description')}
            validationError={touched.description && errors.description}
            value={values.description ?? ''}
            name="description"
          />
        </Col>
        <Col sm={12}>
          <TagsInput
            onChange={handleTagsChange}
            values={tags}
            placeholder={t('inputs.Tags')}
          />
        </Col>
      </Row>
      <Row className="connector-form-section">
        <Col sm={12}>
          <ServersList />
        </Col>
      </Row>
      <Row className="connector-form-section ">
        <Col sm={12}>
          <Collapse
            header={<H4 className="margin-bottom-sm">{t('titles.Advanced settings')}</H4>}
          >
            <div className="margin-top-xs margin-bottom-xs">
              <Input
                label={t('inputs.Default namespace')}
                placeholder={t('inputs.Default namespace')}
                value={values.default_namespace ?? ''}
                name="default_namespace"
                validationError={touched.default_namespace && errors.default_namespace}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="margin-top-xs margin-bottom-xs">
              <Input
                label={t('inputs.Namespace')}
                placeholder={t('inputs.Namespace')}
                value={values.namespace ?? ''}
                name="namespace"
                validationError={touched.namespace && errors.namespace}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

export default ConnectorCommonSettings;
