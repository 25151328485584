import React, { FC, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import get from 'lodash/get';
// Components
import Checkbox from '../../../../../components/checkbox/Checkbox';
import TableCell from '../../../../../components/table/TableCell';
// Types
import { ITableRowProps } from 'src/components/table/TableRow';
import { ILinkItem } from 'src/redux/slices/dashboard/links/types';
import getColSize from 'src/components/table/getColSize';

export interface ILinkItemProps extends ITableRowProps {
  data: ILinkItem;
  actions?: React.ReactNode | React.ReactNode[];
}

const LinkItem: FC<ILinkItemProps> = ({
  data,
  onSelect,
  columns,
  isSelected = false,
  actions,
}) => {
  const colSize = useMemo(() => getColSize(columns), [columns]);

  return (
    <Row className="links-item table-row">
      <Col xs={1} className="table-checkbox links-table-checkbox">
        <Checkbox checked={isSelected} onChange={() => onSelect(data.id)} />
      </Col>
      {columns?.map(({ field, type, size }) => {
        const cell = get(data, field);
        return <TableCell key={field} type={type} value={cell} size={size ?? colSize} />;
      })}
      <div className="links-actions table-row-actions pull-right">{actions}</div>
    </Row>
  );
};

export default LinkItem;
