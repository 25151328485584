import React, { FC, useEffect, useCallback } from 'react';
import { Card, ProgressBar, Intent, Button } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { useQuery, useQueryCache, useMutation } from 'react-query';
// Services
import JobsService from '../../../services/JobsService';
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
// Components
import ControlButton from '../control-button/ControlButton';
import BackupIcon from 'src/components/icons/BackupIcon';
import Loader from 'src/components/loader/Loader';
// Types
import { IJobDirector } from '../types';
import { EJobState, EJobOperations } from '../../../types/enums';
import { SerializedError } from 'src/redux/slices/types';
import { IGetJobStatusSuccessResult } from '../../../services/JobsService';
// Hooks
import useRefreshInterval from '../utils/useRefreshInterval';

import './style.scss';

interface JobDirectorProps {
  id: string;
  label?: string;
}

const JobDirector: FC<JobDirectorProps> = ({ id, label }) => {
  const { data: connector } = useSelector(selectConnectorState);

  const { data, isLoading: loading, error, isFetching, isError } = useQuery<
    IGetJobStatusSuccessResult,
    SerializedError
  >([id, { connectorId: connector?.nid, jobId: id }], JobsService.getStatus, {
    enabled: connector?.nid,
  });

  const [changeJobStatus, { isLoading: operationLoading }] = useMutation(
    JobsService.performJobOperation,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(id);
      },
      onError: (changeStatusError, { operation }) => {
        toastr.error(
          t('alert.Failed'),
          t('alert.Job operation failed', {
            job: label ?? data?.name,
            operation,
            error: changeStatusError,
          })
        );
      },
    }
  );

  const isFinished = Math.floor(data?.progress ?? 0) === 100;

  useRefreshInterval(
    id,
    (data?.state === EJobState.JobRunning || data?.state === EJobState.JobPreparing) &&
      !isFinished
  );

  const { t } = useTranslation(['dashboard']);
  const queryCache = useQueryCache();

  // Handlers
  const handleControlClick = useCallback(
    async (operation: EJobOperations) => {
      if (connector?.nid && id) {
        changeJobStatus({
          connectorId: connector?.nid,
          jobId: id,
          operation,
        });
      }
    },
    [connector, id, changeJobStatus]
  );

  useEffect(() => {
    if (error) {
      toastr.error(
        t('alert.Error'),
        t('alert.Job status', {
          error: error.message,
          job: label ?? id,
        })
      );
    }
  }, [error, id, label, t]);

  return (
    <>
      {!data && loading && <div className="bp3-skeleton job-director-skeleton" />}
      {!loading && (
        <Card
          className={`job-director custom-card custom-card--border ${
            error ? ' disabled' : ''
          }`}
        >
          <Loader withShadow showIcon={false} loading={isFetching} />
          <div className="job-director-header">
            <div className="job-director-name">{label ?? data?.name ?? ''}</div>
            <div className="job-director-controls">
              <>
                {(data?.state === EJobState.JobPaused ||
                  data?.state === EJobState.JobStopped ||
                  data?.state === EJobState.JobPending ||
                  !data) && (
                  <ControlButton
                    icon="play"
                    disabled={operationLoading || isFinished || isError}
                    onClick={() => {
                      handleControlClick(EJobOperations.start);
                    }}
                  />
                )}
                {data?.state === EJobState.JobRunning && (
                  <ControlButton
                    icon="pause"
                    disabled={operationLoading || isFinished || isError}
                    onClick={() => {
                      handleControlClick(EJobOperations.pause);
                    }}
                  />
                )}
                <ControlButton
                  icon="stop"
                  active={data?.state === EJobState.JobStopped}
                  disabled={operationLoading || isFinished || isError}
                  onClick={() => {
                    handleControlClick(EJobOperations.stop);
                  }}
                />
                <ControlButton
                  icon="refresh"
                  disabled={operationLoading || isError}
                  onClick={() => {
                    handleControlClick(EJobOperations.restart);
                  }}
                />
              </>
            </div>
          </div>
          <div className="job-director-status">
            {!!data?.estimated_end && (
              <span>{`${t('titles.Estimated end')}: ${data.estimated_end}`}</span>
            )}
          </div>
          <div className="job-director-status">
            {!!data?.tasks?.failed && (
              <>
                <span className="failed">{`${t('titles.Failed')}: ${
                  data?.tasks?.failed ?? 0
                }`}</span>

                <Button
                  minimal
                  small
                  className="requeue-button"
                  disabled={operationLoading || isError}
                  onClick={() => {
                    handleControlClick(EJobOperations.requeue_failed);
                  }}
                  icon={<BackupIcon />}
                >
                  {t('buttons.requeue failed')}
                </Button>
              </>
            )}
          </div>
          <div className="job-director-status">
            <span>{`${t('titles.Done')}: ${data?.tasks?.completed ?? 0}/${
              data?.tasks?.total ?? 0
            }`}</span>
          </div>
          <div>
            <div className="job-director-progress">
              <ProgressBar
                value={data?.progress ? data?.progress / 100 : 0}
                intent={Intent.SUCCESS}
                animate={data?.state === EJobState.JobRunning}
                stripes={data?.state === EJobState.JobRunning}
                className="job-director-progress-bar"
              />
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default JobDirector;

export const mapJobDirectorProps = ({
  object,
  label,
}: IJobDirector): JobDirectorProps => ({
  id: object,
  label,
});
