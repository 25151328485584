import React, { FC, useState, useCallback, useEffect } from 'react';
import { InputGroup, Icon, Keys } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';

interface ISearchForm {
  onSubmit: (data) => void;
  placeholder?: string;
  initialValue?: string;
  showIcon?: boolean;
}

const SearchForm: FC<ISearchForm> = ({
  onSubmit,
  placeholder,
  initialValue,
  showIcon = true,
}) => {
  const [value, setValue] = useState<string>('');

  const { t } = useTranslation(['select']);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  useEffect(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  return (
    <InputGroup
      onKeyPress={(e) => {
        if (e.which === Keys.ENTER) {
          handleSubmit();
        }
      }}
      onChange={handleChange}
      value={value}
      className="search-input"
      type="search"
      leftIcon={
        showIcon ? (
          <Icon
            icon="search"
            onClick={() => {
              handleSubmit();
            }}
          />
        ) : undefined
      }
      placeholder={placeholder ?? t('inputs.Search')}
    />
  );
};

export default SearchForm;
