import { createAsyncThunk } from '@reduxjs/toolkit';
// Services
import UserConnectorsService from '../../../../services/UserConnectorsService';

// Types
import {
  TestConnectorActionPayload,
  TestConnectorActionSuccessResult,
  AuthorizeConnectorActionPayload,
  AuthorizeConnectorActionSuccessResult,
} from './types';

export const testConnector = createAsyncThunk<
  TestConnectorActionSuccessResult,
  TestConnectorActionPayload
>('connectors/test/testConnector', async ({ id, testData }, { rejectWithValue }) => {
  try {
    const { data } = await UserConnectorsService.test(id, testData);

    return { testingResult: data };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response);
  }
});

export const authorizeConnector = createAsyncThunk<
  AuthorizeConnectorActionSuccessResult,
  AuthorizeConnectorActionPayload
>('connectors/test/authorizeConnector', async ({ id, data }, { rejectWithValue }) => {
  try {
    await UserConnectorsService.authorize(id, data);

    return { authInitialValues: data };
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response);
  }
});
