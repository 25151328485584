import { Quill } from 'react-quill';
import AutoFormat from 'quill-autoformat';
import {
  variableRegex,
  functionRegex,
  extractFunctionName,
  extractArgs,
  extractVariableName,
} from './utils';
import FunctionBlock from './FunctionBlock';
import Variable from './Variable';

AutoFormat.DEFAULTS = {
  function: {
    trigger: /\B[\w\s]/,
    find: functionRegex,
    transform: (value: string) => {
      return JSON.stringify({
        name: extractFunctionName(value),
        isValid: true,
        validArgs: extractArgs(value),
        args: extractArgs(value),
      });
    },
    insert: 'function',
  },
  variable: {
    trigger: /\B[\w\s]/,
    find: variableRegex,
    transform: (value: string) => {
      return JSON.stringify({
        name: extractVariableName(value),
        isValid: true,
      });
    },
    insert: 'variable',
  },
};

Quill.register({
  'formats/variable': Variable,
  'formats/function': FunctionBlock,
  'modules/autoformat': AutoFormat,
});

export const QUILL_MODULES = { autoformat: true, toolbar: false };
export const QUILL_FORMATS = ['variable', 'function'];
