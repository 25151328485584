import React, { FC, useCallback } from 'react';

// Components
import TableSortLabel from './TableSortLabel';
// Types
import { EDashboardTableColTypes, ESort } from 'src/types/enums';

export interface ITableCol {
  field: string;
  label: string;
  type: EDashboardTableColTypes;
  filter?: boolean;
  sort?: boolean;
  size?: number;
  className?: string;
}

interface ITableHeaderCellProps
  extends Pick<ITableCol, 'field' | 'label' | 'sort' | 'className'> {
  size?: number;
  onSort?: (field: string, direction: ESort) => void;
  sortDirection?: ESort;
}

const TableHeaderCell: FC<ITableHeaderCellProps> = ({
  field,
  label,
  size,
  sort,
  sortDirection,
  onSort,
  className,
}) => {
  const handleClick = useCallback(
    (direction: ESort) => {
      if (onSort) {
        onSort(field, direction);
      }
    },
    [field, onSort]
  );

  return (
    <div key={field} title={label} className={className} style={{ width: `${size}%` }}>
      <div className="table-header-title">
        <span>{label ?? ''}</span>
        {sort && <TableSortLabel onClick={handleClick} direction={sortDirection} />}
      </div>
    </div>
  );
};

export default TableHeaderCell;
