import React, { FC, useEffect } from 'react';
import { H3 } from '@blueprintjs/core';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Actions
import { resetPassword } from '../../../redux/slices/auth';
import { resetResetPasswordState } from '../../../redux/slices/auth';

// Hooks
import useRedirectSearchParams from 'src/layouts/auth/useRedirectSearchParams';

// Types
import {
  ResetPasswordFormValues,
  ResetPasswordState,
} from '../../../redux/slices/auth/resetPassword/types';
import { RequestStatus } from '../../../redux/slices/types';
import { RootState } from '../../../redux/rootReducer';

// Utils
import stringifyStateError from '../../../utils/stringifyStateError';

// Components
import Input from '../../../components/input/Input';
import AuthForm from '../AuthForm';
import Button from '../../../components/button/Button';

// Validation schema
import validationSchema from './validationSchema';

// Styles
import './reset-password-styles.scss';

const ForgotPassword: FC = () => {
  const { error, submit, initialValues } = useSelector<RootState, ResetPasswordState>(
    ({ auth }) => auth.resetPassword
  );

  const { t } = useTranslation(['login']);

  const dispatch = useDispatch();
  const history = useHistory();

  const searchParams = useRedirectSearchParams();

  const onSubmit = (data) => {
    dispatch(resetPassword(data));
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
  } = useFormik<ResetPasswordFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const isSubmitDisabled = submit === RequestStatus.pending || !isValid;

  // Effects
  useEffect(() => {
    if (submit === RequestStatus.rejected) {
      const message = stringifyStateError(error);

      toastr.error(
        t('alert.Failed'),
        t('alert.Failed to Reset password', { error: message })
      );
    }

    // Success response
    if (submit === RequestStatus.fulfilled) {
      toastr.success(t('alert.Success'), t('alert.Password reseted successfully'));

      history.push('/login');
    }
  }, [submit, history, error, t]);

  // Reset form when component did unmount
  useEffect(
    () => () => {
      dispatch(resetResetPasswordState());
    },
    [dispatch]
  );

  return (
    <form onSubmit={handleSubmit} className="reset-password-form">
      <AuthForm>
        <H3>{t('titles.Password Reset')}</H3>
        <Input
          onChange={handleChange}
          name="username"
          placeholder={t('inputs.Login/Email')}
          validationError={touched.username && errors.username}
          value={values.username}
          onBlur={handleBlur}
        />

        <Button
          type="submit"
          disabled={isSubmitDisabled}
          loading={submit === RequestStatus.pending}
        >
          {t('buttons.Reset')}
        </Button>

        <p className="no-account text-center">
          {t('links.No Account?')}
          <Link to={{ pathname: '/signup', search: searchParams }}>
            {t('links.Sign Up')}
          </Link>
        </p>

        <Link to={{ pathname: '/login', search: searchParams }} className="custom-link">
          {t('links.Back to the login page')}
        </Link>
      </AuthForm>
    </form>
  );
};

export default ForgotPassword;
