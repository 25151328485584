import React, { FC, useCallback, useMemo } from 'react';
import { FormGroup } from '@blueprintjs/core';
import { useField } from 'formik';

// Components
import DateInputComponent from '../../date-input/DateInput';

import { useTranslation } from 'react-i18next';

// Types
import { IDateInput } from './input-types';
import { TimePrecision } from '@blueprintjs/datetime';
import { Intent } from '@blueprintjs/core';

// Utils
import getClassName from '../utils/getClassName';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';

interface IDateInputProps {
  timePrecision?: TimePrecision;
  minDate?: Date;
  maxDate?: Date;
  locale?: string;
  label: string;
  name: string;
  className: string;
  format?: string;
}

const DateInput: FC<IDateInputProps> = ({
  label,
  name,
  className,
  minDate,
  maxDate,
  locale,
  timePrecision,
  format,
}) => {
  const { t } = useTranslation(['dateinput']);

  const validate = useCallback(
    (value) => {
      if (
        (maxDate && isAfter(fromUnixTime(value), maxDate)) ||
        (minDate && isBefore(fromUnixTime(value), minDate))
      ) {
        return t('validation.Out of range') as string;
      }
      return undefined;
    },
    [t, maxDate, minDate]
  );

  const [field, meta, helpers] = useField({ name, validate });

  const handleChange = (value?: Date | null | string) => {
    // @ts-ignore
    helpers.setValue(isValid(value) ? getUnixTime(value) : null);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  const formattedValue = useMemo(
    () => (field.value ? fromUnixTime(field.value) : undefined),
    [field.value]
  );

  return (
    <FormGroup helperText={meta.touched && meta.error} label={label}>
      <DateInputComponent
        className={className}
        value={formattedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        maxDate={maxDate}
        minDate={minDate}
        locale={locale}
        timePrecision={timePrecision}
        format={format}
        intent={meta.touched && meta.error ? Intent.DANGER : undefined}
      />
    </FormGroup>
  );
};

export default DateInput;

export const mapDateInputProps = ({
  key,
  label,
  classes,
  maxDate,
  minDate,
  locale,
  timePrecision,
  format,
}: IDateInput): IDateInputProps => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
  locale,
  minDate: minDate || minDate === 0 ? fromUnixTime(minDate) : undefined,
  maxDate: maxDate || maxDate === 0 ? fromUnixTime(maxDate) : undefined,
  timePrecision,
  format,
});
