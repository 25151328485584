import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactQuill, { Quill } from 'react-quill';
// Components
import ExpressionInput, { IExpressionInputProps } from './ExpressionInput';
import EditFunctionPopover from './EditFunctionPopover';
// Utils
import { formatValue, getValue } from './utils';
// Styles
import './styles.scss';
import 'react-quill/dist/quill.snow.css';

const ExpressionEditor = forwardRef(
  (
    {
      modules,
      formats,
      onChange,
      validateVariable,
      validateFunction,
      readOnly,
      value,
    }: IExpressionInputProps,
    ref
  ) => {
    const [editableFunction, setEditableFunction] = useState<{
      name: string;
      position: number;
      args: any;
    }>({ name: '', position: -1, args: null });

    const editorRef = useRef<ReactQuill | null>(null);
    const argsEditorRef = useRef<ReactQuill | null>(null);

    const getActiveEditor = useCallback(
      () =>
        editableFunction.name
          ? argsEditorRef.current?.getEditor()
          : editorRef.current?.getEditor(),
      [argsEditorRef, editorRef, editableFunction]
    );

    useImperativeHandle(
      ref,
      () => ({
        getEditor: () => getActiveEditor(),
      }),
      [getActiveEditor]
    );

    const handleFunctionClick = useCallback(
      (e) => {
        const target = e.target.closest('.function.pill');
        if (editorRef.current && target) {
          const elem = Quill.find(target);
          const editor = editorRef.current.getEditor();
          const position = elem.offset(editor.scroll);
          const content = editor.getContents(position);
          const func = content?.ops?.find((item) => item?.insert?.function)?.insert
            .function;
          if (func?.isValid) {
            const { name, args } = func;
            setEditableFunction({
              position,
              name,
              args: formatValue(args.join(' ')),
            });
          }
        }
      },
      [editorRef]
    );

    const handleChangeArgs = useCallback(
      (content) => {
        const { position, name } = editableFunction;
        const editor = editorRef.current?.getEditor();
        if (position !== -1 && name && editor) {
          editor.deleteText(position, 1);
          editor.insertEmbed(
            position,
            'function',
            {
              name,
              args: getValue(content).split(' '),
            },
            'user'
          );
        }
        setEditableFunction({ name: '', args: null, position: -1 });
      },
      [editableFunction, editorRef]
    );

    const handleCancelEditFunction = useCallback(() => {
      setEditableFunction({ name: '', args: null, position: -1 });
    }, []);

    return (
      <>
        <div onClick={handleFunctionClick}>
          <ExpressionInput
            name="expression-input"
            readOnly={readOnly}
            value={value}
            ref={editorRef}
            onChange={onChange}
            modules={modules}
            formats={formats}
            validateFunction={validateFunction}
            validateVariable={validateVariable}
          />
        </div>
        <EditFunctionPopover
          isOpen={!!editableFunction.name}
          onCancel={handleCancelEditFunction}
          onChange={handleChangeArgs}
          value={editableFunction.args}
          ref={argsEditorRef}
          validateVariable={validateVariable}
        />
      </>
    );
  }
);

export default ExpressionEditor;
