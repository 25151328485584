import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import TextArea from '../../../../../../components/textarea/TextArea';
import Input from '../../../../../../components/input/Input';
import { Icon } from '@blueprintjs/core';
// Types
import {
  CreateConnectorFormData,
  IConnectorResponse,
} from '../../../../../../types/internal-Types';
import { getHelperText } from '../../../../utils';
// Style
import './response-styles.scss';

interface IResponseItemProps {
  index: number;
  data: IConnectorResponse;
  path: string;
  onRemove: (index: number) => void;
}

const ResponseItem: FC<IResponseItemProps> = ({ data, index, path, onRemove }) => {
  const { handleBlur, handleChange, touched, errors } = useFormikContext<
    CreateConnectorFormData
  >();
  const { t } = useTranslation(['connectors']);

  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [onRemove, index]);
  return (
    <div className="response-item">
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        type="number"
        value={data?.statusCode + ''}
        name={`${path}.statusCode`}
        label={t('inputs.Status code')}
        placeholder={t('inputs.Status code')}
        validationError={getHelperText(touched, errors, `${path}.statusCode`)}
        className="response-code"
      />
      <TextArea
        onChange={handleChange}
        onBlur={handleBlur}
        label={t('inputs.Response description')}
        placeholder={t('inputs.Response description')}
        validationError={getHelperText(touched, errors, `${path}.description`)}
        value={data.description}
        name={`${path}.description`}
        className="response-description"
      />

      <div className="response-item-controls">
        <Icon icon="trash" onClick={handleRemove} />
      </div>
    </div>
  );
};

export default ResponseItem;
