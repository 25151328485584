import React, { FC, useMemo, useCallback, useState } from 'react';
import { Popover } from '@blueprintjs/core';
import { useLocation, useHistory } from 'react-router-dom';
import { stringify, parse } from 'query-string';
// Icons
import ArrowIcon from '../icons/ArrowIcon';
// Utils
import useLanguage, { LANGUAGE_PARAM_NAME } from '../../utils/useLanguage';
import { getFlagByKey, LanguagesKeys } from '../../redux/slices/language/languages';
// Types
import { IValueKey } from '../generic-selector/GenericSelector';

const LanguageSelector: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { selectedLanguage, allLanguages, setLanguage } = useLanguage();

  const languageOptions = useMemo(
    () =>
      allLanguages.filter(
        ({ Key, Value }) => Key !== selectedLanguage.Key ?? { Key, Value }
      ),
    [allLanguages, selectedLanguage]
  );

  const flag = useMemo(() => getFlagByKey(selectedLanguage.Key as LanguagesKeys), [
    selectedLanguage.Key,
  ]);

  const location = useLocation();
  const history = useHistory();

  const navigation = useMemo(() => {
    const searchParams = parse(location.search);
    delete searchParams[LANGUAGE_PARAM_NAME];
    return stringify(searchParams);
  }, [location.search]);

  // Handlers
  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleSelectLanguage = useCallback(
    (lang: IValueKey) => {
      history.push({ search: navigation });
      setLanguage(lang);
      setIsOpen(false);
    },
    [setLanguage, navigation, history]
  );

  return (
    <div className="languge-selector">
      <Popover
        isOpen={isOpen}
        placement="bottom"
        canEscapeKeyClose
        popoverClassName="select-language-popover"
        minimal
        onInteraction={(isPopoverOpen) => {
          setIsOpen(isPopoverOpen);
        }}
      >
        <div className="select-language-dropdown" onClick={handleOpen}>
          <ArrowIcon />
          <img src={flag} alt={`${selectedLanguage.Value} language`} />
        </div>
        <div className="select-languge-options">
          {languageOptions.map((lang) => (
            <div
              key={lang.Key}
              className="language-option"
              onClick={() => {
                handleSelectLanguage(lang);
              }}
            >
              <img
                src={getFlagByKey(lang.Key as LanguagesKeys)}
                alt={`${selectedLanguage.Value} language`}
              />
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};
export default LanguageSelector;
