import { combineReducers } from 'redux';

// Reducers
import connectorReducer from './connector/connectorSlice';
import componentsReducer from './components/componentsSlice';
import connectorConfigReducer from './connector-config/connectorConfigSlice';
import logsReducer from './logs/logsSlice';
import connectorIdSlugMapReducer from './connector-id-slug-map/connectorIdSlugMapSlice';
import linksReducer from './links/linksSlice';
import chartsReducer from './charts/chartsSlice';

const dashboardReducer = combineReducers({
  connector: connectorReducer,
  components: componentsReducer,
  connectorConfig: connectorConfigReducer,
  logs: logsReducer,
  connectorIdSlugMap: connectorIdSlugMapReducer,
  links: linksReducer,
  charts: chartsReducer,
});

export default dashboardReducer;
