import React, { FC } from 'react';
// Components
import {
  BarChart as BarChartRecharts,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
// Types
import { IBarChart, IChartKey } from '../types';
// Utils
import getClassName from 'src/components/dynamic-layout/utils/getClassName';
// Styles
import './charts-styles.scss';

interface IBarChartProps {
  keys: IChartKey[];
  xAxis?: string;
  yAxis?: string;
  data: any;
  className?: string;
}

const BarChart: FC<IBarChartProps> = ({ xAxis, yAxis, keys, data, className }) => {
  return (
    <div className={className ? `chart-container ${className}` : 'chart-container'}>
      <ResponsiveContainer>
        <BarChartRecharts data={data} height={20}>
          <XAxis dataKey={xAxis ? xAxis : 'name'} />
          <YAxis dataKey={yAxis} />
          <Tooltip />
          <Legend />
          {keys.map(({ key, color }, index) => {
            return <Bar dataKey={key} fill={color} key={index} />;
          })}
        </BarChartRecharts>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChart;

export const mapBarChartProps = ({ xAxis, yAxis, keys, data, classes }: IBarChart) => ({
  xAxis,
  yAxis,
  keys,
  data,
  className: getClassName(classes),
});
