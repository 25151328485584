import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import Input from '../../../../../../components/input/Input';
import { Icon } from '@blueprintjs/core';
import GenericSelector from 'src/components/generic-selector/GenericSelector';
import Checkbox from 'src/components/checkbox/Checkbox';
// Types
import {
  CreateConnectorFormData,
  EConnectorAuthParams,
  IConnectorRequestParameter,
} from '../../../../../../types/internal-Types';
import { getHelperText } from '../../../../utils';
// Style
import './parameters-styles.scss';

interface IParameterFormProps {
  data: IConnectorRequestParameter;
  path: string;
  onRemove: (index: number) => void;
  index: number;
}

const ParameterForm: FC<IParameterFormProps> = ({ data, index, path, onRemove }) => {
  const {
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext<CreateConnectorFormData>();
  const { t } = useTranslation(['connectors']);

  const placeOptions = useMemo(
    () => [
      { Key: EConnectorAuthParams.path, Value: t('selects.Path') },
      { Key: EConnectorAuthParams.query, Value: t('selects.Query') },
    ],
    [t]
  );

  const selectedPlace = useMemo(
    () => placeOptions.filter(({ Key }) => Key === data?.in),
    [data, placeOptions]
  );

  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [onRemove, index]);

  const handleSelectPlace = useCallback(
    (place) => {
      const [{ Key } = { Key: undefined }] = place;

      setFieldValue(`${path}.in`, Key);
      setFieldTouched(`${path}.in`);
    },
    [setFieldValue, setFieldTouched, path]
  );

  return (
    <div className="parameter-form">
      <div className="parameter-form-title">
        {data?.name ? data.name : t('titles.New parameter')}
        <div className="parameter-form-controls">
          <Icon icon="trash" onClick={handleRemove} />
        </div>
      </div>
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={data?.name ?? ''}
        name={`${path}.name`}
        placeholder={t('inputs.Parameter name')}
        validationError={getHelperText(touched, errors, `${path}.name`)}
      />
      <div className="margin-bottom-xxs">
        <GenericSelector
          onValueChanged={handleSelectPlace}
          isMultiSelector={false}
          items={placeOptions}
          selectedItem={selectedPlace}
        />
      </div>
      <Checkbox
        name={`${path}.required`}
        onChange={handleChange}
        checked={data?.required}
        label={t('inputs.Required parameter')}
      />
    </div>
  );
};

export default ParameterForm;
