import BaseApiService, { RequestType, IQuery } from './BaseApiService';

// Services
import IntegrationDebugSettingsService from './IntegrationDebugSettingsService';
// Types
import { IDashboardTableRow, IDynamicLayout } from '../types/internal-Types';
import { SerializedError } from 'src/redux/slices/types';
import { ESort } from 'src/types/enums';
// Utils
import { stringifyFormData } from 'src/utils/stringifyFormData';
import { getRandomKey } from 'src/utils/getRandom';

class TableManagerService extends BaseApiService {
  getRecords = async (
    key: string,
    {
      connectorId,
      tableName,
      query = {},
      page = 1,
      limit = 10,
      sort = { created: ESort.desc },
    }: IGetTableRecordsParams
  ): Promise<IGetTableRecordsResult> => {
    const { data } = await this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          action: 'records',
          op: tableName,
          page,
          limit,
          query,
          sort,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });

    if (data?.output?.error) {
      return Promise.reject({ message: data.output.error } as SerializedError);
    }

    return {
      total: data.output.total,
      rows: data.output.rows.map((row) => {
        return { values: row, id: getRandomKey() };
      }),
    };
  };

  updateRows = async ({
    connectorId,
    tableName,
    records,
    action,
    query,
    select_all,
  }: IUpdateTableRows): Promise<any> => {
    const selectedRecords = records.map(({ values }) => values);
    const { data } = await this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          action,
          op: tableName,
          ...(select_all ? { query, select_all } : { records: selectedRecords }),
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
    if (data?.output?.error) {
      return Promise.reject({ message: data.output.error } as SerializedError);
    }
    return data;
  };

  getCreationForm = async (
    key: string,
    { connectorId, tableName, id }: IGetTableFormParams
  ): Promise<IDynamicLayout> => {
    const { data } = await this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          action: 'form',
          op: tableName,
          ...(id ? { entity_id: id } : {}),
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
    if (data?.output?.error) {
      return Promise.reject({ message: data.output.error } as SerializedError);
    }
    return data.output;
  };

  submitCreationForm = async ({
    connectorId,
    tableName,
    formData,
  }: ICreateRowTableSubmit) => {
    const { data } = await this.request({
      url: connectorId + '/dashboard',
      type: RequestType.put,
      data: {
        input: {
          action: 'form',
          op: tableName,
          data: formData,
        },
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });

    if (data?.output?.error) {
      return Promise.reject({ message: data.output.error } as SerializedError);
    }

    return;
  };

  submitRowForm = async ({
    callback = '',
    formData,
    submitPayload,
    connectorId,
  }: IEditTableRowSubmit): Promise<any> => {
    const { data } = await this.request({
      url: callback,
      type: RequestType.put,
      data: {
        ...stringifyFormData(formData, submitPayload),
        silent: !IntegrationDebugSettingsService.get(connectorId),
      },
    });
    if (data?.output?.error) {
      return Promise.reject({ message: data.output.error } as SerializedError);
    }
    return data;
  };
}

export default new TableManagerService('rest/connector');

export interface IGetTableRecordsParams {
  connectorId: string;
  tableName: string;
  page?: number;
  limit?: number;
  query?: IQuery;
  sort?: { [key: string]: ESort };
}

export interface IGetTableRecordsResult {
  total: number;
  rows: IDashboardTableRow[];
}

export interface IUpdateTableRows {
  connectorId: string;
  tableName: string;
  action: string;
  records: IDashboardTableRow[];
  responseOnRow?: boolean;
  query?: IQuery;
  select_all?: boolean;
}

export interface ICreateRowTableSubmit {
  connectorId: string;
  tableName: string;
  formData: any;
}

export interface IGetTableFormParams {
  connectorId: string;
  tableName: string;
  id?: string;
}

export interface IEditTableRowSubmit {
  callback?: string;
  formData: any;
  submitPayload?: string;

  connectorId: string;
}
