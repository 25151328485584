import { Intent } from '@blueprintjs/core';
import { IComponentStatus } from '../../../redux/slices/dashboard/components/types';

export const getStatusIntent = (status: boolean | IComponentStatus): Intent => {
  if (typeof status === 'object') {
    return Intent.DANGER;
  }

  return status ? Intent.SUCCESS : Intent.DANGER;
};

export const getComponentStatus = (status: boolean | IComponentStatus): string => {
  if (typeof status === 'object') {
    return String(status.error);
  }

  return '';
};
