import React, { FC, useState, useCallback, useEffect } from 'react';
import { Collapse, Icon, Spinner, Button } from '@blueprintjs/core';
import { DynamicLayout, StructureErrorBoundary } from 'wedoio-dynamic-layout';
// Types
import { RequestStatus } from '../../../../redux/slices/types';
// Components
import CloseIcon from '../../../../components/icons/CloseIcon';
import componentsMap from '../../../dynamic-layout/components/componentsMap';
// Utils
import useCompanyActionsForm from './useCompanyActionsForm';
// Styles
import './style.scss';

const CompanyActions: FC = () => {
  const {
    layout,
    loading,
    error,
    getLayout,
    handleDynamicFormSubmit,
  } = useCompanyActionsForm();
  const [open, setOpen] = useState(false);

  const isLayoutEmpty =
    !layout || (Array.isArray(layout) && !layout?.length) || !Object.keys(layout).length;

  const icon =
    loading === RequestStatus.pending ? (
      <Spinner size={20} />
    ) : open ? (
      <CloseIcon />
    ) : (
      'cog'
    );

  const handleClick = useCallback(async () => {
    if (!layout && loading === RequestStatus.idle) {
      await getLayout();
    } else if (layout) {
      setOpen((isopen) => !isopen);
    }
  }, [layout, loading, getLayout]);

  useEffect(() => {
    if (loading === RequestStatus.fulfilled) {
      setOpen(true);
    }
  }, [loading]);

  useEffect(() => {
    if (isLayoutEmpty) {
      setOpen(false);
    }
  }, [isLayoutEmpty]);

  return (
    <>
      <Button
        className="company-actions-button"
        onClick={handleClick}
        disabled={loading === RequestStatus.pending || !!error}
      >
        <Icon icon={icon} iconSize={20} />
      </Button>
      <div className="company-actions">
        <Collapse isOpen={open}>
          {!isLayoutEmpty && (
            <StructureErrorBoundary>
              <DynamicLayout
                structure={layout}
                componentsMap={componentsMap}
                callService={handleDynamicFormSubmit}
              />
            </StructureErrorBoundary>
          )}
        </Collapse>
      </div>
    </>
  );
};

export default CompanyActions;
