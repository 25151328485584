import React, { FC, useCallback, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Selectors
import { selectCurrentUser } from '../../redux/slices/user/selectors';

// Components
import HeaderMenu from './HeaderMenu';
import LanguageSelector from './LanguageSelector';
import UserNotifications from '../notifications/UserNotifications';
// Icons
import logo from '../../images/logo-front.svg';
import menu from '../../images/menu.svg';

// Styles
import './header.scss';

// Types
import { RequestStatus } from 'src/redux/slices/types';

export interface IHeaderProps {
  withBackground?: boolean;
}

const Header: FC<IHeaderProps> = ({ withBackground = false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { data: userData, loading: userLoading } = useSelector(selectCurrentUser);

  const toggleIsMenuOpen = useCallback(
    (isOpen = false) => () => {
      setIsMenuOpen(isOpen);
    },
    [setIsMenuOpen]
  );

  return (
    <div className={`header${withBackground ? ' with-background' : ''}`}>
      <Grid>
        <Row>
          <Col md={3} className="logo-col">
            {userData && (
              <Link to="/" className={'home-link'}>
                <img alt="logo" className="logo responsive-image" src={logo} />
              </Link>
            )}
            {!userData && (
              <Link to="/login" className={'home-link'}>
                <img alt="logo" className="logo responsive-image" src={logo} />
              </Link>
            )}
          </Col>

          <Col md={2} className="right-side pull-right">
            <LanguageSelector />
            {(userData || userLoading === RequestStatus.pending) && (
              <>
                <UserNotifications />
                <span className="menu-btn" onClick={toggleIsMenuOpen(true)}>
                  <img src={menu} style={{ paddingRight: '15px' }} alt="menu" />
                </span>
                <HeaderMenu isOpen={isMenuOpen} onClose={toggleIsMenuOpen()} />
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Header;
