import React, { FC, useCallback, useState } from 'react';
// Components
import EditorResultRow from './EditorResultRow';
import { Collapse, Icon } from '@blueprintjs/core';
import { Row } from 'react-bootstrap';
import EditorCreateRow from './EditorCreateRow';
// Types
import { IValueKey } from '../../generic-selector/GenericSelector';

interface IEditorTableProps {
  onEditValueRequest: (path: string) => void;
  onFieldNameChange: (path: string, newFieldName: string) => void;
  onAddField: (path: string, newFieldName: string, value: string | object) => void;
  onRemoveField: (path: string) => void;
  editorValue: object;
  onEditValue: (value: string) => void;
  handleAddOptionKey: (newValue: any) => void;
  handleAddOptionValue: (newValue: any) => void;
  optionsKeys: IValueKey[];
  optionsValues: IValueKey[];
}

const EditorTable: FC<IEditorTableProps> = ({
  onAddField,
  onEditValueRequest,
  onFieldNameChange,
  onRemoveField,
  editorValue,
  onEditValue,
  handleAddOptionKey,
  handleAddOptionValue,
  optionsKeys,
  optionsValues,
}) => {
  const rows = Object.entries(editorValue);

  const [showCreateField, setShowCreateField] = useState<boolean>(false);

  const cancelShowCreateField = () => {
    setShowCreateField(false);
  };

  const handleAdd = useCallback(
    (newFieldName, newValue) => {
      onAddField('', newFieldName, newValue);
      setShowCreateField(false);
    },
    [onAddField]
  );

  return (
    <div className="editor-table">
      <Row className="create-row">
        <Icon
          className="add-field-button"
          icon="add"
          onClick={() => setShowCreateField(true)}
        />
        <Collapse isOpen={showCreateField} className="add-field-collapse">
          <EditorCreateRow
            optionsKeys={optionsKeys}
            optionsValues={optionsValues}
            handleAddOptionKey={handleAddOptionKey}
            handleAddOptionValue={handleAddOptionValue}
            onAdd={handleAdd}
            onCancelShowCreateField={cancelShowCreateField}
          />
        </Collapse>
      </Row>
      {rows.length ? (
        rows.map(([fieldName, templateValue]) => (
          <EditorResultRow
            optionsKeys={optionsKeys}
            optionsValues={optionsValues}
            handleAddOptionKey={handleAddOptionKey}
            handleAddOptionValue={handleAddOptionValue}
            key={fieldName}
            path={fieldName}
            currentValue={templateValue}
            fieldName={fieldName}
            onEditValueRequest={onEditValueRequest}
            onFieldNameChange={onFieldNameChange}
            onAddField={onAddField}
            onRemoveField={onRemoveField}
            onEditValue={onEditValue}
          />
        ))
      ) : (
        <EditorResultRow
          optionsKeys={optionsKeys}
          optionsValues={optionsValues}
          handleAddOptionKey={handleAddOptionKey}
          handleAddOptionValue={handleAddOptionValue}
          path=""
          currentValue={''}
          fieldName=""
          onEditValueRequest={onEditValueRequest}
          onFieldNameChange={onFieldNameChange}
          onAddField={onAddField}
          onRemoveField={onRemoveField}
          onEditValue={onEditValue}
        />
      )}
    </div>
  );
};

export default EditorTable;
