import React, { FC } from 'react';
import { FormGroup } from '@blueprintjs/core';
import { useField } from 'formik';
// Components
import SimpleArrayEditorComponent from '../../simple-array-editor/SimpleArrayEditor';
// Types
import { ISimpleArrayEditor } from './input-types';
// Utils
import getClassName from '../utils/getClassName';
import { getFormattedOptions } from '../utils/getFormatedOptions';

const SimpleArrayEditor: FC<ISimpleArrayEditor> = ({
  label,
  name,
  className,
  availableValues,
  availableKeys,
}) => {
  const [field, meta, helpers] = useField({ name });

  const formattedAvailableValues = getFormattedOptions(availableValues);
  const formattedAvailableKeys = getFormattedOptions(availableKeys);

  const handleChange = (result) => {
    helpers.setTouched(true);
    helpers.setValue(result);
  };

  return (
    <FormGroup
      helperText={meta.touched && meta.error}
      label={label}
      className={className}
    >
      <SimpleArrayEditorComponent
        onChange={handleChange}
        className={className}
        initialValue={field.value ?? {}}
        availableValues={formattedAvailableValues}
        availableKeys={formattedAvailableKeys}
      />
    </FormGroup>
  );
};

export default SimpleArrayEditor;

export const mapSimpleArrayEditorProps = ({
  key,
  label,
  classes,
  availableValues,
  availableKeys,
}: ISimpleArrayEditor) => ({
  name: `['${key}']`,
  label,
  className: getClassName(classes),
  availableValues,
  availableKeys,
});
