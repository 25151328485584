import React, { FC } from 'react';
// Components
// import Card from './card/Card';
// Types
import { IFluidBlock } from '../block-types';

const FluidBlock: FC<any> = (props) => {
  return (
    /*
    <Card
      title={props.i}
      isStatic={
        props.static || !(props.isDraggable === undefined ? true : props.isDraggable)
      }
    >
      {props.children}
    </Card>*/
    <>{props.children}</>
  );
};

export default FluidBlock;
export const mapFluidBlockProps = ({ children, ...props }: IFluidBlock, builder) => {
  return {
    ...props,
    schema: children,
    children: builder(children),
  };
};
