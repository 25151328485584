import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';

const SignupLogo: FC<{ logo: string }> = ({ logo }) => {
  return (
    <Row className="signup-logo-container margin-xs">
      <Col md={12} className="signup-logo">
        <img src={logo} alt="logo" />
      </Col>
    </Row>
  );
};
export default SignupLogo;
