import React, { FC } from 'react';
import { Icon, IconName, Spinner } from '@blueprintjs/core';

import './style.scss';

interface IControlButtonProps {
  icon: IconName;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  active?: boolean;
  onClick: () => void;
}

const ControlButton: FC<IControlButtonProps> = ({
  onClick,
  icon,
  disabled = false,
  loading = false,
  active = false,
  className = '',
}) => {
  return loading ? (
    <Spinner size={23} />
  ) : (
    <button
      disabled={disabled || active}
      className={`control${disabled ? ' disabled' : ''} ${
        active ? ' active' : ''
      } ${className}`}
    >
      <Icon onClick={onClick} icon={icon} iconSize={23} />
    </button>
  );
};

export default ControlButton;
