import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Layouts
import AuthLayout from '../layouts/auth/AuthLayout';

// Types
import { RootState } from '../redux/rootReducer';
import { UserPermissions } from '../types/enums';

// Selectors
import { selectToken } from '../redux/slices/auth/token/selectors';
import { selectLanguageState } from '../redux/slices/language/selectors';

// Actions
import { getCompanies } from '../redux/slices/companies/list/thunks';
import { reset as resetCompaniesList } from '../redux/slices/companies/list/companiesListSlice';
import { reset as resetSelectedCompany } from '../redux/slices/companies/selected-company/selectedCompanySlice';
import { getUser } from '../redux/slices/user/thunks';
import { getHomePageLayout } from '../redux/slices/homepage/thunk';
import { getMarketPlaces } from '../redux/slices/marketplace/thunk';
import { getConnectors } from '../redux/slices/connectors/list/thunks';
import { reset as resetConnectorsList } from '../redux/slices/connectors/list/connectorsListSlice';

import { reset as resetMarketPlace } from '../redux/slices/marketplace/marketplaceSlice';
import { reset as resetHomepage } from '../redux/slices/homepage/homepageSlice';
import { reset as resetNotifications } from '../redux/slices/notifications/list/notificationsListSlice';
import { reset as resetSlugs } from '../redux/slices/dashboard/connector-id-slug-map/connectorIdSlugMapSlice';

// Hooks
import useRedirectSearchParams from '../layouts/auth/useRedirectSearchParams';

// Components
import PrivateRoute from './PrivateRoute';

// Pages
import DashboardPage from '../pages/dashboard/DashboardPage';
import HomePage from '../pages/home/HomePage';
import NotFound from '../pages/NotFound';
import SettingsPage from '../pages/settings/SettingsPage';
import ResetPassword from '../pages/auth/reset-password/ResetPassword';
import Login from '../pages/auth/login/Login';
import Signup from '../pages/auth/signup/Signup';
import NotificationsPage from '../pages/notifications/NotificationsPage';
import ConnectorsPage from '../pages/connectors/ConnectorsPage';
import ConnectorPage from '../pages/connectors/ConnectorPage';
import CreateConnectorPage from '../pages/connectors/CreateConnectorPage';

import TestPage from '../pages/test/TestPage';

export const DASHBOARD_ROUTE = '/dashboard/';

const Routes = () => {
  const token = useSelector<RootState>(selectToken);
  const { selectedLanguage } = useSelector(selectLanguageState);

  const dispatch = useDispatch();

  const redirectParameters = useRedirectSearchParams();

  // Component did mount
  useEffect(() => {
    if (token) {
      dispatch(getUser());
      dispatch(getCompanies());
      dispatch(getMarketPlaces());
      dispatch(getConnectors());
    }

    return () => {
      dispatch(resetCompaniesList());
      dispatch(resetSelectedCompany());
      dispatch(resetMarketPlace());
      dispatch(resetHomepage());
      dispatch(resetNotifications());
      dispatch(resetSlugs());
      dispatch(resetConnectorsList());
    };
  }, [dispatch, token]);

  useEffect(() => {
    if (token) {
      dispatch(getHomePageLayout(redirectParameters));
    }
  }, [selectedLanguage.Key, dispatch, token, redirectParameters]);

  return (
    <Switch>
      <Route exact path="/login">
        <AuthLayout>
          <Login />
        </AuthLayout>
      </Route>

      <Route exact path="/reset-password">
        <AuthLayout>
          <ResetPassword />
        </AuthLayout>
      </Route>

      <Route exact path={'/signup/'}>
        <AuthLayout size={12} background={false}>
          <Signup />
        </AuthLayout>
      </Route>

      {/* Private routes */}
      <PrivateRoute path="/" exact>
        <HomePage />
      </PrivateRoute>

      {/* Companies list */}
      <PrivateRoute path="/settings/companies" exact>
        <SettingsPage tabId="companies" />
      </PrivateRoute>

      {/* Company edit */}
      <PrivateRoute exact path="/settings/companies/edit/:companyId">
        {(props) => (
          <SettingsPage tabId="companies" companyId={props?.match?.params.companyId} />
        )}
      </PrivateRoute>

      {/* Company create */}
      <PrivateRoute
        exact
        path="/settings/companies/create"
        requiredPermissions={UserPermissions.create_company}
        redirectTo="/settings/companies"
      >
        <SettingsPage tabId="companies/create" />
      </PrivateRoute>

      {/* User edit */}
      <PrivateRoute path="/settings/user-edit">
        <SettingsPage tabId="user-edit" />
      </PrivateRoute>

      <PrivateRoute path={`${DASHBOARD_ROUTE}**`} exact>
        <DashboardPage />
      </PrivateRoute>

      {/* Notifications*/}
      <PrivateRoute path="/notifications" exact>
        <NotificationsPage />
      </PrivateRoute>

      {/* Connectors list */}
      <PrivateRoute path="/connectors" exact>
        <ConnectorsPage />
      </PrivateRoute>

      {/* Create connector */}
      <PrivateRoute path="/connectors/create/">
        <CreateConnectorPage />
      </PrivateRoute>

      {/* Edit and test connector */}
      <PrivateRoute path="/connectors/:connectorId/">
        <ConnectorPage />
      </PrivateRoute>

      {/* Testing page*/}
      <PrivateRoute
        path="/test"
        exact
        requiredPermissions={UserPermissions.test_page}
        redirectTo="/"
      >
        <TestPage />
      </PrivateRoute>

      {/* Not Found Route */}
      <Route to="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
