import { yaml } from '@codemirror/legacy-modes/mode/yaml';
import { jsonLanguage } from '@codemirror/lang-json';
import { StreamLanguage } from '@codemirror/stream-parser';
import { LanguageSupport } from '@codemirror/language';
// Types
import { CodeEditorLanguages } from './types';
import { Diagnostic } from '@codemirror/lint';
import { YAMLError, YAMLSemanticError } from 'yaml/util';
// Utils
import { parse as parseYaml } from 'yaml';
import { parse as parseJson } from '@prantlf/jsonlint';

const lintJSON = (value: string): Diagnostic[] => {
  try {
    parseJson(value);
    return [];
  } catch (err) {
    return [
      {
        from: err.location?.start?.offset,
        to: err.location?.end?.offset,
        message: err.message,
        severity: 'error',
      },
    ];
  }
};

const lintYaml = (value: string): Diagnostic[] => {
  try {
    parseYaml(value, { prettyErrors: true });
    return [];
  } catch (err) {
    if (err instanceof YAMLError || err instanceof YAMLSemanticError) {
      return [
        {
          from: err.range?.start ?? 0,
          to: err.range?.end ?? 0,
          message: err.message,
          severity: 'error',
        },
      ];
    }
    return [];
  }
};

export const getMode = (mode: CodeEditorLanguages) => {
  switch (mode) {
    case 'yaml':
      return { langExtention: StreamLanguage.define(yaml), parser: lintYaml };
    default:
      return { langExtention: new LanguageSupport(jsonLanguage), parser: lintJSON };
  }
};
