import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestType } from '../../../../services/BaseApiService';
// Types
import { RequestStatus } from '../../types';
import { createThunkReducers } from '../../utils';
import { getComponentSettings, updateComponentSettings } from './thunks';
import { IAppendComponentActionPayload, IComponentWithSettings } from './types';

export const initialState: IComponentWithSettings = {
  connectorId: null,
  component: null,
  settings: null,
  loading: RequestStatus.pending,
  error: null,
  componentStatus: false,
};

const componentItemSlice = createSlice({
  name: 'dashboard.components',
  initialState,
  reducers: {
    // Set component initial data
    setComponent: (
      state: IComponentWithSettings,
      action: PayloadAction<IAppendComponentActionPayload>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    ...createThunkReducers(getComponentSettings),
    ...createThunkReducers(updateComponentSettings, RequestType.put),
  },
});

export const { setComponent } = componentItemSlice.actions;

export default componentItemSlice.reducer;
