import { useFormik } from 'formik';
import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import Button, { ButtonSize } from '../../../components/button/Button';
import CompanyLogo from '../../../components/company-logo/CompanyLogo';
import FormActions from '../../../components/form-actions/FormActions';
import GenericSelector, {
  IValueKey,
} from '../../../components/generic-selector/GenericSelector';
import ImagePicker from '../../../components/image-picker/ImagePicker';
import Input from '../../../components/input/Input';
import compPic from '../../../images/companies.svg';
import compBg from '../../../images/companiesBG.svg';
import CustomScrollbars from '../../../components/custom-scrollbars/CustomScrollbars';
// Types
import { RootState } from '../../../redux/rootReducer';
// Actions
import { resetCreateCompanyState } from '../../../redux/slices/companies';
import { createCompany } from '../../../redux/slices/companies/create/thunks';
import { getCompanies } from '../../../redux/slices/companies/list/thunks';
import {
  CreateCompanyFormValues,
  CreateCompanyState,
} from '../../../redux/slices/companies/create/types';
import { RequestStatus } from '../../../redux/slices/types';
// Utils
import getImageAsBase64 from '../../../utils/getImageAsBase64';
import stringifyStateError from '../../../utils/stringifyStateError';
// Styles
import './company-create-styles.scss';

import validationSchema from '../validationSchema';

import { useCountriesList } from '../countryList';

const CompanyCreatePage: FC = () => {
  // State
  const { error, submit, initialValues } = useSelector<RootState, CreateCompanyState>(
    ({ companies }) => companies.create
  );

  const { t } = useTranslation(['company', 'countries']);

  const countriesList = useCountriesList();

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data) => {
    const { field_company_logo, ...formData } = data;
    dispatch(
      createCompany({
        ...formData,
        ...(field_company_logo && field_company_logo.file !== ''
          ? {
              field_company_logo: {
                ...data.field_company_logo,
                file: data.field_company_logo.file.replace(
                  /^data:image\/\w+;base64,/,
                  ''
                ),
              },
            }
          : {}),
      })
    );
  };

  // Formik State
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
    resetForm,
  } = useFormik<CreateCompanyFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
    isInitialValid: false,
  });

  const selectedCountry = useMemo(
    () =>
      countriesList.filter(({ Key }) => Key === values?.field_company_address?.country),
    [countriesList, values]
  );

  // Handlers
  const handleLogoChange = async (file: File) => {
    const base64 = await getImageAsBase64(file);

    setFieldValue('field_company_logo.file', base64, true);
    setFieldValue('field_company_logo.filename', file.name, true);
  };

  const handleLogoReset = () => {
    setFieldValue('field_company_logo', null);
  };

  const handleCancel = useCallback(() => {
    resetForm();
    history.goBack();
  }, [resetForm, history]);

  const isSubmitting = submit === RequestStatus.pending;

  const canResetLogo =
    submit !== RequestStatus.pending &&
    !!values.field_company_logo?.file &&
    values.field_company_logo?.file !== initialValues.field_company_logo?.file;

  const handleCountryChange = (value: IValueKey[]) => {
    const [{ Key: country } = { Key: undefined }] = value;

    setFieldValue('field_company_address.country', country, true);
  };

  // Effects
  useEffect(() => {
    if (submit === RequestStatus.fulfilled) {
      toastr.success(
        t('Success'),
        t('alert.Success create', { companyName: values.title })
      );

      dispatch(resetCreateCompanyState());
      resetForm();
      dispatch(getCompanies());

      setTimeout(() => {
        history.goBack();
      }, 1000);
    }
  }, [submit, dispatch, values.title, resetForm, history, t]);

  useEffect(() => {
    if (error) {
      const message = stringifyStateError(error);

      toastr.error(t('alert.Error create'), message);
    }
  }, [error, t]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateCompanyState());
    };
  }, [dispatch]);

  return (
    <div className="company-create">
      <div className="background-picture">
        <img src={compBg} alt="" />
      </div>

      <div className="iconAccount">
        <img src={compPic} alt="" />
      </div>

      <form onSubmit={handleSubmit}>
        {/* Submit button */}
        <FormActions>
          <Button disabled={isSubmitting} size={ButtonSize.small} onClick={handleCancel}>
            {t('buttons.Cancel')}
          </Button>
          <Button
            size={ButtonSize.small}
            disabled={!isValid || isSubmitting}
            type="submit"
            loading={submit === RequestStatus.pending}
          >
            {t('buttons.Create')}
          </Button>
        </FormActions>

        <CustomScrollbars autoHeight autoHeightMax={'50vh'} style={{ width: '100%' }}>
          <Row>
            <Col xs={8}>
              <h4>{t('titles.General Information')}</h4>
              <Input
                name="title"
                placeholder={t('inputs.Company Name')}
                value={values.title}
                validationError={touched.title && errors.title}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <Input
                name="field_company_vat"
                placeholder={t('inputs.VAT Number')}
                value={values.field_company_vat}
                validationError={touched.field_company_vat && errors.field_company_vat}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Col>
            <Col xs={4} style={{ marginTop: '40px' }}>
              <ImagePicker
                onChange={handleLogoChange}
                onReset={handleLogoReset}
                canReset={canResetLogo}
              >
                <CompanyLogo src={values.field_company_logo?.file} />
              </ImagePicker>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Input
                name="field_invoice_email"
                placeholder={t('inputs.Invoice Email')}
                value={values.field_invoice_email}
                validationError={
                  touched.field_invoice_email && errors.field_invoice_email
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <h4>{t('titles.Billing address')}</h4>
              <Input
                name="field_company_address.thoroughfare"
                placeholder={t('inputs.Address')}
                value={values.field_company_address.thoroughfare}
                validationError={
                  touched.field_company_address?.thoroughfare &&
                  errors.field_company_address?.thoroughfare
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Input
                name="field_company_address.locality"
                placeholder={t('inputs.City')}
                value={values.field_company_address.locality}
                validationError={
                  touched.field_company_address?.locality &&
                  errors.field_company_address?.locality
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Input
                name="field_company_address.postal_code"
                placeholder={t('inputs.Zip code')}
                value={values.field_company_address.postal_code}
                validationError={
                  touched.field_company_address?.postal_code &&
                  errors.field_company_address?.postal_code
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <GenericSelector
                selectedItem={selectedCountry}
                isMultiSelector={false}
                items={countriesList}
                selectorText={t('inputs.Country')}
                onValueChanged={handleCountryChange}
              />
            </Col>
          </Row>
        </CustomScrollbars>
      </form>
    </div>
  );
};

export default CompanyCreatePage;
