import React, { FC, useCallback, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Formik } from 'formik';
// Selectros
import { selectEditConnectorState } from '../../redux/slices/connectors/edit/selectors';
// Actions
import { editConnector } from '../../redux/slices/connectors/edit/thunks';
// Components
import FormActions from 'src/components/form-actions/FormActions';
import Button, { ButtonSize } from 'src/components/button/Button';
import ConnectorForm from './components/form/ConnectorForm';
import ConnectorYamlEditor from './components/yaml-editor/ConnectorYamlEditor';

// Types
import { RequestStatus } from 'src/redux/slices/types';
// Utils
import stringifyStateError from 'src/utils/stringifyStateError';
import { validate, getConnectorDescription } from './utils';

const EditConnectorPage: FC<{ connectorId: string }> = ({ connectorId }) => {
  const { data: connectorFormData, submit, error } = useSelector(
    selectEditConnectorState
  );
  const isSubmitting = submit === RequestStatus.pending;

  const history = useHistory();
  const { t } = useTranslation(['connectors']);
  const dispatch = useDispatch();

  const handleEditConnector = useCallback(
    (data) => {
      const { logo, ...formData } = data;
      const base64 = /^data:image\/\w+;base64,/;
      const isLogoUpdated = !!logo?.file?.match(base64);

      dispatch(
        editConnector({
          id: connectorId,
          connector: getConnectorDescription({
            ...formData,
            ...(!logo
              ? { logo: null }
              : isLogoUpdated
              ? {
                  logo: {
                    ...logo,
                    file: logo.file.replace(base64, ''),
                  },
                }
              : {}),
          }),
        })
      );
    },
    [dispatch, connectorId]
  );

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (error) {
      const message = stringifyStateError(error);

      toastr.error(t('alert.Error update'), message);
    }
  }, [error, t]);

  useEffect(() => {
    if (submit === RequestStatus.fulfilled) {
      toastr.success(t('Success'), t('alert.Success update'));
    }
  }, [dispatch, submit, t]);

  return (
    <Formik
      initialValues={connectorFormData}
      onSubmit={handleEditConnector}
      validate={validate}
      enableReinitialize
    >
      {({ handleSubmit, isValid, dirty }) => (
        <form onSubmit={handleSubmit} className="connector-form">
          <FormActions>
            <Button
              disabled={isSubmitting}
              size={ButtonSize.small}
              onClick={handleCancel}
            >
              {t('buttons.Cancel')}
            </Button>
            <Button
              size={ButtonSize.small}
              loading={isSubmitting}
              disabled={!isValid || isSubmitting || !dirty}
              type="submit"
            >
              {t('buttons.Save')}
            </Button>
          </FormActions>
          <Switch>
            <Route path={`/connectors/${connectorId}/edit/yaml/`}>
              <ConnectorYamlEditor />
            </Route>
            <Route path={`/connectors/${connectorId}/edit/settings/`}>
              <ConnectorForm />
            </Route>
          </Switch>
        </form>
      )}
    </Formik>
  );
};

export default EditConnectorPage;
