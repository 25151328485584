import React, { FC } from 'react';
import { useSelector } from 'react-redux';

// Components
import ConfigDrawer from './ConfigDrawer';
// import CompanyData from '../../../components/company-data/CompanyData';
// Selectors
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
// import {
//   selectCompaniesListState,
//   // selectCompanyByConnectorId,
// } from '../../../redux/slices/companies/list/selectors';
// import { selectSelectedCompanyState } from '../../../redux/slices/companies/selected-company/selectors';
// Actions
// import { selectCompany } from '../../../redux/slices/companies/selected-company/selectedCompanySlice';
// Utils
import FormVisibilityContextProvider from '../../../components/dynamic-layout/form-visibility-context/FormVisibilityContextProvider';
// Types
// import { RequestStatus } from '../../../redux/slices/types';
import { IIntegrationConfig } from '../types';

const IntegrationConfig: FC = () => {
  const { data } = useSelector(selectConnectorState);
  // const { loading } = useSelector(selectCompaniesListState);
  // const isLoading = loading === RequestStatus.pending;
  // const companyData = useSelector(selectCompanyByConnectorId(data?.nid));
  // const selectedCompany = useSelector(selectSelectedCompanyState);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!selectedCompany && !isLoading && data && companyData) {
  //     dispatch(selectCompany(companyData));
  //   }
  // }, [selectedCompany, isLoading, data, companyData, dispatch]);

  return (
    <div className="config-button-container">
      <FormVisibilityContextProvider>
        {data && <ConfigDrawer connector={data} />}
      </FormVisibilityContextProvider>
      {/* <CompanyData loading={isLoading || !selectedCompany} /> */}
    </div>
  );
};

export default IntegrationConfig;

export const mapIntegrationConfigProps = (props: IIntegrationConfig) => props;
