import yup from '../../translations/yup/yupLocale';
import i18n from '../../i18n';
// Types
import { EConnectorAuthTypes } from '../../types/internal-Types';
import { validateTemplateString } from 'src/components/expression-input/utils';

const expressionSchema = yup
  .string()
  .trim()
  .test('isValidVar', i18n.t('connectors:validation:Invalid variable'), function (value) {
    return validateTemplateString(value, undefined, (varName) =>
      // @ts-ignore
      this.options.context?.form.find((field) => field.key === varName)
    );
  });

const validationSchema = yup.object({
  title: yup.string().required().label(i18n.t('connectors:inputs:Connector Name')),
  description: yup.string().label(i18n.t('connectors:inputs:Description')),
  logo: yup
    .object({
      file: yup.string().label(i18n.t('connectors:inputs:Connector Logo')),
    })
    .nullable(),
  default_namespace: yup
    .string()
    .default(function () {
      // @ts-ignore
      return this.parent.title.replace(/\s/, '_');
    })
    .label(i18n.t('connectors:inputs:Default namespace')),
  namespace: yup
    .string()
    .default(function () {
      // @ts-ignore
      return this.parent.title.replace(/\s/, '_');
    })
    .label(i18n.t('connectors:inputs:Namespace')),
  form: yup.array().of(
    yup.object({
      type: yup.string().required().label(i18n.t('connectors:inputs:Type')),
      label: yup.string().required().label(i18n.t('connectors:inputs:Label')),
      key: yup.string().required().label(i18n.t('connectors:inputs:Key')),
    })
  ),
  securitySchemes: yup
    .array()
    .of(
      yup.object({
        title: yup
          .string()
          .required()
          .label(i18n.t('connectors:inputs:Scheme name'))
          .test('unique', i18n.t('connectors:validation:Unique scheme name'), function (
            value
          ) {
            return (
              // @ts-ignore
              this.options?.context?.securitySchemes?.filter(({ name }) => value === name)
                .length < 2
            );
          }),
        type: yup
          .string()
          .required()
          .oneOf([
            EConnectorAuthTypes.api_key,
            EConnectorAuthTypes.basic_auth,
            EConnectorAuthTypes.oauth2,
            EConnectorAuthTypes.bearer,
          ])
          .label(i18n.t('connectors:inputs:Authentication type')),
        in: yup
          .string()
          .when('type', {
            is: EConnectorAuthTypes.api_key,
            then: yup.string().required(),
          })
          .label(i18n.t('connectors:inputs:In')),

        // For API key type
        name: yup
          .string()
          .when('type', {
            is: EConnectorAuthTypes.api_key,
            then: expressionSchema.required(),
          })
          .label(i18n.t('connectors:inputs:Key')),
        value: yup
          .string()
          .when('type', {
            is: EConnectorAuthTypes.api_key,
            then: expressionSchema.required(),
          })
          .when('type', {
            is: EConnectorAuthTypes.bearer,
            then: expressionSchema.required(),
          })
          .label(i18n.t('connectors:inputs:Value')),

        // For Basic type
        username: yup
          .string()
          .when('type', {
            is: EConnectorAuthTypes.basic_auth,
            then: expressionSchema.required(),
          })
          .label(i18n.t('connectors:inputs:Username')),
        password: yup
          .string()
          .when('type', {
            is: EConnectorAuthTypes.basic_auth,
            then: expressionSchema.required(),
          })
          .label(i18n.t('connectors:inputs:Password')),
      })
    )
    .required(),
  security: yup
    .array()
    .of(yup.string())
    .required(i18n.t('connectors:validation:Global scheme required')),
  servers: yup
    .array()
    .of(
      yup.object({
        url: yup.string().url().required().label(i18n.t('connectors:inputs:Url')),
      })
    )
    .required(),
  endpoints: yup
    .array()
    .of(
      yup.object({
        path: yup.string().required().label(i18n.t('connectors:inputs:Path')),
        requests: yup
          .array()
          .of(
            yup.object({
              method: yup.string().required().label(i18n.t('connectors:inputs:Method')),
              tags: yup.array().of(yup.string()).label(i18n.t('connectors:inputs:Tags')),
              operationId: yup
                .string()
                .required()
                .label(i18n.t('connectors:inputs:Operation id'))
                .test(
                  'unique',
                  i18n.t('connectors:validation:Unique operation id'),
                  function (value) {
                    return (
                      // @ts-ignore
                      this.options?.context?.endpoints?.reduce((result, endpoint) => {
                        return (
                          result +
                          endpoint.requests.filter(
                            ({ operationId }) => value === operationId
                          ).length
                        );
                      }, 0) < 2
                    );
                  }
                ),
              responses: yup
                .array()
                .of(
                  yup.object({
                    statusCode: yup
                      .number()
                      .required()
                      .label(i18n.t('connectors:inputs:Status code')),
                    description: yup
                      .string()
                      .required()
                      .label(i18n.t('connectors:inputs:Response description')),
                  })
                )
                .required(),
              parameters: yup.array().of(
                yup.object({
                  name: yup
                    .string()
                    .required()
                    .label(i18n.t('connectors:inputs:Parameter name')),
                  in: yup.string().required(),
                  required: yup.boolean().required().default(false),
                })
              ),
              requestBody: yup.object({
                content: yup.array().of(
                  yup.object({
                    type: yup
                      .string()
                      .required()
                      .label(i18n.t('connectors:inputs:Content type')),
                  })
                ),
              }),
              security: yup
                .array()
                .of(yup.string())
                .label(i18n.t('connectors:inputs:Security schemes')),
              useGlobalSecurity: yup
                .boolean()
                .label(i18n.t('connectors:inputs:Use global authentication')),
            })
          )
          .required(),
      })
    )
    .required(),
});

export default validationSchema;
