import { NonIdealState } from '@blueprintjs/core';
import React, { FC } from 'react';
import CustomScrollbars from '../../../custom-scrollbars/CustomScrollbars';
import { useSelector } from 'react-redux';
// Icons
import compPic from '../../../../images/companies.svg';
import compBg from '../../../../images/companiesBG.svg';
import { selectCompaniesListState } from '../../../../redux/slices/companies/list/selectors';
import { RequestStatus } from '../../../../redux/slices/types';
import { getRandomArray } from '../../../../utils/getRandom';
// Components
import CompanyTeaserMedium from '../CompanyMain/CompanyTeaserMedium';
// Styles
import './styles.scss';

const CompanyListTeaserMedium: FC = () => {
  const { data, loading } = useSelector(selectCompaniesListState);

  const isLoading = loading === RequestStatus.pending;

  if (!isLoading && !data.length) {
    return (
      <div className="non-ideal-state-container">
        <div className="companies-list-divider" />
        <NonIdealState icon="error" title="No companies found" />
      </div>
    );
  }

  return (
    <div className="companies-list">
      <div className="background-picture">
        <img src={compBg} alt="" />
      </div>

      <div className="iconAccount">
        <img src={compPic} alt="" />
      </div>

      <CustomScrollbars autoHeight autoHeightMax={'50vh'} style={{ width: '100%' }}>
        <div className="form-holder">
          <div className="company-list-teaser-medium">
            {!isLoading &&
              data.map((companyData, key) => (
                <CompanyTeaserMedium company={companyData} key={key} />
              ))}
            {/* Skeleton */}
            {isLoading &&
              getRandomArray(3).map((id) => (
                <div className="company-list-medium-skeleton bp3-skeleton" key={id} />
              ))}
          </div>
        </div>
      </CustomScrollbars>
    </div>
  );
};

export default CompanyListTeaserMedium;
