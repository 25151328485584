import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { H4 } from '@blueprintjs/core';
import CodeEditor from '../../../../components/code-editor/CodeEditor';

interface ITestResultProps {
  data: object;
}

const TestResult: FC<ITestResultProps> = ({ data }) => {
  const testingResultString = useMemo(() => JSON.stringify(data, undefined, 2), [data]);
  const { t } = useTranslation(['connectors']);

  return (
    <div className="connector-form-section --border connector-test-result">
      <H4 className="connector-section-title">{t('titles.Result')}</H4>
      <CodeEditor value={testingResultString} mode="json" readOnly />
    </div>
  );
};

export default TestResult;
