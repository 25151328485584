import React, { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { Checkbox, FormGroup } from '@blueprintjs/core';
// Types
import { RequestStatus } from '../../../../../redux/slices/types';
import { SignupStep2 } from '../../../../../services/AuthService';
// Actions
import { submitSignup } from '../../../../../redux/slices/auth/signup/thunk';
// Selectors
import { selectSignupState } from '../../../../../redux/slices/auth/signup/selectors';
// Components
import Input from '../../../../../components/input/Input';
import Password from '../../../../../components/password/Password';
import Button from '../../../../../components/button/Button';
// Validation schema
import validationSchema from './validationSchema';
// Assets
import Profile from '../../../../../images/profile.svg';

interface SignupStep2Form extends SignupStep2 {
  agreement: boolean;
}

const Step2: FC = () => {
  const {
    submit,
    currentStep,
    registration_key,
    company,
    textLoading,
    text,
    first_name: firstName,
    last_name: lastName,
  } = useSelector(selectSignupState);

  const { t } = useTranslation(['signup']);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    await dispatch(submitSignup(data));
  };

  const initialValues: SignupStep2Form = {
    first_name: firstName,
    last_name: lastName,
    company_name: company?.company_name ?? '',
    password: '',
    confirm_password: '',
    step: currentStep,
    registration_key,
    agreement: false,
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    validateForm,
  } = useFormik<SignupStep2Form>({
    initialValues,
    validationSchema,
    initialTouched: {
      first_name: !!firstName,
      last_name: !!lastName,
      company_name: !!company?.company_name,
    },
    onSubmit,
  });

  const isSubmitDisabled = submit === RequestStatus.pending || !isValid;

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <Row>
      <Col md={6}>
        <div className="padding-md">
          {textLoading !== RequestStatus.pending && <h1>{t('titles.Almost ready')}</h1>}
          {textLoading === RequestStatus.pending && (
            <div className="bp3-skeleton heading-skeleton" />
          )}
          {textLoading !== RequestStatus.pending && (
            <p>{text?.left_text ?? t('titles.Just add your name')}</p>
          )}
          {textLoading === RequestStatus.pending && (
            <div className="bp3-skeleton description-skeleton" />
          )}

          {textLoading !== RequestStatus.pending && (
            <div className="profile padding-xs margin-top-xs">
              <img
                className="img-responsive"
                alt="profile-img"
                title={t('img.Profile')}
                src={Profile}
              />
            </div>
          )}
          {textLoading === RequestStatus.pending && (
            <div className="bp3-skeleton image-skeleton margin-top-xs" />
          )}
        </div>
      </Col>
      <Col md={6}>
        <form onSubmit={handleSubmit}>
          <div className="padding-md">
            <Input
              loading={textLoading === RequestStatus.pending}
              onChange={handleChange}
              name="first_name"
              placeholder={t('inputs.First Name')}
              validationError={touched.first_name && errors.first_name}
              value={values.first_name}
              onBlur={handleBlur}
            />
            <Input
              loading={textLoading === RequestStatus.pending}
              onChange={handleChange}
              name="last_name"
              placeholder={t('inputs.Last Name')}
              validationError={touched.last_name && errors.last_name}
              value={values.last_name}
              onBlur={handleBlur}
            />
            <Input
              loading={textLoading === RequestStatus.pending}
              onChange={handleChange}
              name="company_name"
              placeholder={t('inputs.Company Name')}
              validationError={touched.company_name && errors.company_name}
              value={values.company_name}
              onBlur={handleBlur}
            />
            <Password
              loading={textLoading === RequestStatus.pending}
              onChange={handleChange}
              name="password"
              placeholder={t('inputs.Password')}
              validationError={touched.password && errors.password}
              value={values.password}
              onBlur={handleBlur}
            />
            <Password
              loading={textLoading === RequestStatus.pending}
              onChange={handleChange}
              name="confirm_password"
              placeholder={t('inputs.Password')}
              validationError={touched.confirm_password && errors.confirm_password}
              value={values.confirm_password}
              onBlur={handleBlur}
            />

            {textLoading !== RequestStatus.pending && (
              <div className="help-block margin-bottom-xs agreement">
                <FormGroup helperText={touched.agreement && errors.agreement}>
                  <Checkbox
                    onChange={handleChange}
                    name="agreement"
                    checked={values.agreement}
                    onBlur={handleBlur}
                    large
                    className="checkbox-styles"
                    inline
                    labelElement={
                      <small className="terms-and-conditions">
                        {t('titles.I agree')}{' '}
                        <a
                          href="https://wedoio.dk/terms-conditions-wedoio/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('links.Terms and Conditions')}
                        </a>{' '}
                        {t('titles.and')}{' '}
                        <a
                          href="https://wedoio.net/eula-end-user-license-agreement/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('links.EULA')}
                        </a>
                        .
                      </small>
                    }
                  />
                </FormGroup>
              </div>
            )}
            {textLoading === RequestStatus.pending && (
              <div className="bp3-skeleton help-block-skeleton" />
            )}

            {textLoading !== RequestStatus.pending && (
              <Button
                type="submit"
                disabled={isSubmitDisabled}
                loading={submit === RequestStatus.pending}
              >
                {t('buttons.Sign Up')}
              </Button>
            )}
            {textLoading === RequestStatus.pending && (
              <div className="bp3-skeleton button-skeleton" />
            )}
          </div>
        </form>
      </Col>
    </Row>
  );
};

export default Step2;
