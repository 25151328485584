import React, { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
// Styles
import './App.scss';
import './css/global.scss';

// App Routes
import Routes from './routes/Routes';
// Utils
import { getByKey, LanguagesKeys } from './redux/slices/language/languages';
import { getQueryParameterByName } from './utils/genUtils';
import useLanguage, { LANGUAGE_PARAM_NAME } from './utils/useLanguage';

// Intercom
// import { INTERCOM_APP_ID } from './services/IntercomAPIService';
// import { IntercomProvider } from 'react-use-intercom';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App: FC = () => {
  const { setLanguage, selectedLanguage } = useLanguage();

  const location = useLocation();

  const langFromQueryParam = useMemo(() => {
    const param = getQueryParameterByName(LANGUAGE_PARAM_NAME, location.search);
    return typeof param === 'string' ? getByKey(param as LanguagesKeys) : undefined;
  }, [location]);

  useEffect(() => {
    if (langFromQueryParam && langFromQueryParam?.Key !== selectedLanguage.Key) {
      setLanguage(langFromQueryParam);
    }
  }, [langFromQueryParam, selectedLanguage.Key, setLanguage, location]);

  return (
    // <IntercomProvider appId={INTERCOM_APP_ID}>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Routes />
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryCacheProvider>

    // </IntercomProvider>
  );
};

export default App;
