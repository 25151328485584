import componentsMap from '../../components/dynamic-layout/components/componentsMap';

// Components
import CompaniesIntegrationsInfo, {
  mapCompaniesIntegrationsInfoProps,
} from './CompaniesIntegrationsInfo';

import MarketplaceBrowser, {
  mapMarketplaceBrowserProps,
} from './marketplace/MarketplaceBrowser';

import HomeNotifications, {
  mapHomeNotifications,
} from './notifications/HomeNotifications';

import HomeConnectorsList, {
  mapHomeConnectorsListProps,
} from './connectors/HomeConnectorsList';

export enum HomepageTypes {
  CompaniesIntegrationsInfo = 'companies-integrations-info',
  MarketplaceBrowser = 'marketplace-browser',
  HomeNotifications = 'home-notifications',
  ConnectorsList = 'connectors-list',
}

export interface ICompaniesIntegrationsInfo {
  type: HomepageTypes.CompaniesIntegrationsInfo;
}

export interface IMarketplaceBrowser {
  type: HomepageTypes.MarketplaceBrowser;
}

export interface IHomeNotifications {
  type: HomepageTypes.HomeNotifications;
}

export interface IHomeConnectorsList {
  type: HomepageTypes.ConnectorsList;
}

const myComponentsMap = new Map(componentsMap);

myComponentsMap.set(HomepageTypes.CompaniesIntegrationsInfo, [
  CompaniesIntegrationsInfo,
  mapCompaniesIntegrationsInfoProps,
]);
myComponentsMap.set(HomepageTypes.MarketplaceBrowser, [
  MarketplaceBrowser,
  mapMarketplaceBrowserProps,
]);
myComponentsMap.set(HomepageTypes.HomeNotifications, [
  HomeNotifications,
  mapHomeNotifications,
]);

myComponentsMap.set(HomepageTypes.ConnectorsList, [
  HomeConnectorsList,
  mapHomeConnectorsListProps,
]);

export default myComponentsMap;
