import { INotification } from '../types/internal-Types';
import { jsTimeToString } from '../utils/genUtils';
import BaseApiService, { RequestType } from './BaseApiService';
import {
  IGetNotificationsResult,
  IGetNotificationsActionPayload,
} from '../redux/slices/notifications/table/types';

const formatNotificationsData = (notifications): INotification[] =>
  notifications.map(({ id, read, title, type, description, time, meta }) => ({
    id,
    read,
    title,
    description,
    date: jsTimeToString(time) ?? '',
    timestamp: time,
    type,
    link: meta.slug,
    task_id: meta.task_id,
  }));

class NotificationsService extends BaseApiService {
  get = async ({
    userId,
    page,
    limit,
    ...params
  }: IGetNotificationsActionPayload): Promise<IGetNotificationsResult> => {
    const { data } = await this.request({
      url: userId,
      type: RequestType.put,
      data: {
        action: 'fetch',
        input: {
          page,
          limit,
          query: {
            ...params,
          },
        },
      },
    });

    const notifications = formatNotificationsData(data.notifications);

    return { data: { notifications, total: data.total } };
  };

  update = async (ids: string[], userId: string, read: boolean) => {
    const query = { ...(ids.length ? { id_in: ids } : {}) };
    const { data } = await this.request({
      url: userId,
      type: RequestType.put,
      data: {
        action: 'update',
        input: {
          query,
          data: {
            read,
          },
        },
      },
    });
    return {
      data: ids.length ? formatNotificationsData(data.notifications) : [],
      total: data.total,
    };
  };

  delete = async (ids: string[], userId: string, read?: boolean) => {
    const query = {
      ...(ids.length ? { id_in: ids } : {}),
      ...(read !== undefined ? { read } : {}),
    };
    const { data } = await this.request({
      url: userId,
      type: RequestType.put,
      data: {
        action: 'delete',
        input: {
          query,
        },
      },
    });
    return { data: ids.length ? data.query.id.$in : [] };
  };
}

export default new NotificationsService('rest/notifications');
