import React, { FC } from 'react';
interface ISignupForm {
  children: any;
}

const SignupFrom: FC<ISignupForm> = ({ children }) => {
  return (
    <div className="signup-form">
      <div className="signup-form-content">{children}</div>
    </div>
  );
};

export default SignupFrom;
