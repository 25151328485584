import { stringify } from 'query-string';
import { getQueryParameters } from '../../utils/genUtils';

export const getRedirectSearchParams = (search: string) => {
  const {
    language,
    integration,
    email,
    cvr,
    company,
    first_name,
    last_name,
    ...rest
  } = getQueryParameters(search);

  return `?${stringify(rest)}`;
};
