import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import UserConnectorsService from '../../../../services/UserConnectorsService';

// Types
import {
  CreateConnectorActionPayload,
  CreateConnectorActionSuccessResult,
} from './types';

export const createConnector = createAsyncThunk<
  CreateConnectorActionSuccessResult,
  CreateConnectorActionPayload
>('connectors/create/createConnector', async (connector, { rejectWithValue }) => {
  try {
    const { data } = await UserConnectorsService.create(connector);

    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    return rejectWithValue(error.response);
  }
});
