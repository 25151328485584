import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
// Components
import Input from '../../../../../../components/input/Input';
import { Icon } from '@blueprintjs/core';
// Types
import {
  CreateConnectorFormData,
  IRequestContentType,
} from '../../../../../../types/internal-Types';
import { getHelperText } from '../../../../utils';
// Style
import './style.scss';

interface IContentTypeItemProps {
  index: number;
  data: IRequestContentType;
  path: string;
  onRemove: (index: number) => void;
}

const ContentTypeItem: FC<IContentTypeItemProps> = ({ data, path, index, onRemove }) => {
  const { handleBlur, handleChange, touched, errors } = useFormikContext<
    CreateConnectorFormData
  >();
  const { t } = useTranslation(['connectors']);

  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [onRemove, index]);
  return (
    <div className="content-type-item">
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={data?.type ?? ''}
        name={`${path}.type`}
        placeholder={t('inputs.Content type')}
        validationError={getHelperText(touched, errors, `${path}.type`)}
      />

      <div className="content-type-item-controls">
        <Icon icon="trash" onClick={handleRemove} />
      </div>
    </div>
  );
};

export default ContentTypeItem;
