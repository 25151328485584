import React, { FC } from 'react';
import { Card } from '@blueprintjs/core';
import { Col, Row } from 'react-bootstrap';
// TYpes
import { ICompaniesIntegrationsInfo } from './componentsMap';
// Components
import CompanyIntegrations from '../../components/Wedoio/Company/CompanyIntegrations/CompanyIntegrations';
import CompanyListTeaser from '../../components/Wedoio/Company/CompanyList/CompanyListTeaser';

const CompaniesIntegrationsInfo: FC = () => {
  return (
    <Row className={'main-row'}>
      <Col className="sidebar companies-sidebar" md={4}>
        <CompanyListTeaser />
      </Col>
      <Col className="main" md={9}>
        <Card>
          <CompanyIntegrations />
        </Card>
      </Col>
    </Row>
  );
};

export const mapCompaniesIntegrationsInfoProps = (props: ICompaniesIntegrationsInfo) =>
  props;

export default CompaniesIntegrationsInfo;
