import { FC, useState, useEffect, useRef } from 'react';
import * as React from 'react';
import { Button } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import GenericSelector, {
  IValueKey,
} from 'src/components/generic-selector/GenericSelector';
import Panel from '../../../components/panel/Panel';
import CustomSwitch from '../../../components/custom-switch/CustomSwitch';
import DatePicker from '../../../components/date-range-picker/DateRangePicker';
// Icons
import BackupIcon from '../../../components/icons/BackupIcon';
// Actions
import {
  setDateRange,
  setSelectedAction,
  setSelectedState,
} from '../../../redux/slices/dashboard/logs/logsSlice';
// Types
import { EState } from '../../../types/api-Types';
// Utils
import { getMilliseconds } from '../../../utils/genUtils';
import useTaskStates from '../utils/useTaskStates';
import useConnectorActions from '../utils/useConnectorActions';

export interface IDashboardPanelProps {
  onUpdate: () => void;
  selectedAction?: string;
  selectedState?: EState;
}

const DashboardPanel: FC<IDashboardPanelProps> = ({
  onUpdate,
  selectedAction,
  selectedState,
}) => {
  const { actions } = useConnectorActions();
  const states = useTaskStates();

  const [autoUpdate, setAutoUpdate] = useState<boolean>(false);

  const { t } = useTranslation(['dashboard']);

  const createOption = (Key: string, Value: string = t('selects.minutes')) => ({
    Key,
    Value: `${Key} ${Value}`,
  });

  const intervalOptions = [createOption('5'), createOption('10'), createOption('30')];

  const [selectedInterval, setUpdateInterval] = useState<IValueKey>(intervalOptions[0]);

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  const dispatch = useDispatch();

  // Handlers
  const handleActionSelect = (value: IValueKey[]) => {
    const [{ Key } = { Key: undefined }] = value;

    dispatch(setSelectedAction(Key));
  };

  const handleStateSelect = (value: IValueKey[]) => {
    const [{ Key } = { Key: undefined }] = value;

    dispatch(setSelectedState(Key as EState));
  };

  const handleDateSelect = (date) => {
    dispatch(setDateRange(date));
  };

  const handleAutoUpdateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setAutoUpdate(checked);
  };

  const handleIntervalChange = (data: IValueKey[]) => {
    const [intervalData] = data;

    setUpdateInterval(intervalData?.Key ? intervalData : intervalOptions[0]);
  };

  const removeInterval = () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  useEffect(() => {
    removeInterval();

    if (autoUpdate) {
      interval.current = setInterval(() => {
        onUpdate();
      }, getMilliseconds(+selectedInterval.Key));
    }
  }, [autoUpdate, selectedInterval, onUpdate]);

  // Component unmount
  useEffect(() => removeInterval, []);

  const action = React.useMemo(
    () => actions.filter((option) => option.Key === selectedAction),
    [selectedAction, actions]
  );
  const state = React.useMemo(
    () => states.filter((option) => option.Key === selectedState),
    [selectedState, states]
  );

  return (
    <Panel>
      {/* Actions Selector */}
      <GenericSelector
        selectorText={t('inputs.All Actions')}
        isMultiSelector={false}
        onValueChanged={handleActionSelect}
        items={actions}
        selectedItem={action}
      />

      {/* Task Status Selector */}
      <GenericSelector
        selectorText={t('inputs.All States')}
        isMultiSelector={false}
        onValueChanged={handleStateSelect}
        items={states}
        selectedItem={state}
      />

      {/* Datepicker */}
      <DatePicker onChange={handleDateSelect} />

      {/* Actions */}
      <Button className="action-button" icon={<BackupIcon />} onClick={onUpdate} />
      <CustomSwitch
        name={t('inputs.Auto Refresh')}
        onChange={handleAutoUpdateChange}
        checked={autoUpdate}
      />
      <GenericSelector
        isMultiSelector={false}
        items={intervalOptions}
        selectorText={t('inputs.Refresh interval')}
        onValueChanged={handleIntervalChange}
        selectedItem={selectedInterval ? [selectedInterval] : []}
      />
    </Panel>
  );
};

export default DashboardPanel;
