import React, { FC } from 'react';
// Components
import HooksWidget from './components/HooksWidget';
// Utils
import useHooksWidgetData from './useHooksWidgetData';
import { RequestStatus } from 'src/redux/slices/types';
import { IWebcrmHooksManager } from '../../types';
// Types
import { IHookEntity } from '../../../../types/api-Types';
const HOOKS_WIDGET_KEY = 'webcrm_hooks_widget'; // maybe HooksWidget will be re-used for different integrations

const WebcrmHooksManager: FC = () => {
  const { data, loading } = useHooksWidgetData(HOOKS_WIDGET_KEY);
  return (
    <HooksWidget
      loading={loading === RequestStatus.pending}
      widgetKey={HOOKS_WIDGET_KEY}
      data={data as IHookEntity}
    />
  );
};

export const mapWebcrmHooksManagerProps = (props: IWebcrmHooksManager) => ({ ...props });

export default WebcrmHooksManager;
