import { FC } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// Types
import { IConnectorDescription } from 'src/types/api-Types';
// Components
import ConnectorLogo from '../logo/ConnectorLogo';
import { Card, Icon } from '@blueprintjs/core';

export interface IConnectorTeaserProps {
  connector: IConnectorDescription;
}

const ConnectorTeaser: FC<IConnectorTeaserProps> = ({ connector }) => {
  const history = useHistory();

  const handleEditConnector = () => {
    history.push(`/connectors/${connector.nid}/edit/settings`);
  };

  return (
    <Card onClick={handleEditConnector} interactive className="connector-teaser">
      <div style={{ textAlign: 'center' }}>
        <ConnectorLogo
          name={connector.info.title}
          src={connector.info.logo?.teaser ?? ''}
        />
      </div>
      <div className="connector-title">
        <span>{connector.info.title}</span>
      </div>
      <div className="connector-actions">
        <Icon icon="more" />
        <span className="connector-nid">#{connector.nid}</span>
      </div>
    </Card>
  );
};

export default ConnectorTeaser;
