import { createAsyncThunk } from '@reduxjs/toolkit';
import RelationshipManagerService from '../../../../services/LinksManagerService';
import {
  IGetLinksSuccessResult,
  IGetLinkTypesSuccessResult,
  IGetEditFromSuccessResult,
  IGetLinksActionPayload,
  IGetEditFormActionPayload,
  IUpdateLinksActionPayload,
  ISubmitEditFormActionPayload,
} from './types';

export const getLinks = createAsyncThunk<IGetLinksSuccessResult, IGetLinksActionPayload>(
  'dashboard.links/getLinks',
  async (params, { rejectWithValue }) => {
    try {
      const { links, totalCount, error } = await RelationshipManagerService.getLinks(
        params
      );

      if (error) {
        return rejectWithValue(error);
      }

      return {
        data: {
          links,
          totalCount,
        },
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const updateLinks = createAsyncThunk<null, IUpdateLinksActionPayload>(
  'dashboard.links/updateLinks',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await RelationshipManagerService.updateLinks(params);

      if (data?.output?.error) {
        return rejectWithValue(data.output.error);
      }

      return null;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const getTypeOptions = createAsyncThunk<IGetLinkTypesSuccessResult, string>(
  'dashboard.links/getTypeOptions',
  async (connectorId, { rejectWithValue }) => {
    try {
      const {
        options,
        error,
        linksTypes,
        forms,
      } = await RelationshipManagerService.getLinksTypeOptions(connectorId);

      if (error) {
        return rejectWithValue(error);
      }

      return { typeOptions: options, linksTypes, forms };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const getEditForm = createAsyncThunk<
  IGetEditFromSuccessResult,
  IGetEditFormActionPayload
>(
  'dashboard.links/getEditForm',
  async ({ connectorId, linkId, link_type, columns }, { rejectWithValue }) => {
    try {
      const { data } = await RelationshipManagerService.getEditForm({
        connectorId,
        linkId,
        link_type,
        columns,
      });

      if (data.output.error) {
        return rejectWithValue(data.output.error);
      }

      return { form: data.output };
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const submitForm = createAsyncThunk<{}, ISubmitEditFormActionPayload>(
  'dashboard.links/submitForm',
  async ({ callback, data, connectorId }, { rejectWithValue }) => {
    try {
      const { data: response } = await RelationshipManagerService.submitForm(
        callback,
        data,
        connectorId
      );

      if (response.output?.error) {
        return rejectWithValue(response.output.error);
      }

      if (response.output?.errors && response.output?.errors?.length) {
        return rejectWithValue(response.output.errors);
      }
      return;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response);
    }
  }
);
