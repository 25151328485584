import React, { FC, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card, Spinner } from '@blueprintjs/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import LinksTable from './links-table/LinksTable';
import SearchForm from '../../../components/search-field/SearchForm';
import LinksEditForm from './LinksEditForm';
import GenericSelector, {
  IValueKey,
} from '../../../components/generic-selector/GenericSelector';
// Selectors
import {
  selectLinkTypesOptions,
  selectLinks,
} from '../../../redux/slices/dashboard/links/selectors';
import { selectConnectorState } from '../../../redux/slices/dashboard/connector/selectors';
// Actions
import {
  setSelectedType,
  setCurrentPage,
  setShearchKeyword,
  clearSelectedLinks,
  LINKS_PER_PAGE,
  reset,
} from '../../../redux/slices/dashboard/links/linksSlice';
import {
  getTypeOptions,
  getLinks,
  updateLinks,
  getEditForm,
} from '../../../redux/slices/dashboard/links/thunks';

// Types
import { EMassActions } from '../../../redux/slices/dashboard/links/types';
import { RequestStatus } from 'src/redux/slices/types';
import { ILinksManager } from '../types';
// Styles
import './styles.scss';

const RelationshipManager: FC = () => {
  const options = useSelector(selectLinkTypesOptions);
  const {
    selectedType,
    optionsLoading,
    currentPage,
    selectedAction,
    selectedLinks,
    isSelectAll,
    searchKeyword,
    sortBy,
    sortDirection,
  } = useSelector(selectLinks);

  const { data: connector } = useSelector(selectConnectorState);

  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard', 'dynamicForm']);

  const loading = optionsLoading === RequestStatus.pending;

  const fetchLinks = useCallback(() => {
    dispatch(clearSelectedLinks());
    if (connector && connector.nid && selectedType) {
      dispatch(
        getLinks({
          connectorId: connector.nid,
          page: currentPage,
          limit: LINKS_PER_PAGE,
          link_type: selectedType?.Key,
          keyword: searchKeyword,
          sortBy,
          sortDirection,
        })
      );
    }
  }, [
    dispatch,
    connector,
    currentPage,
    selectedType,
    searchKeyword,
    sortBy,
    sortDirection,
  ]);

  const onSubmitForm = useCallback(async () => {
    await dispatch(setCurrentPage(1));
    fetchLinks();
  }, [fetchLinks, dispatch]);

  // Handlers
  const handleSelectType = useCallback(
    ([item]: IValueKey[]) => {
      if (item) {
        dispatch(setSelectedType(item));
      }
    },
    [dispatch]
  );

  const handleSearchFormSubmit = useCallback(
    (value) => {
      dispatch(setShearchKeyword(value));
    },
    [dispatch]
  );

  const handleLinkDelete = useCallback(
    async (ids: string[] = []) => {
      if (ids.length && connector?.nid) {
        await dispatch(
          updateLinks({
            connectorId: connector.nid,
            linkIds: ids,
            action: EMassActions.delete,
          })
        );
        if (currentPage !== 1) {
          await dispatch(setCurrentPage(1));
        } else {
          fetchLinks();
        }
      }
    },
    [connector, dispatch, currentPage, fetchLinks]
  );

  const handleLinksUpdate = useCallback(async () => {
    if (connector?.nid) {
      await dispatch(
        updateLinks({
          connectorId: connector.nid,
          linkIds: isSelectAll ? [] : selectedLinks,
          action: selectedAction ?? '',
          link_type: selectedType?.Key,
          keyword: searchKeyword,
        })
      );
    }
    if (currentPage !== 1) {
      await dispatch(setCurrentPage(1));
    } else {
      fetchLinks();
    }
  }, [
    selectedAction,
    isSelectAll,
    searchKeyword,
    selectedType,
    selectedLinks,
    dispatch,
    connector,
    currentPage,
    fetchLinks,
  ]);

  const handleLinkEdit = useCallback(
    async ({ id, ...columns }) => {
      if (connector?.nid && selectedType) {
        dispatch(
          getEditForm({
            connectorId: connector.nid,
            linkId: id,
            link_type: selectedType?.Key,
            columns,
          })
        );
      }
    },
    [connector, selectedType, dispatch]
  );

  // Effects
  useEffect(() => {
    if (connector?.nid) {
      dispatch(getTypeOptions(connector.nid));
    }
  }, [connector, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedType) {
      fetchLinks();
    }
  }, [connector, currentPage, dispatch, fetchLinks, selectedType, searchKeyword]);

  return (
    <Col sm={12} className="links-manager-container">
      <Card className="shadow-card links-manager">
        <Row className="links-manager-search-params">
          <Col className="search-links" md={5} sm={12}>
            <SearchForm onSubmit={handleSearchFormSubmit} initialValue={searchKeyword} />
          </Col>
          <div className="pull-right links-manager-options">
            {!loading && selectedType && (
              <GenericSelector
                disabled={!options?.length}
                selectorText={t('inputs.Link Type')}
                selectedItem={selectedType ? [selectedType] : undefined}
                isMultiSelector={false}
                items={options}
                onValueChanged={handleSelectType}
              />
            )}
            {!selectedType && (
              <>
                {loading && <Spinner className="options-loading" size={20} />}

                <GenericSelector
                  disabled={!options?.length}
                  selectorText={t('inputs.Link Type')}
                  isMultiSelector={false}
                  items={[{ Key: '-', Value: '-' }]}
                  onValueChanged={handleSelectType}
                />
              </>
            )}
          </div>
        </Row>
        <Row hidden={loading} className="links-manager-form">
          <LinksEditForm onSubmit={onSubmitForm} />
        </Row>
        <LinksTable
          onLinkDelete={handleLinkDelete}
          onLinkEdit={handleLinkEdit}
          onLinksUpdate={handleLinksUpdate}
        />
      </Card>
    </Col>
  );
};

export const mapLinksManagerProps = (props: ILinksManager) => props;

export default RelationshipManager;
