import { useTranslation } from 'react-i18next';
import { IValueKey } from '../../../components/generic-selector/GenericSelector';
import { EState } from '../../../types/api-Types';

const useTaskStates = () => {
  const { t } = useTranslation(['dashboard']);
  return Object.entries(EState).map(([Value, Key]) => {
    return { Key, Value: t(`selects.${Value.replace('Task', 'Task ')}`) } as IValueKey;
  });
};

export default useTaskStates;
