import React, { FC, ImgHTMLAttributes, useCallback } from 'react';
// Icons
import iconIntegration from '../../../../../images/icon-integration.svg';

interface IIntegrationLogo extends ImgHTMLAttributes<HTMLImageElement> {}

const IntegrationLogo: FC<IIntegrationLogo> = (props) => {
  const onError = useCallback((e) => {
    e.target.src = iconIntegration;
  }, []);

  return (
    <>
      {props.src ? (
        <img alt="" {...props} onError={onError} />
      ) : (
        <img alt="" {...props} src={iconIntegration} />
      )}
    </>
  );
};

export default IntegrationLogo;
