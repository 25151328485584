import React, { FC, FocusEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DateRange,
  DateRangeInput as BlueprintDateRangeInput,
  TimePrecision,
} from '@blueprintjs/datetime';

// Types
import { Intent } from '@blueprintjs/core/';

// Utils
import formatDateUtil from 'date-fns/format';
import parseDateUtil from 'date-fns/parse';

// Style
import './date-range-input-style.scss';

interface IDatePickerProps {
  onChange: (selectedDates: DateRange) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: DateRange;
  format?: string;
  className?: string;
  timePrecision?: TimePrecision;
  minDate?: Date;
  maxDate?: Date;
  locale?: string;
  intent?: Intent;
}

const DateRangeInput: FC<IDatePickerProps> = ({
  onChange,
  onBlur,
  value = [null, null],
  format = 'MM-dd-yyyy',
  className,
  timePrecision,
  maxDate,
  minDate,
  locale,
  intent,
}) => {
  const { t } = useTranslation(['dateinput']);

  const handleChange = useCallback(
    (newValue) => {
      onChange(newValue);
    },
    [onChange]
  );

  const formatDate = (date, datelLocale) =>
    formatDateUtil(date, format, { locale: datelLocale });
  const parseDate = (date, datelLocale) =>
    parseDateUtil(date, format, new Date(), { locale: datelLocale });

  return (
    <BlueprintDateRangeInput
      placeholder={format}
      allowSingleDayRange
      shortcuts={false}
      singleMonthOnly
      value={value}
      className={className}
      timePrecision={timePrecision}
      onChange={handleChange}
      locale={locale}
      maxDate={maxDate}
      minDate={minDate}
      formatDate={formatDate}
      parseDate={parseDate}
      invalidDateMessage={t('validation.Invalid date')}
      outOfRangeMessage={t('validation.Out of range')}
      overlappingDatesMessage={t('validation.Overlapping dates')}
      popoverProps={{
        minimal: true,
        popoverClassName: 'date-range-input-popover',
      }}
      startInputProps={{
        className: 'input-styles date-input-textfield --start',
        intent,
        onBlur,
        placeholder: t('inputs.Start date'),
      }}
      endInputProps={{
        className: 'input-styles date-input-textfield --end',
        intent,
        onBlur,
        placeholder: t('inputs.End date'),
      }}
      timePickerProps={
        timePrecision
          ? {
              showArrowButtons: true,
            }
          : undefined
      }
    />
  );
};

export default DateRangeInput;
