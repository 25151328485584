import React, { FC, useCallback, useState } from 'react';
// Components
import MappingResultRow from './MappingResultRow';
import { Collapse, Icon } from '@blueprintjs/core';
import MappingRow from './MappingRow';
import { Row } from 'react-bootstrap';

interface IMappingTableProps {
  onEditValueRequest: (path: string) => void;
  onFieldNameChange: (path: string, newFieldName: string) => void;
  onAddField: (path: string, newFieldName: string) => void;
  onRemoveField: (path: string) => void;
  mapping: object;
}

const MappingTable: FC<IMappingTableProps> = ({
  onAddField,
  onEditValueRequest,
  onFieldNameChange,
  onRemoveField,
  mapping,
}) => {
  const rows = Object.entries(mapping);

  const [showCreateField, setShowCreateField] = useState<boolean>(false);

  const handleAdd = useCallback(
    (newFieldName) => {
      onAddField('', newFieldName);
      setShowCreateField(false);
    },
    [onAddField]
  );

  return (
    <div className="mapping-table">
      <Row className="create-row">
        <Icon
          className="add-field-button"
          icon="add"
          onClick={() => setShowCreateField(true)}
        />
        <Collapse isOpen={showCreateField} className="add-field-collapse">
          <MappingRow
            path=""
            fieldName=""
            onFieldNameChange={handleAdd}
            onCancel={() => {
              setShowCreateField(false);
            }}
          />
        </Collapse>
      </Row>
      {rows.length ? (
        rows.map(([fieldName, templateValue]) => (
          <MappingResultRow
            key={fieldName}
            path={fieldName}
            currentValue={templateValue}
            fieldName={fieldName}
            onEditValueRequest={onEditValueRequest}
            onFieldNameChange={onFieldNameChange}
            onAddField={onAddField}
            onRemoveField={onRemoveField}
          />
        ))
      ) : (
        <MappingResultRow
          path=""
          currentValue=""
          fieldName=""
          onEditValueRequest={onEditValueRequest}
          onFieldNameChange={onFieldNameChange}
          onAddField={onAddField}
          onRemoveField={onRemoveField}
        />
      )}
    </div>
  );
};

export default MappingTable;
