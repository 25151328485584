import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import Button, { ButtonSize } from '../../components/button/Button';
import FormActions from '../../components/form-actions/FormActions';
import useFormVisibilityContext from '../../components/dynamic-layout/form-visibility-context/useFormVisibilityContext';

// tslint:disable-next-line:no-empty-interface
interface IDynamicFormActions {}

const Submit: FC<IDynamicFormActions> = () => {
  const { isValid, isSubmitting } = useFormikContext();
  const { t } = useTranslation(['dashboard']);
  const { toggleIsFormVisible } = useFormVisibilityContext();

  return (
    <FormActions>
      <Button
        disabled={isSubmitting}
        size={ButtonSize.small}
        onClick={() => {
          toggleIsFormVisible(false);
        }}
      >
        {t('buttons.Cancel')}
      </Button>
      <Button
        disabled={isSubmitting || !isValid}
        loading={isSubmitting}
        size={ButtonSize.small}
        type="submit"
      >
        {t('buttons.Save')}
      </Button>
    </FormActions>
  );
};

export const mapDynamicFromActionsProps = () => ({});

export default Submit;
